import { useEffect, useRef } from 'react';
import { useElementSize } from '~/hooks/common/use-element-size';
import useElementSizeStore from '~/stores/elements-size-store';

type UseColumnCountData = {
    ref: React.RefObject<HTMLElement>;
};

export function useColumnCount(): UseColumnCountData {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentDimension = useElementSize(contentRef?.current);
  const { setColumnNumber, setContent } = useElementSizeStore(['setColumnNumber', 'setContent']);

  useEffect(() => {
    if (contentDimension) {
      setColumnNumber?.(Math.floor(contentDimension.width / 224));
    }
  }, [contentDimension, setColumnNumber]);

  useEffect(() => {
    if (contentDimension) {
      setContent?.(contentDimension);
    }
  }, [contentDimension, setContent]);

  return {
    ref: contentRef,
  };
}
