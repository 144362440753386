import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import Dialog from '~/components/ui/overlays/dialog/dialog';
import { trackUploadStore } from '~/stores/track-upload-store';

export function CloseButton() {
  const { isExitDialogOpen, changeExitDialogState, close } = trackUploadStore;
  const { t } = useTranslation('tracks-upload-form');

  function onDiscardAndExitClick() {
    changeExitDialogState(false);
  }

  function onExitClick() {
    close();
  }

  return (
    <Dialog
      isOpen={isExitDialogOpen.value}
      onOpenChange={changeExitDialogState}
      asChild
      containerClassName="bg-dark-300 w-1/2!"
      title={t('header.exit.dialog.title')}
      description={t('header.exit.dialog.description')}
      trigger={<Button className="!bg-dark-100 hover:!bg-dark-200 brief-form__button--exit">{t('header.exit')}</Button>}
    >
      <div className="flex gap-3 items-center mt-4">
        <Button
          className="brief-form__button--discard"
          onClick={onDiscardAndExitClick}
        >
          {t('header.exit.dialog.cancel')}
        </Button>
        <Button
          className="!bg-error-500 hover:!bg-error-400  brief-form__button--save-and-exit"
          onClick={onExitClick}
        >
          {t('header.exit.dialog.exit')}
        </Button>
      </div>
    </Dialog>
  );
}
