import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useEffect, useState } from 'react';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { Artist } from '~/types/schemas/music/artist.schema';
import classNames from 'classnames';
import PresearchBlock from '../../../presearch-block/presearch-block';
import ArtistResult from '../../../artist-result/artist-result';
import EmptyBlock from './empty-block';
import { useTranslation } from 'react-i18next';

type BestArtistResultBlockProps = {
  className?: string;
};

function BestArtistResultBlock(props: BestArtistResultBlockProps) {
  const { className } = props;
  const { artists, isLoading } = useAllTabData();
  const [data, setData] = useState<Artist[] | undefined>(undefined);
  const bestArtist = data?.[0];
  const { handleFocusClick, handleClick } = useCardClick('master_artists');
  const { t } = useTranslation("pre-search");

  useEffect(() => {
    if (!isLoading) {
      setData(artists);
    }
  }, [artists[0]?.id, isLoading]);

  return data ? (
    <PresearchBlock label={t('tab.all.artist.title')} containerClassName={classNames('w-full', className)} childrenClassName="h-56">
      {bestArtist ? (
        <ArtistResult
          name={bestArtist?.full_name || ''}
          artworks={bestArtist.artwork && [bestArtist?.artwork]}
          type={bestArtist?.music_type.map((type) => {
            if (type.includes('core')) return t('music-type.core', { ns: 'common' });
            if (type.includes('production')) return t('music-type.prod', { ns: 'common' });
            return undefined;
          }).filter((type) => type).sort() as string[]}
          onCardClick={() => handleFocusClick(bestArtist?.full_name, bestArtist?.master_artist)}
          onIncludeClick={() => handleClick(bestArtist?.full_name, bestArtist?.master_artist, 'is')}
          onExcludeClick={() => handleClick(bestArtist?.full_name, bestArtist?.master_artist, 'is_not')}
          instagramFollowers={bestArtist?.stats.socials.instagram?.followers}
          tiktokFollowers={bestArtist?.stats.socials.tiktok?.followers}
          spotifyListeners={bestArtist?.stats.spotify?.monthly_listeners}
        />
      ) : <EmptyBlock />}
    </PresearchBlock>
  ) : null;
}

export default BestArtistResultBlock;
