import classNames from 'classnames';
import React, { CSSProperties, MouseEvent, Ref } from 'react';
import { Icon } from '@iconify/react';

type ChipProps = {
  children: React.ReactNode
  className?: string
  color?: string
  iconLeft?: string
  iconRight?: string
  suffix?: string
  outline?: boolean
  style?: CSSProperties
  onClick?: (e: MouseEvent) => void
};

const Chip = React.forwardRef((props: ChipProps, ref: Ref<HTMLButtonElement>) => {
  const {
    children,
    className = '',
    color,
    style = {},
    suffix,
    outline,
    iconLeft,
    iconRight,
    onClick,
  } = props;

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <button
      ref={ref}
      type="button"
      className={classNames(
        'inline-flex text-xs font-semibold items-center h-6 rounded gap-2 leading-none line-clamp-1 outline-none px-2 shrink-0',
        {
          'pr-1': suffix || iconRight,
          'pl-1': iconLeft,
          'border border-white/10 !bg-transparent': outline,
          'cursor-pointer ': onClick,
        },
        className,
      )}
      style={{ ...(color && { backgroundColor: color }), ...style }}
      onClick={handleClick}
      data-no-dnd="true"
    >
      {iconLeft && <Icon icon={iconLeft} />}
      {children}
      {suffix && (
        <span
          className="text-xs  bg-white rounded-full px-1 inline-flex items-center leading-none h-4"
          style={{ color }}
        >
          {suffix}
        </span>
      )}
      {iconRight && <Icon icon={iconRight} />}
    </button>
  );
});

export default Chip;
