import classNames from 'classnames';
import { useState } from 'react';
import useDebounce from '~/hooks/common/use-debounce';
import Filter from './filter';
import { TracklistResult } from './tracklist-result';

const TracklistSearch = (props: { className?: string }) => {
  const { className } = props;
  const [query, setQuery] = useState('');
  const debounceQuery = useDebounce(query, 500);
  const [collections, setCollections] = useState<('briefs' | 'playlists')[]>(['playlists', 'briefs']);

  return (
    <div className={classNames('relative flex flex-1 flex-col items-stretch h-full mr-2 gap-2', className)}>
      <Filter className="pr-6 pl-2" onChange={setQuery} setSelectedFilter={(c) => setCollections(c as ('briefs' | 'playlists')[])} />
      <div className="relative overflow-hidden h-full">
        <TracklistResult
          query={debounceQuery}
          collections={collections}
        />
        <div className="absolute h-4 top-0 w-full bg-gradient-to-b from-dark-600 to-bg-dark-600/0" />
      </div>
    </div>
  );
};

export default TracklistSearch;
