// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { MentionsInput, Mention } from 'react-mentions';
import { activeChannelStore } from '~/stores/chats-store';
import { Channel } from '~/utils/chat/channel';
import { ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { useTranslation } from 'react-i18next';
import { useChatInput } from './use-chat-input';
import UserItem from './mention/user-item';
import './chat-input.styles.scss';
import FileButton from './file/file-button';
import FilePreview from './file/file-preview';

type ThreadInputProps = {
  channel: Channel<ChatMessageContent, ChatMessageMeta>
  className?: string;
};

function ChatInput(props: ThreadInputProps) {
  const { channel, className } = props;
  const { text, files, handleSubmit, handlePresssEnter } = useChatInput(channel);
  const { participants } = activeChannelStore;
  const { t } = useTranslation('brief-chat');

  return (
    <div className={classNames('flex gap-2', className)}>
      <div className="flex-1 rounded-lg bg-dark-200 flex flex-col">
        {files.value?.length ? (
          <div className="grid grid-cols-2 gap-3 p-2 pb-0">
            {files.value?.map((file, i) => (
              <FilePreview
                key={`file-preview-${file.name}`}
                fileName={file.name.split('.').slice(0, -1).join('.')}
                fileExtension={file.name.split('.').pop()}
                onRemove={() => { files.value = files.value?.filter((_, index) => index !== i); }}
              />
            ))}
          </div>
        ) : null}
        <MentionsInput
          value={text.value}
          onChange={(_e, value) => { text.value = value; }}
          onKeyDown={handlePresssEnter}
          placeholder={t('thread.input.placeholder')}
          className="chat-input"
          forceSuggestionsAboveCursor
          ignoreAccents
          spellCheck={false}
        >
          <Mention
            trigger="@"
            data={Object.values(participants.value).map((participant) => ({
              id: participant.id,
              display: `${participant.first_name} ${participant.last_name}`,
            }))}
            appendSpaceOnAdd
            renderSuggestion={(suggestion, _search, _highlight, _index, focused) => (
              <UserItem
                userId={suggestion.id as string}
                isFocused={focused}
              />
            )}
          />
        </MentionsInput>
      </div>
      <FileButton handleFile={(newFiles) => { files.value = newFiles; }} />
      <button aria-label="send-message" type="submit" className="group transition-all duration-300 w-9 h-9 rounded-full" onClick={handleSubmit}>
        <Icon name="send-plane-fill" className="text-[1.5rem]  text-light-400 group-hover:text-light-100" />
      </button>
    </div>
  );
}

export default ChatInput;
