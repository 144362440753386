import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { MouseEvent } from 'react';

type BestTagProps = {
    title: string;
    subtitle?: string
    color?: string;
    className?: string;
    onCardClick?: () => void;
    onIncludeClick?: () => void;
    onExcludeClick?: () => void;
};

function BestTag(props: BestTagProps) {
  const { title, subtitle, color, className, onCardClick, onIncludeClick, onExcludeClick } = props;

  const buttonClassName = 'flex items-center justify-center w-6 h-6 shrink-0 bg-light-300 hover:bg-light-100 text-dark-100 rounded-lg';

  function handleIncludeClick(e: MouseEvent) {
    e.stopPropagation();
    onIncludeClick?.();
  }

  function handleExcludeClick(e: MouseEvent) {
    e.stopPropagation();
    onExcludeClick?.();
  }

  return (
    <div
      onClick={onCardClick}
      className={classNames(
        'flex gap-6 items-center justify-between  rounded-lg p-3 w-fit max-w-full cursor-pointer',
        'bg-dark-200 hover:bg-dark-100',
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <div className="w-3 h-3 shrink-0 rounded" style={{ backgroundColor: color }} />
        <div className="flex flex-col">
          <h5 className="text-sm font-bold text-light-100">{title}</h5>
          <p className="text-xs text-light-500">{subtitle}</p>
        </div>
      </div>
      <div className="flex gap-3">
        <button
          type="button"
          className={buttonClassName}
          onClick={handleExcludeClick}
        >
          <Icon name="subtract-line" />
        </button>
        <button
          type="button"
          className={buttonClassName}
          onClick={handleIncludeClick}
        >
          <Icon name="add-line" />
        </button>
      </div>
    </div>
  );
}

export default BestTag;
