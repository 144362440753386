import { Icon } from '@iconify/react';

type SearchProps = {
  query: string;
  setQuery: (query: string) => void;
  placeholder?: string;
};

export function SearchInput(props: SearchProps) {
  const { query, setQuery, placeholder } = props;

  return (
    <div className="flex gap-3 items-center w-full h-8 rounded px-2 bg-dark-100  mb-1">
      <Icon icon="ri:search-line" className="text-light-400 w-4 h-4" />
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        className="bg-transparent outline-none text-sm placeholder:text-light-400"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key !== 'arrowDown' && e.key !== 'arrowUp' && e.key !== 'Enter' && e.key !== 'Escape') {
            e.stopPropagation();
          }
        }}
      />
    </div>
  );
}
