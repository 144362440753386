import { useMemo } from 'react';
import { formatSecondsToMMSS } from '~/utils/time/audio';
import { useTrackContext } from '../track.context';
import * as Track from '../track';

type TrackDurationProps = {
  className?: string;
};

export function TrackDuration(props: TrackDurationProps) {
  const { className } = props;
  const store = useTrackContext();
  const { duration } = store.use.track();
  const formattedDuration = useMemo(() => formatSecondsToMMSS(duration), [duration]);

  return (
    <Track.Label className={className}>
      {formattedDuration}
    </Track.Label>
  );
}
