import Album from './tabs/album/album';
import Artist from './tabs/artist/artist';
import Bpm from './tabs/bpm/bpm';
import Brief from './tabs/brief/brief';
import Catalog from './tabs/catalog/catalog';
import Playlist from './tabs/playlist/playlist';
import Popularity from './tabs/popularity/popularity';
import ReleaseDate from './tabs/release-date/release-date';
import Sampler from './tabs/sampler/sampler';
import Tag from './tabs/tag/tag';

function Content() {
  return (
    <div className="flex-1 h-full overflow-hidden">
      <ReleaseDate />
      <Bpm />
      <Popularity />
      <Tag />
      <Catalog />
      <Album />
      <Artist />
      <Playlist />
      <Sampler />
      <Brief />
    </div>
  );
}

export default Content;
