import { useTagFilter } from '~/hooks/filter/use-tag-filter';
import { Tag, TagProps } from '../tag';
import { AccordionItem } from './accordion-item';
import { tagSuffix } from '~/utils/tags/tag-suffix';
import usePreSearchStore from '~/stores/presearch-store';
import { Tag as TagType } from '~/types/schemas/data/tags.schema';

interface TagListProps {
  tags?: TagProps[];
}

export function TagList(props: TagListProps) {
  const { tags } = props;
  const { onAddTagClick, onSubtractTagClick, onTagClick } = useTagFilter();
  const presearch = usePreSearchStore(['query', 'setIsOpen']);

  function handleTagClick(id: string, name: string) {
    onTagClick(id, name);
    presearch.setIsOpen(false);
  }

  function handleAddTagClick(id: string, name: string) {
    onAddTagClick(id, name);
    presearch.setIsOpen(false);
  }

  function handleSubtractTagClick(id: string, name: string) {
    onSubtractTagClick(id, name);
    presearch.setIsOpen(false);
  }

  return tags?.length ? (
    <AccordionItem className="flex gap-2 flex-wrap">
      {tags?.map((tag) => (
        <Tag
          key={tag.id}
          {...tag}
          onTagAddClick={handleAddTagClick}
          onTagSubtractClick={handleSubtractTagClick}
          onTagChipClick={handleTagClick}
          tagSuffix={tagSuffix(presearch.query, tag as TagType)}
        />
      ))}
    </AccordionItem>
  ) : null;
}
