import React from 'react';
import toast from 'react-hot-toast';
import Toast, { ToastType } from '../toast';

export const toastr = (
  type: ToastType,
  title: string,
  content?: React.ReactNode,
  toastId?: string,
) => {
  toast(<Toast type={type} title={title} content={content} />, {
    id: toastId,
    duration: 4000,
    position: 'top-right',
    className:
      '!p-0 !bg-transparent !border-0 !rounded-0 !shadow-none !text-white',
  });
};
