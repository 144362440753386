import classNames from 'classnames';

type FileInfoLoaderProps = {
    className?: string
    progress?: number
};

export default function FileInfoLoader(props: FileInfoLoaderProps) {
  const { className, progress } = props;
  return (
    <div className={classNames('flex gap-3 h-full p-3 pr-5 row-span-full', className)}>
      <div className="h-full aspect-square bg-dark-100 rounded animate-pulse" />
      <div className="flex flex-col w-1/2">
        <div className="my-2 h-4 bg-dark-100 rounded animate-pulse" />
        <div className="my-0.5 h-3 w-1/2 bg-dark-100 rounded animate-pulse" />
      </div>
      {progress ? (
        <div className="flex-1 h-full flex items-center justify-end text-lg font-bold gap-0.5">
          {progress}
          <span className="text-xs font-normal mt-1">%</span>
        </div>
      ) : null}
    </div>
  );
}
