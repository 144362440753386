import { z } from 'zod';

const TrackVariationCreateInputSchema = z.object({
  id: z.string(),
  duration: z.number().min(5).max(300),
  variations: z.number().min(1).max(4),
}).strict();

const TrackVariationCreateOutputSchema = z.object({
  result: z.string().array(),
});

const TrackVariationCacheInputSchema = z.object({
  ids: z.string().array().min(1).max(100),
}).strict();

const TrackVariationCacheOutputSchema = z.object({
  result: z.literal('OK'),
});

type TrackVariationCreateInputType = z.infer<typeof TrackVariationCreateInputSchema>;
type TrackVariationCreateOutputType = z.infer<typeof TrackVariationCreateOutputSchema>;
type TrackVariationCacheInputType = z.infer<typeof TrackVariationCacheInputSchema>;
type TrackVariationCacheOutputType = z.infer<typeof TrackVariationCacheOutputSchema>;

export type { TrackVariationCreateInputType, TrackVariationCreateOutputType, TrackVariationCacheInputType, TrackVariationCacheOutputType };
export { TrackVariationCreateInputSchema, TrackVariationCreateOutputSchema, TrackVariationCacheInputSchema, TrackVariationCacheOutputSchema };
