/* eslint-disable camelcase */
import Dialog from '~/components/ui/overlays/dialog/dialog';
import { MouseEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Owner, Track } from '~/types/schemas/music/track.schema';
import { useTranslation } from 'react-i18next';
import { useTrackCredentials } from '../../hooks/use-credentials';
import TrackInteraction from '../interaction';

type CredentialProps = {
  track: Track;
  className?: string;
};

function DisplayCredentials(props: CredentialProps) {
  const { track, className } = props;
  const { display_artists, ownerships } = track;
  const { rightsHoldersByType, addOwnerToFilter } = useTrackCredentials(ownerships);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation('track-interactions');

  function handleClick(e: MouseEvent, owner: Owner & { type: string }) {
    e.stopPropagation();
    addOwnerToFilter(owner);
    setIsDialogOpen(false);
  }

  return (
    <Dialog
      asChild
      isOpen={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      trigger={(
        <TrackInteraction
          icon="credential-line"
          tooltip={t('copyright.tooltip')}
          classSuffix="credentials"
        />
      )}
    >
      <div className={classNames('credentials-dialog', className)} onClick={(e) => e.stopPropagation()}>
        <h4 className="font-bold text-2xl mb-3">{t('copyright.modal.title')}</h4>
        <div className="flex flex-col flex-wrap">
          <DialogBlock label={t('copyright.modal.display-artists.title')}>
            <div className="flex flex-wrap text-light-500">
              {display_artists?.map((artist, i) => (
                <span
                  key={`${artist.id}-${i}`}
                  data-no-dnd="true"
                  className={classNames('right-holder flex mr-2 gap-1 group/owner items-center text-sm cursor-pointer')}
                  onClick={(e) => handleClick(e, { ...artist, type: 'master_artists', rights_type: 'artists' })}
                >
                  <span className="group-hover/owner:underline group-hover/owner:text-light-100">
                    {artist.name}
                  </span>
                </span>
              ))}
            </div>
          </DialogBlock>
          {Object.values(rightsHoldersByType).map((rightHolders, i) => {
            let label: string = '';
            const temp = Object.keys(rightsHoldersByType)[i]!.replace('_', ' ');

            switch (temp) {
              case 'artists':
                label = t('copyright.modal.artists.title');
                break;
              case 'labels':
                label = t('copyright.modal.labels.title');
                break;
              case 'publishers':
                label = t('copyright.modal.publishers.title');
                break;
            }

            return (
              <DialogBlock key={`rightHolders-${i}`} label={label}>
                <div className="flex flex-wrap text-light-500">
                  {rightHolders.map((rightHolder, j) => (
                    <span
                      key={`${rightHolder.id}-${j}`}
                      data-no-dnd="true"
                      className={classNames('right-holder flex mr-2 gap-1 group/owner items-center text-sm cursor-pointer')}
                      onClick={(e) => handleClick(e, { ...rightHolder, type: Object.keys(rightsHoldersByType)[i]! })}
                    >
                      <span className="group-hover/owner:underline group-hover/owner:text-light-100">
                        {rightHolder.name}
                      </span>
                      {j !== rightHolders.length - 1 && ','}
                    </span>
                  ))}
                </div>
              </DialogBlock>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
}

function DialogBlock(props: { label: string, children: ReactNode }) {
  const { label, children } = props;

  return (
    <div className="mb-3">
      <h2 className="text-lg mb-1">
        {`${label[0]?.toUpperCase()}${label.slice(1)}`}
      </h2>
      {children}
    </div>
  );
}

export default DisplayCredentials;
