import { useNavigate } from 'react-router-dom';
import DeleteDialog from '~/pages/briefs/components/delete-dialog';
import Button from '~/components/ui/control/button/button';
import { useDeleteBriefs } from '~/hooks/api/brief';

type DeleteButtonProps = {
  id: string;
  className?: string;
};

const DeleteBrief = (props: DeleteButtonProps) => {
  const { id, className } = props;
  const { mutate: deleteBrief } = useDeleteBriefs();
  const navigate = useNavigate();

  const handleDelete = () => {
    navigate('/briefs');
    deleteBrief({ ids: [id] });
  };

  return (
    <DeleteDialog handleDeleteClick={handleDelete}>
      <Button iconRight="delete-bin-line" className={className}></Button>
    </DeleteDialog>
  );
};

export default DeleteBrief;
