import classNames from 'classnames';

type AgentProps = {
  companyName: string;
  providerTypes: string[];
  className?: string;
};

function Agent(props: AgentProps) {
  const { companyName, providerTypes, className } = props;

  return (
    <div
      className={classNames('agent flex cursor-pointer justify-between items-center rounded bg-dark-300 w-full px-3 py-2 border border-transparent', className)}
    >
      <div className="agent__left flex gap-4">
        <div className="flex flex-col">
          <h6 className="text-light-100">{`${companyName}`}</h6>
          <p className="text-light-500 text-xs"></p>
        </div>
      </div>
      <div className="agent__right flex flex-col items-end justify-center">
        <p className="text-xs text-light-500 capitalize">{providerTypes.join(', ')}</p>
      </div>
    </div>
  );
}

export default Agent;
