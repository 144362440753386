import { createWithEqualityFn } from 'zustand/traditional';
import { PanelFilter } from '~/components/features/filter-panel/components/navigation/navigation';
import { withImmer } from './middlewares/immer-combine';
import { createStorePicker } from './utilities';

const useAddFilterStore = createStorePicker(createWithEqualityFn(
  withImmer({
    currentTab: 'tag',
    isOpen: false,
    query: {
      tag: '',
      release_date: '',
      bpm: '',
      popularity: '',
      catalog: '',
      album: '',
      artist: '',
      playlist: '',
      sampler: '',
      brief: '',
    } as Record<PanelFilter, string>,
  })(
    (set) => ({
      setCurrentTab: (currentTab: string) => set((state) => {
        state.currentTab = currentTab;
      }),
      setIsOpen: (isOpen: boolean) => set((state) => {
        state.isOpen = isOpen;
      }),
      setQuery: (key: PanelFilter, value: string) => set((state) => {
        state.query[key] = value;
      }),
    }),
  ),
));

export default useAddFilterStore;
