import { Track as TrackType } from '~/types/schemas/music/track.schema';
import { ReactNode, useMemo } from 'react';
import { InfiniteScroll } from '~/components/layout/infinite-scroll/infinite-scroll';
import SelectionProvider from '~/providers/selection-provider/selection-provider';
import { Range } from '@tanstack/react-virtual';
import classNames from 'classnames';

import { Playlist } from '~/types/schemas/music/playlists.schema';
import { Brief } from '~/types/schemas/music/brief.schema';
import { useMagicPreviewPreprocess } from '~/hooks/entities/track/actions/magic-preview/use-magic-preview-preprocess';
import { MagicPreviewRealtimeListener } from '~/providers/magic-preview-provider/magic-preview.provider';
import { TrackItemListContext } from './track-item-list.context';
import { renderTrackItemSentinel } from '../common/loader/sentinels';

type TrackItemListProps = {
  context?: {
    type: 'playlist' | 'brief';
    value: Playlist | Brief;
  }
  tracks: TrackType[];
  isFetching: boolean;
  scrollParent: Element | null;
  preProcessMagicPreview?: boolean;
  renderItem: (item: unknown) => ReactNode;
  onRangeChange: (range: Range) => void;
  className?: string;
};

export function TrackItemList(props: TrackItemListProps) {
  const {
    context,
    tracks,
    isFetching,
    scrollParent,
    preProcessMagicPreview = false,
    renderItem,
    onRangeChange,
    className,
  } = props;
  const value = useMemo(() => ({ context, tracks }), [tracks]);
  useMagicPreviewPreprocess({ tracks, preProcessTracks: preProcessMagicPreview });

  return (
    <TrackItemListContext.Provider value={value}>
      <div
        className={classNames(
          'flex flex-col gap-3 h-fit min-h-full ',
          className,
        )}
      >
        <MagicPreviewRealtimeListener>
          <SelectionProvider data={tracks}>
            <InfiniteScroll
              data={tracks}
              count={tracks?.length}
              estimateSize={60}
              overscan={30}
              onRangeChange={onRangeChange}
              isFetching={isFetching}
              renderItem={renderItem}
              renderSentinel={renderTrackItemSentinel}
              scrollParent={scrollParent}
            />
          </SelectionProvider>
        </MagicPreviewRealtimeListener>
      </div>
    </TrackItemListContext.Provider>
  );
}
