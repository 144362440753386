import dayjs from 'dayjs';
import 'dayjs/locale/de';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(UpdateLocale);
dayjs.updateLocale('de', {
  relativeTime: {
    future(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `in ${time}`;
    },
    past(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `vor ${time}`;
    },
    s: 'gerade jetzt',
    ss: 'ein paar Sekunden',
    m: '1 Minute',
    mm: '%d Minuten',
    h: '1 Stunde',
    hh: '%d Stunden',
    d: '1 Tag',
    dd: '%d Tage',
    M: '1 Monat',
    MM: '%d Monate',
    y: '1 Jahr',
    yy: '%d Jahre',
  },
});
