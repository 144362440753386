/* eslint-disable camelcase */
import classNames from 'classnames';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Artwork } from '~/components/ui/cover/artwork';

type playlistCardProps = {
  playlist: Playlist;
  onClick?: () => void;
};

const LOCALE = 'en';

const PlaylistCard = (props: playlistCardProps) => {
  const { playlist, onClick } = props;
  const { title, artworks, tenant, created_at, created_by, descriptions, tracks } = playlist;
  const { first_name, last_name } = created_by;
  const { company_name } = tenant;
  const { t } = useTranslation('libraries');

  return (
    <button
      type="button"
      onClick={onClick}
      className="p-4 flex w-full text-left shrink-0 hover:bg-dark-300/40 outline outline-1 outline-dark-100/40 hover:outline-dark-300/40 transition-all duration-100 rounded-lg"
    >
      <div className="w-[25%] aspect-square mr-4 shrink-0">
        <Artwork icon="ri:headphone-line" artworks={artworks?.map((artwork) => artwork.large)} alt="cover" />
      </div>
      <div className="flex-1 h-full flex flex-col">
        <div className="w-full flex justify-between mt-2 items-center text-xs">
          <h4 className="text-light-500">
            {t('playlist-card.attribution', {
              'company-name': company_name || '',
              'user-fullname': `${first_name} ${last_name}`,
              date: parseDateNumeric(created_at || '', {
                month: 'long',
                day: '2-digit',
                year: dayjs(created_at).year() === dayjs().year() ? undefined : 'numeric',
              }),
            })}
          </h4>
          <span>

          </span>
        </div>
        <div className="mt-3 mb-3">
          <h3 className="font-bold line-clamp-1 text-light-100">{title}</h3>
          <p className="text-xs line-clamp-2 text-light-500 mt-2">
            {descriptions.find((description) => description.locale === LOCALE)?.value}
          </p>
        </div>
        <div className="w-full flex justify-between items-center text-xs mt-auto text-light-500 mb-2">
          <span className={classNames(
            'rounded py-1 px-2 text-light-100 flex items-center gap-2 bg-dark-100',
          )}
          >
            {t('playlist-card.tracks-count', {
              'track-count': tracks || 0,
            })}
          </span>

        </div>
      </div>
    </button>
  );
};

export default PlaylistCard;
