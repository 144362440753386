import FormLayout, { FormLayoutProps } from '~/components/layout/form-layout';
import { Control, useForm } from 'react-hook-form';
import { Playlist, PlaylistUpdate } from '~/types/schemas/music/playlists.schema';
import { useTranslation } from 'react-i18next';
import General from './tabs/general';
import { usePlaylistForm } from './use-playlist-form';
import PlaylistFormFooter from './components/footer';

export type PlaylistFormProps = {
  playlist?: Playlist
};

export type PlaylistStepProps = {
  title: string
  control: Control<PlaylistUpdate>
  playlist?: Playlist
  onDiscardAndExitClick?: () => void
  onSaveAndExitClick?: () => void
};

function PlaylistForm(props: PlaylistFormProps) {
  const { t } = useTranslation('playlist-form');
  const { playlist } = props;
  const { control, handleSubmit } = useForm<PlaylistUpdate>();
  const { handleSaveAndExit, handleDiscardAndExit } = usePlaylistForm(playlist);

  const steps: FormLayoutProps['steps'] = [{
    tab: <General
      title={t('header.title')}
      control={control}
      playlist={playlist}
      onDiscardAndExitClick={() => handleDiscardAndExit()}
      onSaveAndExitClick={handleSubmit(handleSaveAndExit)}
    />,
  }];

  return (
    <FormLayout
      steps={steps}
      footer={(
        <PlaylistFormFooter
          onSaveAndExitClick={handleSubmit(handleSaveAndExit)}
        />
      )}
    />
  );
}

export default PlaylistForm;
