import * as Tabs from '@radix-ui/react-tabs';
import usePreSearchStore from '~/stores/presearch-store';
import { useEffect } from 'react';
import i18n from '~/i18n/config';
import translatedTabsRaw from '~/data/presearch-tabs.json';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { TranslatedTabsSchema } from '~/types/schemas/data/translated-presearch-tabs.schema';
import TabTrigger from '../tab-trigger/tab-trigger';

const translatedTabs = TranslatedTabsSchema.parse(translatedTabsRaw);

export const WITHOUT_QUERY_NAVIGATION_ITEMS: Record<string, string> = {
  home: 'Home',
  tags: 'Music tags',
  targets: 'Target audiences',
  catalogs: 'Catalogs',
};

export const WITH_QUERY_NAVIGATION_ITEMS: Record<string, string> = {
  all: 'All',
  tags: 'Music tags',
  targets: 'Target audiences',
  catalogs: 'Catalogs',
  albums: 'Albums',
  artists: 'Artists',
  playlists: 'Playlists',
  samplers: 'Samplers',
  briefs: 'Briefs',
};

export type PresearchNavigationItem = keyof typeof WITHOUT_QUERY_NAVIGATION_ITEMS | keyof typeof WITH_QUERY_NAVIGATION_ITEMS;

function Navigation() {
  const presearch = usePreSearchStore(['query', 'currentTab', 'tabResultsNumbers', 'setCurrentTab']);
  const isQueryEmpty = !presearch.query;

  useEffect(() => {
    if (isQueryEmpty && !WITHOUT_QUERY_NAVIGATION_ITEMS[presearch.currentTab]) {
      presearch.setCurrentTab('home');
    }

    if (!isQueryEmpty && !WITH_QUERY_NAVIGATION_ITEMS[presearch.currentTab]) {
      presearch.setCurrentTab('all');
    }
  }, [isQueryEmpty, presearch.currentTab, presearch.setCurrentTab]);

  return (
    <Tabs.List className="flex gap-3">
      {Object.entries(!isQueryEmpty ? WITH_QUERY_NAVIGATION_ITEMS : WITHOUT_QUERY_NAVIGATION_ITEMS).map(([key, label]) => {
        return (
          <TabTrigger
            key={`tab-${key}`}
            label={translatedTabs[i18n.language as SupportedLanguages]?.[key] || label}
            value={key}
            isActive={presearch.currentTab === key}
            resultNumber={presearch.tabResultsNumbers[key]?.[presearch.query]}
          />
        );
      })
      }
    </Tabs.List>
  );
}

export default Navigation;
