import { produce } from 'immer';
import { StateCreator, StoreApi } from 'zustand';

export const withImmer = <PrimaryState>(initialState: PrimaryState) => <SecondaryState>(
  createState: (
    set: (fn: (draftState: PrimaryState) => void) => void,
    get: StoreApi<PrimaryState>['getState'],
    api: StoreApi<PrimaryState>
  ) => SecondaryState,
): StateCreator<PrimaryState & SecondaryState> => (set, get, api) => ({
    ...initialState,
    ...createState(
      (fn) => set((baseState) => produce(baseState, fn)),
      get as StoreApi<PrimaryState>['getState'],
      api as StoreApi<PrimaryState>,
    ),
  });
