import React, { PropsWithChildren } from 'react';
import { Track } from '~/types/schemas/music/track.schema';
import { Action } from '~/types/types/actions';
import { SelectionItem } from '~/providers/selection-provider/item';
import { TrackItemProviders } from './track-item.providers';
import { TrackItemWrapper } from './track-item.wrapper';
import { SearchTrackItemHeader } from './header/search';
import { PlaylistTrackItemHeader } from './header/playlist';
import { BriefTrackItemHeader } from './header/brief';

type TrackItemProps = {
  type: 'search' | 'playlist' | 'brief';
  track: Track;
  tracklistId?: string;
};

type TrackItemBaseProps = PropsWithChildren<{
  track: Track;
  context: Action.Play;
}>;

function TrackItemBase(props: TrackItemBaseProps) {
  const { track, context, children } = props;

  return (
    <SelectionItem value={track}>
      <TrackItemProviders track={track} context={context}>
        <TrackItemWrapper>{children}</TrackItemWrapper>
      </TrackItemProviders>
    </SelectionItem>
  );
}

export const TrackItem = React.memo((props: TrackItemProps) => {
  const { type, track, tracklistId } = props;

  if (type === 'search') {
    return (
      <TrackItemBase track={track} context={{ type: 'search' }}>
        <SearchTrackItemHeader />
      </TrackItemBase>
    );
  }

  if (type === 'playlist' && tracklistId) {
    return (
      <TrackItemBase
        track={track}
        context={{ type: 'playlist', id: tracklistId }}
      >
        <PlaylistTrackItemHeader />
      </TrackItemBase>
    );
  }

  if (type === 'brief' && tracklistId) {
    return (
      <TrackItemBase track={track} context={{ type: 'brief', id: tracklistId }}>
        <BriefTrackItemHeader />
      </TrackItemBase>
    );
  }

  return null;
});
