import { components, ValueContainerProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const ValueContainer = (
  props: ValueContainerProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children } = props;

  return (
    <components.ValueContainer
      className="flex w-full gap-1 text-sm !p-0"
      {...props}
    >
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainer;
