import { useTrackContext } from '../../common/track.context';

export function ContentDescription() {
  const store = useTrackContext();
  const track = store.use.track();

  return (
    track.description ? (
      <p className="text-xs text-light-500">
        {track.description}
      </p>
    ) : null
  );
}
