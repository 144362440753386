import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';
import Icon from '~/components/ui/icon/icon';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export type ControlsProps = {
  isPlaying?: boolean
};

export interface ButtonProps {
  onClick: () => void
  isPlayButton?: boolean
  iconName: string
  className?: string
  tooltipContent: string
  dataTestId?: string
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    iconName,
    isPlayButton = false,
    className = '',
    tooltipContent,
    dataTestId,
  } = props;

  return (
    <Tooltip label={tooltipContent}>
      <button
        data-testid={dataTestId}
        type="button"
        onClick={onClick}
        className={`flex h-8 w-8 shrink-0 items-center justify-center transition duration-100 ${isPlayButton && 'text-dark-100 rounded-full bg-white hover:scale-105'
        } ${className}`}
      >
        <Icon name={iconName} />
      </button>
    </Tooltip>
  );
};

export const Controls = (props: ControlsProps) => {
  const { isPlaying } = props;
  const player = usePlayerStore(['togglePlay']);
  const musemind = useMusemindStore(['isPlaying', 'setPlay']);
  const { t } = useTranslation('player');

  const handleTogglePlay = () => {
    if (musemind.isPlaying) {
      musemind.setPlay(false);
    }

    player.togglePlay();
  };

  return (
    <div
      className={classNames(
        'players__controls',
        'flex items-center justify-center gap-4',
      )}
    >
      {isPlaying ? (
        <Button
          tooltipContent={t('control.pause.tooltip')}
          className="player__controls__button player__controls__button--pause"
          onClick={handleTogglePlay}
          isPlayButton
          iconName="pause-fill"
        />
      ) : (
        <Button
          tooltipContent={t('control.play.tooltip')}
          className="player__controls__button player__controls__button--play"
          onClick={handleTogglePlay}
          isPlayButton
          iconName="play-fill"
        />
      )}
    </div>
  );
};
