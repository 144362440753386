import classNames from 'classnames';
import { ReactNode } from 'react';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';

type InformationProps = {
  title: string
  children?: ReactNode,
  className?: string
};

const Information = (props: InformationProps) => {
  const { title, children, className } = props;

  return (
    <div className={classNames(
      'flex flex-col gap-1 p-2 rounded-lg bg-dark-300',
      className,
    )}
    >
      <h5 className="text-xs text-light-500">
        {title}
      </h5>
      <TextLinkParser>
        {children}
      </TextLinkParser>
    </div>
  );
};

export default Information;
