/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import Cover, { CoverType } from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import { Artwork } from '~/types/schemas/common/image.schema';
import IconAdd from 'virtual:icons/ri/add-fill';
import IconSubtract from 'virtual:icons/ri/subtract-fill';
import { MouseEvent } from 'react';

export type CardProps = {
    type?: CoverType;
    artworks?: Artwork[];
    title: string;
    subtitle?: string;
    customSubtitle?: React.ReactNode;
    subtitlePrefx?: string;
    chips?: string[];
    onCardClick?: () => void;
    onIncludeClick?: () => void;
    onExcludeClick?: () => void;
};

function Card(props: CardProps) {
  const { type, artworks, title, subtitle, customSubtitle, subtitlePrefx, chips, onCardClick, onIncludeClick, onExcludeClick: onExcludelick } = props;

  const handleCardClick = (e:MouseEvent) => {
    e.stopPropagation();
    onCardClick?.();
  };

  const handleIncludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onExcludelick?.();
  };

  const buttonClassName = 'rounded-xl flex items-center justify-center transition-delay-400 transition-colors bg-light-100/70 hover:bg-light-100 w-8 aspect-square text-dark-400';

  return (
    <div
      onClick={handleCardClick}
      className={classNames(
        'presearch-card flex flex-col gap-4 w-full h-full p-4 rounded-lg transition duration-100 group/presearch-card cursor-pointer',
        'bg-dark-200 hover:bg-dark-100',
        chips?.length ? 'pb-5' : 'pb-7',
      )}
    >
      <div className="relative overflow-hidden drop-shadow-xl w-full aspect-square">
        <Cover artworks={artworks} alt={title} type={type} />
        <div className={classNames(
          'absolute top-full flex items-end rounded-sm overflow-hidden w-full h-full p-3 transition-transform bg-gradient-to-t from-dark-400/30 to-dark-500/0  bg-dark-100/0',
          'group-hover/presearch-card:-translate-y-full',
        )}
        >
          <div className="flex w-full justify-between mt-full">
            <button type="button" className={classNames(buttonClassName, 'presearch-card-subtract')} onClick={handleExcludeClick}>
              <IconSubtract />
            </button>
            <button type="button" className={classNames(buttonClassName, 'presearch-card-add')} onClick={handleIncludeClick}>
              <IconAdd />
            </button>
          </div>
        </div>
      </div>
      <div className="presearch-card__information flex flex-col gap-2 items-start w-full overflow-hidden">
        <h5 className="presearch-card__information__title font-bold line-clamp-1 text-left">{title}</h5>
        {chips?.length ? (
          <div className="flex gap-2">
            {chips.map((chip) => (
              <Chip key={chip} className="bg-dark-500 capitalize !rounded">
                {chip}
              </Chip>
            ))}
          </div>
        )
          : (subtitle || customSubtitle || subtitlePrefx) ? (
            <div className="flex gap-1 text-light-500 text-xs h-4">
              {subtitlePrefx && (
              <>
                <span className="flex shrink-0">{subtitlePrefx}</span>
                {(subtitle || customSubtitle) && <span>•</span>}
              </>
              )}
              {subtitle || customSubtitle}
            </div>
          ) : null
        }
      </div>
    </div>
  );
}

export default Card;
