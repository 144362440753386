import { z } from 'zod';

const UploadSchema = z.object({
  id: z.string(),
  url: z.string(),
  fields: z.record(z.string()),
});

type Upload = z.infer<typeof UploadSchema>;

export { UploadSchema };
export type { Upload };
