import { optionRoutes } from '~/routes/api';
import useDataStore from '~/stores/mewo-data-store';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';
import { OthersResponse, OthersResponseSchema } from '~/types/schemas/data/mewo-data.schema';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';

export const useGetOtherOptions = () => {
  const { setData } = useDataStore(['setData']);
  const { setData: setDataV2 } = mewoDataStore;

  return useFetch<OthersResponse>({
    url: optionRoutes.other,
    schema: OthersResponseSchema,
    keepPreviousData: true,
    onSuccess(data) {
      setData(data);
      setDataV2(data);
    },
  });
};
