import { useEffect } from 'react';
import { Upload } from '~/types/schemas/file-upload-session/upload.schema';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { trackUploadStore } from '~/stores/track-upload-store';
import { useS3Upload } from '~/hooks/api/upload';
import { useSignal } from '@preact/signals-react';
import FileInfoLoader from '../../file-info-loader';

type TrackUploadProps = {
    file: UploadTrack
    upload?: Upload
};

export function TrackUpload(props: TrackUploadProps) {
  const { file, upload } = props;
  const { uploadEnd } = trackUploadStore;
  const { mutate: uploadFileOnS3 } = useS3Upload();
  const progress = useSignal<number>(0);

  useEffect(() => {
    if (!upload) return;
    uploadFileOnS3({
      upload,
      file: file.file,
      onUploadCompleted() {
        file.step.value = 'matchLaunch';
        uploadEnd(file.id);
      },
      onProgress(p) {
        progress.value = p;
      },
    });
  }, []);

  return <FileInfoLoader className="border-r border-primary-500" progress={progress.value} />;
}
