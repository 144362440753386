import { ChangeEvent, useState } from 'react';
import { Track } from '~/components/ui/forms/input-controls/combobox/custom-render/tracks';
import { ComboboxInput, SelectInputItem } from '~/components/ui/forms/input-controls/combobox/combobox';
import useDebounce from '~/hooks/common/use-debounce';
import { SpotifyTrack } from '~/types/schemas/track-upload/spotify-search.schema';
import { useSearchSpotifyTracks } from '~/hooks/api/upload';

type TitleInputProps = {
  label?: string;
  value?: string;
  error?: string;
  required?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onItemSelect?: (track: SpotifyTrack) => void;
};

export function TitleInput(props: TitleInputProps) {
  const { value, onChange, ...rest } = props;
  const [query, setQuery] = useState(value);
  const debouncedQuery = useDebounce(query, 500);
  const { data, isLoading } = useSearchSpotifyTracks(debouncedQuery);

  const parsedData: SelectInputItem<SpotifyTrack>[] = data?.map((item) => ({
    value: item.id,
    content: {
      id: item.id,
      title: item.title,
      artists: item.artists,
      album: item.album,
      cover: item.cover,
    },
  })) || [];

  function handleChange(e:ChangeEvent<HTMLInputElement>) {
    const v = e.target.value;

    setQuery(v);
    onChange?.(e);
  }

  return (
    <ComboboxInput
      value={value}
      onChange={handleChange}
      items={parsedData}
      render={Track}
      isLoading={isLoading}
      {...rest}
    />
  );
}
