/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import * as Track from '../track';
import { useTrackContext } from '../track.context';

type TrackMusicTypeProps = {
  className?: string;
};

export function TrackMusicType(props: TrackMusicTypeProps) {
  const { className } = props;
  const store = useTrackContext();
  const { music_type } = store.use.track();
  const { t } = useTranslation('music-item');

  return (
    <Track.Label className={className}>
      {music_type === 'core' ? t('music-type.core') : t('music-type.prod')}
    </Track.Label>
  );
}
