import { Icon, IconifyIconProps } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { useTrackPlaybackState } from '~/hooks/entities/track/use-track-playback-state';

type TrackSummaryControlsProps = {
  playingIcon?: IconifyIconProps['icon']
  onTogglePlay?: (e: MouseEvent) => void
};

export function TrackSummaryControls(props: TrackSummaryControlsProps) {
  const { playingIcon = '', onTogglePlay } = props;
  const { isPlaying } = useTrackPlaybackState();

  return (
    <div
      className={classNames(
        ' absolute inset-0 flex rounded justify-center items-center transition-colors cursor-pointer',
        'opacity-0 bg-black/70 group-hover/track-item-cover:opacity-100',
        {
          'opacity-100 ': isPlaying,
        },
      )}
      onClick={onTogglePlay}
    >
      {!isPlaying ? <Icon icon="ri:play-fill" className="text-light-100" /> : null}
      {isPlaying ? <Icon icon={playingIcon} className=" block text-light-100 group-hover/track-item-cover:hidden " /> : null}
      {isPlaying ? <Icon icon="ic:baseline-pause" className=" text-light-100 hidden group-hover/track-item-cover:block" /> : null}
    </div>
  );
}
