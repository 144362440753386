import classNames from 'classnames';
import { MouseEvent } from 'react';
import Cover from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import GeneralIcons from '~/components/ui/icon/general-icons';
import Icon from '~/components/ui/icon/icon';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { Artwork } from '~/types/schemas/common/image.schema';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';

type ArtistResultProps = {
  name: string;
  type?: string[];
  instagramFollowers?: number;
  spotifyListeners?: number;
  tiktokFollowers?: number;
  artworks?: Artwork[];
  onCardClick?: () => void;
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function ArtistResult(props: ArtistResultProps) {
  const { name, type, instagramFollowers, spotifyListeners, tiktokFollowers, artworks, onCardClick, onIncludeClick, onExcludeClick } = props;
  const { t } = useTranslation('pre-search');

  function handleIncludeClick(e: MouseEvent) {
    e.stopPropagation();
    onIncludeClick?.();
  }

  function handleExcludeClick(e: MouseEvent) {
    e.stopPropagation();
    onExcludeClick?.();
  }

  function handleCardClick(e: MouseEvent) {
    e.stopPropagation();
    onCardClick?.();
  }

  return (
    <div
      onClick={handleCardClick}
      className={classNames(
        'best-artist-card relative group/best-artist flex flex-col p-4 gap-3 rounded-lg w-full h-full cursor-pointer',
        'bg-dark-200 hover:bg-dark-100',
      )}
    >
      <div className="flex gap-6 items-center h-full">
        <Cover
          type="artist"
          artworks={artworks}
          alt={name}
          className="!w-[7.5rem] !h-[7.5rem]"
        />
        <div className="flex flex-col gap-3">
          {instagramFollowers ? (
            <div className="flex gap-2 items-center text-xs">
              <GeneralIcons icon="instagram" className="text-base" />
              <span className="text-light-500">{t('tab.all.artist.instagram', { followers: parseThousands(instagramFollowers.toString(), ',') })}</span>
            </div>
          ) : null}
          {tiktokFollowers ? (
            <div className="flex gap-2 items-center text-xs">
              <GeneralIcons icon="tiktok" className="text-base" />
              <span className="text-light-500">{t('tab.all.artist.tiktok', { followers: parseThousands(tiktokFollowers.toString(), ',') })}</span>
            </div>
          ) : null}
          {spotifyListeners ? (
            <div className="flex gap-2 items-center text-xs">
              <GeneralIcons icon="spotify" className="text-base" />
              <span className="text-light-500">{t('tab.all.artist.spotify', { listener: parseThousands(spotifyListeners.toString(), ',') })}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="best-artist-card__information flex flex-col gap-1.5">
        <Tooltip label={name} triggerClassName="text-left">
          <h5 className="font-bold text-2xl line-clamp-1">{name}</h5>
        </Tooltip>
        <div className="flex gap-1.5">
          {type?.map((t) => (
            <Chip key={t} className="bg-dark-500">{t}</Chip>
          ))}
        </div>
      </div>
      <div className={classNames(
        'best-artist-card__buttons absolute opacity-0 right-4 bottom-4 flex gap-3 transition-all',
        'group-hover/best-artist:opacity-100',
      )}
      >
        <button
          type="button"
          className="best-artist-card__buttons__subtract flex items-center justify-center w-6 h-6 shrink-0 bg-light-300 hover:bg-light-100 text-dark-100 rounded-lg"
          onClick={handleExcludeClick}
        >
          <Icon name="subtract-line" />
        </button>
        <button
          type="button"
          className="best-artist-card__buttons__add flex items-center justify-center w-6 h-6 shrink-0 bg-light-300 hover:bg-light-100 text-dark-100 rounded-lg"
          onClick={handleIncludeClick}
        >
          <Icon name="add-line" />
        </button>
      </div>
    </div>
  );
}

export default ArtistResult;
