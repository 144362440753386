import { useSearchStore } from '~/stores/search-store';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tag as TagType } from '~/types/schemas/data/tags.schema';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTagPath } from '~/hooks/utils/use-tag-path';
import PresearchBlock from '../../../presearch-block/presearch-block';
import EmptyBlock from './empty-block';
import BestTag from '../../../best-tag/best-tag';
import { tagSuffix } from '~/utils/tags/tag-suffix';
import { useTagResultBlock } from '~/hooks/entities/presearch/use-tag-result-block';
import { ColoredTag } from '~/components/entities/tags/colored-tag';

type TagResultBlockProps = {
  className?: string;
};

function TagResultBlock(props: TagResultBlockProps) {
  const { className } = props;
  const search = useSearchStore(['facets', 'query']);
  const { findTagPath } = useTagPath();
  const {
    result,
    bestTag,
    otherTags,
    tags,
    handleAddTagClick,
    handleSubtractTagClick,
    handleTagClick,
    handleSubCategoryAddClick,
    handleSubCategorySubtractClick,
    handleSubCategoryClick,
  } = useTagResultBlock();

  const tagIds = tags?.tags?.map((tag) => tag.id);

  const { handleFocusClick, handleClick } = useCardClick(
    bestTag && tagIds?.includes(bestTag?.id) ? 'tags' : 'tag_categories',
  );
  const { t } = useTranslation('pre-search');

  return tags?.tags ? (
    <PresearchBlock
      label={t('tab.all.tags-targets.title')}
      containerClassName={classNames('flex flex-col w-full', className)}
      childrenClassName="h-full"
    >
      {result.length ? (
        <>
          {bestTag ? (
            <BestTag
              title={bestTag?.name || ''}
              subtitle={findTagPath(bestTag as TagType)}
              color={bestTag?.color || ''}
              className="mb-6"
              onCardClick={() => handleFocusClick(bestTag?.name, bestTag?.id)}
              onIncludeClick={() =>
                handleClick(bestTag?.name, bestTag?.id, 'is')
              }
              onExcludeClick={() =>
                handleClick(bestTag?.name, bestTag?.id, 'is_not')
              }
            />
          ) : null}
          {otherTags.length ? (
            <>
              <span className="text-xs">
                {t('tab.all.tags-targets.other-results')}
              </span>
              <div className="flex flex-wrap gap-2">
                {otherTags.map((item) => {
                  if (tagIds?.includes(item.id)) {
                    return (
                      <ColoredTag
                        id={item.id}
                        key={item.id}
                        color={item.color}
                        name={item.name}
                        onTagChipClick={handleTagClick}
                        tagSuffix={tagSuffix(search.query, item as TagType)}
                      />
                    );
                  }
                  return (
                    <ColoredTag
                      key={item.id}
                      id={item.id}
                      color={item.color}
                      name={item.name}
                      onTagChipClick={handleSubCategoryClick}
                      tagSuffix={tagSuffix(search.query, item as TagType)}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
        </>
      ) : (
        <EmptyBlock />
      )}
    </PresearchBlock>
  ) : null;
}

export default TagResultBlock;
