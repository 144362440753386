import { Track } from '~/types/schemas/music/track.schema';
import { useBriefTrackItemList } from '~/hooks/entities/track/track-item-list/use-brief-track-item-list';
import { IngestLoading } from '~/pages/briefs/components/ingest-loading';
import { Brief } from '~/types/schemas/music/brief.schema';
import { TrackItemList } from '../track-item-list';
import { BriefListHeader } from './brief-list-header';
import { BriefTrackItemListEmpty } from './brief-track-item-list.empty';
import { TrackItem } from '../../track-item/track-item';
import { renderBriefTrackItemSentinel } from '../../common/loader/sentinels';

type BriefTrackItemListProps = {
  brief: Brief;
  scrollParentId: string
};

export function BriefTrackItemList(props: BriefTrackItemListProps) {
  const { brief, scrollParentId } = props;
  const { tracks, isFetching, isLoading, handleNextPageFetch } = useBriefTrackItemList(brief.id);

  if (isLoading) {
    return (
      <>
        <BriefListHeader />
        {renderBriefTrackItemSentinel('flex-1')}
      </>
    );
  }

  if (tracks.length) {
    return (
      <div className="@container">
        <IngestLoading id={brief.id} />
        <BriefListHeader />
        <TrackItemList
          context={{
            type: 'brief',
            value: brief,
          }}
          tracks={tracks}
          isFetching={isFetching}
          scrollParent={document.getElementById(scrollParentId)}
          onRangeChange={handleNextPageFetch}
          renderItem={(track) => <TrackItem type="brief" tracklistId={brief.id} track={track as Track} />}
          className="pt-2"
          preProcessMagicPreview
        />
      </div>
    );
  }

  return (
    <BriefTrackItemListEmpty />
  );
}
