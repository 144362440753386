import { Icon } from '@iconify/react';
import { ChangeEvent, FocusEventHandler, MutableRefObject, Ref, forwardRef } from 'react';
import { InputProps } from '../input';

interface TextInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function Text(props: TextInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    iconLeft,
    iconRight,
    required = false,
    onChange,
    ...inputProps
  } = props;

  function handleClick() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className} data-no-dnd="true">
      <div className={containerClass} onClick={handleClick}>
        {label && (
        <span className={labelClass}>
          {label}
          {required && <span className="text-error-500">*</span>}
        </span>
        )}
        <div className="flex items-center gap-3">
          {iconLeft ? <Icon icon={iconLeft} className="text-light-500" /> : null}
          <input
            ref={inputRef}
            aria-label={label}
            placeholder={placeholder || label}
            className={inputClass}
            type="text"
            onChange={handleChange}
            {...inputProps}
          />
          {iconRight ? <Icon icon={iconRight} className="text-light-500" /> : null}
        </div>
      </div>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const TextInput = forwardRef(Text);
