import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from './search-input';
import { SearchResult } from './search-result';
import { CreatePlaylist } from './create-playlist';

export function AddToPlaylistContent() {
  const [query, setQuery] = useState('');
  const { t } = useTranslation('track-interactions');

  return (
    <DropdownMenu.SubMenuContainer>
      <SearchInput query={query} setQuery={setQuery} placeholder={t('add-playlist.dropdown.input')} />
      <CreatePlaylist />
      <SearchResult query={query} collection="playlists" />
    </DropdownMenu.SubMenuContainer>
  );
}
