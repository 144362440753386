import React from 'react';
import { motion } from 'framer-motion';
import SubCategory, { SubCategoryProps } from './sub-category';

interface SubCategoryListProps {
    subCategories?: SubCategoryProps[]
}

function SubCategoryList(props: SubCategoryListProps) {
  const { subCategories } = props;

  const listVariants = {
    hidden: {
      transition: {
        staggerChildren: 0.01,
        staggerDirection: -1,
      },
    },
    visible: {
      transition: {
        staggerChildren: 0.01,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      x: -16,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  return subCategories?.length ? (
    <motion.ul
      variants={listVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {subCategories?.map((subCategory) => (
        <motion.li variants={itemVariants}>
          <SubCategory
            key={subCategory.id}
            {...subCategory}
          />
        </motion.li>
      ))}
    </motion.ul>
  ) : null;
}

export default React.memo(SubCategoryList);
