import { KeyboardEvent, MouseEvent } from 'react';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useTrackItemContext } from '~/components/entities/track/track-item/track-item.context';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';

export function useTrackItem() {
  const trackStore = useTrackContext();
  const trackItemStore = useTrackItemContext();
  const { values } = useSelectionContext();

  const track = trackStore.use.track();

  const isOpen = trackItemStore.use.isOpen();
  const isTrackActive = trackStore.use.isTrackActive();
  const onAnimationComplete = trackItemStore.use.onAnimationComplete();
  const isSelected = values.some((v) => v.id === track.id);

  const onOpenChange = trackItemStore.use.onOpenChange();

  function handleClick(e: MouseEvent | KeyboardEvent) {
    if (e.ctrlKey || e.metaKey || e.shiftKey) return;
    onOpenChange(!isOpen);
  }

  return {
    isOpen,
    isTrackActive,
    isSelected,
    onAnimationComplete,
    handleClick,
  };
}
