import { TranslateSelect } from '~/components/ui/control/translate-select/translate-select';
import { useBriefContext } from '~/pages/briefs/brief.context';

export function LanguageSelector() {
  const { targetLanguage, handleTargetLanguageChange } = useBriefContext();

  return (
    <TranslateSelect targetLanguage={targetLanguage} onLanguageChange={handleTargetLanguageChange} />
  );
}
