import { components, MenuProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const Menu = (props: MenuProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children } = props;

  return (
    <components.Menu
      className="!bg-dark-300 shadow-lg !p-1 "
      {...props}
    >
      {children}
    </components.Menu>
  );
};

export default Menu;
