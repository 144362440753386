import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '~/components/ui/icon/icon';
import NotificationCard from './components/notification-group/notification-card/notification-card';
import { useNotificationCenter } from './hooks/use-notification-center';
import { useTranslation } from 'react-i18next';

type Props = {
  togglePanel: () => void;
}

function NotificationCenter({ togglePanel }: Props) {
  const { channel, handleCardClick, notifications, showLoadMoreButton } = useNotificationCenter();
  const { t } = useTranslation('notification-center');

  useEffect(() => {
    if (channel && channel.lastMessage.value) {
      channel.setLastReadMessageTimetoken(channel.lastMessage.value.timetoken);
    }
  }, [channel, channel?.lastMessage.value]);

  if (!channel || channel.isLoading.value) {
    return (
      <div className="w-96 p-3 pr-1 flex flex-col gap-3 bg-dark-300 rounded-lg h-[38rem] overflow-hidden shadow-lg">
        loading...
      </div>
    );
  }

  return (
    <div className="w-96 p-3 pr-1 flex flex-col gap-3 bg-dark-300 rounded-lg h-[38rem] overflow-hidden shadow-lg">
      <div className="flex items-center gap-2">
        <h2 className="text-base font-bold">{t('pop-in.header.title')}</h2>
        <span className="text-light-500 text-xs">{t('pop-in.header.count', {
          "count-unread": channel.unreadMessageCount.value,
        })}</span>
      </div>
      <div className="flex flex-col gap-2 overflow-y-scroll scrollbar-on-hover pr-1 self-stretch  h-full">
        {notifications.value.length ? (
          <>
            {notifications.value.map((notification) => (
              <NotificationCard
                key={notification.timetoken}
                notification={notification}
                onClick={() => handleCardClick(notification)}
                markRead={() => notification.markAsRead()}
              />
            ))}
            {showLoadMoreButton
              ? <button type="button" onClick={() => channel.getOlderMessages()} className="text-xs bg-dark-100 px-2.5 py-1 w-fit rounded">Load more</button>
              : null}
          </>
        ) : (
          <div className="flex flex-col h-full justify-center items-center">
            <h4 className="text-sm text-light-400">{t('pop-in.placeholder')}</h4>
          </div>
        )}
      </div>
      <div className="flex w-full justify-between pr-2 text-xs">
        <NavLink to="/notifications" className="flex items-center gap-1 text-primary-400">
          <a onClick={togglePanel}>{t('pop-in.footer.link')}</a>
          <Icon name="arrow-right-up-line" />
        </NavLink>
      </div>
    </div>
  );
}

export default NotificationCenter;
