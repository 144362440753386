import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import { useLicensingQuoteDialog } from '~/hooks/dialog/licensing-quote/use-licensing-quote-dialog';
import { DialogDescription, DialogTitle } from '../common';
import { DialogExit } from '../common/exit';

//TODO: Handle input values reset
export function LicensingQuoteHeader() {
  const { track } = useLicensingQuoteDialog();
  const { t } = useTranslation('request-form');

  return (
    <div className="w-full flex justify-between items-center px-4 mb-2">
      <div>
        <DialogTitle>{t('header.title')}</DialogTitle>
        <DialogDescription className="line-clamp-1 mr-4">
          {`${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`}
        </DialogDescription>
      </div>
      <div className="flex items-center gap-3">
        {/* <Button
          onClick={clearFields}
          className="!bg-transparent border border-dark-100 hover:!bg-dark-100"
        >
          {t('header.button.clear')}
        </Button> */}
        <DialogExit className="!bg-dark-100 hover:!bg-dark-200">
          {t('header.button.exit')}
        </DialogExit>
      </div>
    </div>
  );
}
