import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';

type LicenseQuoteFooterProps = {
  onClick: () => void;
};

export function LicensingQuoteFooter(props: LicenseQuoteFooterProps) {
  const { onClick } = props;
  const { t } = useTranslation('request-form');

  return (
    <div className="flex justify-end gap-3 mt-4">
      <Button className="playlist-form__submit-button" onClick={onClick}>
        {t('footer.button.send')}
      </Button>
    </div>
  );
}
