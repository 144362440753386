import React, { FocusEvent, MouseEvent, MutableRefObject, Ref, createRef } from 'react';
import './input.style.scss';
import classNames from 'classnames';
import { TextInput } from './components/text';
import { FileInput } from './components/file';
import { DateInput } from './components/date';
import { TimeInput } from './components/time';
import { TagInput } from './components/tag';
import { NumberInput } from './components/number';
import { PasswordInput } from './components/password';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InputProps<T = any> {
    value?: string;
    defaultValue?: string;
    label?: string;
    labelInfo?: string;
    error?: string;
    iconLeft?: string;
    iconRight?: string;
    className?: string;
    containerClassName?: string;
    labelClassName?: string;
    info?: string;
    placeholder?: string;
    autofocus?: boolean;
    type?: 'text' | 'date' | 'email' | 'password' | 'time' | 'file' | 'tags' | 'number';
    separator?: string;
    options?: T[];
    accept?: string;
    isLoading?: boolean;
    isFocused?: boolean;
    success?: boolean;
    required?: boolean;
    disabled?: boolean;
    render?: (props: T) => JSX.Element;
    onChange?: (value: string) => void;
    onNumberChange?: (value: number) => void;
    onOptionClick?: (e: MouseEvent, item: T) => void;
    onFilesChange?: (files: File[]) => void;
}

const Input = React.forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const {
    type = 'text',
    label,
    className,
    containerClassName,
    labelClassName,
    success,
    error,
    iconLeft,
    info,
    defaultValue = '',
    separator,
    value,
    onChange,
    onNumberChange,
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const inputRef = ref || createRef<HTMLInputElement>();

  const boxClassName = classNames('w-full', className);

  const containerClass = classNames(
    'relative transition-all flex flex-col py-2 px-3 justify-center cursor-text border-r bg-dark-300 rounded transition duration-200 hover:border-dark-100',
    {
      '!border-error-500': !!error,
      '!border-primary-500': isFocused,
      '!border-transparent': !isFocused,
      '!border-success-500': success,
      'opacity-70 pointer-event-none': inputProps.disabled,
    },
    containerClassName,
  );

  const inputClass = classNames(
    'w-full bg-transparent relative outline-none opacity-0 transition-all duration-200 appearance-none text-sm h-4 !z-0 placeholder:text-white/60',
    {
      'opacity-100': isFocused || !label || !!value || !!defaultValue,
      'mr-6': iconLeft || info,
    },
  );

  const labelClass = classNames(
    'text-light-100 text-sm translate-y-2.5 transition-all duration-200',
    {
      '!translate-y-0 !text-xxs text-light-500 leading-none mb-0': (true || value !== '' || defaultValue !== ''),
      'text-success-500': success,
      'text-error-500': error !== undefined,
    },
    labelClassName,
  );

  function handleChange(v: string) {
    onChange?.(v);
  }

  function handleNumberChange(v: number) {
    onNumberChange?.(v);
  }

  function handleFocus(e: FocusEvent) {
    e.stopPropagation();
    setIsFocused(true);
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  function handleBlur(e: FocusEvent) {
    e.stopPropagation();
    setIsFocused(false);
    (inputRef as MutableRefObject<HTMLInputElement>).current?.blur();
  }

  const commonProps = {
    ref: inputRef,
    type,
    label,
    value,
    defaultValue,
    isFocused,
    className: boxClassName,
    containerClass,
    labelClass,
    inputClass,
    separator,
    error,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onNumberChange: handleNumberChange,
    ...inputProps,
  };

  if (type === 'tags') {
    return (
      <TagInput {...commonProps} />
    );
  }

  if (type === 'file') {
    return (
      <FileInput {...commonProps} />
    );
  }

  if (type === 'date') {
    return (
      <DateInput {...commonProps} />
    );
  }

  if (type === 'time') {
    return (
      <TimeInput {...commonProps} />
    );
  }

  if (type === 'number') {
    return (
      <NumberInput
        {...commonProps}
      />
    );
  }

  if (type === 'password') {
    return (
      <PasswordInput
        {...commonProps}
      />
    );
  }

  return (
    <TextInput {...commonProps} />
  );
});

export default Input;
