import { components, GroupBase, IndicatorsContainerProps } from 'react-select';
import { OptionType } from '../select';

const IndicatorContainer = (
  props: IndicatorsContainerProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children } = props;

  return (
    <components.IndicatorsContainer
      className="absolute right-0 top-0 h-full flex"
      {...props}
    >
      {children}
    </components.IndicatorsContainer>
  );
};

export default IndicatorContainer;
