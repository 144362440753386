import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { Language } from '~/types/types/languages';

export const LANGUAGES: { value: SupportedLanguages, label: string }[] = [
  { value: 'en-US', label: 'English' },
  { value: 'fr-FR', label: 'Français' },
  { value: 'es-ES', label: 'Español' },
  { value: 'de-DE', label: 'Deutsch' },
  { value: 'ja-JP', label: '日本語' },
  { value: 'pt-PT', label: 'Português' },
];

export const SHORT_LANGUAGES: { value: Language, label: string }[] = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'es', label: 'Español' },
  { value: 'de', label: 'Deutsch' },
  { value: 'ja', label: '日本語' },
  { value: 'pt', label: 'Português' },
];
