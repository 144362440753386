import { useTagChip } from '~/hooks/entities/track/chips/use-tag-chip';
import TrackChip from './chip';

type TrackChipTagProps = {
  id: string;
  type: 'tag' | 'target';
};

export function TrackChipTag(props: TrackChipTagProps) {
  const { id, type } = props;
  const { tag, onClick } = useTagChip(id);

  if (type === 'target' && tag.type === 1) {
    return <TrackChip color={tag.color} label={tag.name} onClick={onClick} />;
  }

  if (type === 'tag' && tag.type === 0) {
    return <TrackChip color={tag.color} label={tag.name} onClick={onClick} />;
  }

  return null;
}
