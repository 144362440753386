import { useMemo } from 'react';
import useDataStore from '~/stores/mewo-data-store';
import { OwnerShips, Owner } from '~/types/schemas/music/track.schema';
import { FilterList } from '~/types/schemas/search/filter.schema';

export function useParsedRightsHolder(ownerships: OwnerShips) {
  const rights = useDataStore((s) => s.rights.object);
  const parsedRightsHolder = useMemo((): Record<string, Owner[]> => {
    const parsedOwner: Record<string, Owner[]> = {};

    const parseOwner = (owner: Owner, type: keyof FilterList) => {
      if (rights[owner.rights_type]?.name) {
        if (parsedOwner[type]) {
          parsedOwner[type]?.push(owner);
        } else {
          parsedOwner[type] = [owner];
        }
      }
    };

    ownerships?.master.artists.forEach((artist) => {
      parseOwner(artist, 'artists');
    });

    ownerships?.publishing.artists.forEach((artist) => {
      parseOwner(artist, 'artists');
    });

    ownerships?.master.labels.forEach((label) => {
      parseOwner(label, 'labels');
    });

    ownerships?.publishing.publishers.forEach((publisher) => {
      parseOwner(publisher, 'publishers');
    });

    return parsedOwner;
  }, [rights, ownerships]);

  return parsedRightsHolder;
}
