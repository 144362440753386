import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAlbumSearch } from '~/hooks/api/album';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';

function Album() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const { data: albums, fetchNextPage, isFetchingNextPage, hasNextPage } = useAlbumSearch(addFilter.query.album);
  const { handleClick: addToFilter, handleArtistClick: addArtistToFilter } = useCardClick('album');
  const { t } = useTranslation('filter');

  const flattenAlbums = albums?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  function handleArtistClick(artist: DisplayArtist) {
    addArtistToFilter(artist);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.albums.tab.title')} value="album">
      <ItemSearch
        itemType="album"
        inputPlaceholder={t('panel.albums.tab.input.placeholder')}
        value={addFilter.query.album}
        onValueChange={(q) => addFilter.setQuery('album', q)}
        items={flattenAlbums?.map((album) => ({
          type: 'album',
          artworks: album.artwork ? [album.artwork] : [],
          title: album.title,
          customSubtitle: album.artists.length ? <ClickableNameList onNameClick={(a) => handleArtistClick(a)} names={album.artists} /> : undefined,
          subtitlePrefix: album.release_date ? dayjs(album.release_date).format('YYYY') : undefined,
          onIncludeClick: () => handleClick(album.title, album.id, 'is'),
          onExcludeClick: () => handleClick(album.title, album.id, 'is_not'),
        }))}
        totalCount={flattenAlbums.length}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        isEndReached={!hasNextPage}
      />
    </Tab>
  );
}

export default Album;
