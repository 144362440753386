import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useCatalogFilter } from '~/hooks/filter/use-catalog-filter';

export function useCatalogCard() {
  const { onCatalogClick } = useCatalogFilter();
  const store = useTrackContext();
  const track = store.use.track();

  function handleClick() {
    if (track.catalog) onCatalogClick(track.catalog?.id, track.catalog?.name);
  }

  return {
    content: track.catalog?.name,
    onClick: handleClick,
  };
}
