import { createContext, useContext } from 'react';
import { StoreApi } from 'zustand';
import { WithSelectors } from '~/stores/utilities';

export type TrackItemState = {
  isOpen: boolean
  isAnimationComplete: boolean
  onAnimationComplete: (complete: boolean) => void
  onOpenChange: (isOpen: boolean) => void
};

const TrackItemContext = createContext<StoreApi<TrackItemState> & WithSelectors<StoreApi<TrackItemState>> | null>(null);

function useTrackItemContext() {
  const store = useContext(TrackItemContext);

  if (!store) {
    throw new Error('useTrackItemContext must be used within a TrackItem.Root');
  }
  return store;
}

export { TrackItemContext, useTrackItemContext };
