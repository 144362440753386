import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type DropdownMenuItemProps = {
    children: React.ReactNode;
};

export function ContextSubMenu(props: DropdownMenuItemProps) {
  const { children } = props;

  return (
    <DropdownMenu.Sub>
      {children}
    </DropdownMenu.Sub>
  );
}
