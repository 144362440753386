import { useTranslation } from "react-i18next";

export function SearchTrackItemListEmpty() {
  const { t } = useTranslation('search');

  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <span className="text-xl font-bold">{t('empty-search-list.title')}</span>
      <p className="text-light-400">{t('empty-search-list.description')}</p>
    </div>
  );
}