import { createWithEqualityFn } from 'zustand/traditional';
import { withImmer } from './middlewares/immer-combine';
import { createStorePicker } from './utilities';

const useElementSizeStore = createStorePicker(createWithEqualityFn(
  withImmer({
    columnNumber: undefined as number | undefined,
    sidebar: {} as DOMRectReadOnly,
    content: {} as DOMRectReadOnly,
    player: {} as DOMRectReadOnly,
    presearchInput: {} as DOMRectReadOnly,
    advancedSearch: {} as DOMRectReadOnly,
  })(
    (set) => ({
      setSidebar: (sidebar: DOMRectReadOnly) => set((state) => {
        state.sidebar = sidebar;
      }),
      setContent: (content: DOMRectReadOnly) => set((state) => {
        state.content = content;
      }),
      setPlayer: (player: DOMRectReadOnly) => set((state) => {
        state.player = player;
      }),
      setAdvancedSearch: (advancedSearch: DOMRectReadOnly) => set((state) => {
        state.advancedSearch = advancedSearch;
      }),
      setPresearchInputSize: (presearchInputSize: DOMRectReadOnly) => set((state) => {
        state.presearchInput = presearchInputSize;
      }),
      setColumnNumber: (maxItems: number) => set((state) => {
        state.columnNumber = maxItems;
      }),
    }),
  ),
));

export default useElementSizeStore;
