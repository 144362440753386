import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AddToPlaylistContent } from './playlist-content';
import { AddToBriefContent } from './brief-content';
import { SubmenuTrigger } from './submenu-trigger';

type TrackActionsAddToTracklistProps = {
  type: 'brief' | 'playlist';
};

export function TrackActionsAddToTracklist(
  props: TrackActionsAddToTracklistProps,
) {
  const { type } = props;
  const { t } = useTranslation('track-interactions');

  useEffect(() => {
    // disable navigation with keyboard
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }, []);

  if (type === 'brief') {
    return (
      <DropdownMenu.SubMenu>
        <SubmenuTrigger label={t('add-brief.trigger')} />
        <AddToBriefContent />
      </DropdownMenu.SubMenu>
    );
  }

  if (type === 'playlist') {
    return (
      <DropdownMenu.SubMenu>
        <SubmenuTrigger label={t('add-playlist.trigger')} />
        <AddToPlaylistContent />
      </DropdownMenu.SubMenu>
    );
  }

  return null;
}
