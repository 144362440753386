import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';

type TabTriggerProps = {
    label: string;
    value: string;
};

function TabTrigger(props: TabTriggerProps) {
  const { label, value } = props;
  return (
    <Tabs.Trigger
      value={value}
      className={classNames(
        `filter-panel__tab-trigger--${value}`,
        'text-left w-full rounded px-2 text-light-100 transition-colors duration-200 ease-in-out',
        'bg-dark-300 hover:bg-dark-100 data-[state=active]:bg-dark-100',
      )}
    >
      <span className="text-xs">{label}</span>
    </Tabs.Trigger>
  );
}

export default TabTrigger;
