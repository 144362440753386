import { z } from 'zod';

export const SearchResultSchema = z.object({
  total: z.object({
    value: z.number(),
    relation: z.string(),
  }),
  hits: z.array(z.any()),
});

export function createSearchResultSchema<S extends z.Schema>(schema: S) {
  return z.object({
    total: z.object({
      value: z.number(),
      relation: z.string(),
    }),
    hits: schema.array(),
  });
}

export type SearchResult = z.infer<typeof SearchResultSchema>;
