import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type PlaylistTrackItemLayoutProps = PropsWithChildren<{
    className?: string
}>;

export function PlaylistTrackItemLayout(props: PlaylistTrackItemLayoutProps) {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        'grid items-center gap-4',
        'grid-cols-playlist-track-item-base',
        '@[50rem]:grid-cols-playlist-track-item-lg',
        className,
      )}
    >
      {children}
    </div>
  );
}
