import * as Tabs from '@radix-ui/react-tabs';
import { ReactNode } from 'react';
import classNames from 'classnames';
import AddFilterElement from '../../filter-panel-element';

type TabProps = {
    label: string;
    value: string;
    children: ReactNode;
};

function Tab(props: TabProps) {
  const { label, value, children } = props;

  return (
    <Tabs.Content
      value={value}
      className={classNames(
        `filter-panel__tab--${value}`,
        'gap-6 h-full',
      )}
    >
      <AddFilterElement label={label} className="w-full">
        {children}
      </AddFilterElement>
    </Tabs.Content>
  );
}

export default Tab;
