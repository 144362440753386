import classNames from 'classnames';
import { TrackCardAlbum } from './album';
import { TrackCardReleaseDate } from './release';
import { TrackCardCatalog } from './catalog';

type CardProps = {
    label: string,
    content?: string
    onContentClick?: () => void
};

function TrackCard(props: CardProps) {
  const { label, content, onContentClick } = props;

  return (
    <div className="flex w-full flex-col gap-1 p-2 rounded-lg bg-dark-100/30">
      <h5 className="text-xs text-light-500">
        {label}
      </h5>
      <div className="flex gap-2">
        <h6
          onClick={onContentClick}
          className={classNames('text-sm', {
            'cursor-pointer hover:underline': onContentClick && content,
            'pointer-events-none': !onContentClick || !content,
          })}
        >
          {content || '-'}
        </h6>
      </div>
    </div>
  );
}

const Album = TrackCardAlbum;
const ReleaseDate = TrackCardReleaseDate;
const Catalog = TrackCardCatalog;

export default TrackCard;

export {
  Album,
  ReleaseDate,
  Catalog,
};
