import { useDownloadTrackCopyright } from '~/hooks/api/track';
import { Track } from '~/types/schemas/music/track.schema';

export function useDownloadCopyrights(track: Track) {
  const { mutate: mutateDownloadTrackCopyright } = useDownloadTrackCopyright();

  function downloadTrackCopyright() {
    mutateDownloadTrackCopyright({ id: track.id });
  }

  return {
    downloadTrackCopyright,
  };
}
