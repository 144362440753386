import { useTagData } from '~/hooks/data/use-tag-data';
import { useTagFilter } from '~/hooks/filter/use-tag-filter';

export function useTagChip(id: string) {
  const { tag } = useTagData(id);
  const { onTagClick } = useTagFilter();

  function handleClick() {
    onTagClick(id, tag.name);
  }

  return {
    tag,
    onClick: handleClick,
  };
}
