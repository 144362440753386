import { useBriefContext } from '../../brief.context';
import AgentList from '../agent-list';

export function AgentListChip() {
  const { data } = useBriefContext();
  const { owned_by_tenant: ownedByTenant, agents } = data;

  return ownedByTenant ? (
    <AgentList recipients={agents} />
  ) : null;
}
