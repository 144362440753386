import classNames from 'classnames';
import Cover from './cover';
import { CircleButton } from './circle-button';

type TrackCardPreviewProps = {
    cover?: string
    title?: string
    version?: string
    subtitle?: string
    isSelected?: boolean
    isValidated?: boolean
    onSelectClick?: () => void
    onEditClick?: () => void
    onDeleteClick?: () => void
};

export function TrackInformationDisplay(props: TrackCardPreviewProps) {
  const { cover, title, version, subtitle, isSelected, isValidated = false, onEditClick, onSelectClick, onDeleteClick } = props;
  const buttonClass = 'w-6 h-6 shrink-0 rounded-full flex items-center justify-center';

  return (
    <div className={classNames('relative w-full overflow-hidden shrink-0', {
      'bg-dark-300/20': !cover,
      'row-span-full': !isSelected,
    })}
    >
      <div className="absolute w-full h-full -z-10 ">
        {cover && <div className="absolute w-full h-full bg-gradient-to-r from-dark-500" />}
        <img src={cover} alt="" className="w-full h-full object-cover object-center blur-3xl " />
      </div>
      <div className="flex h-full gap-4 items-center justify-between p-3 pr-5">
        <Cover
          img={cover}
          title={title}
          version={version}
          artists={subtitle}
        />
        <div className="w-fit flex gap-4 min-w-6 shrink-0">
          {(isSelected && onEditClick) && (
            <CircleButton
              icon="ri:pencil-line"
              className={classNames(buttonClass, 'bg-dark-300 border border-dark-100')}
              onClick={onEditClick}
            />
          )}
          {(!isSelected) && (
            <div className="flex gap-3 items-center">
              <CircleButton
                icon="ri:delete-bin-line"
                className={classNames(buttonClass, 'transition-all delay-100 bg-dark-300 border border-dark-100 flex opacity-0 group-hover/track-item:opacity-100')}
                onClick={onDeleteClick}
              />
              <div className="relative">
                <CircleButton
                  icon="ri:check-line"
                  className={classNames(buttonClass, 'absolute inset-0 transition-all pointer-events-none bg-success-500 border-none group-hover/track-item:opacity-0', {
                    'opacity-0 pointer-events-none': !isValidated,
                  })}
                />
                <CircleButton
                  icon="ri:eye-fill"
                  className={classNames(buttonClass, 'transition-all delay-100 bg-dark-300 border border-dark-100 flex opacity-0 group-hover/track-item:opacity-100')}
                  onClick={onSelectClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
