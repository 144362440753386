import { InfiniteData, QueryKey } from '@tanstack/react-query';
import { queryClient } from '~/boot/react-query';
import { SearchResult } from '~/types/schemas/search/search.schema';

export function filterInfiniteData<T>(
  data: InfiniteData<SearchResult>,
  filterFunction: (item: T) => boolean,
): InfiniteData<SearchResult> {
  const [firstPage, ...otherPages] = data?.pages || [];
  const total = firstPage?.total.value || 0;

  return {
    ...data,
    pages: [{
      hits: firstPage?.hits.filter(filterFunction) || [],
      total: {
        ...firstPage?.total,
        value: total > 0 ? total - 1 : 0,
      },
    }, ...otherPages],
  } as InfiniteData<SearchResult>;
}

export function updateInfiniteDataItem<T>(
  data: InfiniteData<SearchResult>,
  itemUpdater: (item: T) => T,
  findFunction: (item: T) => boolean,
): InfiniteData<SearchResult> {
  const [firstPage, ...otherPages] = data?.pages || [];
  const total = firstPage?.total.value || 0;

  return {
    ...data,
    pages: [{
      hits: firstPage?.hits.map((item) => (findFunction(item as T) ? itemUpdater(item) : item)) || [],
      total: {
        ...firstPage?.total,
        value: total,
      },
    }, ...otherPages],
  } as InfiniteData<SearchResult>;
}

export function addInfiniteDataItems<T>(
  data: InfiniteData<SearchResult>,
  newItems: T[],
): InfiniteData<SearchResult> {
  const [firstPage, ...otherPages] = data?.pages || [];
  const total = firstPage?.total.value || 0;

  return {
    ...data,
    pages: [{
      hits: [...newItems, ...(firstPage?.hits || [])],
      total: {
        ...firstPage?.total,
        value: total + 1,
      },
    }, ...otherPages],
  } as InfiniteData<SearchResult>;
}

export function reorderInfiniteDataItem<T>(
  data: InfiniteData<SearchResult>,
  sortFunction: (a: T, b: T) => number,
): InfiniteData<SearchResult> {
  const [firstPage, ...otherPages] = data?.pages || [];
  const total = firstPage?.total.value || 0;

  return {
    ...data,
    pages: [{
      hits: firstPage?.hits.sort((a, b) => {
        return sortFunction(a as T, b as T);
      }) || [],
      total: {
        ...firstPage?.total,
        value: total,
      },
    }, ...otherPages],
  } as InfiniteData<SearchResult>;
}

export function multipleDeleteUpdater<T>(
  queryKey: QueryKey,
  filterFunction: (item: T) => boolean,
) {
  queryClient.setQueriesData<InfiniteData<SearchResult>>(
    { queryKey },
    (oldData) => filterInfiniteData(oldData as InfiniteData<SearchResult>, filterFunction),
  );
}

export function updateInfiniteDataUpdater<T>(
  queryKey: QueryKey,
  itemUpdater: (item: T) => T,
  findFunction: (item: T) => boolean,
) {
  queryClient.setQueriesData<InfiniteData<SearchResult>>(
    { queryKey },
    (oldData) => updateInfiniteDataItem(oldData as InfiniteData<SearchResult>, itemUpdater, findFunction),
  );
}

export function addInfiniteDataUpdater<T>(
  queryKey: QueryKey,
  newItem: T,
) {
  queryClient.setQueriesData<InfiniteData<SearchResult>>(
    { queryKey },
    (oldData) => addInfiniteDataItems(oldData as InfiniteData<SearchResult>, [newItem]),
  );
}

export function addMultipleInfiniteDataUpdater<T>(
  queryKey: QueryKey,
  newItems: T[],
) {
  queryClient.setQueriesData<InfiniteData<SearchResult>>(
    { queryKey },
    (oldData) => addInfiniteDataItems(oldData as InfiniteData<SearchResult>, newItems),
  );
}

export function reorderInfiniteDataUpdater<T>(
  queryKey: QueryKey,
  sortFunction: (a: T, b: T) => number,
) {
  queryClient.setQueriesData<InfiniteData<SearchResult>>(
    { queryKey },
    (oldData) => reorderInfiniteDataItem(oldData as InfiniteData<SearchResult>, sortFunction),
  );
}

export function updateData<T>(
  queryKey: QueryKey,
  updater: (oldData: T) => T,
) {
  queryClient.setQueriesData<T>(
    { queryKey },
    (oldData) => updater(oldData as T),
  );
}
