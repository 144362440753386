import * as TrackActionsDownload from './download/download';
import { TrackActionsSearchSimilarTracks } from './similarity-search';
import { TrackActionsCopyrightModal } from './copyright-modal';
import { TrackActionsLicensingQuoteModal } from './licensing-quote';
import { TrackActionsLyricsModal } from './lyrics';
import { TrackActionsAddToTracklist } from './add-to-tracklist/add-to-tracklist';
import { TrackActionsRemoveFromTracklist } from './remove-from-tracklist';

const Similarity = TrackActionsSearchSimilarTracks;
const Download = TrackActionsDownload;
const Copyright = TrackActionsCopyrightModal;
const LicensingQuote = TrackActionsLicensingQuoteModal;
const Lyrics = TrackActionsLyricsModal;
const AddToTracklist = TrackActionsAddToTracklist;
const RemoveFromTracklist = TrackActionsRemoveFromTracklist;

export {
  Similarity,
  Download,
  Copyright,
  LicensingQuote,
  Lyrics,
  AddToTracklist,
  RemoveFromTracklist,
};
