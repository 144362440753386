import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { useAddToTracklist } from '~/hooks/entities/track/actions/use-add-track-to-tracklist';

export function CreatePlaylist() {
  const { t } = useTranslation('track-interactions');
  const { createNewPlaylist } = useAddToTracklist();

  return (
    <DropdownMenu.Item icon="ri:add-line" onClick={createNewPlaylist}>{t('add-playlist.dropdown.footer')}</DropdownMenu.Item>
  );
}
