import { reset } from 'linkifyjs';
import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';

export function useTagFilter() {
  const search = useSearchStore(['addValueToNewBlock', 'resetFilters', 'facets']);

  function handleClick(
    id: string,
    label: string,
    type: 'tags' | 'tag_categories',
    condition: keyof typeof itemConditionsOptions = 'is',
    reset = false
  ) {
    if(reset) search.resetFilters();
    search.addValueToNewBlock(termOptions[type]!, itemConditionsOptions[condition]!, {
      value: id,
      label,
    });
  }


  function handleTagClick(
    id: string,
    label: string,
    type: 'tags' | 'tag_categories' | undefined = 'tags',
    condition: keyof typeof itemConditionsOptions | undefined = 'is',
    reset:boolean | undefined = false
  ) {
    handleClick(id, label, type, condition, reset);
  }

  function handleAddTagClick(id: string, label: string) {
    handleClick(id, label, 'tags', 'is');
  }

  function handleSubtractTagClick(id: string, label: string) {
    handleClick(id, label, 'tags', 'is_not');
  }

  function handleSubCategoryAddClick(id: string, label: string) {
    handleClick(id, label, 'tag_categories', 'is');
  }

  function handleSubCategorySubtractClick(id: string, label: string) {
    handleClick(id, label, 'tag_categories', 'is_not');
  }

  return {
    onAddTagClick: handleAddTagClick,
    onSubtractTagClick: handleSubtractTagClick,
    onTagClick: handleTagClick,
    onSubCategoryAddClick: handleSubCategoryAddClick,
    onSubCategorySubtractClick: handleSubCategorySubtractClick,
  };
}
