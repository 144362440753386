import { components, PlaceholderProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const Placeholder = (
  props: PlaceholderProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children, isFocused } = props;

  return (
    <components.Placeholder
      className="absolute capitalize inset-0 flex items-center h-full !m-0"
      {...props}
    >
      {isFocused && children}
    </components.Placeholder>
  );
};

export default Placeholder;
