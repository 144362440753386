import Pubnub from 'pubnub';
import { signal } from '@preact/signals-react';
import { RealtimeUser } from '~/types/types/realtime';

export const realtimeStore = {
  ready: signal(false),
  error: signal<Pubnub.PubnubStatus | undefined>(undefined),
  users: signal<Record<string, RealtimeUser>>({}),
  userToFetch: signal<string[]>([]),
  addUserToFetch(id: string) {
    if (!this.users.value[id] && !this.userToFetch.value.includes(id)) {
      this.userToFetch.value = [...this.userToFetch.value, id];
    }
  },
};
