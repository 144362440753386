import { useCreateBrief } from '~/hooks/api/brief';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateButton from './create-button';

const CreateBrief = () => {
  const { mutate: createBrief } = useCreateBrief();
  const navigate = useNavigate();
  const { t } = useTranslation('brief-form');

  function handleClick() {
    createBrief({ title: t('input.title.default') }, {
      onSuccess: (data) => {
        navigate(`/briefs/${data.id}`);
      },
    });
  }

  return (
    <CreateButton onClick={handleClick} />
  );
};

export default CreateBrief;
