/* eslint-disable camelcase */
import { useParams } from 'react-router-dom';

import { useGetPlaylistDetails } from '~/hooks/api/playlist';
import { PlaylistTrackItemList } from '~/components/entities/track/track-item-list/playlist/playlist-track-item-list';
import PlaylistHeader from './components/header';

export function Playlist() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPlaylistDetails(id);
  const isReady = id && data && !isLoading;

  const scrollId = `playlist-content-${id}`;

  return isReady && (
    <section className="relative self-stretch h-full overflow-hidden">
      <div className="flex h-full">
        <div className="pt-4 flex-1">
          <div id={scrollId} className="flex flex-col h-full w-full overflow-y-auto items-stretch">
            <PlaylistHeader detail={data} className="z-10" />
            <div className="h-full">
              <div className="flex flex-col px-4 h-fit min-h-full z-0">
                <PlaylistTrackItemList playlist={data} scrollParentId={scrollId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Playlist;
