import Cover from '~/components/ui/cover/cover';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { useInView } from 'react-intersection-observer';
import { Channel } from '~/utils/chat/channel';
import { realtimeStore } from '~/stores/realtime-store';
import { briefChatsStore } from '~/stores/chats-store';
import { useComputed } from '@preact/signals-react';
import { globalStore } from '~/stores/global-store-v2';
import { useGetThreadTracks } from '~/hooks/api/thread';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import { parseMessage } from '../../chat/chat-messages/message-group/message/message-parser';
import ChatCardLoader from './chat-card-loader';

/* eslint-disable react/no-unused-prop-types */
export type ChatCardProps = {
  channel: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>;
  onClick?: () => void;
};

function ChatCard(props: ChatCardProps) {
  const { user } = globalStore;
  const { channel, onClick } = props;
  const { ref, inView } = useInView();
  const textParts = parseMessage(
    channel.lastMessage.value?.deleted.value
      ? '<Message deleted>'
      : channel.lastMessage.value?.content.text || '',
  );
  const { brief } = briefChatsStore;
  const { data: tracks, isLoading: trackIsLoading } = useGetThreadTracks(brief.value?.id, channel.custom?.trackId ? [channel.custom?.trackId] : []);

  const messageUser = useComputed(() => (
    channel.lastMessage.value?.userId
      ? realtimeStore.users.value[channel.lastMessage.value.userId]
      : undefined
  ));
  const isUnread = useComputed(() => !channel.lastMessage.value?.isReadByUser(user.value!.id));

  useEffect(() => {
    if (inView) {
      channel.init();
    }
  }, [inView]);

  if (channel.isLoading.value || trackIsLoading) {
    return <ChatCardLoader ref={ref} />;
  }

  return (
    <div ref={ref} className="thread-list__item relative flex items-stretch gap-3 py-3 pl-3 pr-6 bg-dark-200 border border-transparent hover:border-dark-100 rounded-lg cursor-pointer" onClick={onClick}>
      <Cover artworks={tracks?.[0]?.artwork && [tracks?.[0].artwork]} alt={tracks?.[0]?.title || ''} className="rounded !w-10 !h-10 shrink-0" iconClassName="text-base" />
      <div className="w-full flex flex-col overflow-hidden gap-3">
        <div>
          <h6 className="text-sm text-light-100 line-clamp-1">{tracks?.[0]?.title}</h6>
          <ClickableNameList names={tracks?.[0]?.display_artists} />
        </div>
        <div className="flex flex-col gap-1 w-full text-xs">
          <span className="text-light-100 line-clamp-1">{`${messageUser.value?.name} (${messageUser.value?.custom?.company}) :`}</span>
          <p className="text-light-500 line-clamp-3">
            {textParts.map((part, i) => (
              <span
                key={`${part.text}-${part.type}-${i}`}
                className={classNames({
                  'font-semibold': part.type === 'mention',
                })}
              >
                {part.text}
              </span>
            ))}
          </p>
          {/* <span className="text-light-100">{`${messageCount} Messages`}</span> */}
        </div>
      </div>
      {isUnread.value ? (
        <div className="w-3 h-3 rounded-full bg-primary-500 absolute right-3 top-3" />
      ) : null}
    </div>
  );
}

export default React.memo(ChatCard);
