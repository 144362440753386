import { useGetTrackVersions } from '~/hooks/api/track';
import { useTrackContext } from '~/components/entities/track/common/track.context';

export function useTrackVersions() {
  const store = useTrackContext();
  const track = store.use.track();
  const { data: versions, isLoading } = useGetTrackVersions(track.id);

  return {
    versions,
    isLoading,
  };
}
