import { useEffect, useState } from 'react';
import useMusemindStore from '~/stores/musemind-store';
import VideoIcon from 'virtual:icons/ri/video-line';

const VideoThumbnail = () => {
  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const musemind = useMusemindStore(['videoElement', 'isPlaying']);

  useEffect(() => {
    if (!musemind.videoElement) return;
    if (!thumbnail && musemind.isPlaying) return;

    const canvas = document.createElement('canvas');

    canvas.width = musemind.videoElement.videoWidth;
    canvas.height = musemind.videoElement.videoHeight;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    context.drawImage(musemind.videoElement, 0, 0, canvas.width, canvas.height);

    const newThumbnail = canvas.toDataURL('image/png');

    if (newThumbnail !== 'data:,') setThumbnail(newThumbnail);
  }, [musemind.videoElement, musemind.isPlaying]);

  return (
    thumbnail ? <img src={thumbnail} alt="video" className="w-full h-full object-cover" /> : <VideoIcon />
  );
};

export default VideoThumbnail;
