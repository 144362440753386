import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type LibraryHeaderProps = {
  total?: number;
  className?: string;
};

const PlaylistLibraryHeader = (props: LibraryHeaderProps) => {
  const { total, className } = props;
  const { t } = useTranslation('libraries');

  return (
    <div className={classNames('bg-dark-500 z-10', className)}>
      <h1 className="w-full text-xl flex justify-between items-center">
        {`${t('playlists.title')} ${total ? `(${total})` : ''}`}
      </h1>
    </div>
  );
};

export default PlaylistLibraryHeader;
