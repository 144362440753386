import usePreSearchStore from '~/stores/presearch-store';
import { useTranslation } from 'react-i18next';
import translatedFilterHighlightRaw from '~/data/presearch-filter-highlight.json';
import { TranslatedFilterHighlightSchema } from '~/types/schemas/data/translated-filter-hightlight';
import i18n from '~/i18n/config';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import PresearchBlock from '../../../presearch-block/presearch-block';
import FilterHighLight from '../../../filter-hightlight/filter-hightlight';
import { TagChip } from '~/components/ui/control/tag-chip/tag-chip';
import { ColoredTagChip } from '~/components/ui/control/tag-chip/colored-tag-chip';

const translatedFilterHighlight = TranslatedFilterHighlightSchema.parse(
  translatedFilterHighlightRaw,
);

function FiltersHighlightBlock() {
  const presearch = usePreSearchStore(['setCurrentTab']);
  const { t } = useTranslation('pre-search');

  const filterHighlight =
    translatedFilterHighlight[i18n.language as SupportedLanguages]!;

  return (
    <PresearchBlock
      label={t('tab.home.filters.label')}
      childrenClassName="flex gap-6"
      containerClassName="col-span-full"
    >
      <FilterHighLight
        id="presearch-filter-highlight-block-tags"
        title={filterHighlight.tag.title}
        description={filterHighlight.tag.description}
        color="#672BE9"
        icon="tag"
        onCardClick={() => presearch.setCurrentTab('tags')}
        exampleLabel={filterHighlight.tag!.query.title}
        queryExample={
          <div className="flex gap-2 text-sm flex-wrap">
            {filterHighlight.tag.query.content.map((item, index) => {
              const splitItem = item.split(':');

              if (splitItem.length === 1) {
                return splitItem[0];
              }

              return (
                <ColoredTagChip
                  key={index}
                  label={splitItem[1]!}
                  color={splitItem[2]}
                />
              );
            })}
          </div>
        }
      />
      <FilterHighLight
        id="presearch-filter-highlight-block-targets"
        title={filterHighlight.target.title}
        description={filterHighlight.target.description}
        color="#8645D8"
        icon="target"
        onCardClick={() => presearch.setCurrentTab('targets')}
        exampleLabel={filterHighlight.target!.query.title}
        queryExample={
          <div className="flex gap-2 text-sm flex-wrap">
            {filterHighlight.target.query.content.map((item, index) => {
              const splitItem = item.split(':');

              if (splitItem.length === 1) {
                return splitItem[0];
              }

              return (
                <ColoredTagChip
                  key={index}
                  label={splitItem[1]!}
                  color={splitItem[2]}
                />
              );
            })}
          </div>
        }
      />
    </PresearchBlock>
  );
}

export default FiltersHighlightBlock;
