/* eslint-disable prefer-destructuring */
import { createWithEqualityFn } from 'zustand/traditional';
import { createStorePicker } from './utilities';
import { withImmer } from './middlewares/immer-combine';

const useMusemindStore = createStorePicker(
  createWithEqualityFn(
    withImmer({
      videoElement: undefined as HTMLVideoElement | undefined,
      videoName: undefined as string | undefined,
      currentAudioTrack: undefined as HTMLAudioElement | undefined,
      trackId: undefined as string | undefined,
      audioTitle: undefined as string | undefined,
      audioTracks: undefined as HTMLAudioElement[] | undefined,
      selectedAudioTrack: 0,
      pipPosition: undefined as DOMRectReadOnly | undefined,
      volume: 50,
      isMuted: false,
      isPlaying: false,
      isPipOpen: false,
      isUploadDialogOpen: false,
      isCreateVariationsLoading: false,
    })((set) => ({
      setVideoElement: (videoName: string, videoElement: HTMLVideoElement) =>
        set((state) => {
          if (state.videoElement) {
            state.videoElement.pause();
            state.isPlaying = false;
            state.isMuted = false;
            state.volume = 50;
            state.pipPosition = undefined;
          }
          state.videoElement = videoElement;
          state.videoName = videoName;
        }),
      setAudioTracks: (title: string, trackId: string, audioTracksURLs: string[]) =>
        set((state) => {
          if (state.audioTracks) {
            state.audioTracks.forEach((audioTrack) => {
              audioTrack.pause();
            });
          }
          const audioTracks = audioTracksURLs.map((audioTrackURL) => {
            const audioTrack = new Audio(audioTrackURL);
            audioTrack.volume = state.volume / 100;
            audioTrack.muted = state.isMuted;
            return audioTrack;
          });
          state.trackId = trackId;
          state.isPipOpen = true;
          state.audioTracks = audioTracks;
          state.audioTitle = title;
          state.currentAudioTrack = audioTracks[0];
          state.selectedAudioTrack = 0;
          state.videoElement!.volume = 0;
          state.videoElement!.currentTime = 0;
          state.isPlaying = true;
          state.videoElement!.play();
          state.currentAudioTrack!.play();
        }),
      setSelectedAudioTrack: (selectedAudioTrack: number) =>
        set((state) => {
          state.selectedAudioTrack = selectedAudioTrack;
          state.currentAudioTrack!.pause();
          state.videoElement!.pause();
          state.isPlaying = false;

          if (selectedAudioTrack !== -1) {
            state.videoElement!.volume = 0;
            state.currentAudioTrack = state.audioTracks![selectedAudioTrack];
            state.currentAudioTrack!.currentTime = 0;
            state.currentAudioTrack!.volume = state.volume / 100;
            state.currentAudioTrack!.muted = state.isMuted;
            state.currentAudioTrack!.play();
          } else {
            state.videoElement!.volume = state.volume / 100;
          }
          state.videoElement!.currentTime = 0;
          state.videoElement!.play();
          state.isPlaying = true;
        }),
      setIsUploadDialogOpen: (isUploadDialogOpen: boolean) =>
        set((state) => {
          state.isUploadDialogOpen = isUploadDialogOpen;
        }),
      setPipPosition: (pipPosition: DOMRectReadOnly) =>
        set((state) => {
          state.pipPosition = pipPosition;
        }),
      setIsPipOpen: (isPipOpen: boolean) =>
        set((state) => {
          state.isPipOpen = isPipOpen;
          state.videoElement?.pause();
          state.currentAudioTrack?.pause();
          state.isPlaying = false;
        }),
      setIsCreateVariationsLoading: (isCreateVariationLoading: boolean) =>
        set((state) => {
          state.isCreateVariationsLoading = isCreateVariationLoading;
        }),
      videoSeek: (time: number) =>
        set((state) => {
          if (state.videoElement) {
            state.videoElement.currentTime = time;
          }
          if (state.currentAudioTrack) {
            state.currentAudioTrack.currentTime = time;
          }
        }),
      togglePipOpen: () =>
        set((state) => {
          state.isPipOpen = !state.isPipOpen;
        }),
      togglePlay: () =>
        set((state) => {
          if (state.videoElement) {
            if (state.videoElement.paused) {
              state.isPlaying = true;
              state.currentAudioTrack?.play();
              state.videoElement.play();
            } else {
              state.isPlaying = false;
              state.currentAudioTrack?.pause();
              state.videoElement.pause();
            }
          }
        }),
      setPlay: (isPlaying: boolean) =>
        set((state) => {
          state.isPlaying = isPlaying;
          if (isPlaying) {
            state.currentAudioTrack?.play();
            state.videoElement?.play();
          }
          if (!isPlaying) {
            state.currentAudioTrack?.pause();
            state.videoElement?.pause();
          }
        }),
      toggleMute: () =>
        set((state) => {
          state.isMuted = !state.isMuted;

          if (state.currentAudioTrack) {
            state.currentAudioTrack.muted = state.isMuted;
          }

          if (state.videoElement) {
            state.videoElement.muted = state.isMuted;
          }
        }),
      changeVolume: (volume: number) =>
        set((state) => {
          if (state.currentAudioTrack && state.selectedAudioTrack !== -1) {
            state.currentAudioTrack.volume = volume / 100;
            if (volume === 0) {
              state.currentAudioTrack.muted = true;
              state.isMuted = true;
            }
            if (volume > 0) {
              state.currentAudioTrack.muted = false;
              state.isMuted = false;
            }
          } else if (state.videoElement) {
            state.videoElement.volume = volume / 100;
            if (volume === 0) {
              state.videoElement.muted = true;
              state.isMuted = true;
            }
            if (volume > 0) {
              state.videoElement.muted = false;
              state.isMuted = false;
            }
          }

          state.volume = volume;
        }),
      resetStore: () =>
        set((state) => {
          if (state.videoElement) {
            state.videoElement.pause();
            state.videoElement.currentTime = 0;
          }
          if (state.currentAudioTrack) {
            state.currentAudioTrack.pause();
            state.currentAudioTrack.currentTime = 0;
          }
          state.trackId = undefined;
          state.videoElement = undefined;
          state.videoName = undefined;
          state.currentAudioTrack = undefined;
          state.audioTitle = undefined;
          state.audioTracks = undefined;
          state.selectedAudioTrack = 0;
          state.pipPosition = undefined;
          state.isMuted = false;
          state.isPlaying = false;
          state.isPipOpen = false;
          state.isUploadDialogOpen = false;
          state.isCreateVariationsLoading = false;
        }),
    })),
  ),
);

export default useMusemindStore;
