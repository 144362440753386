import type { MouseEvent } from 'react';
import {
  MouseSensor as LibMouseSensor,
} from '@dnd-kit/core';
import { shouldHandleEvent } from '../should-handle-event';

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) =>
        shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}
