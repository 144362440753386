import { MouseEvent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchStore } from '~/stores/search-store';
import MewoLogo from '~/assets/mewo/logo.svg';
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { useTranslation } from 'react-i18next';
import { trackUploadStore } from '~/stores/track-upload-store';
import { useElementSize } from '~/hooks/common/use-element-size';
import useElementSizeStore from '~/stores/elements-size-store';
import ItemButton from './components/item-button';
import TracklistSearch from './components/tracklist-search/tracklist-search';
import './sidebar.style.scss';
import CreateBrief from './components/create-brief';
import CreatePlaylist from './components/create-playlist';
import NotificationTrigger from './components/notification-trigger';
import Profil from './components/profil';

type SidebarProps = {
  className?: string
};

const Sidebar = (props: SidebarProps) => {
  const { className = '' } = props;
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarDimensions = useElementSize(sidebarRef?.current);
  const location = useLocation();
  const { setSidebar } = useElementSizeStore(['setSidebar']);
  const search = useSearchStore(['searchParams', 'resetFilters']);
  const briefForm = useBriefFormStore(['isOpen', 'openExitDialog']);
  const { t } = useTranslation('sidebar');
  const { isOpen: trackUpload, openExitDialog: exitTrackUpload } = trackUploadStore;

  useEffect(() => {
    setSidebar?.(sidebarRef?.current?.getBoundingClientRect() as DOMRectReadOnly);
  }, [setSidebar]);

  useEffect(() => {
    if (sidebarDimensions) {
      setSidebar?.(sidebarDimensions);
    }
  }, [sidebarDimensions, setSidebar]);

  function handleResetFilters(e: MouseEvent) {
    e.stopPropagation();
    search.resetFilters();
  }

  function handleSidebarClick(e: MouseEvent) {
    e.stopPropagation();
    if (briefForm.isOpen) {
      briefForm.openExitDialog();
    }
    if (trackUpload.value) {
      exitTrackUpload();
    }
  }

  return (
    <div
      ref={sidebarRef}
      className={`relative w-64 flex flex-col bg-dark-600 items-stretch ${className}`}
    >
      <div className=" flex items-center justify-between w-full h-fit mt-6 mb-10 px-6">
        <img src={MewoLogo} alt="Mewo" className="w-1/2" />
        <NotificationTrigger />
      </div>
      <nav className="px-6 flex flex-col gap-4">
        <ItemButton
          key="item-button-Search"
          onClick={(e) => location.pathname === '/search' && handleResetFilters(e)}
          link={location.pathname === '/search' ? '/search' : `/search?${search.searchParams ? `q=${search.searchParams}` : ''}`}
          name={t('link.search')}
          type="search"
        />
        <ItemButton
          key="item-button-Briefs"
          link="/briefs"
          name={t('link.briefs')}
          type="brief"
          suffix={<CreateBrief />}
        />
        <ItemButton
          key="item-button-Playlists"
          link="/playlists"
          name={t('link.playlists')}
          type="playlist"
          suffix={<CreatePlaylist />}
        />
      </nav>
      <section className="pl-4 pt-6 flex flex-col gap-3 overflow-hidden h-full">
        <TracklistSearch />
      </section>
      <Profil />
      {(briefForm.isOpen || trackUpload.value) ? <div className="absolute inset-0 w-full h-full opacity-0 bg-dark-400/50 animate-slideRight animation-delay-200" onClick={handleSidebarClick} /> : null}
    </div>
  );
};

export default Sidebar;
