import { useTranslation } from 'react-i18next';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { dashboardRoutes, realtimeRoutes, userRoutes } from '~/routes/api';
import { RealtimeResponse, RealtimeResponseSchema } from '~/types/schemas/api/realtime.schema';
import { globalStore } from '~/stores/global-store-v2';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';
import { User } from '~/types/schemas/auth/user';
import { Agent, AgentSchema, Provider, ProviderSchema } from '~/types/schemas/common/agent.schema';
import { api } from '~/utils/api';
import { sortingAgentsByName } from '~/utils/common/sort/agent';
import { pathToUrl } from '~/utils/path-to-url';
import { updateData } from '~/hooks/utils/react-query/update-helper';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';

export const useGetAgents = () => {
  const { setDataAgents } = mewoDataStore;

  return useFetch<Agent[]>({
    url: dashboardRoutes.agents,
    schema: AgentSchema.array(),
    onSuccess(agents) {
      const sortedAgents = agents.sort(sortingAgentsByName);
      setDataAgents(sortedAgents);
    },
  });
};

export const useAddFavoritesAgent = () => {
  const { t } = useTranslation('toast');
  return useGenericMutation<string, string>({
    url: dashboardRoutes.favoritesAgents,
    func: (agentId) => api.put(pathToUrl(dashboardRoutes.favoritesAgents, { id: agentId })).text(),
    updater: (data) => {
      updateData<User>(
        [userRoutes.user],
        (oldData) => ({
          ...oldData,
          favorite_agents: [...(oldData.favorite_agents || []), data],
        }),
      );
    },
    mutateOptions: {
      onError: () => {
        toastr('error', 'Oops !', 'Error updating user favorite agents');
        toastr('error', t('user.error-updating-favorites.title'), t('user.error-updating-favorites.content'));
      },
    },
  });
};

export const useRemoveFavoritesAgent = () => {
  const { t } = useTranslation('toast');
  return useGenericMutation<string, string>({
    url: dashboardRoutes.favoritesAgents,
    func: (agentId) => api.delete(pathToUrl(dashboardRoutes.favoritesAgents, { id: agentId })).text(),
    updater: (agentId) => {
      updateData<User>(
        [userRoutes.user],
        (oldData) => ({
          ...oldData,
          favorite_agents: oldData.favorite_agents?.filter((agent) => agent !== agentId) || [],
        }),
      );
    },
    mutateOptions: {
      onError: () => {
        toastr('error', t('user.error-updating-favorites.title'), t('user.error-updating-favorites.content'));
      },
    },
  });
};

export const useGetProviders = () =>
  useFetch<Provider[]>({
    url: dashboardRoutes.providers,
    schema: ProviderSchema.array(),
  });

export const useGetRealtimeData = () =>
  useFetch<RealtimeResponse>({
    url: realtimeRoutes.token,
    schema: RealtimeResponseSchema,
    onSuccess(data) {
      globalStore.setRealtimeData(data);
    },
  });
