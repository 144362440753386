import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { YoutubeVideo, YoutubeVideoResponseSchema, YoutubeVideoSchema } from '~/types/schemas/common/youtube.schema';

type useURLVideoTiltleParams = {
  videoId: string;
};

export function useURLVideoTiltle():UseMutationResult<YoutubeVideo, Error, useURLVideoTiltleParams> {
  return useMutation({
    mutationFn: async (params: { videoId: string }) => {
      const { videoId } = params;
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${import.meta.env.VITE_YOUTUBE_DATA_API_KEY}&part=snippet`,
      );
      const data = YoutubeVideoResponseSchema.parse(await response.json());
      return YoutubeVideoSchema.parse(data.items[0]);
    /* return data.items[0].snippet.title; */
    } });
}
