import { useEffect } from 'react';
import { useTrackPresearch } from '~/hooks/api/track/use-track-presearch';
import { useAlbumSearch } from '~/hooks/api/album';
import { useArtistSearch } from '~/hooks/api/artist';
import { useBriefSearch } from '~/hooks/api/brief';
import { useCatalogSearch } from '~/hooks/api/catalog';
import { usePlaylistSearch } from '~/hooks/api/playlist';
import { useSamplerSearch } from '~/hooks/api/sampler';
import { TagDataType, usePopulatedTagsSearch, useTagSearch } from '~/hooks/api/tag';
import usePreSearchStore from '~/stores/presearch-store';
import { Album } from '~/types/schemas/music/album.schema';
import { Artist } from '~/types/schemas/music/artist.schema';
import { Brief } from '~/types/schemas/music/brief.schema';
import { Catalog } from '~/types/schemas/music/catalogs.schema';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { Track } from '~/types/schemas/music/track.schema';
import { PopulatedTagCategory } from '~/types/schemas/data/tags.schema';

type PresearchData = {
  albums: Album[],
  artists: Artist[],
  briefs: Brief[],
  catalogs: Catalog[],
  playlists: Playlist[],
  samplers: Playlist[],
  tracks: Track[],
  allTags?: TagDataType,
  tags?: PopulatedTagCategory[],
  targets?: PopulatedTagCategory[],
  isLoading: boolean,
  isDataEmpty?: boolean
};

export function useAllTabData(): PresearchData {
  const { query, setTabResultsNumbers } = usePreSearchStore(['query', 'setTabResultsNumbers']);
  const { data: albums, isLoading: isAlbumsLoading } = useAlbumSearch(query);
  const { data: artists, isLoading: isArtistsLoading } = useArtistSearch(query);
  const { data: briefs, isLoading: isBriefsLoading } = useBriefSearch(query);
  const { data: catalogs, isLoading: isCatalogsLoading } = useCatalogSearch(query);
  const { data: playlists, isLoading: isPlaylistsLoading } = usePlaylistSearch(query);
  const { data: samplers, isLoading: isSamplersLoading } = useSamplerSearch(query);
  const { data: tracks, isLoading: isTracksLoading } = useTrackPresearch();
  const { data: allTags, isLoading: isAllTagsLoading } = useTagSearch(query);
  const { data: { categories: tagCategories, rawData: tagsRawData }, isLoading: isTagsLoading } = usePopulatedTagsSearch(query, 'TAG');
  const { data: { categories: targetCategories, rawData: targetsRawData }, isLoading: isTargetsLoading } = usePopulatedTagsSearch(query, 'TARGET');

  useEffect(() => {
    setTabResultsNumbers('albums', query, albums?.pages[0]?.total.value || 0);
  }, [albums]);

  useEffect(() => {
    setTabResultsNumbers('artists', query, artists?.pages[0]?.total.value || 0);
  }, [artists]);

  useEffect(() => {
    setTabResultsNumbers('briefs', query, briefs?.pages[0]?.total.value || 0);
  }, [briefs]);

  useEffect(() => {
    setTabResultsNumbers('catalogs', query, catalogs?.pages[0]?.total.value || 0);
  }, [catalogs]);

  useEffect(() => {
    setTabResultsNumbers('playlists', query, playlists?.pages[0]?.total.value || 0);
  }, [playlists]);

  useEffect(() => {
    setTabResultsNumbers('samplers', query, samplers?.pages[0]?.total.value || 0);
  }, [samplers]);

  useEffect(() => {
    setTabResultsNumbers('tags', query, tagsRawData?.tags?.length || 0);
  }, [tagsRawData]);

  useEffect(() => {
    setTabResultsNumbers('targets', query, targetsRawData?.tags?.length || 0);
  }, [targetsRawData]);

  return {
    albums: albums?.pages.flatMap((page) => page.hits) || [],
    artists: artists?.pages.flatMap((page) => page.hits) || [],
    briefs: briefs?.pages.flatMap((page) => page.hits) || [],
    catalogs: catalogs?.pages.flatMap((page) => page.hits) || [],
    playlists: playlists?.pages.flatMap((page) => page.hits) || [],
    samplers: samplers?.pages.flatMap((page) => page.hits) || [],
    allTags,
    tags: tagCategories,
    targets: targetCategories,
    tracks: tracks?.hits || [],
    isLoading: isAlbumsLoading || isArtistsLoading || isBriefsLoading || isCatalogsLoading || isPlaylistsLoading || isSamplersLoading || isTracksLoading || isTagsLoading || isTargetsLoading || isAllTagsLoading,
    isDataEmpty: !albums?.pages.length && !artists?.pages.length && !briefs?.pages.length && !catalogs?.pages.length && !playlists?.pages.length && !samplers?.pages.length && !tracks?.hits.length && !tagsRawData?.tags?.length && !targetsRawData?.tags?.length && !allTags?.tags.length,
  };
}
