import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import React, { Ref } from 'react';
import Icon from '~/components/ui/icon/icon';

export type TooltipProps = {
  label?: string;
  children: React.ReactNode;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  leftIcon?: string;
  rightIcon?: string;
  asChild?: boolean;
  disabled?: boolean;
  triggerClassName?: string;
  delayDuration?: number;
};

export const Tooltip = React.forwardRef(
  (props: TooltipProps, forwardRef: Ref<HTMLButtonElement>) => {
    const { children, label, side, sideOffset, align, leftIcon, rightIcon, disabled, triggerClassName, asChild, delayDuration = 700 } = props;

    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root
          delayDuration={delayDuration}
        >
          <TooltipPrimitive.Trigger
            className={classNames(triggerClassName, 'cursor-default')}
            data-no-dnd="true"
            ref={forwardRef}
            asChild={asChild}
            onClick={(e) => e.preventDefault()}
          >
            {children}
          </TooltipPrimitive.Trigger>
          {!disabled && label && (
            <TooltipPrimitive.Portal>
              <TooltipPrimitive.Content
                side={side}
                align={align}
                className="TooltipContent flex gap-2 items-center z-[100] text-xs rounded px-2 py-1 bg-dark-200 shadow-lg"
                sideOffset={sideOffset || 5}
              >
                {leftIcon ? <Icon name={leftIcon} /> : null}
                {label}
                {rightIcon ? <Icon name={rightIcon} /> : null}
              </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
          )}
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  },
);
