/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import * as musicMetadata from 'music-metadata-browser';
import { signal } from '@preact/signals-react';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { bufferToImage } from '~/utils/common/buffer-to-image';
import { findVersionByLabel } from '~/utils/common/find-version';

export async function FileToUploadTrack(file: File): Promise<UploadTrack> {
  const metadata = await musicMetadata.parseBlob(file);
  const { common: { title, picture, artists } } = metadata;
  const bufferImage = picture?.[0]?.data;
  const image = bufferImage && bufferToImage(bufferImage);

  return {
    id: `${file.name}-${file.type}-${file.size}`,
    name: file.name,
    type: file.type,
    size: file.size,
    matchedMetadata: signal(undefined),
    metadata: {
      title: title || file.name,
      album: metadata.common.album,
      artists,
      cover: image,
    },
    step: signal('waiting'),
    musicType: signal('core'),
    version: signal(file.name.match(/inst/gi)?.[0] ? findVersionByLabel('instrumental')?.id! : findVersionByLabel('original')?.id!),
    file,
  };
}

export function FileArrayToUploadTrackArray(files: File[]): Promise<UploadTrack[]> {
  return Promise.all(files.map(FileToUploadTrack));
}
