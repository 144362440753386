/* eslint-disable camelcase */
import { Track } from '~/types/schemas/music/track.schema';
import classNames from 'classnames';
import { useState } from 'react';
import SearchSimilarTracks from './components/search-similar-track/search-similar-track';
import CreateVariation from './components/create-variation/create-variation';
import DownloadTrack from './components/download-track/download-track';
import DisplayCredentials from './components/display-credentials/display-credentials';
import DisplayLyrics from './components/display-lyrics/display-lyrics';
import RemoveTrackFromTracklist from './components/remove-from-tracklist/remove-from-tracklist';
import AddToTracklist from './components/add-to-tracklist/add-to-tracklist';
import Interest from './components/interest/interest';

type TrackInteractionsProps = {
  track: Track;
  isRemovable?: boolean;
  tracklistId?: string;
  tracklistType?: 'brief' | 'playlist';
  containerClassName?: string;
  nestedContainerClassName?: string;
  isMusemind?: boolean;
};

function TrackInteractions(props: TrackInteractionsProps) {
  const { track, isRemovable, tracklistId, tracklistType, containerClassName, nestedContainerClassName, isMusemind } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const defaultClassName = 'flex text-white items-center justify-end';

  return (
    <div className={classNames(
      'track-interactions',
      defaultClassName,
      containerClassName,
    )}
    >
      <div className={classNames(
        'track-interactions__hiddable',
        defaultClassName,
        nestedContainerClassName,
        isDropdownOpen ? '!flex' : '',
      )}
      >
        <SearchSimilarTracks track={track} />
        <DownloadTrack track={track} onOpenChange={setIsDropdownOpen} />
        <DisplayCredentials track={track} />
        <DisplayLyrics track={track} />
        <Interest track={track} />
        <AddToTracklist track={track} onOpenChange={setIsDropdownOpen} />
      </div>
      <CreateVariation track={track} isMusemind={isMusemind} isBrief={tracklistType === 'brief'} />
      {isRemovable && tracklistId ? <RemoveTrackFromTracklist trackId={track.id} tracklistId={tracklistId} tracklistType={tracklistType!} /> : null}
    </div>
  );
}

export default TrackInteractions;
