import classNames from 'classnames';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ReactNode } from 'react';

type TrackCoverProps = {
    img?: string
    icon?: string
    title?: string
    version?: string
    artists?: string
    album?: string
    titleClassName?: string
    className?: string
    width?: string
    height?: string
    customMedia?: ReactNode
};

export default function Cover(props: TrackCoverProps) {
  const {
    img,
    icon = 'ri:music-line',
    title,
    version,
    titleClassName,
    artists,
    album,
    className,
    width = 'auto',
    height = '3.5rem',
    customMedia,
  } = props;

  return (
    <div className={classNames('flex gap-3 items-center h-full', className)}>
      {
        !customMedia ? (
          <div
            style={{ width, height }}
            className={classNames('bg-dark-300 aspect-square rounded overflow-hidden flex items-center justify-center shrink-0')}
          >
            {img
              ? <img src={img} alt="track cover" className={classNames('w-full h-full object-cover')} />
              : (
                <Icon icon={icon} className="text-base" />
              )
                }
          </div>
        ) : customMedia
      }
      <div className="flex flex-col overflow-hidden">
        <div className="flex items-center gap-1 line-clamp-1">
          <h3 className={classNames('font-bold text-sm text-light-100 line-clamp-1', titleClassName)}>
            {title}
          </h3>
        </div>
        <p className="text-xs text-light-400 line-clamp-1">{artists}</p>
        {album && (
        <div className="flex gap-1 items-center">
          <Icon icon="ri:album-line" className="text-light-100 text-xs" />
          <p className="text-xs italic text-light-400 line-clamp-1 flex-1">{album}</p>
        </div>
        )}
        {!!version && (
        <div className="flex gap-1 items-center">
          <Icon icon="ri:price-tag-3-line" className="text-light-100 text-xs" />
          <p className="text-xs text-light-400 line-clamp-1 flex-1">{version}</p>
        </div>
        )}
      </div>
    </div>
  );
}
