import * as Track from '~/components/entities/track/common/track';
import usePlayerStore from '~/stores/player-store';

export function PlayerVolume() {
  const { volume, changeVolume, isMuted, toggleMute } = usePlayerStore(['volume', 'changeVolume', 'toggleMute', 'isMuted']);

  return (
    <Track.Volume
      volume={volume}
      onVolumeChange={changeVolume}
      toggleMute={toggleMute}
      muted={isMuted}
    />
  );
}
