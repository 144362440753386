import { IconifyIconProps } from '@iconify/react';
import React, { ForwardedRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import * as ContextMenu from './context-menu';

type ContextMenuDialogItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  disabled?: boolean;
  onClick?: () => void;
}>;

export const ContextMenuDialogItem = React.forwardRef((props: ContextMenuDialogItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
  const { icon, disabled, onClick, children } = props;

  return (
    <ContextMenu.Item
      ref={ref}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      onSelect={(event) => {
        event.preventDefault();
      }}
      className={classNames({
        'opacity-50 pointer-events-none': disabled,
      })}
    >
      {children}
    </ContextMenu.Item>
  );
});
