import { useTranslation } from 'react-i18next';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { tracklistRoutes } from '~/routes/api';
import { Track } from '~/types/schemas/music/track.schema';
import {
  Tracklist,
  TracklistSearchSchema,
} from '~/types/schemas/music/tracklist.schema';
import { api } from '~/utils/api';
import { pathToUrl } from '~/utils/path-to-url';
import {
  addMultipleInfiniteDataUpdater,
  multipleDeleteUpdater,
} from '~/hooks/utils/react-query/update-helper';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';
import { usePost } from '~/hooks/utils/react-query/use-post';

export const useTracklistSearch = (
  collections: ('playlists' | 'briefs')[],
  query?: string,
  trackId?: string,
) => {
  return useLoadMore<Tracklist>({
    url: tracklistRoutes.search,
    schema: TracklistSearchSchema,
    queryParams: {
      query: query || '',
      size: 50,
      track: trackId,
      collections,
      sort: {
        key: 'created_at',
        direction: -1,
      },
    },
  });
};

export const useRemoveTrackFromTracklist = () => {
  const { t } = useTranslation('toast');

  return useGenericMutation<
    {
      type: 'playlist' | 'brief';
      id: string;
      tracks: Track[];
    },
    Track[]
  >({
    url: tracklistRoutes.tracksMeta,
    func: (data) =>
      api
        .delete(
          pathToUrl(tracklistRoutes.tracksMeta, {
            type: `${data?.type}s`,
            id: data?.id,
          }),
          { json: { ids: data?.tracks.map((track) => track.id) } },
        )
        .json(),
    updater: (data) => {
      const { type, id, tracks } = data;
      const trackIds = tracks.map((track) => track.id);
      multipleDeleteUpdater<Track>(
        [pathToUrl(tracklistRoutes.tracks, { id, type })],
        (track) => !trackIds.includes(track.id),
      );
      // TODO: Update the tracklist count (code below is commented out because it's not working as expected)
      /* updateData<Brief | Playlist>(
        [pathToUrl(tracklistRoutes.detail, { id, type })],
        (oldData) => {
          return {
            ...oldData,
            tracks: (oldData.tracks || 0) + tracks.length,
          };
        },
      ); */
    },
    mutateOptions: {
      onSuccess: (data, params) => {
        toastr(
          'success',
          t('tracklist.success-removal.title'),
          t('tracklist.success-removal.content', {
            'tracklist-type': params.type,
          }),
        );
      },
      onError: (error, params) => {
        if (error.message.includes('403') && params.type === 'brief') {
          toastr(
            'error',
            t('tracklist.error-sharing.title'),
            t('tracklist.error-sharing.content'),
          );
        } else {
          toastr(
            'error',
            t('tracklist.error-removal.title'),
            t('tracklist.error-removal.content', {
              'tracklist-type': params.type,
            }),
          );
        }
      },
    },
  });
};

export const useAddTrackToTracklist = () => {
  const { t } = useTranslation('toast');

  return useGenericMutation<
    {
      type: 'playlist' | 'brief';
      id: string;
      tracks: Track[];
    },
    Track[]
  >({
    url: tracklistRoutes.tracksMeta,
    func: (data) =>
      api
        .post(
          pathToUrl(tracklistRoutes.tracksMeta, {
            type: `${data?.type}s`,
            id: data?.id,
          }),
          { json: { ids: data?.tracks.map((track) => track.id) } },
        )
        .json(),
    updater: (data) => {
      const { type, id, tracks } = data;
      addMultipleInfiniteDataUpdater<Track>(
        [pathToUrl(tracklistRoutes.tracks, { id, type })],
        tracks,
      );

      // TODO: Update the tracklist count (code below is commented out because it's not working as expected)
      /* updateData<Brief | Playlist>(
        [pathToUrl(tracklistRoutes.detail, { id, type })],
        (oldData) => {
          return {
            ...oldData,
            tracks: (oldData.tracks || 0) + tracks.length,
          };
        },
      ); */
    },
    mutateOptions: {
      onSuccess: (data, params) => {
        if (params.tracks.length > 1) {
          toastr(
            'success',
            t('tracklist.success-multiple-addition.title'),
            t('tracklist.success-multiple-addition.content', {
              'tracklist-type': params.type,
              'tracks-number': params.tracks.length,
            }),
          );
          return;
        }
        toastr(
          'success',
          t('tracklist.success-addition.title'),
          t('tracklist.success-addition.content', {
            'tracklist-type': params.type,
          }),
        );
      },
      onError: (error, params) => {
        if (error.message.includes('403') && params.type === 'brief') {
          toastr(
            'error',
            t('tracklist.error-sharing.title'),
            t('tracklist.error-sharing.content'),
          );
        } else {
          toastr(
            'error',
            t('tracklist.error-addition.title'),
            t('tracklist.error-addition.content', {
              'tracklist-type': params.type,
            }),
          );
        }
      },
    },
  });
};

export const useDownloadTracklistCopyright = () =>
  useGenericMutation<
    {
      id: string;
      type: 'playlist' | 'brief';
    },
    string
  >({
    url: `${tracklistRoutes.copyright}`,
    func: (data) =>
      api
        .get(
          pathToUrl(tracklistRoutes.copyright, {
            id: data?.id,
            type: data?.type,
          }),
        )
        .text(),
    mutateOptions: {
      onSuccess: async (data, params) => {
        const element = document.createElement('a');
        const file = new Blob([data], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `copyrights-track-${params.id}.txt`;
        element.click();
      },
    },
  });

export const useDownloadTracklistTracks = () => {
  const { t } = useTranslation('toast');

  return usePost<
    {
      context: 'playlist' | 'brief';
      id: string;
      with_versions: boolean;
      quality: 'original' | 'hd_mp3';
    },
    void
  >({
    url: tracklistRoutes.archive,
    mutateOptions: {
      onSuccess: (_, params) => {
        toastr(
          'success',
          t('archive.ongoing-generation.title', {
            'file-type': params.context,
          }),
          t('archive.ongoing-generation.content'),
        );
      },
      onError: (_, params) => {
        toastr(
          'error',
          t('archive.error-generation.title', { 'file-type': params.context }),
          t('archive.error-generation.content'),
        );
      },
    },
  });
};

export const useCreateVariationsBulk = () => {
  return usePost<
    {
      ids: string[];
      duration: number;
      variations: number
    },
    {
      results: Record<string, string[]>;
    }
  >({
    url: tracklistRoutes.variationsBulk,
  });
};
