import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { fileUploadStore } from '~/stores/file-upload-store';
import { useComputed } from '@preact/signals-react';
import TrackWaiting from './steps/track-waiting';
import { TrackUpload } from './steps/track-upload';
import { TrackLaunchMatch } from './steps/track-launch-match';
import { TrackMatchResult } from './steps/track-match-result';
import TrackDisplay from './steps/track-display';
import { TrackForm } from './steps/track-form';
import { Card } from '../../layout/card';

type TrackMatchProps = {
    file: UploadTrack
    isSelected?: boolean
    isValidated?: boolean
};

export function TrackSteps(props: TrackMatchProps) {
  const { file, isSelected, isValidated } = props;
  const { uploads } = fileUploadStore;
  const upload = useComputed(() => uploads.value[file.id]).value;
  const step = useComputed(() => file.step.value).value;

  function chooseCardContent() {
    if (isSelected && upload) {
      switch (step) {
        case 'custom':
          return <TrackForm file={file} uploadId={upload.id} />;
        case 'noMatch':
          return <TrackForm file={file} uploadId={upload.id} />;
        default:
          return <TrackDisplay file={file} uploadId={upload.id} isSelected={isSelected} isValidated={isValidated} />;
      }
    }

    if (!isSelected) {
      switch (step) {
        case 'waiting':
          return <TrackWaiting file={file} />;
        case 'upload':
          return <TrackUpload file={file} upload={upload} />;
        case 'matchLaunch':
          return <TrackLaunchMatch file={file} upload={upload} />;
        case 'match':
          return <TrackMatchResult file={file} upload={upload} />;
        default:
          return <TrackDisplay file={file} uploadId={upload?.id} isSelected={isSelected} isValidated={isValidated} />;
      }
    }

    return <TrackDisplay file={file} uploadId={upload?.id} isSelected={isSelected} isValidated={isValidated} />;
  }

  return (
    <Card>
      {chooseCardContent()}
    </Card>
  );
}
