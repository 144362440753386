import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchStore } from '~/stores/search-store';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';

export function useSimilarTracks(id: string, title: string, artists: DisplayArtist[]) {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useSearchStore(['searchParams', 'setSimilarityTrack', 'resetSimilarityURL']);

  function findSimilarTracks() {
    search.setSimilarityTrack(id, title, artists.map((a) => a.name).join(', '));
    search.resetSimilarityURL();

    if (location.pathname !== '/search') {
      navigate(search.searchParams ? `/search?q=${search.searchParams.toString()}` : '/');
    }
  }

  return {
    findSimilarTracks,
  };
}
