import { Track as TrackType } from '~/types/schemas/music/track.schema';
import { Action as ActionType } from '~/types/types/actions';
import { createStore } from 'zustand';
import { createSelectors } from '~/stores/utilities';
import { TrackContext, TrackState } from './track.context';
import * as TrackSummary from './track-summary/track-summary';
import { TrackWaveform } from './waveform';
import { TrackDuration } from './labels/duration';
import { TrackLabel } from './labels/label';
import { TrackMusicType } from './labels/music-type';
import { TrackPopularity } from './labels/popularity';
import { TrackAction } from './action-list/action/action';
import * as TrackChip from './chips/chip';
import * as TrackCard from './cards/card';
import { TrackActionList } from './action-list/action-list';
import TrackAddedBy from './added-by/added-by';
import { TrackSimilarity } from './labels/similarity';
import { TrackVersions } from './labels/versions';
import { TrackVolume } from './volume/volume';

type TrackRootProps = {
    track: TrackType;
    context: ActionType.Play
    children: React.ReactNode;
};

function Track(props: TrackRootProps) {
  const { track, context, children } = props;

  const TrackStoreBase = createStore<TrackState>((set) => ({
    track,
    context,
    isTrackActive: false,
    setIsTrackActive: (isActive: boolean) => set({ isTrackActive: isActive }),
  }));

  const TrackStore = createSelectors(TrackStoreBase);

  return (
    <TrackContext.Provider value={TrackStore}>
      {children}
    </TrackContext.Provider>
  );
}

const Root = Track;
const Summary = TrackSummary;
const Waveform = TrackWaveform;
const Label = TrackLabel;
const ActionList = TrackActionList;
const Action = TrackAction;
const Duration = TrackDuration;
const MusicType = TrackMusicType;
const Popularity = TrackPopularity;
const Similarity = TrackSimilarity;
const Versions = TrackVersions;
const AddedByChip = TrackAddedBy;
const Volume = TrackVolume;
const Chip = TrackChip;
const Card = TrackCard;

export {
  Root,
  Summary,
  Waveform,
  Label,
  ActionList,
  Action,
  Duration,
  MusicType,
  Popularity,
  Similarity,
  Versions,
  AddedByChip,
  Volume,
  Chip,
  Card,
};
export type { TrackRootProps };
