import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router-dom';
import { queryClient } from '~/boot/react-query';
import { MewoDevTools } from '~/components/features/dev-tool/dev-tool';
import { Toaster } from 'react-hot-toast';
import DataProvider from './data-provider/data-provider';
import PubnubProvider from './pubnub-provider';
import '../i18n/config';
import { DndProvider } from './dnd-provider';
import { ContextProvider } from './context-provider';

const AppProviders = () => (
  <QueryClientProvider client={queryClient}>
    <PubnubProvider>
      <MewoDevTools />
      <Toaster position="top-right" />
      <DataProvider>
        <ContextProvider>
          <DndProvider>
            <Outlet />
          </DndProvider>
        </ContextProvider>
      </DataProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </PubnubProvider>
  </QueryClientProvider>
);

export default AppProviders;
