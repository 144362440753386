import { termOptions, itemConditionsOptions, useSearchStore } from '~/stores/search-store';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';

export function useArtistFilter() {
  const search = useSearchStore(['addValueToMatchingBlock']);

  function handleArtistClick(artist: DisplayArtist) {
    search.addValueToMatchingBlock(termOptions.master_artists!, itemConditionsOptions.is!, {
      value: artist.id,
      label: artist.name,
    });
  }

  return {
    onArtistClick: handleArtistClick,
  };
}
