import { useTrackContext } from '~/components/entities/track/common/track.context';
import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';

export function useTrackPlaybackState() {
  const store = useTrackContext();
  const track = store.use.track();

  const player = usePlayerStore(['isPlaying', 'currentTrack']);
  const magicPreview = useMusemindStore(['trackId', 'isPlaying', 'isCreateVariationsLoading']);

  const playerTrackId = player.currentTrack?.id;
  const magicPreviewTrackId = magicPreview.trackId;

  const isCurrentPlayerTrack = playerTrackId === track.id;
  const isCurrentMagicPreviewTrack = magicPreviewTrackId === track.id;
  const isCurrentTrack = (player.isPlaying && isCurrentPlayerTrack) || (magicPreview.isPlaying && isCurrentMagicPreviewTrack);

  const isPlaying = isCurrentTrack && (player.isPlaying || magicPreview.isPlaying);
  const isPending = magicPreview.isCreateVariationsLoading;

  return {
    isCurrentTrack,
    isPlaying,
    isPending,
  };
}
