import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { Participant } from '~/types/schemas/chats/participant.schema';

type UserAvatarProps = {
    user: Participant;
    className?: string;
};

function UserAvatar(props: UserAvatarProps) {
  const { user, className } = props;
  return (
    user.organization_settings.avatar ? <img src={user.organization_settings.avatar} alt={`${user.first_name} ${user.last_name}`} className={classNames('object-cover aspect-square', className)} /> : (
      <div className={classNames('flex justify-center items-center  bg-dark-100 text-xxs h-4 w-4 rounded-full text-light-400 aspect-square', className)}>
        <Icon name="user-line" />
      </div>
    ));
}

export default UserAvatar;
