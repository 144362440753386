import { useEffect, useState } from 'react';

export function useElementSize(element: HTMLElement | null):DOMRectReadOnly | undefined {
  const [size, setSize] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    if (!element) return;

    setSize(element.getBoundingClientRect());
  }, [element]);

  useEffect(() => {
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      setSize(entries[0]?.target.getBoundingClientRect() || null);
    });

    observer.observe(element);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return size || undefined;
}
