import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translateFilters } from '~/stores/search-store';
import dayjs from 'dayjs';

type Missing = {
  translationKey: string;
  namespace: string;
  language: string;
  fallbackValue: string;
};

const isProductionCode = process.env.NODE_ENV === 'production';
const fallbackLanguage = 'en-US';

const projectToken = import.meta.env.VITE_SIMPLE_LOCALIZE_PROJECT_KEY; // YOUR PROJECT TOKEN
const env = import.meta.env.VITE_APP_ENV; // YOUR ENVIRONMENT

const cdnBaseUrl = 'https://cdn.simplelocalize.io';
const environment = env === 'dev' || env === 'preprod' ? '_latest' : '_production';
const loadPathWithNamespaces = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}`;

const storedLanguage = localStorage.getItem('language');
const navigatorLanguage =
  navigator.language === 'ja' || navigator.language === 'ja-JP' ? 'ja-JP' : 'en-US';
const defaultLanguage = storedLanguage || navigatorLanguage || 'en-US';

const missing: Missing[] = [];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    lng: defaultLanguage,
    react: {
      useSuspense: false,
    },
    fallbackLng: fallbackLanguage,
    backend: {
      fallbackLanguage,
      loadPath: loadPathWithNamespaces,
    },
    ns: [
      'alert',
      'login',
      'sidebar',
      'search',
      'error-page',
      'filter',
      'libraries',
      'toast',
      'pre-search',
      'track-interactions',
      'tracklist-page',
      'brief-page',
      'brief-form',
      'brief-chat',
      'playlist-page',
      'playlist-form',
      'brief-chat',
      'magic-preview',
      'notification-center',
      'music-item',
      'common',
      'tracks-upload-form',
      'dropdown-menus',
      'translate-select',
    ],
    saveMissing: !isProductionCode,
    defaultNS: '', // you can set default namespace here
    missingKeyHandler: async (languages, namespace, translationKey, fallbackValue) => {
      console.debug(`[${namespace}][${translationKey}] not available in Translation Hosting`);
      missing.push({
        translationKey,
        namespace: namespace ?? '',
        language: languages[0] ?? fallbackLanguage,
        fallbackValue: fallbackValue ?? '',
      });
    },
  });

i18n.on('languageChanged', async (lng) => {
  const shortLanguage = lng.split('-')[0] || 'en';
  dayjs.locale(shortLanguage);
});

i18n.on('added', () => {
  translateFilters();
  // translatePresearchNavigation();
});

export default i18n;
