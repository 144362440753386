import * as DialogPrimitive from '@radix-ui/react-dialog';
import { DialogContainer } from '../common';
import { LicensingQuoteHeader } from './header';
import { LicensingQuoteForm } from './form';
import { useState } from 'react';

type RightHoldersDialogProps = {
  children: React.ReactNode;
};

export function LicensingQuoteDialog(props: RightHoldersDialogProps) {
  const { children } = props;
  const [open, setOpen] = useState(false);

  function handleSubmit() {
    setOpen(false);
  }

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setOpen}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <DialogContainer className="px-0">
        <LicensingQuoteHeader />
        <LicensingQuoteForm onSubmit={handleSubmit} />
      </DialogContainer>
    </DialogPrimitive.Root>
  );
}
