import { useComputed } from '@preact/signals-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { activeChannelStore } from '~/stores/chats-store';
import { notificationStore } from '~/stores/notifications-store';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { Message } from '~/utils/chat/message';

export function useNotificationCenter() {
  const navigate = useNavigate();
  const channel = notificationStore.channel.value;
  const showLoadMoreButton = channel?.hasOlderMessages.value;

  const notifications = useComputed(() => channel?.messages.value.slice().reverse() ?? []);

  const handleCardClick = useCallback((notification: Message<ChatNotificationMessage>) => {
    activeChannelStore.trackId.value = notification.content.data.track.id;
    navigate(`/briefs/${notification.content.data.brief.id}`);

    notification.markAsRead();
  }, []);

  return {
    channel,
    notifications,
    showLoadMoreButton,
    handleCardClick,
  };
}
