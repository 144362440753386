/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { Range, defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useMemo, useRef } from 'react';

type InfiniteScrollProps<D> = {
    data?: D[],
    count?: number;
    estimateSize: number;
    overscan?: number;
    scrollParent?: Element | null;
    isFetching?: boolean;
    isEndReached?: boolean;
    onRangeChange?: (range: Range) => void;
    renderItem: (item?: D) => ReactNode;
    renderSentinel?: (style?: CSSProperties) => ReactNode;
};

export const InfiniteScroll = React.memo(<ItemData = unknown>(props: InfiniteScrollProps<ItemData>) => {
  const { data = [], count = 0, estimateSize, overscan = 20, scrollParent, isFetching, onRangeChange, renderItem, renderSentinel } = props;
  const rootRef = useRef<HTMLDivElement | null>(null);

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => scrollParent || rootRef.current,
    rangeExtractor: (range) => {
      onRangeChange?.(range);
      return defaultRangeExtractor(range);
    },
    estimateSize: () => estimateSize,
    overscan,
  });

  const items = virtualizer.getVirtualItems();
  const lastItem = useMemo(() => [...items].pop(), [items]);

  return (
    <section
      ref={rootRef}
      className={classNames('w-full h-full relative', {
        'overflow-y-auto custom-scrollbar contain-layout contain-size ': !scrollParent,
      })}
      style={{
        height: virtualizer.getTotalSize(),
      }}
    >
      <div
        className="w-full absolute pointer-events-none"
        style={{
          height: `${(items[0]?.start || 0)}px`,
        }}
      >
        <div
          style={{
            height: '100%',
          }}
        >
          {renderSentinel?.()}
        </div>
      </div>
      <div
        className="absolute inset-0 w-full h-fit"
        style={{ transform: `translateY(${items[0]?.start}px)` }}
      >
        {items.map((item) => {
          const itemData = data?.[item.index];

          return (
            <div
              key={item.key}
              data-index={item.index}
              ref={virtualizer.measureElement}
            >
              {itemData
                ? renderItem(itemData)
                : renderSentinel?.({ height: estimateSize })
                }
            </div>
          );
        })}
        {isFetching
          ? (
            <div className="w-full flex items-center justify-center text-light-400 py-4">
              <Icon icon="svg-spinners:6-dots-scale-middle" className="animate-spin" />
            </div>
          )

          : null}
      </div>
      <div
        className="w-full absolute"
        style={{
          height: `calc(100% - ${lastItem?.end}px)`,
          transform: `translateY(${(lastItem?.end || 0) + estimateSize}px)`,
        }}
      >
        {renderSentinel?.()}
      </div>
    </section>
  );
});
