/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import React, { Ref, useEffect, useRef, useState } from 'react';
import SelectIcon from 'virtual:icons/ri/arrow-down-s-line';
import Checkbox, { CheckedState } from '~/components/ui/forms/input-controls/checkbox/checkbox';
import { useClickOutside } from '~/hooks/common/use-click-outside';

export type SelectFilterOption = {
  label: string;
  value: string;
  checked?: boolean;
};

type SelectFilterProps = {
  placeholder?: string;
  options: SelectFilterOption[];
  onChange: (options: SelectFilterOption[]) => void;
  className?: string;
};

const SelectFilter = (props: SelectFilterProps) => {
  const { options, className = '', placeholder, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selected = options?.filter((o) => o.checked);
  const contentRef = useRef<HTMLDivElement>(null);
  const value = selected?.map((s) => s.label).join(', ');

  useClickOutside(contentRef, () => handleBlur());

  const handleFocus = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleChange = (option: SelectFilterOption) => {
    const newOptions = options?.map((o) => {
      if (o.value === option.value) {
        return option;
      }
      return o;
    });
    onChange(newOptions);
  };

  return (
    <Select.Root open={isOpen} defaultValue={value}>
      <Select.Trigger
        onClick={handleFocus}
        className={classNames('flex overflow-hidden gap-2 items-center outline-none h-7 pl-3 pr-2 justify-between border border-transparent hover text-sm border-dark-100 bg-dark-300 rounded transition duration-200 focus:border-primary-500', className, {
          'border-primary-500': isOpen,
          'text-light-500': selected.length <= 0,
          'text-light-100': selected.length > 0,
        })}
      >
        <Select.Value placeholder={placeholder}>
          {selected.length > 0 ? value : placeholder}
        </Select.Value>
        <Select.Icon className={classNames('w-fit flex justify-end', {
          'transition-transform transform rotate-180': isOpen,
        })}
        >
          <SelectIcon className="text-light-500" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content ref={contentRef} position="popper" className="p-1 mt-1 w-max bg-dark-300 rounded z-[100]">
          <Select.Viewport>
            {
              options?.map((option) => (
                <SelectItem key={option.value} {...option} onChange={handleChange} />
              ))
            }
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

type SelectItemProps = SelectFilterOption & {
  onChange: (option: SelectFilterOption) => void;
};

const SelectItem = React.forwardRef((props: SelectItemProps, forwardedRef: Ref<HTMLDivElement>) => {
  const { label, value, checked, onChange } = props;
  const [isChecked, setIsChecked] = useState<CheckedState>(checked as CheckedState);
  const option = { label, value, checked };

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked as CheckedState);
    }
  }, [checked]);

  function handleCheckClick(b: CheckedState) {
    setIsChecked(b === 'indeterminate' ? false : !b);
    onChange?.({ ...option, checked: b === 'indeterminate' ? false : !b });
  }

  return (
    <Select.Item ref={forwardedRef} value={value} className="flex items-center cursor-pointer rounded gap-2 px-1.5 py-1 group transition-all hover:bg-dark-100 outline-none focus-bg-dark-100" asChild>
      <div onClick={() => handleCheckClick(isChecked)}>
        <Checkbox checked={checked!} onChange={handleCheckClick} />
        <label htmlFor={`checkbox-${value}`} className="text-light-500 text-sm select-none group-hover:text-light-100">{label}</label>
      </div>
    </Select.Item>
  );
});

export default SelectFilter;
