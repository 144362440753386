import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type BriefTrackItemLayoutProps = PropsWithChildren<{
    className?: string
}>;

export function BriefTrackItemLayout(props: BriefTrackItemLayoutProps) {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        'grid items-center gap-4',
        'grid-cols-brief-track-item-sm ',
        '@[45rem]:grid-cols-brief-track-item-base',
        '@[48rem]:grid-cols-brief-track-item-md',
        '@[50rem]:grid-cols-brief-track-item-lg',
        className,
      )}
    >
      {children}
    </div>
  );
}
