/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import IconCloseLine from 'virtual:icons/ri/close-line';
import IconFileAddLine from 'virtual:icons/ri/file-add-line';

type FileProps = {
  onChange?: (files: globalThis.File[]) => void
  maxFiles?: number
  maxSize?: number
  accept?: { [key: string]: string[] }
  disabled?: boolean
  className?: string
  multiple?: boolean
  placeholder?: string
};

const FileDrop = (props: FileProps) => {
  const {
    onChange,
    maxFiles = 1,
    maxSize = 1,
    accept,
    disabled = false,
    className = '',
    multiple = false,
    placeholder = 'Drag\'n\'Drop your file',
  } = props;
  const [dragOver, setDragOver] = useState(false);
  const sizeConversion = maxSize * 1000000;
  const [fileName, setFileName] = useState<string[]>([]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFileName(acceptedFiles.map((file) => file.name));
    if (onChange) onChange(acceptedFiles);
  };

  const deleteFile = (e: React.MouseEvent) => {
    e.preventDefault();
    setFileName([]);
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      onDragEnter={() => setDragOver(true)}
      onDragLeave={() => setDragOver(false)}
      maxFiles={maxFiles}
      multiple={multiple}
      accept={accept}
      maxSize={sizeConversion}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="w-full h-full">
          <div
            {...getRootProps()}
            className={`flex h-52 w-full cursor-pointer gap-3 flex-col items-center justify-center rounded-md border-2 border-dashed border-dark-100 bg-dark-400/80 transition-colors duration-300 hover:border-primary-500 ${
              dragOver ? 'border-primary-500' : ''
            } ${
              disabled
                ? 'cursor-not-allowed bg-dark-400 hover:border-dark-300'
                : ''
            } ${className}`}
          >
            <IconFileAddLine className="text-3xl mb-4" />
            <input {...getInputProps()} />
            {fileName.length === 0 && (
              // eslint-disable-next-line react/no-unescaped-entities
              <p className="text-xs">{placeholder}</p>
            )}
            {fileName.length > 0 && (
              <div className="flex gap-2">
                <p className="text-xs">{fileName.join(', ')}</p>
                <button
                  type="button"
                  className="flex items-center rounded-full justify-center w-4 h-4 transition-color duration-100 border border-1 border-dark-100 bg-drak-500 hover:border-primary-500"
                  onClick={deleteFile}
                >
                  <IconCloseLine className="text-[8px]" />
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDrop;
