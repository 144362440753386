import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { ReactNode, Ref } from 'react';
import DropdownItem from './dropdown-item';
import './dropdown.scss';

export type dropdownElement = {
  label: string;
  subtitle?: string;
  href?: string;
  navlink?: string;
  disabled?: boolean;
  onclick?: (() => void) | undefined;
  menu?: {
    title?: string;
    elements: dropdownElement[];
  }[];
};

export type dropdownGroups = {
  title?: string;
  elements: dropdownElement[];
}[];

export const animationClassName: Record<string, string> = {
  top: 'slide-down-fade',
  right: 'slide-left-fade',
  bottom: 'slide-up-fade',
  left: 'slide-right-fade',
};

export const contentClassName = (side: 'top' | 'right' | 'bottom' | 'left', className: string) =>
  `p-1 select-none rounded-md bg-dark-500 border border-dark-100 text-xs z-[100] ${animationClassName[side]} ${className}`;

type DropdownProps = {
  groups: dropdownGroups;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactNode;
  disabled?: boolean;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  width?: string;
  className?: string;
  itemClassName?: string;
  triggerClassname?: string;
  asChild?: boolean;
};

const Dropdown = React.forwardRef(
  (props: DropdownProps, forwardRef: Ref<HTMLButtonElement>) => {
    const {
      groups,
      disabled,
      children,
      triggerClassname,
      asChild,
      align = 'center',
      side = 'bottom',
      open = false,
      width = '192px',
      className = '',
      onOpenChange,
      itemClassName = '',
    } = props;

    return (
      <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
        <DropdownMenu.Trigger
          className={classNames(triggerClassname, disabled && 'opacity-50 pointer-events-none')}
          asChild={asChild}
          ref={forwardRef}
        >
          {children}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={contentClassName(side, className)}
            style={{ width }}
            align={align}
            side={side}
            sideOffset={5}
          >
            {groups.map((group, groupIndex) => (
              <div key={`group-${groupIndex}`}>
                <DropdownMenu.Group>
                  {group.title && (
                    <DropdownMenu.Label className="p-1 text-gray-400">
                      {group.title}
                    </DropdownMenu.Label>
                  )}
                  {group.elements.map((item, itemIndex) => (
                    <DropdownItem
                      key={`group-${groupIndex}-item-${itemIndex}`}
                      width={width}
                      className={itemClassName}
                      menuClassName={contentClassName(side, className)}
                      {...item}
                    />
                  ))}
                </DropdownMenu.Group>
                {groupIndex < groups.length - 1 && (
                  <DropdownMenu.Separator className="w-full h-px bg-dark-100 my-1" />
                )}
              </div>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  },
);

export default Dropdown;
