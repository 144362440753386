import usePlayerStore from '~/stores/player-store';
import classNames from 'classnames';
import usePreSearchStore from '~/stores/presearch-store';
import * as Track from '~/components/entities/track/common/track';
import { PlayerControls } from './components/player-controls';
import TrackInteractions from '../track-interactions/track-interactions';
import { PlayerSummary } from './components/summary/summary';

export type PlayerProps = {
  className?: string
};

export const Player = (props: PlayerProps) => {
  const { className } = props;
  const presearch = usePreSearchStore(['isOpen']);
  const player = usePlayerStore(['currentTrack']);
  const track = player.currentTrack;

  return track ? (
    <Track.Root track={track} context={{ type: 'player' }}>
      <section
        className={classNames(
          'player @container flex w-full p-4 gap-4 bg-dark-600 border-t border-dark-100/50 shadow-lg text-xl ',
          presearch.isOpen && 'z-[70]',
          className,
        )}
      >
        <PlayerSummary className="!w-[25%]" />
        <PlayerControls className="!w-[50%]" />
        <TrackInteractions track={track} containerClassName="!w-[25%]" />
      </section>
    </Track.Root>
  ) : null;
};
