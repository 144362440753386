import { createContext, useContext } from 'react';
import { Message } from '~/types/schemas/notifications/message.schema';

type MagicPreviewContextType = {
  data: Record<string, Message>;
};

export const MagicPreviewContext = createContext<MagicPreviewContextType | null>(null);

export function useMagicPreviewContext() {
  const context = useContext(MagicPreviewContext);

  if (!context) {
    throw new Error('useMagicPreviewContext must be used within a MagicPreviewProvider');
  }

  return context;
}
