import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';
import { Content } from './content/content';

export function TrackItemWrapper(props: PropsWithChildren) {
  const { children } = props;
  const { isOpen, isTrackActive, isSelected, handleClick, onAnimationComplete } = useTrackItem();

  return (
    <div
      className={classNames(
        'group/track-item',
        'rounded-lg border border-transparent overflow-hidden select-none outline-none',
        'hover:!bg-dark-300/60  hover:border-white/5',
        {
          'bg-dark-300/60  border-white/5': isTrackActive,
          'bg-dark-300/60': isSelected || isOpen,
        },
      )}
      onClick={handleClick}
    >
      <div className="p-2">
        {children}
      </div>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            layout='position'
            initial={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
            transition={{ duration: 0.4 }}
            onAnimationComplete={() => {
              onAnimationComplete(true)
            }}

          >
            <div className="p-2">
              <Content />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
