import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useBpmFilter } from '~/hooks/filter/use-bpm-filter';

export function useBpmChip() {
  const { onBpmClick } = useBpmFilter();
  const store = useTrackContext();
  const track = store.use.track();

  const bpm = track.bpm || 0;
  const roundedBpm = Math.floor(bpm);

  function handleClick() {
    onBpmClick(roundedBpm);
  }

  return {
    bpm: roundedBpm.toString(),
    onClick: handleClick,
  };
}
