import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { dateDifferenceWithToday } from '~/utils/common/date-difference';

type Status = {
    label: string,
    color: string
};

export function useTrackSummaryChip() {
  const store = useTrackContext();
  const track = store.use.track();
  const { t } = useTranslation('music-item');

  const status: Status | null = useMemo(() => {
    const dateDifference = dateDifferenceWithToday(track.release_date!);

    if (dateDifference > 0) {
      return {
        label: t('cover.soon'),
        color: 'orange-400',
      };
    }

    if (dateDifference <= 0 && dateDifference >= -7) {
      return {
        label: t('cover.new'),
        color: 'primary-400',
      };
    }

    return null;
  }, [track.release_date]);

  return {
    status,
  };
}
