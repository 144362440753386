import classNames from 'classnames';
import BriefFormFooter from '../components/footer';
import BriefFormHeader from '../components/header';

type BriefFormTabProps = {
    title: string
    briefTitle?: string
    isFirstStep?: boolean
    isLastStep?: boolean
    displayFooter?: boolean
    children: React.ReactNode
    onExitClick?: () => void
    onDiscardAndExitClick?: () => void
    onSaveAndExitClick?: () => void
    onNextClick?: () => void
    onPreviousClick?: () => void
    onSendClick?: () => void
    onDeleteClick?: () => void
};

function Tab(props: BriefFormTabProps) {
  const {
    title = '',
    briefTitle = '',
    isFirstStep = false,
    isLastStep = false,
    displayFooter = true,
    children,
    ...actions
  } = props;

  return (
    <>
      <BriefFormHeader title={title} briefTitle={briefTitle} {...actions} />
      <div className="flex flex-col gap-4 h-full form-content">
        {children}
      </div>
      <div className={classNames('transition-all duration-400', {
        'opacity-0 pointer-events-none -translate-y-2 ': !displayFooter,
        'opacity-100 pointer-events-auto': displayFooter,
      })}
      >
        <BriefFormFooter {...actions} isFirstStep={isFirstStep} isLastStep={isLastStep} />
      </div>
    </>
  );
}

export default Tab;
