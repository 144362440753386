import { signal } from '@preact/signals-react';
import Pubnub, { MessageAction } from 'pubnub';
import { realtimeStore } from '~/stores/realtime-store';
import { ChannelMembershipCustom, ChatMessageContent, MessageDTOParams, MessageReceipt, SendMessageOptionParams } from '~/types/types/chat';
import { RealtimeUser } from '~/types/types/realtime';
import { Message } from './message';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Channel<MessageContent, MessageMeta = Record<string, any>, Custom = any> {
  private pubnub: Pubnub;

  id: string;

  userId: string;

  private timeTokenPerUser = new Map<string, { timetoken: string, messageTimetoken: string }>();

  private membersFetched = false;

  private membersSelfOnly = false;

  totalMessageCount = signal(0);

  unreadMessageCount = signal(0);

  receipts: Record<string, MessageReceipt[]> = {};

  messages = signal<Message<MessageContent, MessageMeta>[]>([]);

  hasOlderMessages = signal(false);

  lastMessage = signal<Message<MessageContent, MessageMeta> | undefined>(undefined);

  custom: Custom | undefined;

  isLoading = signal(true);

  isLoadingMore = signal(false);

  private listener?: Pubnub.ListenerParameters;

  private initLaucned = false;

  private buildReceipts() {
    this.receipts = {};

    this.timeTokenPerUser.forEach((value, userId) => {
      this.receipts[value.messageTimetoken] ??= [];
      this.receipts[value.messageTimetoken]!.push({ timetoken: value.timetoken, userId });
    });

    this.buildMessagesWithReceipts();
  }

  private buildMessagesWithReceipts() {
    this.messages.value.forEach((message) => {
      message.receipts = this.receipts[message.timetoken];
    });
  }

  constructor(opts: {
    pubnub: Pubnub,
    userId: string,
    channel: string,
    lastMessage?: Message<MessageContent, MessageMeta>,
    custom?: Custom,
    membersSelfOnly?: boolean,
  }) {
    this.pubnub = opts.pubnub;
    this.userId = opts.userId;
    this.id = opts.channel;

    if (opts.custom) {
      this.custom = opts.custom;
    }

    if (opts.lastMessage) {
      this.messages.value = [opts.lastMessage];
      this.lastMessage.value = opts.lastMessage;
    }

    if (opts.membersSelfOnly) {
      this.membersSelfOnly = opts.membersSelfOnly;
    }
  }

  async init() {
    if (this.initLaucned) {
      return;
    }

    this.initLaucned = true;

    await Promise.all([
      this.getMembers(),
      this.getMessageCount(),
      this.lastMessage.value?.fetchMessageActions(),
    ]);

    const lastReadTimetoken = this.timeTokenPerUser.get(this.userId)?.messageTimetoken;

    if (lastReadTimetoken) {
      this.unreadMessageCount.value = await this.getUnreadMessageCount(lastReadTimetoken);
    } else {
      this.unreadMessageCount.value = this.totalMessageCount.value;
    }

    this.isLoading.value = false;
  }

  async getMembers() {
    if (!this.membersFetched) {
      const result = await this.pubnub.objects.getChannelMembers<ChannelMembershipCustom, RealtimeUser['custom']>({
        channel: this.id,
        include: {
          customFields: true,
          totalCount: false,
          customUUIDFields: true,
          UUIDFields: true,
        },
        limit: 100,
        ...(this.membersSelfOnly ? { filter: `uuid.id == "${this.userId}"` } : {}),
      });

      this.membersFetched = true;

      const users: Record<string, RealtimeUser> = {};

      result.data.forEach((member) => {
        if (member.custom) {
          this.timeTokenPerUser.set(member.uuid.id, {
            timetoken: member.custom.lastReadTimetoken,
            messageTimetoken: member.custom.lastReadMessageTimetoken,
          });
        }

        // add user to store if it does not exists
        if ('custom' in member.uuid && !realtimeStore.users.value[member.uuid.id]) {
          users[member.uuid.id] = {
            id: member.uuid.id,
            name: member.uuid.name!,
            custom: member.uuid.custom!,
          };
        }
      });

      if (Object.keys(users).length) {
        realtimeStore.users.value = {
          ...realtimeStore.users.value,
          ...users,
        };
      }

      this.buildReceipts();
    }
  }

  async getMessageCount() {
    if (!this.totalMessageCount.value) {
      const totalResult = await this.pubnub.messageCounts({ channels: [this.id], channelTimetokens: ['0'] });

      this.totalMessageCount.value = totalResult.channels[this.id] || 0;
    }

    return this.totalMessageCount.value;
  }

  async getUnreadMessageCount(timetoken: string) {
    const result = await this.pubnub.messageCounts({ channels: [this.id], channelTimetokens: [timetoken] });

    return result.channels[this.id] || 0;
  }

  async getOlderMessages(): Promise<{ messages: Message<MessageContent, MessageMeta>[]; hasMore: boolean }> {
    this.isLoadingMore.value = true;

    try {
      await this.init();

      if (!this.totalMessageCount.value) {
        return {
          messages: [],
          hasMore: false,
        };
      }

      const result = await this.pubnub.fetchMessages({
        channels: [this.id],
        start: this.messages.value.at(0)?.timetoken,
        count: 100,
        includeMeta: true,
        includeMessageActions: true,
      });

      const messages = result.channels[this.id];

      if (!messages) {
        this.hasOlderMessages.value = false;

        return {
          messages: [],
          hasMore: false,
        };
      }

      const nextMessages = messages.map((message) => new Message<MessageContent, MessageMeta>(
        this.pubnub,
        message,
        this.receipts[message.timetoken],
      ));

      this.messages.value = [
        ...nextMessages,
        ...this.messages.value,
      ];

      this.lastMessage.value = this.messages.value.at(-1);
      this.hasOlderMessages.value = this.messages.value.length !== this.totalMessageCount.value;

      return {
        messages: nextMessages,
        hasMore: this.hasOlderMessages.value,
      };
    } finally {
      this.isLoadingMore.value = false;
      this.isLoading.value = false;
    }
  }

  async setLastReadMessageTimetoken(timetoken: string) {
    const currentTime = await this.pubnub.time();

    await this.pubnub.objects.setMemberships({
      uuid: this.pubnub.getUUID(),
      channels: [{
        id: this.id,
        custom: {
          lastReadMessageTimetoken: timetoken,
          lastReadTimetoken: String(currentTime.timetoken),
        } as ChannelMembershipCustom,
      }],
      filter: `channel.id == "${this.id}"`,
    });

    await this.pubnub.signal({
      channel: this.id,
      message: {
        type: 'receipt',
        timetoken,
      },
    });

    this.unreadMessageCount.value = 0;
    this.timeTokenPerUser.set(this.pubnub.getUUID(), {
      timetoken: String(currentTime.timetoken),
      messageTimetoken: timetoken,
    });

    this.buildReceipts();
  }

  async sendMessage(text?: string, params?: SendMessageOptionParams) {
    const messageContent: ChatMessageContent = {
      text,
      type: 'text',
    };

    if (params?.files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of params.files) {
        // eslint-disable-next-line no-await-in-loop
        const result = await this.pubnub.sendFile({
          channel: this.id,
          file,
          storeInHistory: false,
        });

        const url = this.pubnub.getFileUrl({ channel: this.id, id: result.id, name: file.name });

        messageContent.files ??= [];
        messageContent.files.push({
          id: result.id,
          name: file.name,
          size: file.size,
          url,
          type: file.type,
        });
      }
    }

    await this.pubnub.publish({
      channel: this.id,
      message: messageContent,
    });
  }

  addMessage(messageEvent: MessageDTOParams) {
    const message = new Message<MessageContent, MessageMeta>(this.pubnub, messageEvent);

    this.messages.value = [
      ...this.messages.value,
      message,
    ];

    if (!realtimeStore.users.value[message.userId]) {
      realtimeStore.addUserToFetch(message.userId);
    }

    this.unreadMessageCount.value += 1;
    this.totalMessageCount.value += 1;
    this.lastMessage.value = message;
  }

  addAction(action: MessageAction) {
    const message = this.messages.value.find((msg) => msg.timetoken === action.messageTimetoken);

    message?.addActions([action]);
  }

  listen() {
    this.listener = {
      signal: (signalEvent) => {
        if (signalEvent.channel === this.id) {
          if (signalEvent.message.type === 'receipt') {
            this.timeTokenPerUser.set(signalEvent.publisher, {
              messageTimetoken: signalEvent.message.timetoken,
              timetoken: signalEvent.timetoken,
            });

            this.buildReceipts();
          }
        }
      },
    };

    this.pubnub.addListener(this.listener);

    return () => {
      this.listenOff();
    };
  }

  subscribe() {
    const listener: Pubnub.ListenerParameters = {
      message: (messageEvent) => {
        if (messageEvent.channel === this.id) {
          this.addMessage(messageEvent);
        }
      },
      messageAction: (messageActionEvent) => {
        if (messageActionEvent.channel === this.id) {
          this.addAction(messageActionEvent.data);
        }
      },
    };

    this.pubnub.subscribe({ channels: [this.id] });
    this.pubnub.addListener(listener);

    return () => {
      this.pubnub.unsubscribe({ channels: [this.id] });
      this.pubnub.removeListener(listener);
    };
  }

  listenOff() {
    if (this.listener) {
      this.pubnub.removeListener(this.listener);
    }
  }
}
