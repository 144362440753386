import ky, { Options } from 'ky';
import merge from 'lodash/merge';
import { globalStore } from '~/stores/global-store-v2';
import { getCookie } from '../common/cookie';

function applyOptions(base: Options, opts?: Options) {
  return merge({}, base, opts);
}

export const getHttp = () => {
  const { apiUrl } = globalStore;
  const token = getCookie('x-auth');
  const headers = {
    'Content-Type': 'application/json',
    'x-auth': token,
  };

  return {
    get: (path: string, options?: Options) => ky.get(`${apiUrl.value}/${path}`, applyOptions({ headers }, options)),
    post: (path: string, options?: Options) => ky.post(`${apiUrl.value}/${path}`, applyOptions({ headers }, options)),
    put: (path: string, options?: Options) => ky.put(`${apiUrl.value}/${path}`, applyOptions({ headers }, options)),
    delete: (path: string, options?: Options) => ky.delete(`${apiUrl.value}/${path}`, applyOptions({ headers }, options)),
  };
};
