import { useTonalityChip } from '~/hooks/entities/track/chips/use-tonality-chip';
import TrackChip from './chip';

export function TrackChipTonality() {
  const { label, onClick } = useTonalityChip();

  return (
    <TrackChip icon="ri:music-fill" label={label} onClick={onClick} />
  );
}
