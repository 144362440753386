import { AnimatePresence, motion } from 'framer-motion';
import { briefChatsStore } from '~/stores/chats-store';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';
import ChatCard from './chat-card/chat-card';

type ChatListProps = {
  onClick?: (channel: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>) => void;
};

function ChatList(props: ChatListProps) {
  const { onClick } = props;

  return (
    <AnimatePresence>
      <motion.div
        className="thread-list flex flex-col gap-2 rounded-lg overflow-hidden"
      >
        {briefChatsStore.channels.value.map((channel, i) => (
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ delay: 0.1 * i }}
            custom={i}
            key={channel.id}
          >
            <ChatCard
              key={channel.id}
              onClick={() => onClick?.(channel)}
              channel={channel}
            />
          </motion.div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
}

export default ChatList;
