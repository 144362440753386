import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import GeneralIcons from '~/components/ui/icon/general-icons';

export type NavigationItems = {
  name: string
  link: string
  suffix?: ReactNode
  disabled?: boolean
  onClick?: (e:MouseEvent) => void
};

interface ItemButtonProps extends NavigationItems {
  type?: 'playlist' | 'brief' | 'search'
  className?: string
}

const ItemButton = (props: ItemButtonProps) => {
  const { suffix, type, name: label, link, className, disabled, onClick } = props;

  return (
    <div className="flex-1 group flex" onClick={onClick}>
      <NavLink
        id={`nav-${label.toLowerCase()}`}
        to={link}
        className={({ isActive }) => {
          return classNames(
            'flex flex-[2] w-full gap-2 items-center transition-colors duration-100 text-light-500 hover:text-light-100',
            isActive && 'text-white',
            disabled && 'pointer-events-none text-dark-100 hover:text-dark-100',
            className,
          );
        }}
      >
        {({ isActive }) => (
          <div className="flex items-center gap-3 text-sm">
            <GeneralIcons icon={`${type}-${isActive ? 'fill' : 'line'}`} />
            <span>{label}</span>
          </div>
        )}
      </NavLink>
      <div className="flex-[1] opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center shrink-0">
        {suffix || null}
      </div>
    </div>
  );
};

export default ItemButton;
