import IconCheckLine from 'virtual:icons/ri/check-line';
import IconIndeterminate from 'virtual:icons/material-symbols/check-indeterminate-small';
import classNames from 'classnames';
import { ChangeEvent, MouseEvent } from 'react';

export type CheckedState = boolean | 'indeterminate';

type CheckboxProps = {
  checked: CheckedState
  checkboxClassName?: string
  onChange?: (checked: CheckedState) => void
  onClick?: (e:MouseEvent) => void
};

const Checkbox = (props: CheckboxProps) => {
  const { checked, checkboxClassName, onChange, onClick } = props;

  function chooseIcon() {
    switch (checked) {
      case true:
        return <IconCheckLine />;
      case false:
        return null;
      default:
        return <IconIndeterminate />;
    }
  }

  function handleCheckChange(e: ChangeEvent<HTMLInputElement>) {
    return onChange?.(e.target.checked);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="flex gap-3 items-center cursor-pointer select-none text-white">
      <div className={classNames('text-[0.5rem] w-3 h-3 rounded-sm border border-dark-100 bg-dark-300 transition-all duration-200', {
        '!bg-primary-500 border-primary-500': checked,
      }, checkboxClassName)}
      >
        {chooseIcon()}
      </div>
      <input type="checkbox" className="hidden" checked={checked === 'indeterminate' ? undefined : checked} onClick={onClick} onChange={handleCheckChange} />
    </label>
  );
};

export default Checkbox;
