import { useTrackContext } from '~/components/entities/track/common/track.context';
import useCurrentTime from '~/hooks/music/use-current-time';
import { usePlayTrack } from '~/hooks/music/use-player';
import usePlayerStore from '~/stores/player-store';

export function useTrackWaveform() {
  const store = useTrackContext();

  const track = store.use.track();
  const context = store.use.context();

  const playTrack = usePlayTrack();
  const { seekTo, currentTrack, isPlaying } = usePlayerStore(['seekTo', 'currentTrack', 'audioFile', 'isPlaying']);
  const currentTime = useCurrentTime(track?.id);

  const isPlaybackTrack = currentTrack?.id === track?.id;
  const isCurrentTrackPlaying = isPlaybackTrack && isPlaying;

  const trackDuration = track?.duration || 0;
  const progress = currentTime ? (currentTime / trackDuration) * 100 : 0;

  function handleSeek(newProgress: number) {
    if (isPlaybackTrack) {
      seekTo(newProgress);
    } else {
      playTrack({ track, context, progress: newProgress });
    }
  }

  return {
    isPlaybackTrack,
    isPlaying: isCurrentTrackPlaying,
    seekTo: handleSeek,
    progress,
  };
}
