import LatestIcon from 'virtual:icons/mdi/latest';
import { useRelativeTime } from '~/hooks/common/use-relative-time';
import classNames from 'classnames';
import { realtimeStore } from '~/stores/realtime-store';
import { parseMessage } from '~/components/features/brief-chats/components/chat/chat-messages/message-group/message/message-parser';
import { useComputed } from '@preact/signals-react';
import { Message } from '~/utils/chat/message';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';

export type NotificationPanelCardProps = {
  notification: Message<ChatNotificationMessage>;
  onClick: () => void;
  markRead: () => void;
};

function NotificationCard(props: NotificationPanelCardProps) {
  const { notification, onClick } = props;
  const { user } = globalStore;
  const time = useRelativeTime(+notification.timetoken / 1e4);
  const textParts = parseMessage(notification.content.data.text);
  const from = useComputed(() => realtimeStore.users.value[notification.userId]);
  const isUnread = useComputed(() => !notification.readActions?.find((action) => action.uuid === user.value?.id));
  const { t } = useTranslation('notification-center');

  useEffect(() => {
    if (!from.value) {
      realtimeStore.addUserToFetch(notification.userId);
    }
  }, [from.value]);

  return (
    <div className="relative group flex flex-col p-3 rounded-lg bg-dark-200 hover:bg-dark-100 cursor-pointer" onClick={onClick}>
      <div className="flex flex-col pr-3">
        <h4 className="font-bold text-xs text-light-100">{notification.content.data.brief.title}</h4>
        <p className="text-xs text-light-400 mb-2">{notification.content.data.track.title}</p>
        <div className="flex flex-col gap-0.5">
          <span className="text-xs">
            {notification.content.type === 'mention' ? (
              <span className="text-light-300">
                <Trans
                  t={t}
                  i18nKey="content.mention"
                  defaults="You have been mentioned by <bold>{{name}}</bold> :"
                  values={{ name: from.value?.name }}
                  components={{ bold: <b className="text-light-100" /> }}
                />
                {'You have been mentioned by '}
                <b className="text-light-100">{`${from.value?.name} :`}</b>
              </span>
            ) : null}
            {notification.content.type === 'message' ? (
              <span className="text-light-300">
                <Trans
                  t={t}
                  i18nKey="content.message"
                  defaults="New message from <bold>{{user}}</bold> :"
                  values={{ user: from.value?.name }}
                  components={{ bold: <b className="text-light-100" /> }}
                />
              </span>
            ) : null
            }
          </span>
          <p className="text-xs line-clamp-3 text-light-400">
            {textParts.map((part, i) => (
              <span
                key={`${part.text}-${part.type}-${i}`}
                className={classNames({
                  'font-semibold': part.type === 'mention',
                })}
              >
                {part.text}
              </span>
            ))}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-1 mt-1">
        <LatestIcon className="w-3 h-3 text-light-100" />
        <p className="text-xs text-light-500">{time}</p>
      </div>
      {isUnread.value
        ? (
          <>
            <div className="absolute right-2 top-2 rounded-full w-3 h-3 bg-primary-500" />
            <div
              className="group-hover:opacity-100 hover:border-dark-50 border-transparent border border-solid opacity-0 absolute right-1 top-1 text-xs bg-dark-200 px-2 py-1 rounded-md transition"
              onClick={(e) => {
                e.stopPropagation();
                props.markRead();
              }}
            >
              {t('pop-in.card.button.mark-as-read')}
            </div>
          </>
        )
        : null
      }
    </div>
  );
}

export default NotificationCard;
