import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type TrackLabelProps = PropsWithChildren<{
  className?: string;
}>;

export function TrackLabel(props: TrackLabelProps) {
  const { className, children } = props;

  return (
    <span className={classNames('text-xs text-light-400 select-none', className)}>
      {children}
    </span>
  );
}
