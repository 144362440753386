import { useTranslation } from 'react-i18next';
import { RightHoldersDialog } from '~/components/ui/overlays/dialogs/right-holders/right-holders';
import * as ContextMenu from '../common/context-menu';

export function TrackActionsCopyrightModal() {
  const { t } = useTranslation('track-interactions');

  return (
    <RightHoldersDialog>
      <ContextMenu.DialogItem icon="ic:baseline-copyright">
        {t('copyright.tooltip')}
      </ContextMenu.DialogItem>
    </RightHoldersDialog>
  );
}
