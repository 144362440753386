import Dialog from '~/components/ui/overlays/dialog/dialog';
import FileDrop from '~/components/ui/forms/input-controls/filedrop/filedrop';
import useMusemindStore from '~/stores/musemind-store';
import usePreSearchStore from '~/stores/presearch-store';
import classNames from 'classnames';
import Button from '~/components/ui/control/button/button';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { useTranslation } from 'react-i18next';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';

type UploadDialogProps = {
  className?: string
};

const UploadDialog = (props: UploadDialogProps) => {
  const { className = '' } = props;
  const musemind = useMusemindStore(['setVideoElement', 'setIsPipOpen', 'setIsUploadDialogOpen', 'isUploadDialogOpen']);
  const presearch = usePreSearchStore(['setIsOpen']);
  const { t } = useTranslation(['magic-preview', 'toast']);

  const handleUpload = async (files: File[]) => {
    const file = files[0];
    if (file) {
      const video = document.createElement('video');

      if (video.canPlayType(file.type) === '') {
        toastr('error', t('musemind.error-unsupported-video.title', { ns: 'toast' }), t('musemind.error-unsupported-video.content', { ns: 'toast' }));
        return;
      }

      video.src = URL.createObjectURL(file);
      video.controls = false;
      video.classList.add('w-full', 'h-full');

      video.onloadedmetadata = function () {
        musemind.setVideoElement(file.name, video);
        musemind.setIsPipOpen(true);
        musemind.setIsUploadDialogOpen(false);
      };
    }
  };

  const handleTriggerClick = () => {
    presearch.setIsOpen(false);
    musemind.setIsPipOpen(false);
  };

  return (
    <Dialog
      onOpenChange={(b) => musemind.setIsUploadDialogOpen(b)}
      isOpen={musemind.isUploadDialogOpen}
      triggerClassName="w-fit h-8"
      containerClassName="upload-dialog !h-fit w-full max-w-[35rem] aspect-[16/9]"
      asChild
      trigger={(
        <Button
          type="button"
          onClick={handleTriggerClick}
          className={classNames('flex group/upload gap-2 pr-2 items-center justify-between transition-colors duration-100 text-light-500 hover:text-light-100 h-8 border-2 border-primary-500 bg-transparent hover:!border-primary-400 hover:bg-transparent font-semibold', className)}
        >
          <div className="flex items-center gap-3 text-sm leading-none">
            <GeneralIcons icon="variation-line" />
            <span>{t('header.trigger.label')}</span>
          </div>
        </Button>
      )}
    >
      <FileDrop
        placeholder={t('modal.upload-video.content')}
        className="w-full h-full"
        accept={{ 'video/*': [] }}
        maxSize={10000000}
        maxFiles={1}
        multiple={false}
        onChange={handleUpload}
      />
    </Dialog>
  );
};

export default UploadDialog;
