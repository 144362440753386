import classNames from 'classnames';
import React from 'react';

function ChatCardLoader(_props: unknown, ref: React.Ref<HTMLDivElement>) {
  const pulseClass = 'rounded bg-dark-100 animate-pulse';

  return (
    <div
      ref={ref}
      className="relative flex items-stretch gap-3 py-3 pl-3 pr-6 bg-dark-200 border border-transparent hover:border-dark-100 rounded-lg cursor-pointer"
    >
      <div className={classNames('!w-10 !h-10 aspect-square', pulseClass)} />
      <div className="w-full flex flex-col overflow-hidden gap-3">
        <div className="w-[80%] flex flex-col gap-1">
          <div className={classNames('h-5 w-[50%]', pulseClass)} />
          <div className={classNames('h-4 w-[60%]', pulseClass)} />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <div className={classNames('h-4', pulseClass)} />
          <div className={classNames('h-4', pulseClass)} />
          <div className={classNames('h-4 w-[20%]', pulseClass)} />
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(ChatCardLoader);
