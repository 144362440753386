import { DragOverlay } from '@dnd-kit/core';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';
import { Track } from '~/types/schemas/music/track.schema';
import { snapRightToCursor } from '~/utils/dnd-kit/modifiers/snapRightToCursor';

type TrackDragOverlayProps = {
  activeTrack: Track | null;
};
export function TrackOverlay(props: TrackDragOverlayProps) {
  const { activeTrack } = props;
  const { versions } = mewoDataStore;

  const versionId = activeTrack?.version as keyof typeof versions.object;
  const version = versions.object.value[versionId]?.name;

  return (
    <DragOverlay
      modifiers={[snapRightToCursor]}
      dropAnimation={null}
      className="w-fit cursor-copy p-12"
    >
      {activeTrack ? (
        <div className="w-fit max-w-[260px] h-fit bg-dark-300/80 px-2 py-1 rounded cursor-copy fixed">
          <p className="text-sm font-medium text-wrap">
            {`${activeTrack.title} • ${activeTrack.display_artists.map((artist) => artist.name).join(', ')}`}
          </p>
          {activeTrack.version !== '5989daf4d3f20f148cff8d5f' ? (
            <span className="text-sm text-light-400">{`(${version})`}</span>
          ) : null}
        </div>
      ) : null}
    </DragOverlay>
  );
}
