import RangeSlider from '~/components/ui/control/slider/range.slider';

type RangeProps = {
  value: [number, number];
  customFrom?: string;
  customTo?: string;
  min: number;
  max: number;
  onValueChange?: (value: [number, number]) => void;
};

function Range(props: RangeProps) {
  const { value, customFrom, customTo, onValueChange, min, max } = props;
  const [from, to] = value;

  return (
    <div className="flex flex-col gap-3 p-2">
      <div className="w-full flex justify-between">
        <span className="text-xs text-light-500">{customFrom || from}</span>
        <span className="text-xs text-light-500">{customTo || to}</span>
      </div>
      <RangeSlider
        value={value}
        min={min}
        max={max}
        onValueChange={onValueChange}
        trackClassName="!bg-dark-400"
        className="w-full"
      />
    </div>
  );
}

export default Range;
