/* eslint-disable react-hooks/exhaustive-deps */
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import usePlaylistFormStore from '~/stores/forms/playlist-form-store';
import useInterestFormStore from '~/stores/forms/interest-form-store';
import useGlobalFormStore from '~/stores/forms/global-form-store';
import { trackUploadStore } from '~/stores/track-upload-store';
import BriefForm from './brief/brief-form';
import PlaylistForm from './playlist/playlist-form';
import './form.styles.scss';
import InterestForm from './interest/interest-form';
import TracksUpload from '../tracks-upload/tracks-upload';

function FormHandler() {
  const globalForm = useGlobalFormStore(['isOpen', 'setIsOpen']);
  const briefForm = useBriefFormStore(['isOpen', 'brief', 'close']);
  const playlistForm = usePlaylistFormStore(['isOpen', 'playlist', 'close']);
  const interestForm = useInterestFormStore(['isOpen', 'interest', 'close']);
  const { isOpen: { value: trackUpload }, context, close: trackUploadClose } = trackUploadStore;
  const location = useLocation();

  const isFormOpen = briefForm.isOpen || playlistForm.isOpen || interestForm.isOpen || trackUpload;

  useEffect(() => {
    if (location.pathname !== `/briefs/${briefForm.brief?.id}` || !location.pathname.includes('/briefs')) {
      briefForm.close();
    }
    if (location.pathname !== `/briefs/${briefForm.brief?.id}` || briefForm.isOpen) {
      trackUploadClose();
    }
    if (playlistForm.playlist && location.pathname !== `/playlists/${playlistForm.playlist?.id}`) {
      playlistForm.close();
    }
    interestForm.close();
  }, [location.pathname, briefForm.brief?.id]);

  useEffect(() => {
    globalForm.setIsOpen(briefForm.isOpen || playlistForm.isOpen || interestForm.isOpen);
  }, [briefForm.isOpen, playlistForm.isOpen, interestForm.isOpen, trackUpload]);

  return isFormOpen && (
    <div className="sticky top-0 flex flex-col z-20">
      <div className="flex justify-center overflow-y-auto items-center p-6 absolute inset-0 h-[calc(100vh_-_4rem)] z-10 overflow-hidden animate-slideInBottom bg-dark-400/90 backdrop-blur-sm">
        {briefForm.isOpen ? <BriefForm brief={briefForm.brief} /> : null}
        {playlistForm.isOpen ? <PlaylistForm playlist={playlistForm.playlist} /> : null}
        {interestForm.isOpen ? <InterestForm /> : null}
        {trackUpload && context.id.value && <TracksUpload context="brief" contextId={context.id.value} />}
      </div>
    </div>
  );
}

export default FormHandler;
