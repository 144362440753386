import { Icon, IconifyIconProps } from '@iconify/react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';
import React, { ForwardedRef, MouseEvent, PropsWithChildren } from 'react';

type ContextMenuItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  onClick?: (e: MouseEvent) => void;
  className?: string;
}> & ContextMenu.ContextMenuItemProps;

export const ContextMenuItem = React.forwardRef((props: ContextMenuItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
  const { icon, onClick, children, className, ...rest } = props;
  return (
    <ContextMenu.Item
      className={classNames('flex items-center gap-3 h-8 px-2 text-white text-sm select-none rounded cursor-default outline-none border border-transparent focus:bg-dark-100  focus:border-white/10', className)}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      <div className="size-4 flex items-center justify-center">
        {icon && <Icon icon={icon} className="w-4 h-4" />}
      </div>
      <div className="flex-1">
        {children}
      </div>
    </ContextMenu.Item>
  );
});
