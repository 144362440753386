import classNames from 'classnames';
import Cover from '~/components/ui/cover/cover';
import { Artwork } from '~/types/schemas/common/image.schema';

type SuggestedSearchProps = {
    artwork?: Artwork;
    title: string;
    onClick?: () => void;
};

function SuggestedSearch(props: SuggestedSearchProps) {
  const { artwork, title, onClick } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'flex items-center gap-3 p-2 rounded-lg text-left',
        'hover:bg-dark-100',
      )}
    >
      <Cover
        artworks={artwork && [artwork]}
        alt={title}
        className="!w-20 !aspect-[2/1]"
      />
      <h5 className="font-bold text-sm line-clamp-1">{title}</h5>
    </button>
  );
}

export default SuggestedSearch;
