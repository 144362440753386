import Cover, { CoverType } from '~/components/ui/cover/cover';
import { Artwork } from '~/types/schemas/common/image.schema';
import IconAdd from 'virtual:icons/ri/add-fill';
import IconSubtract from 'virtual:icons/ri/subtract-fill';
import React, { MouseEvent } from 'react';
import classNames from 'classnames';

export type ItemProps = {
    title?: string;
    subtitle?: string;
    customSubtitle?: React.ReactNode;
    subtitlePrefix?: string;
    type?: CoverType;
    artworks: Artwork[];
    onIncludeClick?: () => void;
    onExcludeClick?: () => void;
};

function FilterItem(props: ItemProps) {
  const { title, subtitle, customSubtitle, subtitlePrefix, type, artworks, onIncludeClick, onExcludeClick } = props;

  const handleIncludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onExcludeClick?.();
  };

  const buttonClassName = 'rounded flex items-center justify-center transition-colors bg-light-100/70 hover:bg-light-100 w-4 h-4 text-dark-400 text-xs';

  return (
    <div className="filter-item flex items-center p-2 gap-2 rounded bg-transparent hover:bg-dark-100">
      <div className="filter-item__cover w-8 h-8 shrink-0">
        <Cover artworks={artworks} alt={title || ''} type={type} iconClassName="text-sm" />
      </div>
      <div className="filter-item__information flex flex-1 overflow-hidden flex-col">
        <h6 className="filter-item__information__title text-xs text-light-100 line-clamp-1">{title}</h6>
        {(subtitle || customSubtitle || subtitlePrefix) && (
        <div className="flex gap-1 text-light-500 text-xs h-4">
          {subtitlePrefix && (
          <>
            <span className="flex shrink-0">{subtitlePrefix}</span>
            {(subtitle || customSubtitle) && <span>•</span>}
          </>
          )}
          {subtitle || customSubtitle}
        </div>
        )}
      </div>
      <div className="filter-item__actions flex gap-2 ml-auto">
        <button type="button" className={classNames('filter-item__actions--subtract', buttonClassName)} onClick={handleExcludeClick}>
          <IconSubtract />
        </button>
        <button type="button" className={classNames('filter-item__actions--add', buttonClassName)} onClick={handleIncludeClick}>
          <IconAdd />
        </button>
      </div>
    </div>
  );
}

export default React.memo(FilterItem);
