import useMusemindStore from '~/stores/musemind-store';
import MuseMindDndContext from '~/pages/briefs/components/musemind-dnd-context';
import usePreSearchStore from '~/stores/presearch-store';
import DraggableDiv from './components/draggable-div';
import ResizableDiv from './components/resizable-div';
import VideoController from './components/video-controller';

const Musemind = () => {
  const presearch = usePreSearchStore(['isOpen']);
  const musemind = useMusemindStore(['isPipOpen', 'videoElement']);

  return (
    <MuseMindDndContext>
      {musemind.videoElement && musemind.isPipOpen && !presearch.isOpen ? (
        <DraggableDiv className="absolute h-fit bottom-0 right-0 z-[50] w-[35%] animate-fadeIn">
          <ResizableDiv minWidth={480}>
            <VideoController />
          </ResizableDiv>
        </DraggableDiv>
      ) : null}
    </MuseMindDndContext>
  );
};

export default Musemind;
