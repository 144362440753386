import { signal } from '@preact/signals-react';
import { QUEUE_SIZE } from '~/components/features/tracks-upload/track-uploads.constants';
import { UploadFile, UploadsObj } from '~/types/features/file-upload/context';

export const fileUploadStore = {
  files: signal<UploadFile[]>([]),
  queueSize: signal<number>(QUEUE_SIZE),
  session: signal<string | undefined>(undefined),
  uploads: signal<UploadsObj>({}),
  matches: signal<UploadsObj>({}),
  reset() {
    fileUploadStore.files.value = [];
    fileUploadStore.uploads.value = {};
    fileUploadStore.matches.value = {};
    fileUploadStore.session.value = undefined;
  },
  setSession(session: string) {
    fileUploadStore.session.value = session;
  },
  addFiles(newFiles: UploadFile[]) {
    fileUploadStore.files.value = [...fileUploadStore.files.value, ...newFiles];
  },
  addUploads(newUploads: UploadsObj) {
    fileUploadStore.uploads.value = { ...fileUploadStore.uploads.value, ...newUploads };
  },
  removeFile(fileId: string) {
    fileUploadStore.files.value = fileUploadStore.files.value.filter((file) => file.id !== fileId);
  },
};
