export function parseResultNumber(total: number): string {
  if (total > 100000) {
    return '100k+';
  }
  if (total > 50000) {
    return '50k+';
  }
  if (total > 10000) {
    return '10k+';
  }
  if (total > 5000) {
    return '5k+';
  }
  if (total > 1000) {
    return '1k+';
  }
  if (total > 500) {
    return '500+';
  }
  if (total > 100) {
    return '100+';
  }
  return total.toString();
}
