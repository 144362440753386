import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { MouseEvent, PropsWithChildren } from 'react';

type DialogContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function DialogContainer(props: DialogContainerProps) {
  const { children, className } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
  }

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className="fixed inset-0 bg-dark-400/80 z-[90] backdrop-blur" />
      <DialogPrimitive.Content
        onClick={handleClick}
        className={classNames(
          'fixed top-[50%] left-[50%]  translate-x-[-50%] translate-y-[-50%] ',
          'z-[100] max-h-[85vh] w-[90vw] max-w-2xl',
          'bg-dark-300 rounded-lg p-4 overflow-hidden flex flex-col gap-4 items-stretch shadow-2xl',
          className,
        )}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
}
