import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { useTranslation } from 'react-i18next';
import { useArtistSearch } from '~/hooks/api/artist';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';
import { ItemProps } from '../../../items/filter-item';

function Artist() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const { data: artists, fetchNextPage, isFetchingNextPage, hasNextPage } = useArtistSearch(addFilter.query.artist || '');
  const { handleClick: addToFilter } = useCardClick('master_artists');
  const { t } = useTranslation('filter');

  const flattenArtists = artists?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  const formattedArtists: ItemProps[] = flattenArtists?.map((artist) => ({
    type: 'artist',
    artworks: artist.artwork ? [artist.artwork] : [],
    title: artist.full_name,
    chips: artist.music_type,
    onIncludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is'),
    onExcludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is_not'),
  }));

  return (
    <Tab label={t('panel.artists.tab.title')} value="artist">
      <ItemSearch
        itemType="artist"
        inputPlaceholder={t('panel.artists.tab.input.placeholder')}
        value={addFilter.query.artist}
        items={formattedArtists}
        totalCount={formattedArtists.length}
        hasNextPage={hasNextPage}
        isEndReached={!hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        onValueChange={(q) => addFilter.setQuery('artist', q)}
      />
    </Tab>
  );
}

export default Artist;
