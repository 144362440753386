import classNames from 'classnames';
import { cva, VariantProps } from 'cva';
import { Artwork } from '~/types/schemas/common/image.schema';
import IconMusicLine from 'virtual:icons/ri/music-line';
import PlaylistIcon from 'virtual:icons/ri/headphone-line';
import BriefIcon from 'virtual:icons/ri/megaphone-line';
import ArtistIcon from 'virtual:icons/ri/user-line';
import AlbumIcon from 'virtual:icons/ri/album-line';
import CatalogIcon from 'virtual:icons/ri/bookmark-line';

const containerStyle = cva('h-fit aspect-square overflow-hidden shrink-0', {
  variants: {
    detail: {
      true: 'w-64 rounded-lg',
      false: 'w-full drop-shadow-md rounded-md',
    },
  },
  defaultVariants: {
    detail: false,
  },
});

export type CoverType = 'track' | 'playlist' | 'brief' | 'artist' | 'album' | 'catalog';

type CoverProps = VariantProps<typeof containerStyle> & {
  type?: CoverType;
  artworks?: Artwork[];
  alt: string;
  iconClassName?: string;
  className?: string;
};

const PlaceHolderIcon = (type?: CoverType) => {
  switch (type) {
    case 'brief':
      return <BriefIcon />;
    case 'playlist':
      return <PlaylistIcon />;
    case 'artist':
      return <ArtistIcon />;
    case 'album':
      return <AlbumIcon />;
    case 'catalog':
      return <CatalogIcon />;
    default:
      return <IconMusicLine />;
  }
};

const getCovers = (artworks: Artwork[], alt: string, type?: CoverType, iconClassName?: string) => {
  switch (artworks.length) {
    // no covers
    case 0:
      return (
        <div className={classNames('w-full h-full flex justify-center items-center bg-dark-300 text-[3vw] text-light-500', iconClassName)}>
          {PlaceHolderIcon(type)}
        </div>
      );

    // 4 small covers (4 x 128)
    case 4:
      return (
        <div className="grid grid-cols-2 grid-rows-2">
          {artworks.map((artwork) => (
            <img
              key={artwork.small}
              src={artwork.small}
              srcSet={
                `${artwork.xsmall} 50w,`
                + `${artwork.small} 150w,`
                + `${artwork.large} 500w`
              }
              alt={alt}
              className="w-full h-full object-cover"
            />
          ))}
        </div>
      );

    // 1 big cover (1 x 256)
    default:
      return (
        <img
          src={artworks[0]!.large}
          srcSet={
            `${artworks[0]!.xsmall} 50w,`
            + `${artworks[0]!.small} 150w,`
            + `${artworks[0]!.large} 500w`
          }
          alt={alt}
          className="w-full h-full object-cover"
        />
      );
  }
};

const Cover = (props: CoverProps) => {
  const { artworks, detail, alt, type = 'track', className = '', iconClassName } = props;

  return (
    <div className={classNames(containerStyle({ detail }), className)}>
      {getCovers(artworks || [], alt, type, iconClassName)}
    </div>
  );
};

export default Cover;
