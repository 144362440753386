import { z } from 'zod';
import { organizationSettings } from './agent.schema';

export const UserWithOrgSchema = z.object({
  id: z.string(),
  company_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  organization_settings: organizationSettings,
});

export type UserWithOrg = z.infer<typeof UserWithOrgSchema>;
