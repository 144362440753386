import { useTrackContext } from '~/components/entities/track/common/track.context';

export function LyricsContent() {
  const store = useTrackContext()
const track = store.use.track()

  return (
    <div className="h-full overflow-y-auto custom-scrollbar pr-3">
      <p className="text-light-500 whitespace-pre-line">
        {track.lyrics}
      </p>
    </div>
  );
}
