import { useLocation, useNavigate } from 'react-router-dom';
import usePreSearchStore from '~/stores/presearch-store';
import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';

type CardClickHandlers = {
  handleFocusClick: (title: string, id: string) => void;
  handleClick: (label: string, value: string, condition: 'is' | 'is_not') => void;
  handleArtistClick: (artist: DisplayArtist) => void;
};

export function useCardClick(term: keyof typeof termOptions): CardClickHandlers {
  const location = useLocation();
  const navigate = useNavigate();
  const search = useSearchStore(['addValueToMatchingBlock', 'resetFilters']);
  const presearch = usePreSearchStore(['setIsOpen']);

  function handleFocusClick(title: string, id: string) {
    search.resetFilters();
    search.addValueToMatchingBlock(termOptions[term]!, itemConditionsOptions.is!, {
      label: title,
      value: id,
    });
    presearch.setIsOpen(false);
  }

  function handleClick(label: string, value: string, condition: 'is' | 'is_not') {
    search.addValueToMatchingBlock(termOptions[term]!, itemConditionsOptions[condition]!, {
      label,
      value,
    });
    presearch.setIsOpen(false);

    if (location.pathname !== '/search') {
      navigate('/search');
    }
  }

  function handleArtistClick(artist: DisplayArtist) {
    search.addValueToMatchingBlock(termOptions.master_artists!, itemConditionsOptions.is!, {
      label: (artist as DisplayArtist).name,
      value: artist.id,
    });
    presearch.setIsOpen(false);

    if (location.pathname !== '/search') {
      navigate('/search');
    }
  }

  return {
    handleFocusClick,
    handleClick,
    handleArtistClick,
  };
}
