import classNames from 'classnames';
import Button from '~/components/ui/control/button/button';
import useInterestFormStore from '~/stores/forms/interest-form-store';
import { useTranslation } from 'react-i18next';

type InterestFormHeaderProps = {
  onDiscardAndExitClick?: () => void
  onClearFieldsClick?: () => void
};

function InterestFormHeader(props: InterestFormHeaderProps) {
  const { t } = useTranslation('request-form');
  const { onDiscardAndExitClick, onClearFieldsClick } = props;
  const interestForm = useInterestFormStore(['trackTitle', 'interest']);

  return (
    <div className="h-8 flex justify-between items-center w-full">
      <div className="flex flex-col gap-1">
        <h1 className="text-2xl font-bold">{t('header.title')}</h1>
        <p className="text-xs text-light-500">{interestForm.trackTitle}</p>
      </div>
      <div className="flex items-center gap-3">
        <Button className={classNames('!bg-transparent border border-dark-100 hover:!bg-dark-100')} onClick={onClearFieldsClick}>
          {t('header.button.clear')}
        </Button>
        <Button className="!bg-dark-100 hover:!bg-dark-200" onClick={onDiscardAndExitClick}>
          {t('header.button.exit')}
        </Button>
      </div>
    </div>
  );
}

export default InterestFormHeader;
