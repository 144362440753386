import dayjs from 'dayjs';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(UpdateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `in ${time}`;
    },
    past(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `${time} ago`;
    },
    s: 'just now',
    ss: 'a few seconds',
    m: '1 min',
    mm: '%d min',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});
