import { Combobox, Portal } from '@ark-ui/react';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface OptionsProps<T = any> {
    items: T[];
    isLoading?: boolean;
    render: (item: T) => ReactNode;
}

export function SelectInputPortal(props: OptionsProps) {
  const { items, isLoading, render } = props;
  const { t } = useTranslation('common');

  return (
    <Portal>
      <Combobox.Positioner>
        <Combobox.Content
          style={{ width: 'calc(100% + 1.5rem)' }}
          className={classNames('h-fit opacity-0 mt-1 -ml-3 max-h-64 bg-dark-300 border border-dark-100/50 shadow-lg rounded z-50 p-1 overflow-y-auto custom-scrollbar animate-slideInBottom', {
            'opacity-100': isLoading || !!items.length,
          })}
        >
          {!items.length && !isLoading && (
            <div className="w-full flex justify-center items-center my-3">
              <span className="text-light-400">{t('no-results')}</span>
            </div>
          )}
          {isLoading && (
            <div className="w-full flex justify-center items-center my-3">
              <Icon icon="svg-spinners:6-dots-scale-middle" className="text-light-400" />
            </div>
          )}
          {!isLoading && items.map((item, index) => (
            <Combobox.Item
              key={`${item.value}-${index}`}
              item={item}
              className="rounded hover:bg-dark-200 group/list-item data-[highlighted]:bg-dark-200"
            >
              {render?.({ ...item.content })}
            </Combobox.Item>
          ))}
        </Combobox.Content>
      </Combobox.Positioner>
    </Portal>
  );
}
