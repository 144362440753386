import { TagChip } from '../../ui/control/tag-chip/tag-chip';

export interface TagProps {
  id: string;
  name: string;
  synonyms?: string[];
  relevant?: boolean;
  onTagAddClick?: (id: string, name: string) => void;
  onTagSubtractClick?: (id: string, name: string) => void;
  onTagChipClick?: (id: string, name: string) => void;
  tagSuffix?: string;
}

export function Tag(props: TagProps) {
  const {
    id,
    name,
    relevant,
    onTagAddClick,
    onTagSubtractClick,
    onTagChipClick,
    tagSuffix,
  } = props;

  function handleAddClick() {
    onTagAddClick?.(id, name);
  }

  function handleSubtractClick() {
    onTagSubtractClick?.(id, name);
  }

  function handleChipClick() {
    onTagChipClick?.(id, name);
  }

  return (
    <TagChip
      label={`${name}${`${tagSuffix ? ` (${tagSuffix})` : ''}`}`}
      relevant={relevant}
      onClick={handleChipClick}
      onAddClick={handleAddClick}
      onSubtractClick={handleSubtractClick}
    />
  );
}
