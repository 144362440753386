import { useTranslation } from 'react-i18next';
import { LanguageSelector } from './language-selector';

export function DescriptionHeader() {
  const { t } = useTranslation('brief-page');

  return (
    <div className="pt-2 pr-2 pl-4 w-full flex items-center justify-between">
      <h2>{t('header.brief-content.title')}</h2>
      <LanguageSelector />
    </div>
  );
}
