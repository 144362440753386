import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { useTranslation } from 'react-i18next';
import { useSamplerSearch } from '~/hooks/api/sampler';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';

function Sampler() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const { data: catalogs, fetchNextPage, isFetchingNextPage, hasNextPage } = useSamplerSearch(addFilter.query.sampler || '');
  const { handleClick: addToFilter } = useCardClick('internal_refs');
  const { t } = useTranslation('filter');

  const flattenSamplers = catalogs?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.samplers.tab.title')} value="sampler">
      <ItemSearch
        itemType="sampler"
        inputPlaceholder={t('panel.samplers.tab.input.placeholder')}
        value={addFilter.query.sampler}
        onValueChange={(q) => addFilter.setQuery('sampler', q)}
        items={flattenSamplers?.map((sampler) => ({
          type: 'playlist',
          artworks: sampler.artworks || [],
          title: sampler.title,
          subtitle: sampler.tenant?.company_name || undefined,
          onIncludeClick: () => handleClick(sampler.title, sampler.id, 'is'),
          onExcludeClick: () => handleClick(sampler.title, sampler.id, 'is_not'),
        }))}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        totalCount={flattenSamplers.length}
        fetchNextPage={fetchNextPage}
        isEndReached={!hasNextPage}
      />
    </Tab>
  );
}

export default Sampler;
