import { useGetOtherOptions } from '~/hooks/api/options';
import { useGetTags } from '~/hooks/api/tag';

export function useMewoData() {
  const tags = useGetTags();
  const others = useGetOtherOptions();

  const isLoading = tags.isLoading || others.isLoading;
  const isSuccess = tags.isSuccess && others.isSuccess;

  return {
    isLoading,
    isSuccess,
    error: tags.error || others.error,
  };
}
