import { useTranslation } from 'react-i18next';
import { useDownloadTrackFile } from '~/hooks/entities/track/actions/download/use-download-hd-mp3';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as ContextMenu from '../../common/context-menu';

export function DownloadOriginal() {
  const store = useTrackContext()
const track = store.use.track()
  const { t } = useTranslation('track-interactions');
  const { downloadTrackFile } = useDownloadTrackFile(track, 'original');

  return (
    <ContextMenu.Item
      onClick={downloadTrackFile}
      icon="teenyicons:file-outline"
    >
      {t('download.dropdown.group.tracks.original')}
    </ContextMenu.Item>
  );
}
