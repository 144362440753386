import { useTrackContext } from '~/components/entities/track/common/track.context';
import ChatTrigger from '~/components/features/brief-chats/components/chat-trigger/chat-trigger';
import { useBriefContext } from '~/pages/briefs/brief.context';

type TrackChatTriggerProps = {
    className?: string;
};

export function TrackChatTrigger(props: TrackChatTriggerProps) {
  const { className } = props;

  const { data: brief } = useBriefContext();
  const store = useTrackContext();
  const track = store.use.track();

  return (
    <div className={className}>
      <ChatTrigger briefId={brief.id} trackId={track.id} />
    </div>
  );
}
