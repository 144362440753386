import { useGetUser } from '~/hooks/api/auth';
import { useMewoData } from '~/hooks/data/use-mewo-data';

export function useFetchData() {
  const session = useGetUser();
  const mewoData = useMewoData();

  return {
    isLoading: mewoData.isLoading || (session.isLoading && session.fetchStatus !== 'idle'),
    isDataReady: mewoData.isSuccess,
  };
}
