import classNames from 'classnames';
import { ReactNode } from 'react';

type AddFilterElementProps = {
    label: string;
    children: ReactNode;
    className?: string;
};

function AddFilterElement(props: AddFilterElementProps) {
  const { label, children, className } = props;
  return (
    <div className={classNames('pt-2 px-2 h-full flex flex-col items-stretch', className)}>
      <h4 className="text-xs text-light-500 px-2 mb-1 h-4">{label}</h4>
      <div className="flex flex-col gap-0.5 h-full overflow-hidden">
        {children}
      </div>
    </div>
  );
}

export default AddFilterElement;
