import { useTranslation } from 'react-i18next';
import { LicensingQuoteDialog } from '~/components/ui/overlays/dialogs/licensing-quote/licensing-quote';
import * as DropdownMenu from '../common/dropdown-menu';

export function TrackActionsLicensingQuoteModal() {
  const { t } = useTranslation('track-interactions');

  return (
    <LicensingQuoteDialog>
      <DropdownMenu.DialogItem icon="tabler:mail-dollar">
        {t('quote.tooltip')}
      </DropdownMenu.DialogItem>
    </LicensingQuoteDialog>
  );
}
