import classNames from 'classnames';
import { Artwork } from '~/components/ui/cover/artwork';
import BriefInteractions from '~/pages/briefs/components/brief-interactions';
import { BriefDescription } from '~/components/entities/brief/description/description';
import { useBriefContext } from '../brief.context';
import { AgentListChip } from './header/agent-list';
import { ConfidentialityChip } from './header/confidentiality';
import { BudgetChip } from './header/budget';
import { DeadlineChip } from './header/deadline';
import { TrackNumberChip } from './header/tracks-number';
import { CreationInfos } from './header/creation-info';

type BriefHeaderProps = {
    className?: string
};

export function BriefHeader(props: BriefHeaderProps) {
  const { className } = props;
  const { data } = useBriefContext();
  const { id, title, artworks, owned_by_tenant: ownedByTenant, tracks: numberOfTracks } = data;

  return (
    <section className={classNames('flex flex-col gap-6 h-fit select-none @container', className)}>
      <div className="flex gap-6 h-32 px-4 @[52rem]:h-64">
        <Artwork className="w-fit hidden aspect-square @[52rem]:block" artworks={artworks?.map((artwork) => artwork.large)} alt={title} icon="ri:megaphone-line" />
        <div className="flex flex-1 flex-col h-full">
          <div className="relative flex flex-col gap-2 justify-start self-stretch h-full">

            <div className="h-full flex flex-col justify-center">
              <div className="w-full flex justify-between items-center">
                <CreationInfos />
              </div>
              <span id="brief-title" className="text-[3vw] leading-[3.5vw] font-bold text-start break-words line-clamp-2">
                {title}
              </span>
            </div>
          </div>
          <div className="flex-col gap-3 hidden @[52rem]:flex">
            <div className="flex gap-4">
              <AgentListChip />
              <ConfidentialityChip />
            </div>
            <div className="flex gap-4">
              <BudgetChip />
              <DeadlineChip />
              <TrackNumberChip />
            </div>
            <BriefInteractions
              id={id!}
              data={data}
              ownedByTenant={ownedByTenant}
              isEmpty={!((numberOfTracks || 0) > 0)}
            />
          </div>
        </div>
      </div>
      <div className="flex-col gap-3 px-4 flex @[52rem]:hidden">
        <div className="flex gap-4">
          <AgentListChip />
          <ConfidentialityChip />
        </div>
        <div className="flex gap-4">
          <BudgetChip />
          <DeadlineChip />
          <TrackNumberChip />
        </div>
        <BriefInteractions
          id={id!}
          data={data}
          ownedByTenant={ownedByTenant}
          isEmpty={!((numberOfTracks || 0) > 0)}
        />
      </div>
      <div className="px-4">
        <BriefDescription />
      </div>
    </section>
  );
}
