import { useEffect } from 'react';
import { Upload } from '~/types/schemas/file-upload-session/upload.schema';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useMatchTrack } from '~/hooks/api/upload';
import FileInfoLoader from '../../file-info-loader';

type TrackMatchProps = {
    file: UploadTrack
    upload?: Upload
};

export function TrackLaunchMatch(props: TrackMatchProps) {
  const { file, upload } = props;
  const { mutate: launchMatch } = useMatchTrack(upload?.id);

  useEffect(() => {
    if (!upload) return;
    launchMatch(undefined, {
      onSuccess: () => {
        file.step.value = 'match';
      },
    });
  }, []);

  return <FileInfoLoader className="border-r border-teal-300" />;
}
