import { useSearchStore } from '~/stores/search-store';
import { useEffect, useState } from 'react';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';

function ResultNumber() {
  const search = useSearchStore(['nbResults', 'isSearchLoading']);
  const [currentNumber, setCurrentNumber] = useState(search.nbResults);
  const { t } = useTranslation('search');

  useEffect(() => {
    if ((currentNumber === search.nbResults) || search.isSearchLoading) {
      return;
    }

    setCurrentNumber(search.nbResults);
  }, [currentNumber, search.isSearchLoading, search.nbResults]);

  return (
    <div className="h-4 border-l border-x-primary-400 pl-2 text-xs flex items-center text-light-100">
      <span>
        {t('results.results-number', { number: parseThousands(currentNumber.toString(), ' ') })}
      </span>
    </div>
  );
}

export default ResultNumber;
