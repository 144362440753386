import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreateBrief, useUpdateNewBriefRound } from '~/hooks/api/brief';
import Button from '~/components/ui/control/button/button';
import { Brief } from '~/types/schemas/music/brief.schema';

type NewBriefRoundProps = {
  data: Brief
};

function getNewRoundTitle(title: string) {
  const isTitleContainsRound = title.toLocaleLowerCase().includes('round');

  if (isTitleContainsRound) {
    const roundMatch = title.match(/round (\d+)/i);
    const round = roundMatch?.[1] ? parseInt(roundMatch[1], 10) : 1;

    return `${title.replace(/round (\d+)/i, '')}Round ${round + 1}`;
  }

  return `${title} - Round 2`;
}

function useCreateNewRound() {
  const { mutate: createBrief, isPending: isCreatePending } = useCreateBrief();
  const { mutate: updateBrief, isPending: isUpdatePending } = useUpdateNewBriefRound();

  function handleCreateNewRound(brief:Brief, onSuccess: (newRound: Brief) => void) {
    const newTitle = getNewRoundTitle(brief.title);
    createBrief({ title: newTitle }, {
      onSuccess(data) {
        if (data.id) {
          updateBrief({ ...brief, title: newTitle, id: data.id }, {
            onSuccess(newRoundData) {
              onSuccess(newRoundData);
            },
          });
        }
      },
    });
  }

  return {
    handleCreateNewRound,
    isPending: isCreatePending || isUpdatePending,
  };
}

function NewBriefRound(props: NewBriefRoundProps) {
  const { data } = props;
  const { handleCreateNewRound, isPending } = useCreateNewRound();
  const navigate = useNavigate();
  const { t } = useTranslation('brief-page');

  function handleNewRound() {
    handleCreateNewRound(data, (newRound) => {
      navigate(`/briefs/${newRound.id}`, {
        replace: true,
      });
    });
  }

  return (
    data.owned_by_tenant ? (
      <Button isLoading={isPending} className="playlist__edit-button w-fit h-full" iconRight="pencil-line" onClick={handleNewRound} disabled={isPending}>
        {t('header.interactions.button.new-round')}
      </Button>
    ) : null
  );
}

export default NewBriefRound;
