import { useParams } from 'react-router-dom';
import { BriefProvider } from '~/providers/briefs/brief-provider';
import { useBriefData } from '~/hooks/data/use-brief-data';
import { useMemo } from 'react';
import { BriefTrackItemList } from '~/components/entities/track/track-item-list/brief/brief-track-item-list';
import { BriefHeader } from './components/brief-header';
import { BriefAsideChannel } from './components/brief-aside-chat';
import { BriefContext } from './brief.context';

function BriefBase({ id }:{id:string}) {
  const { data, isLoading, targetLanguage, handleTargetLanguageChange } = useBriefData(id);

  const contextValue = useMemo(() => ({
    data,
    isLoading,
    targetLanguage,
    handleTargetLanguageChange,
  }), [data, isLoading, targetLanguage]);

  const scrollId = `brief-content-${id}`;

  return (
    <BriefContext.Provider value={contextValue}>
      <BriefProvider brief={data}>
        <section className="relative self-stretch h-full overflow-hidden">
          <div className="flex h-full">
            <div className="pt-4 flex-1">
              <div id={scrollId} className="flex flex-col h-full w-full overflow-y-auto items-stretch">
                <BriefHeader className="z-10 pb-4" />
                <div className="h-full">
                  <div className="flex flex-col px-4 h-fit min-h-full z-0">
                    <BriefTrackItemList brief={data} scrollParentId={scrollId} />
                  </div>
                </div>
              </div>
            </div>
            <BriefAsideChannel id={id} title={data.title} />
          </div>
        </section>
      </BriefProvider>
    </BriefContext.Provider>
  );
}

export function Brief() {
  const { id } = useParams<{ id: string }>();
  return id ? (
    <BriefBase id={id} />
  ) : null;
}
