import { Icon } from '@iconify/react/dist/iconify.js';
import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import React, { Ref } from 'react';

export type SelectMiniProps = {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  options: Array<{ value: string; label: string }>;
  className?: string;
  position?: 'popper' | 'item-aligned';
  sideOffset?: number;
  disabled?: boolean;

};

type SelectItemProps = {
  option: { value: string; label: string };
  className?: string;
};

const SelectItem = React.forwardRef(
  (props: SelectItemProps, forwardedRef: Ref<HTMLDivElement>) => {
    const { option, className } = props;
    const { value, label } = option;
    return (
      <Select.Item
        {...props}
        className={classNames('outline-none select-none rounded', className)}
        value={value}
        ref={forwardedRef}
      >
        <Select.ItemText>{label}</Select.ItemText>
      </Select.Item>
    );
  },
);

const SelectMini = (props: SelectMiniProps) => {
  const { label, value, onChange, disabled = false, options, className, position = 'item-aligned', sideOffset = 4 } = props;

  return (
    <Select.Root onValueChange={onChange} value={value}>
      <Select.Trigger
        className={classNames(
          'SelectTrigger bg-dark-300 px-2 py-1 rounded flex items-center text-xs outline-none justify-between',
          disabled ? 'opacity-50 cursor-default' : 'cursor-pointer',
          className,
        )}
        aria-label="Food"
        disabled={disabled}
      >
        <Select.Value className="" />
        <Select.Icon className="SelectIcon ml-3">
          <Icon icon="ri:arrow-down-s-line" className="text-xl" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content position={position} sideOffset={sideOffset} className="SelectContent w-full bg-dark-300 p-1 rounded shadow-md">
          <Select.Viewport className="SelectViewport">
            <Select.Group>
              {label ? <Select.Label className="SelectLabel text-xs text-light-500">{label}</Select.Label> : null}
              {options.map((option) => (
                <SelectItem
                  key={option.value}
                  option={option}
                  className="SelectItem text-xs p-2 rounded-sm hover:bg-dark-200  w-full"
                />
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default SelectMini;
