import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';

export function useCatalogFilter() {
  const search = useSearchStore(['addValueToMatchingBlock']);

  function handleCatalogClick(id: string, label: string) {
    search.addValueToMatchingBlock(termOptions.catalog!, itemConditionsOptions.is!, {
      value: id,
      label,
    });
  }

  return {
    onCatalogClick: handleCatalogClick,
  };
}
