import useCurrentTime from '~/hooks/music/use-current-time';
import { Track } from '~/types/schemas/music/track.schema';

export interface TimeProps {
  track: Track | undefined;
}

const parseDuration = (duration: number) => {
  if (Number.isNaN(duration)) return '00:00';

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

export const Time = (props: TimeProps) => {
  const { track } = props;

  const currentTime = useCurrentTime(track?.id);

  return (
    <div className="player__time flex text-xs font-bold gap-2">
      <span className="player__time__current text-primary-500 w-10">{parseDuration(currentTime!)}</span>
      <span className="player__time__duration w-10">{parseDuration(track?.duration || 0)}</span>
    </div>
  );
};
