/* eslint-disable no-unsafe-optional-chaining */
import { DndContext, DragEndEvent, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { KeyboardSensor } from '~/utils/dnd-kit/class/keyboard-sensor';
import { MouseSensor } from '~/utils/dnd-kit/class/mouse-sensor';
import useMusemindStore from '~/stores/musemind-store';

type MuseMindDndContextProps = {
    children: React.ReactNode;
};

const MuseMindDndContext = (props: MuseMindDndContextProps) => {
  const { children } = props;
  const { setNodeRef } = useDroppable({ id: 'musemind-pip-droppable' });
  const musemind = useMusemindStore(['pipPosition', 'setPipPosition']);

  const handleDragEnd = (ev: DragEndEvent) => {
    if (musemind.pipPosition === undefined) return;
    const newLeft = musemind.pipPosition?.left + ev.delta.x;
    const newTop = musemind.pipPosition?.top + ev.delta.y;
    musemind.setPipPosition({
      ...musemind.pipPosition,
      top: newTop,
      left: newLeft,
    } as DOMRectReadOnly);
  };

  /*  const restrictToParentElement: Modifier = ({
    draggingNodeRect,
    transform,
  }) => {
    if (!draggingNodeRect || !contentNodeRect) {
      return transform;
    }

    const updatedContentNodeRect = {
      width: contentNodeRect.width,
      height: contentNodeRect.height,
      left: contentNodeRect.left,
      top: contentNodeRect.top,
    } as DOMRectReadOnly;

    return restrictToBoundingRect(transform, draggingNodeRect, updatedContentNodeRect);
  }; */

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext sensors={sensors} modifiers={[restrictToWindowEdges]} onDragEnd={handleDragEnd}>
      <div ref={setNodeRef}>
        {children}
      </div>
    </DndContext>
  );
};

export default MuseMindDndContext;
