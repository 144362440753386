import { KyOptions } from 'ky/distribution/types/options';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ZodSchema } from 'zod';
import { api } from '../api';

export type QueryKeyT = [string, KyOptions | undefined];
export type FetcherOptions<T> = {
  options: QueryFunctionContext<QueryKeyT>;
  schema?: ZodSchema<T>;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
};

export type InfiniteFetcherOptions<T> = {
  schema: ZodSchema<T>
  onSuccess?: (data: T) => void;
} & QueryFunctionContext<QueryKeyT, number>;

export const fetcher = <T>(params: FetcherOptions<T>): Promise<T> => {
  const { options, schema, onSuccess, onError } = params;
  const { queryKey } = options;
  const [url, queryParams] = queryKey;

  return api
    .get(url, {
      ...queryParams,
      ...(queryParams?.json ? { json: queryParams.json } : {}),
    })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return schema ? schema.parse(data) : data as T;
    })
    .then((data) => {
      onSuccess?.(data);
      return data;
    })
    .catch((error) => {
      onError?.(error as Error);
      throw error;
    });
};

export const postFetcher = <T>({ queryKey }: QueryFunctionContext<QueryKeyT>, schema: ZodSchema<T>): Promise<T> => {
  const [url, params] = queryKey;

  return api
    .post(url, {
      ...params,
      ...(params?.json ? { json: params.json } : {}),
    })
    .then((res) => res.json())
    .then((data) => schema.parse(data));
};

export const infiniteFetcher = <T>({
  queryKey,
  pageParam,
  schema,
}: InfiniteFetcherOptions<T>): Promise<T> => {
  const [url, params] = queryKey;

  return api
    .post(url, {
      ...params,
      json: {
        ...params,
        page: pageParam,
      },
    })
    .then((res) => res.json())
    .then((data) => schema.parse(data));
};
