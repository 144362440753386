import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import {
  BlockValue,
  BpmRangeType,
  FilterBlock,
  itemConditionsOptions,
  numberConditionsOptions,
  PopularityRangeType,
  ReleaseDateRangeType,
  SimilarityTrackType,
  SimilarityURLType,
  termOptions,
} from '../../../stores/search-store';

export function stringifySearchParams(filters: {
  blocks: FilterBlock[];
  query?: string;
  similarityTrack?: SimilarityTrackType;
  similarityURL?: SimilarityURLType;
  releaseDate?: ReleaseDateRangeType;
  popularity?: PopularityRangeType;
  bpm?: BpmRangeType;
  musicType?: string[];
  sort?: string;
}) {
  let params = '';
  const next: Record<string, { k: string; v: string }[]> = {};

  filters.blocks?.forEach(({ term, condition, values }) => {
    const k = `${term.value}-${condition.value}`;
    const v = values.map(({ label, value }) => {
      return {
        k: encodeURIComponent(label!),
        v: value!,
      };
    });

    if (v.length !== 0) next[k] = [...(next[k] || []), ...v];
  });

  Object.keys(next).forEach((k, i) => {
    const v = next[k];
    const vStr = v?.map(({ k: key, v: value }) => `${key}:${value}`).join(',');
    params += `${k}=${vStr}${i < Object.keys(next).length - 1 ? '&' : ''}`;
  });

  const { name, id, artists } = filters.similarityTrack || {};
  const { jobId, title, youtubeId } = filters.similarityURL || {};
  const { from, to } = filters.releaseDate || {};
  const { from: fromPopularity, to: toPopularity } = filters.popularity || {};
  const { from: fromBpm, to: toBpm } = filters.bpm || {};

  const result = [
    params,
    filters.query ? `contain=${encodeURIComponent(filters.query)}` : '',
    filters.similarityTrack?.id
      ? `similar_to=${encodeURIComponent(name!)}:${encodeURIComponent(artists!)}:${id}`
      : '',
    filters.similarityURL
      ? `similar_to_url=${encodeURIComponent(title!)}:${jobId}:${youtubeId}`
      : '',
    filters.musicType?.length
      ? `music_type=${filters.musicType.join(',')}`
      : '',
    from && to ? `release_date=${from?.toString()}::${to?.toString()}` : '',
    fromPopularity && toPopularity
      ? `popularity=${fromPopularity?.toString()}:${toPopularity?.toString()}`
      : '',
    fromBpm && toBpm ? `bpm=${fromBpm?.toString()}:${toBpm?.toString()}` : '',
    filters.sort ? `sort=${filters.sort}` : '',
  ]
    .filter((v) => v)
    .join('&');

  return result;
}

// a function which parses the search params and returns an object with the parsed values
export function parseSearchParams(search: string): {
  blocks: FilterBlock[];
  query: string | null;
  similarityTrack: SimilarityTrackType | null;
  similarityURL: SimilarityURLType | null;
  releaseDate: ReleaseDateRangeType | null;
  popularity: PopularityRangeType | null;
  bpm: BpmRangeType | null;
  musicType: string[] | null;
  sort?: string;
} {
  const params = new URLSearchParams(search);

  const blocks: FilterBlock[] = [];
  let query: string | null = null;
  let similarityTrack: SimilarityTrackType | null = null;
  let similarityURL: SimilarityURLType | null = null;
  let releaseDate: ReleaseDateRangeType | null = null;
  let popularity: PopularityRangeType | null = null;
  let bpm: BpmRangeType | null = null;
  let musicType: string[] | null = null;
  let sort: string | undefined;

  params.forEach((value, key) => {
    if (
      key !== 'similar_to' &&
      key !== 'contain' &&
      key !== 'similar_to_url' &&
      key !== 'release_date' &&
      key !== 'popularity' &&
      key !== 'bpm' &&
      key !== 'music_type' &&
      key !== 'sort'
    ) {
      const [term, condition] = key.split('-');

      if (term !== 'tags' && term !== 'tag_categories') {
        const pattern = /([^,].+?):([a-f0-9]{24})/g;
        const matches = value
          .match(pattern)
          ?.map((item) => item.trim().replace(/^,/, ''));

        const blockId = nanoid();
        const values: BlockValue[] =
          matches?.map((val) => {
            const vSplitted = val.split(':');

            const v = vSplitted.pop();
            const k = vSplitted.join(':');

            return {
              blockId,
              id: nanoid(),
              label: k?.trimStart(),
              value: v,
            };
          }) || [];

        blocks.push({
          id: blockId,
          term: termOptions[term!]!,
          condition:
            itemConditionsOptions[condition!]! ||
            numberConditionsOptions[condition!]!,
          values,
        });
      }

      if (term === 'tags' || term === 'tag_categories') {
        value.split(',').forEach((v) => {
          const blockId = nanoid();
          const [k, val] = v.split(':');

          const values = [
            {
              blockId,
              id: nanoid(),
              label: k,
              value: val,
            },
          ];

          blocks.push({
            id: blockId,
            term: termOptions[term!]!,
            condition: itemConditionsOptions[condition!]!,
            values,
          });
        });
      }
    }
    if (key === 'contain') {
      query = value;
    }

    if (key === 'similar_to') {
      const valueSplited = value.split(':');
      const id = valueSplited.pop();
      const artists = valueSplited.pop();
      const name = valueSplited.join(':');
      similarityTrack = {
        id,
        name,
        artists,
      };
    }

    if (key === 'similar_to_url') {
      const valueSplited = value.split(':');
      const youtubeId = valueSplited.pop();
      const jobId = valueSplited.pop();
      const title = valueSplited.join(':');
      if (jobId && title && youtubeId) {
        similarityURL = {
          youtubeId,
          jobId,
          title,
        };
      }
    }

    if (key === 'release_date') {
      const [from, to] = value.split('::');
      if (from && to) {
        const fromParsed = dayjs(from).toISOString();
        const toParsed = dayjs(to).toISOString();
        if (fromParsed && toParsed) {
          releaseDate = {
            from: fromParsed,
            to: toParsed,
          };
        }
      }
    }

    if (key === 'popularity') {
      const [from, to] = value.split(':');
      if (from && to) {
        const fromParsed = parseInt(from, 10);
        const toParsed = parseInt(to, 10);
        if (fromParsed !== undefined && toParsed !== undefined) {
          popularity = {
            from: fromParsed,
            to: toParsed,
          };
        }
      }
    }

    if (key === 'bpm') {
      const [from, to] = value.split(':');
      if (from && to) {
        const fromParsed = parseInt(from, 10);
        const toParsed = parseInt(to, 10);
        if (fromParsed !== undefined && toParsed !== undefined) {
          bpm = {
            from: fromParsed,
            to: toParsed,
          };
        }
      }
    }

    if (key === 'music_type') {
      const values = value.split(',');
      if (values.length) {
        musicType = values.map((v) => v.trim());
      }
    }

    if (key === 'sort') {
      sort = value;
    }
  });

  return {
    blocks,
    query,
    similarityTrack,
    similarityURL,
    releaseDate,
    popularity,
    bpm,
    musicType,
    sort,
  };
}
