import { TrackMetadata } from '~/types/features/track-upload/track-metadata.types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import TrackDetailItem from './track-detail-item';

type TrackCardInformationsProps = {
    track?: TrackMetadata,
};

export default function TrackDetailGroup(props: TrackCardInformationsProps) {
  const { track } = props;
  const { user } = globalStore;
  const { t } = useTranslation('tracks-upload-form');

  return (
    <ul className="row-span-4 grid grid-rows-subgrid px-5 py-0 ">
      <TrackDetailItem label={t('card.informations.title')} value={track?.title} />
      <TrackDetailItem label={t('card.informations.artists')} value={track?.artists?.join(', ')} />
      <div className="flex gap-3 items-center">
        <TrackDetailItem className="flex-1" label={t('card.informations.album')} value={track?.album} />
        <TrackDetailItem className="flex-1" label={t('card.informations.catalog')} value={track?.catalog || user.value?.company_name || 'Catalog'} />
      </div>
      <TrackDetailItem label={t('card.informations.release')} value={track?.releaseDate ? dayjs(track?.releaseDate).format('MMMM DD, YYYY') : '-'} />
    </ul>
  );
}
