import { useEffect } from 'react';
import { Upload } from '~/types/schemas/file-upload-session/upload.schema';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { trackUploadStore } from '~/stores/track-upload-store';
import { globalStore } from '~/stores/global-store-v2';
import { useTenantCatalogSearch } from '~/hooks/api/catalog';
import { useGetMatchByUploadId } from '~/hooks/api/upload';
import FileInfoLoader from '../../file-info-loader';

type TrackMatchProps = {
    file: UploadTrack
    upload?: Upload
};

export function TrackMatchResult(props: TrackMatchProps) {
  const { file, upload } = props;
  const { data, error } = useGetMatchByUploadId(upload?.id);
  const { sortTracks, onMatchFound, onMatchNotFound } = trackUploadStore;
  const { data: catalogs } = useTenantCatalogSearch('', globalStore.user.value!.tenant_id!);

  useEffect(() => {
    if (error) {
      if (error.message === 'No match') {
        file.step.value = 'noMatch';
        sortTracks();
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const matchedData = data.metadata?.match?.result;
      if (matchedData) {
        onMatchFound(file, {
          title: matchedData?.title,
          artists: matchedData?.artists,
          album: matchedData?.album,
          releaseDate: matchedData?.release_date,
          cover: matchedData?.cover,
          catalog: catalogs?.pages[0]?.hits[0]?.name,
        });
      }

      if (!matchedData) {
        onMatchNotFound(file);
      }
    }
  }, [data]);

  return <FileInfoLoader className="border-r border-teal-500" />;
}
