/* eslint-disable @typescript-eslint/no-explicit-any */
import { globalStore } from '~/stores/global-store-v2';
import ky, { Hooks } from 'ky';
import { KyOptions } from 'ky/distribution/types/options';
import { userSettingsStore } from '~/stores/user-settings-store';

const { apiUrl, userToken, user } = globalStore;
const { language } = userSettingsStore;

function getHeaders() {
  return {
    'Content-Type': 'application/json',
    'x-auth': userToken.value,
    'x-preferred-locale': language.value.split('-')[0],
  };
}

const hooks: Hooks = {
  afterResponse: [
    async (_input, _options, response) => {
      if (user.value && response.status === 401) {
        globalStore.handleUnauthorized();
      }

      // handle invalid token for old api endpoints
      if (response.status === 400) {
        const body = await response.clone().json();
        if ((body as any).key === 'invalid_token') {
          globalStore.handleUnauthorized();
        }
      }
      return response;
    },
  ],
};

export const api = {
  get: (url: string, params?: KyOptions) =>
    ky.get(`${apiUrl.value}/${url}`, {
      headers: getHeaders(),
      hooks,
      ...(params || {}),
    }),
  post: (url: string, params?: KyOptions) =>
    ky.post(`${apiUrl.value}/${url}`, {
      headers: getHeaders(),
      hooks,
      ...(params || {}),
    }),
  put: (url: string, params?: KyOptions) =>
    ky.put(`${apiUrl.value}/${url}`, {
      headers: getHeaders(),
      hooks,
      ...(params || {}),
    }),
  patch: (url: string, params?: KyOptions) =>
    ky.patch(`${apiUrl.value}/${url}`, {
      headers: getHeaders(),
      hooks,
      ...(params || {}),
    }),
  delete: (url: string, params?: KyOptions) =>
    ky.delete(`${apiUrl.value}/${url}`, {
      headers: getHeaders(),
      hooks,
      ...(params || {}),
    }),
};
