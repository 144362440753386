import { motion } from 'framer-motion';

interface AccordionItemProps {
    children: React.ReactNode;
    className?: string;
}

export function AccordionItem(props: AccordionItemProps) {
  const { children, className } = props;

  const anim = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -10, transition: { ease: 'easeOut', duration: 0.1 } },
  };

  return (
    <motion.li
      variants={anim}
      className={className}
    >
      {children}
    </motion.li>
  );
}
