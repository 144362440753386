/* eslint-disable jsx-a11y/label-has-associated-control */
import { useSearchStore } from '~/stores/search-store';
import { useEffect, useState } from 'react';
import FilterDropDown from './filter-dropdown';
import { useTranslation } from 'react-i18next';

const MusicType = () => {
  const search = useSearchStore(['musicType', 'setMusicType']);
  const [core, setCore] = useState(search.musicType.includes('core'));
  const [production, setProduction] = useState(search.musicType.includes('production'));
  const { t } = useTranslation(['filter']);

  useEffect(() => {
    setCore(search.musicType.includes('core'));
    setProduction(search.musicType.includes('production'));
  }, [search.musicType]);

  useEffect(() => {
    const types = [];
    if (core) types.push('core');
    if (production) types.push('production');

    search.setMusicType(types);
  }, [core, production, search.setMusicType]);

  const isActive = (core && !production) || (!core && production);

  return (
    <FilterDropDown
      label={t('music-type.title')}
      isActive={isActive}
      triggerContent={(
        <span className="text-xs text-light-500">
          {search.musicType.map((type) => (type === 'core' ? t('music-type.value.core') : t('music-type.value.prod'))).join(', ')}
        </span>
      )}
      className="w-62"
    >
      <div className="flex flex-col gap-3 py-2 px-1">
        <label className="flex items-center gap-3">
          <input checked={core} onChange={() => setCore(!core)} type="checkbox" className="accent-primary-500 w-4 rounded aspect-square" />
          <span className="text-xs text-light-500">{t('music-type.options.core')}</span>
        </label>
        <label className="flex items-center gap-3">
          <input checked={production} onChange={() => setProduction(!production)} type="checkbox" className="accent-primary-500 w-4 rounded aspect-square" />
          <span className="text-xs text-light-500">{t('music-type.options.prod')}</span>
        </label>
      </div>
    </FilterDropDown>
  );
};

export default MusicType;
