import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import GeneralIcons from '~/components/ui/icon/general-icons';

export type NavigationItems = {
  name: string
  link: string
  suffix?: ReactNode
  disabled?: boolean
};

interface ItemButtonProps extends NavigationItems {
  droppableId?: string
  type?: 'playlist' | 'brief'
  className?: string
  isCreatedByUser?: boolean
}

const TracklistLink = (props: ItemButtonProps) => {
  const { suffix, name: label, link, className, isCreatedByUser, disabled, droppableId, type } = props;
  const navRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { setNodeRef, isOver } = useDroppable({
    id: droppableId || label,
    data: {
      type,
      accepts: ['track'],
    },
  });

  const icon = useMemo(() => {
    switch (type) {
      case 'brief':
        return 'brief';
      case 'playlist':
        return isCreatedByUser ? 'playlist' : 'collab-playlist';
      default:
        return undefined;
    }
  }, [isCreatedByUser, type]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOver) {
      const timeout = setTimeout(() => {
        navigate(link);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isOver, link, navigate]);

  return (
    <div
      ref={setNodeRef}
      id={`sidebar__tracklist__${droppableId}`}
      className={(`sidebar__tracklist__${type} sidebar__tracklist__item px-2 rounded`)}
      data-over={isOver}
    >

      <div ref={navRef} className="w-full group flex justify-between">
        <NavLink
          to={link}
          className={({ isActive }) => {
            return classNames(
              'flex w-full py-2 gap-2 items-center transition-colors duration-100 text-light-500 hover:text-light-100',
              isActive && '!text-light-100',
              disabled && 'pointer-events-none text-dark-100 hover:text-dark-100',
              className,
            );
          }}
        >
          {({ isActive }) => (
            <div className="flex w-full items-center gap-4 text-xs ">
              <div className="sidebar__tracklist__item__icon w-3 shrink-0">
                <GeneralIcons icon={`${icon}-${isActive ? 'fill' : 'line'}`} />
              </div>
              <span className="sidebar__tracklist__item__title text-ellipsis overflow-hidden">{label}</span>
            </div>
          )}
        </NavLink>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          {suffix || null}
        </div>
      </div>
    </div>
  );
};

export default TracklistLink;
