import { Icon, IconifyIconProps } from '@iconify/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MouseEvent } from 'react';

type DropdownMenuItemProps = {
  icon?: IconifyIconProps['icon'];
  onClick?: () => void;
  children: React.ReactNode;
};

export function DropdownMenuSubItem(props: DropdownMenuItemProps) {
  const { icon, onClick, children } = props;

  function handleClick(e:MouseEvent) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <DropdownMenu.SubTrigger className="flex items-center gap-3 h-8 px-2 text-white text-sm select-none rounded cursor-default outline-none border border-transparent focus:bg-dark-100  focus:border-white/10" onClick={handleClick}>
      <div className="size-4 flex items-center justify-center">
        {icon && <Icon icon={icon} className="w-4 h-4" />}
      </div>
      <div className="flex-1">
        {children}
      </div>
      <div className="size-4 flex items-center justify-center">
        <Icon icon="ri:arrow-right-s-line" className="w-4 h-4" />
      </div>
    </DropdownMenu.SubTrigger>
  );
}
