/* eslint-disable jsx-a11y/control-has-associated-label */
import { ChangeEvent, FocusEventHandler, Ref, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface DateInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function Password(props: DateInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    required = false,
    onChange,
    onFocus,
    onBlur,
    type,
    ...inputProps
  } = props;
  const [showPassword, setShowPassword] = useState(!(type === 'password'));

  function handleClick() {
    setShowPassword((prev) => !prev);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className} data-no-dnd="true">
      <div
        className={classNames(containerClass, '!cursor-pointer group/file-input !flex-row gap-3 items-center')}
      >
        <div className="flex flex-col flex-1">
          {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-error-500">*</span>}
          </span>
          )}
          <div className="flex items-center gap-3 overflow-hidden">
            <input
              ref={inputRef}
              aria-label={label}
              placeholder={placeholder || label}
              type={showPassword ? 'text' : 'password'}
              className={classNames(inputClass)}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              {...inputProps}
            />
          </div>
        </div>
        <button type="button" className="transition-all size-6 shrink-0 flex items-center justify-center rounded bg-dark-400 group-hover/file-input:bg-dark-400/60" onClick={handleClick}>
          <Icon icon={showPassword ? 'ri:eye-line' : 'ri:eye-off-line'} className="text-light-100 text-xs" />
        </button>
      </div>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const PasswordInput = forwardRef(Password);
