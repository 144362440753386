import React, { useEffect, useMemo, useState } from 'react';
import SearchInput from '~/components/ui/search-input/search-input';
import useDebounce from '~/hooks/common/use-debounce';
import { InfiniteScroll } from '~/components/layout/infinite-scroll/infinite-scroll';
import { Range } from '@tanstack/react-virtual';
import TagItem, { TagItemProps } from '../items/tag-item';

type ItemSearchProps = {
  inputPlaceholder: string;
  items: TagItemProps[];
  value?: string;
  onValueChange?: (value: string) => void;
};

const TagItemRender = React.memo((props: {tag: TagItemProps}) => {
  const { tag } = props;
  return <TagItem {...tag} />;
});

const FETCH_OVERSCAN = 10;

function TagSearch(props: ItemSearchProps) {
  const { inputPlaceholder, items, value: defautlValue, onValueChange } = props;
  const [value, setValue] = useState(defautlValue || '');
  const [isEndReached, setIsEndReached] = useState(false);
  const debouncedValue = useDebounce(value, 500);
  const [currentLimit, setCurrentLimit] = useState(50);
  const currentItems = useMemo(() => items.slice(0, currentLimit), [items, currentLimit]);
  const hasNextPage = items.length > currentItems.length;

  function handleRangeChange({ endIndex }: Range) {
    if (endIndex >= (currentItems?.length || 0) - FETCH_OVERSCAN && hasNextPage) {
      setCurrentLimit(currentLimit + 50);
    }
    setIsEndReached(endIndex === items.length - 1);
  }

  useEffect(() => {
    if (debouncedValue !== undefined) {
      onValueChange?.(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <div className="flex flex-col items-stretch gap-3 h-full overflow-hidden  mt-2">
      <div className="px-2">
        <SearchInput
          value={value}
          onChange={setValue}
          placeholder={inputPlaceholder}
          className="w-full"
        />
      </div>
      <div id="filter-search-tag" className="flex flex-col h-full gap-0.5 overflow-y-auto custom-scrollbar p-2 pb-0">
        <div className="w-full h-fit">
          <InfiniteScroll
            data={currentItems}
            count={currentItems.length}
            estimateSize={42}
            overscan={5}
            renderItem={(tag) => <TagItemRender tag={tag as TagItemProps} />}
            onRangeChange={handleRangeChange}
            isEndReached={isEndReached}
            scrollParent={document.getElementById('filter-search-tag')}
          />

        </div>
      </div>
    </div>
  );
}

export default TagSearch;
