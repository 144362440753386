import dayjs from 'dayjs';
import { albumRoutes } from '~/routes/api';
import { Album, AlbumSearchResultSchema } from '~/types/schemas/music/album.schema';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';

type AlbumSearchOptions = {
  query: string;
  params?: object;
};

const useGenericAlbumSearch = (options: AlbumSearchOptions) => {
  const { query, params } = options;
  return useLoadMore<Album>({
    url: albumRoutes.search,
    schema: AlbumSearchResultSchema,
    queryParams: {
      query,
      size: 50,
      ...params,
    },
  });
};

export const useAlbumSearch = (query:string) => {
  return useGenericAlbumSearch({
    query,
  });
};

export const useNewReleaseAlbumSearch = (query:string) => {
  return useGenericAlbumSearch({
    query,
    params: {
      filters: {
        release_date: {
          gte: dayjs().subtract(1, 'month').startOf('day').toISOString(),
          lte: dayjs().endOf('day').toISOString(),
        },
      },
      sort: {
        key: 'release_date',
        direction: -1,
      },
    } });
};

export const usePreReleaseAlbumSearch = (query:string) => {
  return useGenericAlbumSearch({
    query,
    params: {
      filters: {
        release_date: {
          gte: dayjs().add(1, 'day').startOf('day').toISOString(),
          lte: dayjs('3000').endOf('year').toISOString(),
        },
      },
      sort: {
        key: 'release_date',
        direction: 1,
      },
    } });
};
