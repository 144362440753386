import classNames from 'classnames';
import { ReactNode } from 'react';
import Button from '~/components/ui/control/button/button';
import useMusemindStore from '~/stores/musemind-store';
import { useTranslation } from 'react-i18next';

export default function VariationControlBase() {
  const musemind = useMusemindStore(['audioTracks', 'selectedAudioTrack', 'setSelectedAudioTrack']);
  const { t } = useTranslation('magic-preview');

  return (
    <div className="flex gap-2">
      {musemind.audioTracks ? (
        <>
          <ControlButton label={t('pip.footer.ost')} isActive={musemind.selectedAudioTrack === -1} onClick={() => musemind.setSelectedAudioTrack(-1)} />
          <ControlButton label={t('pip.footer.v1')} isActive={musemind.selectedAudioTrack === 0} onClick={() => musemind.setSelectedAudioTrack(0)} />
          <ControlButton label={t('pip.footer.v2')} isActive={musemind.selectedAudioTrack === 1} onClick={() => musemind.setSelectedAudioTrack(1)} />
          <ControlButton label={t('pip.footer.v3')} isActive={musemind.selectedAudioTrack === 2} onClick={() => musemind.setSelectedAudioTrack(2)} />
        </>
      ) : null}
    </div>
  );
}

export const ControlButton = (props: { label?: string, children?: ReactNode, isActive: boolean, isLoading?: boolean, onClick: () => void }) => {
  return (
    <Button
      disabled={props.isLoading}
      isLoading={props.isLoading}
      onClick={props.onClick}
      className={classNames(
        'px-2 py-1 text-xs text-center rounded-md border-2 transition-colors duration-100 text-light-100',
        {
          'bg-primary-500 hover:bg-primary-400 border-transparent': props.isActive,
          'border-primary-500 hover:border-primary-400 bg-transparent': !props.isActive,
        },
      )
      }
      dataNoDnd
    >
      {props.label}
      {props.children}
    </Button>
  );
};
