import { ReactNode } from 'react';

type NavButtonProps = {
    Icon: ReactNode
    onClick: () => void
};

const NavButton = (props: NavButtonProps) => {
  const { Icon, onClick } = props;

  return (
    <button type="button" className="w-6 aspect-square bg-dark-300 rounded-full flex items-center justify-center transition hover:bg-dark-100" onClick={onClick}>
      {Icon}
    </button>
  );
};

export default NavButton;
