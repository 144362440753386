import { useEffect } from 'react';
import { useCreateUploadSession } from '~/hooks/api/upload';
import { fileUploadStore } from '~/stores/file-upload-store';

type FileUploadSessionSetupProps = {
    context: string,
    contextId: string,
};

export function FileUploadSessionSetup(props: FileUploadSessionSetupProps) {
  const { context, contextId } = props;
  const { setSession: setContext } = fileUploadStore;
  const { mutate: createSession } = useCreateUploadSession();

  useEffect(() => {
    createSession({
      context,
      contextId,
    }, {
      onSuccess(data) {
        setContext(data);
      },
    });
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      Init
    </div>
  );
}
