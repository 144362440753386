import { t } from 'i18next';
import { useEffect } from 'react';
import { queryClient } from '~/boot/react-query';
import { Alert } from '~/components/ui/notifications/alert/alert';
import { playlistRoutes } from '~/routes/api';
import { Track } from '~/types/schemas/music/track.schema';
import { pathToUrl } from '~/utils/path-to-url';

type ProcessingTracksProps = {
    id: string
    tracks?: Track[]
};

export function ProcessingTracks(props: ProcessingTracksProps) {
  const { id, tracks = [] } = props;
  const isAllTrackReady = !tracks?.length || !!tracks?.every((track) => !!track.waveform && !!track.duration);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!isAllTrackReady) {
      interval = setInterval(() => {
        queryClient.invalidateQueries({ queryKey: [pathToUrl(playlistRoutes.tracks, { id })] });
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [id, isAllTrackReady]);

  return !isAllTrackReady
    ? <Alert type="warning" title={t('track-ai-analysis.title')} message={t('track-ai-analysis.message')} className="mb-2" />
    : null;
}
