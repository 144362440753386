import classNames from 'classnames';
import { activeChannelStore } from '~/stores/chats-store';

export type UserItemProps = {
  userId: string,
  isFocused: boolean,
  onClick?: () => void;
};

function UserItem(props: UserItemProps) {
  const { userId, isFocused, onClick } = props;
  const { participants } = activeChannelStore;
  const user = participants.value[userId];
  const avatar = user?.organization_settings.avatar;

  return (
    <div
      className={classNames('flex w-full items-center gap-3 px-3 py-2 hover:bg-dark-100 transition-colors cursor-pointer', {
        'bg-dark-100': isFocused,
        'bg-dark-200': !isFocused,
      })}
      onClick={onClick}
    >
      {avatar ? <img src={avatar} alt="" className="w-8 h-8 object-cover rounded-full" /> : (
        <div className="w-8 h-8 shrink-0 flex items-center justify-center rounded-full" style={{ backgroundColor: user?.avatar_color }}>
          <span className="text-white font-bold text-sm">
            {user?.first_name[0]!.toUpperCase()}
            {user?.last_name[0]!.toUpperCase()}
          </span>
        </div>
      )}
      <div>
        <h6 className="text-sm text-light-100">{`${user?.first_name} ${user?.last_name}`}</h6>
        <p className="text-xs text-light-500">{user?.company_name}</p>
      </div>
    </div>
  );
}

export default UserItem;
