import { artistRoutes } from '~/routes/api';
import { Artist, ArtistSearchResultSchema } from '~/types/schemas/music/artist.schema';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';

export const useArtistSearch = (query:string) =>
  useLoadMore<Artist>({
    url: artistRoutes.search,
    schema: ArtistSearchResultSchema,
    queryParams: {
      query,
      size: 50,
    },
  });
