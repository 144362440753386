import { catalogRoutes } from '~/routes/api';
import { Catalog, CatalogSearchResultSchema } from '~/types/schemas/music/catalogs.schema';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';

type CatalogSearchOptions = {
  query: string;
  params?: object;
};

const useGenericCatalogSearch = (options: CatalogSearchOptions) => {
  const { query, params } = options;
  return useLoadMore<Catalog>({
    url: catalogRoutes.search,
    schema: CatalogSearchResultSchema,
    queryParams: {
      query,
      size: 50,
      ...params,
    },
  });
};

export const useCatalogSearch = (query: string) =>
  useGenericCatalogSearch({
    query,
  });

export const useTenantCatalogSearch = (query: string, tenantId: string) =>
  useGenericCatalogSearch({
    query,
    params: {
      filters: {
        tenant: [tenantId],
      },
    },
  });
