import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { useEffect, useState } from 'react';
import CardShelf from '../../../card-shelf/card-shelf';
import { useTranslation } from 'react-i18next';

function ArtistResultBlock() {
  const { artists, isLoading } = useAllTabData();
  const [currentArtists, setCurrentArtists] = useState(artists);
  const { handleFocusClick, handleClick } = useCardClick('master_artists');
  const { t } = useTranslation('pre-search');

  useEffect(() => {
    if (!isLoading) {
      setCurrentArtists(artists);
    }
  }, [isLoading, JSON.stringify(artists)]);

  return currentArtists?.length ? (
    <CardShelf
      label={t('tab.all.artists.title')}
      containerClassName="flex-1 col-span-full"
      rowLimit={1}
      cardsProps={currentArtists?.map((artist) => ({
        type: 'artist',
        artworks: artist.artwork ? [artist.artwork] : undefined,
        title: artist.full_name,
        chips: artist.music_type.map((type) => {
          if (type.includes('core')) return t('music-type.core', { ns: 'common' });
          if (type.includes('production')) return t('music-type.prod', { ns: 'common' });
          return undefined;
        }).filter((type) => type).sort() as string[],
        onCardClick: () => handleFocusClick(artist.full_name, artist.master_artist),
        onIncludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is'),
        onExcludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is_not'),
      }))}
    />
  ) : null;
}

export default ArtistResultBlock;
