import { ChangeEvent, FocusEventHandler, Ref, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { parseThousands } from '~/utils/common/parse-thousands';
import { InputProps } from '../input';

interface NumberInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function Number(props: NumberInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    value,
    placeholder,
    className,
    separator,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    required = false,
    onNumberChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;
  const [currentValue, setCurrentValue] = useState<string | undefined>(parseThousands(value, separator));

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const regex = new RegExp(`${separator}`, 'g');
    const parsedNumber = parseInt(newValue.replace(regex, ''), 10);
    onNumberChange?.(parsedNumber);
    setCurrentValue(parseThousands(newValue, separator));
  }

  return (
    <div className={className} data-no-dnd="true">
      <div className={classNames(containerClass, '!cursor-pointer group/file-input !flex-row gap-3 items-center')}>
        <div className="flex flex-col flex-1">
          {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-error-500">*</span>}
          </span>
          )}
          <div className="flex items-center gap-3 overflow-hidden">
            <input
              {...inputProps}
              type="text"
              ref={inputRef}
              aria-label={label}
              value={currentValue}
              placeholder={placeholder || label}
              className={classNames(inputClass, 'opacity-100')}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const NumberInput = forwardRef(Number);
