import { createWithEqualityFn } from 'zustand/traditional';
import { createStorePicker } from '../utilities';
import { withImmer } from '../middlewares/immer-combine';

const useGlobalFormStore = createStorePicker(createWithEqualityFn(
  withImmer({
    isOpen: false,
  })(
    (set) => ({
      setIsOpen: (isOpen: boolean) => set((state) => {
        state.isOpen = isOpen;
      }),
    }),
  ),
));

export default useGlobalFormStore;
