import { useSearchStore } from '~/stores/search-store';
import { Tag, TagSubCategory } from '~/types/schemas/data/tags.schema';

const NUMBER_OF_TAGS = 10;

export function useBestTagResult(subCategories?: TagSubCategory[], tags?: Tag[]) {
  const search = useSearchStore(['facets']);

  function sortedTagsByMostRelevant(tagArray?: Tag[]): Tag[] {
    if (!tagArray) return [];

    return [...tagArray]?.sort((a, b) => {
      const itemFacetsA = search.facets?.tags?.[a.id] || 0;
      const itemFacetsB = search.facets?.tags?.[b.id] || 0;

      if (itemFacetsA === itemFacetsB) {
        return 0;
      }
      if (itemFacetsA === undefined) {
        return 1;
      }
      if (itemFacetsB === undefined) {
        return -1;
      }

      return itemFacetsA > itemFacetsB ? -1 : 1;
    }) || [];
  }

  const allData = [...(subCategories || []), ...sortedTagsByMostRelevant(tags)];
  const allTags = [...allData].slice(0, NUMBER_OF_TAGS + 1);
  const bestTag = allData.at(0);
  const otherTags = [...allData].slice(1, NUMBER_OF_TAGS + 1);

  return {
    allData,
    allTags,
    bestTag,
    otherTags,
  };
}
