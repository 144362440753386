import { Icon } from '@iconify/react/dist/iconify.js'
import classNames from 'classnames'

type CircleButtonProps = {
    onClick?: () => void
    className?: string
    disabled?: boolean
    icon?: string
}

export function CircleButton(props: CircleButtonProps) {
    const { icon = "", onClick, className, disabled } = props
    return (
        <button
            className={classNames('w-6 h-6 shrink-0 bg-dark-300 border border-dark-100 rounded-full flex items-center justify-center', className)}
            onClick={onClick}
            disabled={disabled}
        >
            <Icon icon={icon} className='text-light-100 text-xs' />
        </button>
    )
}