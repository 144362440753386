/* eslint-disable jsx-a11y/control-has-associated-label */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { contentClassName, dropdownGroups } from '~/components/ui/control/dropdown/dropdown';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import GeneralIcons from '~/components/ui/icon/general-icons';
import DropdownItem from '~/components/ui/control/dropdown/dropdown-item';
import { Track } from '~/types/schemas/music/track.schema';
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDownloadTrack, useDownloadTrackCopyright } from '~/hooks/api/track';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';

type DownloadTrackProps = {
  track: Track;
  onOpenChange?: (isOpen: boolean) => void;
};

const DownloadTrack = (props: DownloadTrackProps) => {
  const { track, onOpenChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: downloadTrack } = useDownloadTrack();
  const { mutate: downloadTrackCopyright } = useDownloadTrackCopyright();
  const { t } = useTranslation(['track-interactions', 'toast']);

  const triggerClassName = 'w-7 h-7 flex items-center justify-center cursor-pointer transition-colors outline-none duration-100  hover:text-primary-400 text-xl';
  const dropdownWidth = '384px';

  function handleOpenChange(open: boolean) {
    setIsOpen(open);
    onOpenChange?.(open);
  }

  function handleDownloadTrack(format: 'hd_mp3' | 'original') {
    downloadTrack({ trackId: track.id, format });
    handleOpenChange(false);
    toastr('success', t('archive.download-wait.title', { ns: 'toast' }), t('archive.download-wait.content', { ns: 'toast' }));
  }

  function handleDownloadCopyright() {
    downloadTrackCopyright({ id: track.id });
    handleOpenChange(false);
  }

  const groups: dropdownGroups = [
    {
      title: t('download.dropdown.group.tracks.title'),
      elements: [
        {
          label: t('download.dropdown.group.tracks.mp3'),
          onclick: () => handleDownloadTrack('hd_mp3'),
        },
        {
          label: t('download.dropdown.group.tracks.original'),
          onclick: () => handleDownloadTrack('original'),
        },
      ],
    },
    {
      title: t('download.dropdown.group.info.title'),
      elements: [
        {
          label: t('download.dropdown.group.info.copyrights'),
          onclick: () => handleDownloadCopyright(),
        },
      ],
    },
  ];

  return (
    <div data-no-dnd="true">
      <DropdownMenu.Root onOpenChange={(b) => handleOpenChange(b)} open={isOpen}>
        <Tooltip label={t('download.tooltip')} asChild>
          <DropdownMenu.Trigger asChild>
            <button type="button" className={classNames('track-interactions__download', triggerClassName)}>
              <GeneralIcons icon="download-line" className="text-base" />
            </button>
          </DropdownMenu.Trigger>
        </Tooltip>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={contentClassName('bottom', 'track-download__dropdown')}
            style={{ width: dropdownWidth }}
            align="end"
            side="bottom"
            sideOffset={5}
          >

            {groups.map((group, groupIndex) => (
              <div key={`group-${groupIndex}`}>
                <DropdownMenu.Group>
                  {group.title && (
                    <DropdownMenu.Label className="p-1 text-gray-400">
                      {group.title}
                    </DropdownMenu.Label>
                  )}
                  {group.elements.map((item, itemIndex) => (
                    <DropdownItem
                      key={`group-${groupIndex}-item-${itemIndex}`}
                      width="192px"
                      className=""
                      menuClassName={contentClassName('bottom', '')}
                      {...item}
                    />
                  ))}
                </DropdownMenu.Group>
                {groupIndex < groups.length - 1 && (
                  <DropdownMenu.Separator className="w-full h-px bg-dark-100 my-1" />
                )}
              </div>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default DownloadTrack;
