import { useState, useEffect } from 'react';

export const useBlur = () => {
  // get the initial state
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    // helper functions to update the status
    const onKeyPress = (e: KeyboardEvent) => {
      // Détecte Ctrl + PrtScn (Windows)
      if ((e.ctrlKey && e.key === 'PrintScreen') || (e.key === 'PrintScreen')) {
        setBlur(true);
      }

      // Détecte Cmd + Shift (Mac)
      if (e.metaKey && e.shiftKey) {
        setBlur(true);
      }
    };
    const onKeyUp = () => setBlur(false);

    // assign the listener
    // update the status on the event
    window.addEventListener('keydown', onKeyPress);
    window.addEventListener('keyup', onKeyUp);

    // remove the listener
    return () => {
      window.removeEventListener('keydown', onKeyPress);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  // return the status
  return blur;
};
