import { Icon } from '@iconify/react';
import classNames from 'classnames';

type AlertProps = {
    type: 'success' | 'error' | 'warning' | 'info';
    title: string,
    message: string;
    className?: string;
};

export function Alert(props: AlertProps) {
  const { type, title, message, className } = props;

  const colorSelector = {
    'bg-success-500/10 text-success-500': type === 'success',
    'bg-error-500/10 text-error-500': type === 'error',
    'bg-warning-500/10 text-warning-500': type === 'warning',
    'bg-primary-400/10 text-primary-400': type === 'info',
  };

  return (
    <section className={classNames('p-3 flex gap-3 w-full rounded-lg animate-slideLeft', className, colorSelector)}>
      <Icon icon="ri:information-2-line" className="" />
      <div className="flex-1">
        <h3 className="text-sm">{title}</h3>
        <p className="text-xs text-light-100">{message}</p>
      </div>
    </section>
  );
}
