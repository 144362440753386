import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { TranslateDropdownMenu } from '../menus/dropdown-menu/translate/translate';
import { useTranslateSelectContext } from './translate-select.context';

export function TranslateButton() {
  const { userLanguage, onLanguageChange } = useTranslateSelectContext();
  const { t } = useTranslation('translate-select');

  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className="w-fit flex gap-2 items-center  p-2 pr-4 rounded-lg h-10 bg-dark-200 hover:bg-dark-100 transition-all outline-none border border-transparent focus:bg-dark-100  focus:border-white/10">
        <Icon icon="ri:translate-ai-2" />
        <span className="text-light-100 text-xs">{t('translate')}</span>
      </RadixDropdownMenu.Trigger>
      <TranslateDropdownMenu userLanguage={userLanguage} onLanguageChange={onLanguageChange} align="end" sideOffset={8} />
    </RadixDropdownMenu.Root>
  );
}
