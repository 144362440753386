import Tab from '../tab';
import TrackResultBlock from './track-result-block';
import TagResultBlock from './tag-result-block';
import BestArtistResultBlock from './best-artist-result-block';
import AlbumResultBlock from './album-result-block';
import CatalogResultBlock from './catalogs-result-block';
import ArtistResultBlock from './artist-result-block';

function All() {
  return (
    <Tab id="presearch-all-tab" value="all">
      <div className="col-span-full grid grid-cols-3 gap-6">
        <BestArtistResultBlock className="w-full" />
        <TrackResultBlock className="w-full" />
        <TagResultBlock className="w-full" />
      </div>
      <AlbumResultBlock />
      <CatalogResultBlock />
      <ArtistResultBlock />
    </Tab>
  );
}

export default All;
