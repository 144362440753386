import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetBriefDetails } from '~/hooks/api/brief';
import { ErrorPage } from '../Errors/error';
import { Brief } from './brief';

export function BriefCheck() {
  const { id } = useParams<{ id: string }>();
  const { error } = useGetBriefDetails(id);
  const { t } = useTranslation('error-page');

  if (error) {
    return (
      <ErrorPage
        title={t('not-found.brief.title')}
        message={t('not-found.brief.message')}
        errorCode={404}
      />
    );
  }

  return <Brief />;
}
