import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useClickOutside } from '~/hooks/common/use-click-outside';

type DialogProps = {
  trigger: ReactNode;
  title?: string;
  description?: string;
  containerClassName?: string;
  triggerClassName?: string;
  portalClassName?: string;
  overlayClassName?: string;
  children: ReactNode;
  asChild?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const Dialog = (props: DialogProps) => {
  const { title, trigger, containerClassName, triggerClassName, portalClassName, overlayClassName, description, asChild, isOpen, disabled = false, onOpenChange, children } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(isOpen || false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDialogIsOpen(isOpen || false);
  }, [isOpen]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (isOpen !== undefined && onOpenChange === undefined) return;
    setDialogIsOpen(newIsOpen);
    onOpenChange?.(newIsOpen);
  };

  useClickOutside(ref, () => handleOpenChange(false));

  return (
    <DialogPrimitive.Root open={dialogIsOpen}>
      <DialogPrimitive.Trigger onClick={() => handleOpenChange(true)} className={triggerClassName} asChild={asChild} disabled={disabled}>
        {trigger}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal data-no-dnd="true">
        <DialogPrimitive.Overlay className={classNames('fixed inset-0 bg-dark-400/90 z-[90] backdrop-blur', overlayClassName)} />
        <DialogPrimitive.Content data-no-dnd="true" ref={ref} className={classNames('fixed top-[50%] left-[50%] -translate-x-1/2 z-[100] bg-dark-300/50 -translate-y-1/2 p-4 rounded-lg w-full max-w-[50rem] max-h-[80vh] overflow-hidden flex flex-col items-stretch', containerClassName)}>
          {title
            ? <DialogPrimitive.Title className={`font-bold text-xl ${description ? 'mb-1' : 'mb-5'}`}>{title}</DialogPrimitive.Title>
            : null}
          {description ? (
            <DialogPrimitive.Description className="mb-5 text-light-500 text-xs ">
              {description}
            </DialogPrimitive.Description>
          ) : null}
          <div className="overflow-y-auto h-full custom-scrollbar">
            {children}
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default Dialog;
