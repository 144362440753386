import { useSearchStore } from '~/stores/search-store';
import SearchChip from './search-chip';

const SimilarChip = () => {
  const search = useSearchStore(['similarityTrack', 'similarityURL', 'resetSimilarityTrack', 'resetSimilarityURL']);

  const handleReset = () => {
    search.resetSimilarityTrack();
    search.resetSimilarityURL();
  };

  const title = search.similarityURL?.jobId
    ? search.similarityURL.title
    : `${search.similarityTrack.name}${search.similarityTrack.artists ? ` - ${search.similarityTrack.artists}` : ''}`;

  return (
    <SearchChip
      value={title}
      onRemoveClick={handleReset}
    />
  );
};

export default SimilarChip;
