import { z } from 'zod';
import { SupportedLanguagesSchema } from './translate.schema';

const CardContentSchema = z.object({
  title: z.string(),
  description: z.string(),
  query: z.object({
    title: z.string(),
    content: z.array(z.string()),
  }),
});

export const TranslatedFilterHighlightSchema = z.record(
  SupportedLanguagesSchema,
  z.object({
    tag: CardContentSchema,
    target: CardContentSchema,
  }),
);
