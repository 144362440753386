import { useCreatePlaylist } from '~/hooks/api/playlist';
import { useAddTrackToTracklist } from '~/hooks/api/track-list';
import { Track } from '~/types/schemas/music/track.schema';

type CreateTracklist = {
    handleCreatePlaylist: (tracks: Track[]) => void;
};

export function useCreatePlaylistFromTrack(): CreateTracklist {
  const { mutate: createPlaylist } = useCreatePlaylist();
  const { mutate: addTrackToTracklist } = useAddTrackToTracklist();

  function handleCreatePlaylist(tracks: Track[]) {
    createPlaylist({
      title: tracks[0]?.title,
    }, {
      onSuccess(data) {
        addTrackToTracklist({ type: 'playlist', id: data.id!, tracks });
      },
    });
  }

  return {
    handleCreatePlaylist,
  };
}
