import { useEffect, useMemo, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { PopulatedTagCategory } from '~/types/schemas/data/tags.schema';
import usePreSearchStore from '~/stores/presearch-store';
import { CategoryProps } from './category';
import {
  TagsAccordionContext,
  TagsAccordionContextType,
} from './tags-accordion.context';
import { CategoryList } from './category-list';

export interface TagsAccordionProps extends TagsAccordionContextType {
  categories?: CategoryProps[];
}

export function TagsAccordion(props: TagsAccordionProps) {
  const { categories, ...contextActions } = props;
  const { query } = usePreSearchStore(['query']);
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    const newDefaultValue =
      categories
        ?.filter((category) =>
          contextActions.categoryDefaultOpen?.(
            category as PopulatedTagCategory,
          ),
        )
        .map((category) => category.id) || [];

    setValues(query ? newDefaultValue : []);
  }, [query]);

  const contextDefaultValue = useMemo(
    () => ({
      ...contextActions,
    }),
    [contextActions],
  );

  return (
    <TagsAccordionContext.Provider value={contextDefaultValue}>
      <Accordion.Root
        value={values}
        onValueChange={(v) => setValues(v)}
        type="multiple"
        className="w-full flex flex-col gap-4 overflow-hidden"
      >
        <CategoryList categories={categories} />
      </Accordion.Root>
    </TagsAccordionContext.Provider>
  );
}
