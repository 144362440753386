import { PropsWithChildren, useMemo, useState } from 'react';
import { useRealtimeMessageData } from '~/hooks/realtime/use-realtime-message-data';
import { Message } from '~/types/schemas/notifications/message.schema';
import { MagicPreviewContext } from './magic-preview.context';

export function MagicPreviewRealtimeListener(props: PropsWithChildren) {
  const { children } = props;
  const [data, setData] = useState<Record<string, Message>>({});
  const contextValue = useMemo(() => ({ data }), [data]);

  function updateData(trackId: string, message: Message) {
    setData((prevData) => {
      if (prevData[trackId]) {
        return {
          ...prevData,
          [trackId]: message,
        };
      }

      return { ...prevData, [trackId]: message };
    });
  }

  useRealtimeMessageData({
    type: 'MUSEMIND_TRACK_VARIATION',
    fn(message) {
      if (message.type === 'MUSEMIND_TRACK_VARIATION') {
        updateData(message.data.trackId, message);
      }
    },
  });

  return (
    <MagicPreviewContext.Provider value={contextValue}>
      {children}
    </MagicPreviewContext.Provider>
  );
}
