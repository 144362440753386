import { useEffect } from 'react';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { useChatData } from './use-chat-data';

export function useChat() {
  const realtime = useRealtime();
  const { participants, isLoading, isError } = useChatData(briefChatsStore.brief.value?.id, activeChannelStore.channel.value?.custom?.trackId);

  useEffect(() => {
    if (participants) {
      activeChannelStore.setParticipants(participants);
    }
  }, [participants]);

  return {
    channel: activeChannelStore.channel.value!,
    isLoading: !realtime.ready || isLoading,
    isError,
  };
}
