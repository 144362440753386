import { useRef } from 'react';
import Icon from '~/components/ui/icon/icon';
import * as Portal from '@radix-ui/react-portal';
import { AnimatePresence, motion } from 'framer-motion';
import NotificationCenter from '~/components/features/notifications-center/notifications-center';
import { notificationStore } from '~/stores/notifications-store';
import { useComputed, useSignal } from '@preact/signals-react';
import { useClickOutside } from '~/hooks/common/use-click-outside';

function NotificationTrigger() {
  const ref = useRef<HTMLDivElement>(null);
  const isPanelOpen = useSignal(false);
  const refTrigger = useRef<HTMLDivElement>(null);
  const refPosition = ref.current?.getBoundingClientRect();
  const portalRef = useRef<HTMLDivElement>(null);
  const hasUnread = useComputed(() => {
    if (!notificationStore.channel.value) return false;

    if (notificationStore.channel.value.unreadMessageCount.value > 0) {
      return true;
    }

    return false;
  });

  function togglePanel() {
    isPanelOpen.value = !isPanelOpen.value;
  }

  useClickOutside(portalRef, (e) => {
    if (refTrigger.current?.contains(e.target as Node)) return;
    isPanelOpen.value = false;
  });

  return (
    <div ref={ref} className="flex items-center justify-center w-8 h-8 rounded-lg bg-transparent hover:bg-dark-300 cursor-pointer">
      <div ref={refTrigger} className="relative" onClick={togglePanel}>
        <Icon name="notification-2-line" className="text-light-100 text-lg" />
        {hasUnread.value ? <div className="absolute top-1 right-0 w-2 h-2 bg-primary-400 rounded-full" /> : null}
      </div>
      <AnimatePresence>
        {isPanelOpen.value ? (
          <Portal.Root ref={portalRef} className="absolute z-[100]" style={{ top: (refPosition?.top ?? 0) + (ref.current?.clientHeight ?? 0), left: refPosition?.left }}>
            <motion.div
              initial={{ height: '0', padding: 0, width: '0', opacity: 0 }}
              animate={{ height: 'auto', width: 'auto', padding: 8, paddingRight: 36, opacity: 1 }}
              exit={{ height: '0', width: '0', padding: 0, opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
              className=""
            >
              <NotificationCenter togglePanel={togglePanel} />
            </motion.div>
          </Portal.Root>
        ) : null}
      </AnimatePresence>

    </div>
  );
}

export default NotificationTrigger;
