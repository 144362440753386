import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '~/assets/mewo/logo.svg';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { globalStore } from '~/stores/global-store-v2';
import { pageRoutes } from '~/routes/pages';
import { useLogin } from '~/hooks/api/auth';
import { z } from 'zod';
import { useAuthCheck } from '~/hooks/auth/use-auth-check';
import Input from '~/components/ui/forms/input-controls/input/input';

const Login = () => {
  const { handleSubmit, control } = useForm();
  const { setUser, invalidToken } = globalStore;
  const { mutate: login, isPending } = useLogin();
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const location = useLocation();

  useAuthCheck();

  invalidToken.value = false;

  async function handleLogin(formData: FieldValues) {
    const parsedData = z.object({
      email: z.string(),
      password: z.string(),
    }).parse(formData);

    login(parsedData, {
      onSuccess: (data) => {
        const { user, token } = data;
        setUser(user, token);
        navigate(location.state?.from ? location.state.from : pageRoutes.main);
      },
      onError: () => {
        toastr('error', t('auth.login.invalid-credential.title', { ns: 'toast' }), t('auth.login.invalid-credential.content', { ns: 'toast' }));
      },
    });
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center w-72">
        <img src={Logo} alt="mewo" className="w-[70%]" />
        <form
          className="flex flex-col items-center justify-center w-full mt-8 gap-4"
          onSubmit={handleSubmit(handleLogin)}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: t('form.email.rules.required'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('form.email.rules.pattern'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                disabled={isPending}
                className="w-full"
                label={t('form.email.label')}
                placeholder={t('form.email.placeholder')}
                type="email"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: t('form.password.rules.required'),
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                disabled={isPending}
                className="w-full"
                label={t('form.password.label')}
                placeholder={t('form.password.placeholder')}
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <div className="flex flex-col w-[70%] items-center gap-3">
            <Button
              className="w-full mt-4 flex justify-center items-center"
              type="submit"
              data-testid="login-button"
              disabled={isPending}
              isLoading={isPending}
            >
              {t('form.submit.label')}
            </Button>
            <Link to="https://dashboard.mewo.io/register" className="text-xs text-light-500 hover:underline hover:text-light-100 transition-colors">
              {t('form.register.label')}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
