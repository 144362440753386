import * as Track from '~/components/entities/track/common/track';
import { ChipTagList } from './tag-list';

export function ChipList() {
  return (
    <div className="flex gap-2 flex-wrap">
      <Track.Chip.Bpm />
      <Track.Chip.Tonality />
      <ChipTagList />
    </div>
  );
}
