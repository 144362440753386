import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';

export function TrackVersionWrapper(props: PropsWithChildren) {
  const { children } = props;
  const { isOpen, isTrackActive, isSelected, handleClick } = useTrackItem();

  return (
    <div
      className={classNames(
        'group/track-item',
        'rounded-lg border border-transparent overflow-hidden select-none',
        'hover:!bg-dark-200/60  hover:border-white/5',
        {
          'bg-dark-200/60  border-white/5': isTrackActive,
          'bg-dark-200/60': isSelected || isOpen,
        },
      )}
      onClick={handleClick}
    >
      <div className="p-2">
        {children}
      </div>
    </div>
  );
}
