import { useTracklistSearch } from '~/hooks/api/track-list';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreatePlaylist } from '~/hooks/api/playlist';
import { InfiniteScroll } from '~/components/layout/infinite-scroll/infinite-scroll';
import { globalStore } from '~/stores/global-store-v2';
import { Range } from '@tanstack/react-virtual';
import React, { CSSProperties } from 'react';
import { useDragToTracklist } from '~/hooks/interactions/use-drag-to-tracklist';
import TracklistLink from './button';

type TracklistSearchProps = {
  query: string;
  collections: ('playlists' | 'briefs')[];
};

const TracklistItem = React.memo((props: { tracklist: Tracklist }) => {
  const { tracklist } = props;
  const { type, value } = tracklist;
  const { user } = globalStore;

  return (
    <TracklistLink
      key={`item-button-${value.id}`}
      link={`/${type}s/${value.id}`}
      name={tracklist.value.title}
      droppableId={value.id}
      type={type}
      className="text-sm"
      isCreatedByUser={user.value?.id === value.created_by?.id}
    />
  );
});

function renderSentinel(style?: CSSProperties) {
  return (
    <div
      className="w-full h-full bg-repeat-y bg-sidebar-tracklist-sentinel-sm 2xl:bg-sidebar-tracklist-sentinel-base"
      style={{
        ...style,
      }}
    />
  );
}

export function TracklistResult(props: TracklistSearchProps) {
  const { query, collections } = props;
  const { data: tracklists, fetchNextPage, hasNextPage, isFetchingNextPage } = useTracklistSearch(collections, query);
  const flatTracklists = tracklists?.pages.flatMap((page) => page.hits);
  const total = tracklists?.pages[0]?.total.value;
  const { mutate: createPlaylist } = useCreatePlaylist();
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');

  useDragToTracklist();

  function handleCreatePlaylist() {
    createPlaylist({
      title: t('playlist-form:input.title.default', { ns: 'playlist-form' }),
    }, {
      onSuccess: (newPlaylist) => {
        navigate(`/playlists/${newPlaylist.id}`);
      },
    });
  }

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (flatTracklists?.length || 0) - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }

  return (
    <div id="tracklist-search" className="h-full overflow-y-auto scrollbar-on-hover">

      <InfiniteScroll
        data={flatTracklists}
        count={flatTracklists?.length}
        estimateSize={21}
        overscan={15}
        onRangeChange={handleNextPageFetch}
        renderSentinel={renderSentinel}
        renderItem={(tracklist) => <TracklistItem tracklist={tracklist as Tracklist} />}
        scrollParent={document.getElementById('tracklist-search')}
      />

      {!query && flatTracklists?.length === 0 && (
        <div className="flex flex-col items-center gap-2 text-xs text-light-500">
          <span className="w-full pt-3 block text-center break-words">
            {t('briefs-playlists-list.empty', { 'tracklist-type': collections.join('/'), interpolation: { escapeValue: false } })}
          </span>
          {collections.includes('playlists') && (
            <button type="button" className="hover:underline hover:text-light-100" onClick={handleCreatePlaylist}>
              {t('briefs-playlists-list.create-playlist')}
            </button>
          )}
        </div>
      )}

      {query && total === 0 && (
        <div className="flex flex-col items-center gap-2 text-xs text-light-500">
          <span className="w-full pt-3 block text-center break-words">
            {t('briefs-playlists-list.no-results', { query, interpolation: { escapeValue: false } })}
          </span>
        </div>
      )}
    </div>
  );
}
