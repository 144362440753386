import * as Track from '~/components/entities/track/common/track';
import { BriefTrackItemLayout } from '~/components/layout/track-items/brief-track-item.layout';
import { useBriefContext } from '~/pages/briefs/brief.context';
import { TrackChatTrigger } from './actions/chat-trigger/chat-trigger';
import { TrackItemDropdownMenu } from '../menus/dropdown-menu/dropdown-menu';
import { RemoveFromTracklist } from './actions/remove-from-tracklist';
import { TrackItemHeaderSummary } from './summary/summary';
import { MagicPreview } from './actions/magic-preview';

export function BriefTrackItemHeader() {
  const { data } = useBriefContext();
  // named grid rows
  return (
    <BriefTrackItemLayout>
      <TrackItemHeaderSummary className="col-start-[summary]" />
      <Track.MusicType className="col-start-[music-type] justify-self-center hidden @[48rem]:block" />
      <Track.AddedByChip className="col-start-[added-by] justify-self-center hidden @[45rem]:flex" />
      <TrackChatTrigger className="col-start-[chat-trigger] justify-self-start" />
      <Track.Popularity className="col-start-[popularity] justify-self-center" />
      <Track.Waveform className="col-start-[waveform] justify-self-end hidden @[50rem]:block" />
      <Track.Duration className="col-start-[duration] justify-self-end" />
      <Track.ActionList className="col-start-[actions] justify-self-end">
        <MagicPreview />
        {!data.owned_by_tenant ? <RemoveFromTracklist /> : null}
        <TrackItemDropdownMenu />
      </Track.ActionList>
    </BriefTrackItemLayout>
  );
}
