import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { ChipButton } from '~/components/ui/control/chip-button/chip-button';
import React from 'react';
import { motion } from 'framer-motion';
import * as Accordion from '@radix-ui/react-accordion';
import { TagProps } from '../tag';
import { TagList } from './tag-list';
import { AccordionContent } from './accordion-content';
import { useTagsAccordionContext } from './tags-accordion.context';
import { useTagFilter } from '~/hooks/filter/use-tag-filter';
import usePreSearchStore from '~/stores/presearch-store';

export interface SubCategoryProps {
  id: string;
  name: string;
  tags?: TagProps[];
  relevant?: boolean;
}

function SubCategory(props: SubCategoryProps) {
  const { id, name, tags, relevant } = props;
  const { onSubCategoryAddClick, onSubCategorySubtractClick } = useTagFilter();
  const presearch = usePreSearchStore(['setIsOpen']);

  function handleSubCategoryAddClick(id: string, name: string) {
    onSubCategoryAddClick(id, name);
    presearch.setIsOpen(false);
  }

  function handleSubCategorySubtractClick(id: string, name: string) {
    onSubCategorySubtractClick(id, name);
    presearch.setIsOpen(false);
  }

  const anim = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      variants={anim}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="rounded-lg w-full flex flex-col bg-dark-300 overflow-hidden mb-3"
    >
      <Accordion.Item value={id}>
        <Accordion.Trigger
          type="button"
          className=" w-full flex items-center justify-between p-4"
        >
          <div className="flex gap-4 items-center">
            <h6>{name}</h6>
            <div className="flex gap-2 items-center">
              <ChipButton
                icon="ri:subtract-line"
                onClick={() => handleSubCategorySubtractClick?.(id, name)}
              />
              <ChipButton
                icon="ri:add-line"
                onClick={() => handleSubCategoryAddClick?.(id, name)}
                className={classNames({
                  'opacity-50': !relevant,
                })}
              />
            </div>
          </div>
          <Icon
            icon="ri:arrow-down-s-line"
            className="text-light-500 ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[is-open=true]:rotate-180"
          />
        </Accordion.Trigger>
        <AccordionContent>
          <TagList tags={tags} />
        </AccordionContent>
      </Accordion.Item>
    </motion.div>
  );
}

export default React.memo(SubCategory);
