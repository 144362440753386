import hightlight from '~/data/presearch-highlight.json';
import Highlight from '../../../highlight/highlight';
import PresearchBlock from '../../../presearch-block/presearch-block';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n/config';

function HighlightResultBlock() {
  const { t } = useTranslation("pre-search");
  const locales = hightlight.locales[i18n.language as keyof typeof hightlight.locales];

  return (
    <PresearchBlock label={t('tab.home.highlight.label')} containerClassName="flex-1">
      <Highlight
        title={locales.title}
        description={locales.description}
        chips={locales.chips}
        artwork={hightlight.artworks}
        linkParams={hightlight.searchParams}
      />
    </PresearchBlock>
  );
}

export default HighlightResultBlock;
