import { useQuery } from '@tanstack/react-query';
import usePreSearchStore from '~/stores/presearch-store';
import { TrackSearchResultSchema } from '~/types/schemas/music/track.schema';
import { getHttp } from '~/utils/http/client';
import { trackKeys } from '~/hooks/api/query-keys';

export function useTrackPresearch() {
  const client = getHttp();
  const presearch = usePreSearchStore(['query']);

  return useQuery({
    queryKey: trackKeys.presearch(presearch.query),
    queryFn: async () => {
      return client.post('searchmode/track/search', {
        json: {
          query: presearch.query,
          size: 10,
          page: 1,
        },
      })
        .json()
        .then((data) => TrackSearchResultSchema.passthrough().parse(data))
        .catch();
    },
  });
}
