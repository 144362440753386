import { useEffect } from 'react';
import { SearchTrackItemListEmpty } from '~/components/entities/track/track-item-list/search/search-track-item-list.empty';
import NotificationCard from '~/components/features/notifications-center/components/notification-group/notification-card/notification-card';
import { useNotificationCenter } from '~/components/features/notifications-center/hooks/use-notification-center';
import { NotificationListEmpty } from './components/notification-list.empty';

function Notificiations() {
  const { channel, handleCardClick, notifications, showLoadMoreButton } = useNotificationCenter();

  useEffect(() => {
    if (channel && channel.lastMessage.value) {
      channel.setLastReadMessageTimetoken(channel.lastMessage.value.timetoken);
    }
  }, [channel, channel?.lastMessage.value]);

  if (!channel || channel.isLoading.value) {
    return (
      <>
        loading;
      </>
    );
  }

  if (notifications.value.length === 0) {
    return (
      <div className="flex flex-col relative px-4 min-h-full h-fit">
        <NotificationListEmpty />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col gap-2 w-[70%] xl:w-1/2">
        {notifications.value.map((notification) => (
          <NotificationCard
            key={notification.timetoken}
            notification={notification}
            onClick={() => handleCardClick(notification)}
            markRead={() => notification.markAsRead()}
          />
        ))}
        {showLoadMoreButton ? (
          <button
            type="button"
            className="flex items-center justify-center w-full h-10 text-sm text-gray-500 transition-colors duration-200 rounded-md hover:text-gray-700 focus:outline-none"
            onClick={() => channel.getOlderMessages()}
          >
            Load more
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Notificiations;
