import { SHORT_LANGUAGES } from '~/constants/languages';
import { Language } from '~/types/types/languages';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '../common/dropdown-menu';

type TranslateDropdownMenuProps = {
    userLanguage: Language
    targetLanguage?: Language
    onLanguageChange: (language: Language) => void
} & RadixDropdownMenu.DropdownMenuContentProps;

export const TranslateDropdownMenu = (props : TranslateDropdownMenuProps) => {
  const { userLanguage, targetLanguage, onLanguageChange, ...rest } = props;
  const { t } = useTranslation('dropdown-menus');

  const languageWithoutTarget = SHORT_LANGUAGES.filter((l) => l.value !== targetLanguage);
  const userLanguageObject = languageWithoutTarget.find((l) => l.value === userLanguage);
  const languageWithoutTargetAndUser = languageWithoutTarget.filter((l) => l.value !== userLanguage);

  return (
    <DropdownMenu.Content {...rest}>
      {userLanguageObject ? (
        <DropdownMenu.Group label={t('translate.groups.recommended')}>
          <DropdownMenu.Item key={userLanguageObject.value} onClick={() => onLanguageChange(userLanguageObject.value)}>
            {userLanguageObject.label}
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      ) : null}
      <DropdownMenu.Group label={t('translate.groups.ai-translation')}>
        {languageWithoutTargetAndUser.map((l) => (
          <DropdownMenu.Item key={l.value} onClick={() => onLanguageChange(l.value)}>
            {l.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Group>
    </DropdownMenu.Content>
  );
};
