import { useEffect, useState } from 'react';

export function useElementIsNotVisible(element: HTMLElement | null): boolean {
  const [isNotVisible, setIsNotVisible] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (element) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          setIsNotVisible(!entry.isIntersecting);
        });
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
  }, [element]);

  return isNotVisible;
}
