import { useNavigate } from 'react-router-dom';
import { useDeletePlaylist } from '~/hooks/api/playlist';
import Button from '~/components/ui/control/button/button';

type DeletePlaylistProps = {
  id: string;
  className?: string;
};

const DeletePlaylist = (props: DeletePlaylistProps) => {
  const { id, className } = props;
  const { mutate: deletePlaylist } = useDeletePlaylist();
  const navigate = useNavigate();

  const handleDelete = () => {
    navigate('/playlists');
    deletePlaylist({ ids: [id] });
  };

  return (
    <Button iconRight="delete-bin-line" className={className} onClick={handleDelete}></Button>
  );
};

export default DeletePlaylist;
