import { useLocation, useSearchParams as useReactSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSearchStore } from '~/stores/search-store';

export function useSearchParams() {
  const location = useLocation();
  const [params, setParams] = useReactSearchParams();
  const search = useSearchStore(['writeToURL', 'readFiltersFromURL', 'writeFiltersToURL']);

  // write to url
  useEffect(() => {
    if (search.writeToURL) {
      search.writeFiltersToURL(setParams);
    }
  }, [search.writeToURL, setParams, search.writeFiltersToURL]);

  // read from url
  useEffect(() => {
    search.readFiltersFromURL(params.get('q'));
  }, [params, search.readFiltersFromURL, location]);
}
