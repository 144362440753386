import SelectMini from '~/components/ui/forms/select-mini/select-mini';
import { useSearchStore } from '~/stores/search-store';
import { useTranslation } from 'react-i18next';

const TrackSort = () => {
  const search = useSearchStore(['similarityTrack', 'similarityURL', 'sort', 'setSort', 'query']);
  const { t } = useTranslation('search');

  const isSimilaritySearch = (search.similarityTrack.id !== undefined || search.similarityURL !== undefined);
  let value = search.sort;

  let options = [
    { value: 'relevance', label: t('results.sort.relevance') },
    { value: 'popularity', label: t('results.sort.popularity') },
    { value: 'release_date', label: t('results.sort.recently') },
  ];

  if (isSimilaritySearch) {
    value = 'similarity';
    options = [
      { value: 'similarity', label: t('results.sort.similarity') },
    ];
  }

  const handleChange = (v: string) => {
    if (v !== 'similarity') {
      search.setSort(v);
    }
  };

  return (
    <SelectMini
      position="popper"
      sideOffset={4}
      className="w-fit !bg-dark-100"
      value={value}
      onChange={handleChange}
      options={options}
      disabled={isSimilaritySearch}
    />
  );
};

export default TrackSort;
