import { DragOverlay } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import { snapRightToCursor } from '~/utils/dnd-kit/modifiers/snapRightToCursor';

type MultipleTracksOverlayProps = {
  trackIds: string[];
};
export function TrackGroupOverlay(props: MultipleTracksOverlayProps) {
  const { trackIds } = props;
  const { t } = useTranslation('music-item');

  return (
    <DragOverlay
      modifiers={[snapRightToCursor]}
      dropAnimation={null}
      className="w-fit cursor-copy p-12"
    >
      <div className="w-fit max-w-[260px] h-fit bg-dark-300/80 px-2 py-1 rounded cursor-copy fixed">
        <p className="text-sm font-medium text-wrap">
          {t('drag-overlay.multiple', { length: trackIds.length })}
        </p>
      </div>
    </DragOverlay>
  );
}
