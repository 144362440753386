import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';

type CreateBriefProps = {
    onClick: () => void;
};

function CreateBrief(props: CreateBriefProps) {
  const { onClick } = props;

  return (
    <button
      type="button"
      aria-label="Create a new brief"
      id="brief__library__create-button"
      className={classNames(
        'p-2 bg-light-100 rounded-full flex items-center justify-center',
        'hover:bg-light-200 hover:scale-105 active:scale-95 transition-all',
      )}
      onClick={onClick}
    >
      <Icon name="add-line" className="text-5xl text-dark-100 leading-none" />
    </button>
  );
}

export default CreateBrief;
