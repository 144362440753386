import { useTrackContext } from '../track.context';
import * as Track from '../track';

type TrackVersionsProps = {
  className?: string;
};

export function TrackVersions(props: TrackVersionsProps) {
  const { className } = props;
  const store = useTrackContext();
  const { versions } = store.use.track();

  return (
    <Track.Label className={className}>
      {versions > 0 ? `+${versions}` : null}
    </Track.Label>
  );
}
