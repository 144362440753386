import { useCreatePlaylistFromTrack } from '~/hooks/api/playlist';
import {
  useAddTrackToTracklist,
  useRemoveTrackFromTracklist,
} from '~/hooks/api/track-list';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';

export function useAddToTracklist() {
  const store = useTrackContext();
  const track = store.use.track();

  const { mutate: removeTrackFromTrackListMutation } = useRemoveTrackFromTracklist();
  const { mutate: addTrackToTrackListMutation } = useAddTrackToTracklist();
  const { mutate: handleCreatePlaylistFromTrack } = useCreatePlaylistFromTrack(track);

  const { values } = useSelectionContext();

  function addTrackToTracklist(tracklist: Tracklist) {
    addTrackToTrackListMutation({
      type: tracklist.type,
      id: tracklist.value.id!,
      tracks: values.length > 1 ? values : [track],
    });
  }

  function removeTrackFromTracklist(tracklist: Tracklist) {
    removeTrackFromTrackListMutation({
      type: tracklist.type,
      id: tracklist.value.id!,
      tracks: [track],
    });
  }

  function createNewPlaylist() {
    handleCreatePlaylistFromTrack({ title: track.title });
  }

  return {
    addTrackToTracklist,
    removeTrackFromTracklist,
    createNewPlaylist,
  };
}
