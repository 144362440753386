import { signal } from '@preact/signals-react';
import { RealtimeResponse } from '~/types/schemas/api/realtime.schema';
import { User } from '~/types/schemas/auth/user';
import { getCookie, setCookie } from '~/utils/common/cookie';

export const globalStore = {
  apiUrl: signal(localStorage.getItem('apiUrl') || import.meta.env.VITE_API_URL),
  user: signal<User | undefined>(undefined),
  userToken: signal(getCookie('x-auth')),
  realtimeData: signal<RealtimeResponse | undefined>(undefined),
  invalidToken: signal(false),
  loggedAs: signal(localStorage.getItem('loggedAs') === 'true'),
  setApiUrl: (url: string) => {
    globalStore.apiUrl.value = url;
    localStorage.setItem('apiUrl', url);
  },
  setAdminToken: (token: string, reload?: boolean) => {
    setCookie('x-auth', token, { path: '/', secure: true });
    window.localStorage.setItem('loggedAs', 'true');

    if (reload) {
      window.location.reload();
    }
  },
  setUser(user: User | null, token?: string) {
    if (user) {
      globalStore.user.value = user;

      if (token) {
        globalStore.userToken.value = token;
        setCookie('x-auth', token, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 72), secure: true });
        localStorage.removeItem('loggedAs');
      }
    }

    if (!user) {
      localStorage.removeItem('userToken');
      globalStore.userToken.value = undefined;
      globalStore.user.value = undefined;
      localStorage.removeItem('loggedAs');
    }
  },
  setRealtimeData: (data: RealtimeResponse) => {
    globalStore.realtimeData.value = data;
  },
  handleUnauthorized: () => {
    globalStore.user.value = undefined;
    globalStore.userToken.value = undefined;
    localStorage.removeItem('userToken');
    localStorage.removeItem('headers');
    localStorage.removeItem('loggedAs');
    globalStore.invalidToken.value = true;
  },
};
