/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import { useState } from 'react';
import Button from '~/components/ui/control/button/button';
import { useSearchStore } from '~/stores/search-store';
import useAddFilterStore from '~/stores/add-filter-store';
import Range from '../../../range/range';
import Tab from '../tab';
import { useTranslation } from 'react-i18next';

function ReleaseDate() {
  const [value, setValue] = useState<[number, number]>([1930, dayjs().year()]);
  const [isPreReleaseDisplayed, setIsPreReleaseDisplayed] = useState(false);
  const today = dayjs().endOf('day').year();
  const search = useSearchStore(['setReleaseDate']);
  const addFilter = useAddFilterStore(['setIsOpen']);
  const { t } = useTranslation('filter');

  function handleApply() {
    search.setReleaseDate(value[0], isPreReleaseDisplayed ? 2099 : value[1]);
    addFilter.setIsOpen(false);
  }

  const handleChange = (v: number[]) => {
    if (v[1] !== today) setIsPreReleaseDisplayed(false);
    setValue(v as [number, number]);
  };

  function handleCheck() {
    setIsPreReleaseDisplayed(!isPreReleaseDisplayed);
    if (value[0] && value[1]) {
      if (!isPreReleaseDisplayed) {
        setValue([value[0], today]);
      } else {
        setValue([value[0], value[1]]);
      }
    }
  }

  return (
    <Tab label={t('panel.release-date.title')} value="release_date">
      <div className="flex flex-col justify-between h-full">
        <div>
          <Range
            value={value}
            customTo={isPreReleaseDisplayed ? t('panel.release-date.tab.upcoming') : value[1] === today ? t('panel.release-date.tab.today') : value[1].toString()}
            min={1930}
            max={dayjs().year()}
            onValueChange={handleChange}
          />
          <label className="flex items-center gap-3 px-2">
            <input checked={isPreReleaseDisplayed} onChange={handleCheck} type="checkbox" className="accent-primary-500 w-4 rounded aspect-square" />
            <span className="text-xs text-light-500">{t('panel.release-date.tab.include-upcoming')}</span>
          </label>
        </div>
        <Button className="filter-panel__apply-button ml-auto mt-auto col-span-full" onClick={() => handleApply()}>{t('panel.slider.apply')}</Button>
      </div>
    </Tab>
  );
}

export default ReleaseDate;
