interface SingleProps {
  artwork: string
  alt?: string
}

// TODO: Handle image loading error
function Single(props: SingleProps) {
  const { artwork, alt } = props;

  return (
    <img
      src={artwork}
      alt={alt}
      className="w-full h-full object-cover"
      loading="lazy"
    />
  );
}

export default Single;
