import classNames from 'classnames';
import React, { Ref } from 'react';
import Icon from '~/components/ui/icon/icon';
import { useTranslation } from 'react-i18next';

type CreateButtonProps = {
  isLoading?: boolean
  onClick?: () => void
};

const CreateButton = React.forwardRef((props: CreateButtonProps, forwardedRef: Ref<HTMLButtonElement>) => {
  const { isLoading, onClick } = props;
  const { t } = useTranslation('sidebar');

  return (
    <button
      ref={forwardedRef}
      type="button"
      disabled={isLoading}
      onClick={onClick}
      className={classNames(
        'relative group/new-button w-full flex items-center rounded overflow-hidden justify-end',
        isLoading ? 'pointer-events-none text-dark-100 hover:text-dark-100' : 'text-dark-300 hover:text-dark-400',
      )}
    >
      <span className="absolute translate-x-full rounded-l  text-xs px-1 group-hover/new-button:-translate-x-3 transition-transform  bg-light-100">{t('link.hover.new')}</span>
      <Icon name="add-line" className="flex z-10 justify-center items-center transition-colors duration-100  bg-light-500 rounded group-hover/new-button:rounded-l-0 group-hover/new-button:bg-light-100 w-4 h-4" />
    </button>
  );
});

export default CreateButton;
