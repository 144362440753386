import { useTrackVersions } from '~/hooks/entities/track/versions/use-track-versions';
import { motion } from 'framer-motion';
import { TrackVersion } from './track-version';
import { useEffect } from 'react';
import { useTrackItemContext } from '../../track-item.context';

export function VersionList() {
  const { versions } = useTrackVersions();
  const trackItemStore = useTrackItemContext();
  const isAnimationComplete = trackItemStore.use.isAnimationComplete();

  return versions && isAnimationComplete ? (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
    >
      <div className="flex flex-col gap-1">
        {versions?.map((track, index) => (
          <TrackVersion key={index} version={track} />
        ))}
      </div>
    </motion.div>
  ) : null;
}
