import { useEffect, useState } from 'react';
import { useGetBriefDetails, useGetTranslateBriefDetails } from '~/hooks/api/brief';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { Language } from '~/types/types/languages';

export function useBriefData(id: string) {
  const { data, isLoading } = useGetBriefDetails(id);
  const [targetLanguage, setTargetLanguage] = useState<Language | undefined>(undefined);

  function handleTargetLanguageChange(language?: Language) {
    if (language) {
      setTargetLanguage(language);
    } else {
      setTargetLanguage(undefined);
    }
  }

  const {
    data: translatedData,
    isLoading: isTranslatedLoading,
    error: translateError,
  } = useGetTranslateBriefDetails(id, targetLanguage);

  useEffect(() => {
    if (translateError) {
      setTargetLanguage(undefined);
      toastr('error', 'Oops !', 'Translation failed');
    }
  }, [translateError]);

  return {
    data: (targetLanguage && translatedData.data) || data,
    isLoading: targetLanguage ? isTranslatedLoading : isLoading,
    targetLanguage,
    handleTargetLanguageChange,
  };
}
