import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useIngestUploadSession } from '~/hooks/api/upload';
import Button from '~/components/ui/control/button/button';
import { briefStore } from '~/stores/briefs-store';
import { fileUploadStore } from '~/stores/file-upload-store';
import { trackUploadStore } from '~/stores/track-upload-store';

type SendButtonProps = {
  briefId: string;
};

export function SendButton(props: SendButtonProps) {
  const { briefId } = props;
  const { session } = fileUploadStore;
  const { createIngest } = briefStore;
  const { trackList, close, validated } = trackUploadStore;
  const { t } = useTranslation('tracks-upload-form');
  const { mutate: ingestUploadSession } = useIngestUploadSession(session.value);

  const isAllTrackValidated = trackList.value.every((track) => validated.value.includes(track.id));

  function handleLocalStorage() {
    createIngest(briefId, trackList.value.map((track) => {
      const matchedData = track.matchedMetadata.value;
      if (!matchedData) return undefined;

      return ({
        title: matchedData.title,
        artists: matchedData.artists,
        cover: matchedData.cover,
        version: track.version.value,
      });
    }).filter(Boolean));
  }

  function handleClick() {
    handleLocalStorage();
    ingestUploadSession(undefined, {
      onSuccess: () => {
        close();
      },
    });
  }

  return (
    <Button
      className={classNames({
        '!bg-dark-100 opacity-50 pointer-events-none': !isAllTrackValidated,
      })}
      iconRight="send-plane-fill"
      onClick={handleClick}
    >
      {t('header.send')}
    </Button>
  );
}
