export class TimeTicker {
  private static interval?: NodeJS.Timeout;

  private static callbacks = new Set<() => void>();

  private static tick() {
    this.callbacks.forEach((callback) => callback());
  }

  static start() {
    this.interval = setInterval(() => { this.tick(); }, 1000);
  }

  static stop() {
    clearInterval(this.interval);
  }

  static subscribe<T extends() => void>(callback: T): T {
    this.callbacks.add(callback);

    if (!this.interval) {
      this.start();
    }

    return callback;
  }

  static unsubscribe(callback: () => void) {
    this.callbacks.delete(callback);
  }
}
