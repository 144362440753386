import { useCreatePlaylist } from '~/hooks/api/playlist';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateButton from './create-button';

const CreatePlaylist = () => {
  const { mutate: createPlaylist, isPending } = useCreatePlaylist();
  const { t } = useTranslation('playlist-form');
  const navigate = useNavigate();

  return (
    <CreateButton
      isLoading={isPending}
      onClick={() => createPlaylist({
        title: t('input.title.default', { ns: 'playlist-form' }),
      }, {
        onSuccess: (newPlaylist) => {
          navigate(`/playlists/${newPlaylist.id}`);
        },
      })}
    />
  );
};

export default CreatePlaylist;
