import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';

type FilePreviewProps = {
    fileName?: string;
    fileExtension?: string;
    className?: string;
    onRemove?: () => void;
};

function FilePreview(props: FilePreviewProps) {
  const { fileName, fileExtension, className, onRemove } = props;

  return (
    <div className={classNames('relative flex gap-3 items-center w-full h-fit text-xs text-ligth-400 py-1.5 px-2 bg-dark-100 rounded group/file-preview', className)}>
      <span className="flex-1 ellipsis overflow-hidden">{fileName}</span>
      <div className="p-1 px-2 text-xxs bg-dark-200 rounded">
        {`${fileExtension?.toUpperCase()}`}
      </div>
      {onRemove ? (
        <button type="button" className="absolute hidden justify-center items-center top-0 right-0 rounded bg-light-100 h-4 w-4 translate-x-1/4 -translate-y-1/4 group-hover/file-preview:flex" onClick={onRemove}>
          <Icon name="close-line" className="text-dark-100 text-xs" />
        </button>
      ) : null}
    </div>
  );
}

export default FilePreview;
