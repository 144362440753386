import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';

export type ToastType = 'default' | 'success' | 'error' | 'warning';

type ToastProps = {
  type: ToastType
  title?: string
  content?: React.ReactNode
  icon?: string
};

const Toast = (props: ToastProps) => {
  const { type, title, content, icon } = props;

  function chooseIcon() {
    switch (type) {
      case 'success':
        return 'check-line';
      case 'error':
        return 'close-circle-line';
      case 'warning':
        return 'error-warning-line';
      default:
        return '';
    }
  }

  const backgroundStateColor = {
    default: 'bg-dark-300/80',
    success: 'bg-success-500',
    warning: 'bg-warning-500',
    error: 'bg-error-500',
  };

  const textStateColor = {
    default: 'text-dark-500',
    success: 'text-success-500',
    warning: 'text-warning-500',
    error: 'text-error-500',
  };

  return (
    <div className={classNames(
      ' w-96 p-5 bg-dark-300/90 backdrop-blur-md overflow-hidden rounded relative',
      `toast-${type}`,
    )}
    >
      <div className={classNames('absolute top-0 left-0 w-1 h-full', backgroundStateColor[type])} />
      <div className="w-full h-ful flex">
        <div className="h-full flex justify-start mr-5 icon">
          <Icon name={icon || chooseIcon()} className={classNames(' text-[1rem] leading-4', textStateColor[type])} />
        </div>
        <div>
          <h2 className=" text-sm leading-none mb-2 font-bold">{title}</h2>
          {content ? <div className="text-xs text-light-500">{content}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default Toast;
