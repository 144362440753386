import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useTranslation } from 'react-i18next';
import { useRightHoldersDialog } from '~/hooks/dialog/right-holders/use-right-holders-dialog';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';
import { useState } from 'react';
import { DialogContainer, DialogTitle } from '../common';
import { RightTypeBlock } from './right-type-block';

type RightHoldersDialogProps = {
  children: React.ReactNode;
};

export function RightHoldersDialog(props: RightHoldersDialogProps) {
  const { children } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const { rightsHolders, rightHolderClick } = useRightHoldersDialog(track.ownerships);
  const { t } = useTranslation('track-interactions');
  const [isOpen, setIsOpen] = useState(false);

  function handleClick<T extends {id:string, name:string}>(rightHolder: T, type: keyof typeof rightsHolders) {
    rightHolderClick(rightHolder, type);
    setIsOpen(false);
  }

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={(b) => setIsOpen(b)}>
      <DialogPrimitive.Trigger asChild>
        {children}
      </DialogPrimitive.Trigger>
      <DialogContainer>
        <div>
          <DialogTitle>
            {t('copyright.modal.title')}
          </DialogTitle>
        </div>
        <div>
          <RightTypeBlock
            title={t('copyright.modal.display-artists.title')}
            rightHolders={track.display_artists}
            onRightsHolderClick={(rightHolder) => handleClick<DisplayArtist>(rightHolder, 'master_artists')}
          />
          <RightTypeBlock
            title={t('copyright.modal.artists.title')}
            rightHolders={rightsHolders.artists}
            onRightsHolderClick={(rightHolder) => handleClick(rightHolder, 'artists')}
          />
          <RightTypeBlock
            title={t('copyright.modal.labels.title')}
            rightHolders={rightsHolders.labels}
            onRightsHolderClick={(rightHolder) => handleClick(rightHolder, 'labels')}
          />
          <RightTypeBlock
            title={t('copyright.modal.publishers.title')}
            rightHolders={rightsHolders.publishers}
            onRightsHolderClick={(rightHolder) => handleClick(rightHolder, 'publishers')}
          />
        </div>
      </DialogContainer>
    </DialogPrimitive.Root>
  );
}
