import GeneralIcons from '~/components/ui/icon/general-icons';
import Icon from '~/components/ui/icon/icon';

function ChatLoader() {
  return (
    <div className="flex flex-col h-full pr-2 gap-6 rounded-lg bg-dark-300">
      <div className="flex flex-col gap-3 px-4 pt-6">
        <button type="button" className="flex group/back items-center gap-2 text-xs">
          <Icon name="arrow-left-s-line" className="text-light-100 " />
          <span className="transition-transform group-hover/back:-translate-x-1">
            Back to threads
          </span>
        </button>
        <div className="flex items-center gap-3 w-full overflow-hidden">
          <div className="!w-10 !h-10 shrink-0 rounded bg-dark-100 animate-pulse" />
          <div className="w-full flex flex-col gap-2 justify-between">
            <div className="h-4 w-[25%] rounded bg-dark-100 animate-pulse" />
            <div className="h-3 w-[50%] rounded bg-dark-100 animate-pulse" />
          </div>
        </div>
        <div className="h-3 w-full rounded bg-dark-100 animate-pulse" />
      </div>
      <div className="self-stretch h-full overflow-y-auto px-4 scrollbar-on-hover flex justify-center items-center">
        <GeneralIcons icon="loader" className="text-3xl text-dark-100 animate-pulse" />
      </div>
      <div className="px-4 pb-4">
        <div className="h-8 bg-dark-100 rounded animate-pulse w-[80%]" />
      </div>
    </div>
  );
}

export default ChatLoader;
