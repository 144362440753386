import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import Cover from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { Artwork } from '~/types/schemas/common/image.schema';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';
import { parseDuration } from '~/utils/common/parse-duration';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';

type TrackResultProps = {
    title: string,
    artists?: DisplayArtist[],
    artwork?: Artwork
    type: string
    duration?: number
    interactiveButton?: ReactNode
    onArtistNameClick?: (artist: DisplayArtist) => void
    onClick?: (e: MouseEvent) => void
};

function TrackResult(props: TrackResultProps) {
  const { title, artists, artwork, type, duration, interactiveButton, onArtistNameClick, onClick } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'text-left flex gap-3 p-2 rounded-lg w-full transition duration-100 items-center',
        ' hover:bg-dark-100',
      )}
    >
      <Cover artworks={artwork && [artwork]} alt={title} className="!h-10 !w-10" iconClassName="text-[95%]" />
      <div className="flex flex-col w-full overflow-hidden">
        <Tooltip label={title} triggerClassName="text-left " align="start">
          <h5 className=" font-bold text-sm text-light-100 line-clamp-1">{title}</h5>
        </Tooltip>
        <ClickableNameList names={artists} onNameClick={(artist) => onArtistNameClick?.((artist))} />
      </div>
      <div className="flex items-center ">
        <Chip className="bg-dark-500">{type.toLocaleUpperCase()[0]}</Chip>
        <span className="text-xs text-light-500 w-12 align-middle text-center ">{parseDuration(duration || 0)}</span>
        {interactiveButton}
      </div>
    </button>
  );
}

export default TrackResult;
