import Button from '~/components/ui/control/button/button';
import Dialog from '~/components/ui/overlays/dialog/dialog';
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { useTranslation } from 'react-i18next';

type BriefFormHeaderProps = {
  title: string
  briefTitle?: string
  onSaveAndExitClick?: () => void
  onDiscardAndExitClick?: () => void
};

function BriefFormHeader(props: BriefFormHeaderProps) {
  const { t } = useTranslation('brief-form');
  const { title, briefTitle, onSaveAndExitClick, onDiscardAndExitClick } = props;
  const briefForm = useBriefFormStore(['isExitDialogOpen', 'changeExitDialogState']);
  return (
    <div className="h-8 flex justify-between items-center w-full">
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold">
          {title}
        </h1>
        {briefTitle ? <span className="text-sm text-light-500">{briefTitle}</span> : null}
      </div>
      <Dialog
        isOpen={briefForm.isExitDialogOpen}
        onOpenChange={briefForm.changeExitDialogState}
        asChild
        containerClassName="bg-dark-300 w-1/2!"
        title={t('header.exit.title')}
        description={t('header.exit.description')}
        trigger={<Button className="!bg-dark-100 hover:!bg-dark-200 brief-form__button--exit">{t('header.exit')}</Button>}
      >
        <div className="flex gap-3 items-center mt-4">
          <Button
            className="!bg-error-500 hover:!bg-error-400 brief-form__button--discard"
            onClick={onDiscardAndExitClick}
          >
            {t('header.exit.discard')}
          </Button>
          <Button
            className="brief-form__button--save-and-exit"
            onClick={onSaveAndExitClick}
          >
            {t('header.exit.save')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default BriefFormHeader;
