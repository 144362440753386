import { Controller } from 'react-hook-form';
import Textarea from '~/components/ui/forms/textarea/textarea';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input-controls/input/input';
import InterestFormHeader from '../components/header';
import { InterestStepProps } from '../interest-form';

function General(props: InterestStepProps) {
  const { t } = useTranslation('request-form');
  const {
    control,
    onDiscardAndExitClick,
    onClearFieldsClick,
  } = props;

  return (
    <>
      <InterestFormHeader onClearFieldsClick={onClearFieldsClick} onDiscardAndExitClick={onDiscardAndExitClick} />
      <div className="flex flex-col items-stretch gap-4 h-full form-content">
        <div className="form-content__row flex gap-3">
          <Controller
            name="production_type"
            control={control}
            rules={{ required: t('input.production-type.empty-warning') }}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.production-type.label')}
                placeholder={t('input.production-type.placeholder')}
                className="playlist-form__title flex-1"
                value={value}
                onChange={onChange}
                required
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="production_name"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.production-name.label')}
                placeholder={t('input.production-name.placeholder')}
                className="playlist-form__title flex-1"
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row flex gap-3">
          <Controller
            name="licensing_client_name"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.licensing-name.label')}
                className="playlist-form__title flex-1"
                info={t('input.licensing-name.tooltip')}
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="licensing_country"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.licensing-country.label')}
                info={t('input.licensing-country.tooltip')}
                className="playlist-form__title flex-1"
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row h-full">
          <Controller
            name="creative_description"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Textarea
                value={value}
                onChange={onChange}
                label={t('input.description.label')}
                info={t('input.description.tooltip')}
                boxClassName="h-full"
                className="h-full"
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row">
          <Controller
            name="video_links"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Textarea
                value={value}
                onChange={onChange}
                label={t('input.video-links.label')}
                info={t('input.video-links.tooltip')}
                rows={2}
                boxClassName="h-fit"
                className="h-full"
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row flex gap-3">
          <Controller
            name="max_length"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.max-length.label')}
                placeholder={t('input.max-length.placeholder')}
                className="playlist-form__title flex-1"
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="requested_rights"
            control={control}
            rules={{ required: t('input.rights.empty-warning') }}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.rights.label')}
                placeholder={t('input.rights.placeholder')}
                className="playlist-form__title flex-1"
                info={t('input.rights.tooltip')}
                value={value}
                onChange={onChange}
                required
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row">
          <Controller
            name="medias"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.medias.label')}
                placeholder={t('input.medias.placeholder')}
                className="playlist-form__title flex-1"
                info={t('input.medias.tooltip')}
                value={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="flex form-content__row gap-3">
          <Controller
            name="territories"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                value={value}
                onChange={onChange}
                label={t('input.territories.label')}
                info={t('input.territories.tooltip')}
                placeholder={t('input.territories.placeholder')}
                className="flex-1"
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row flex gap-3">
          <Controller
            name="duration"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.duration.label')}
                value={value || ''}
                info={t('input.duration.tooltip')}
                onChange={onChange}
                placeholder={t('input.duration.placeholder')}
                className="flex-1"
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="launch_date"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                value={value}
                onChange={onChange}
                label={t('input.launch-date.label')}
                type="date"
                info={t('input.launch-date.tooltip')}
                placeholder="Day"
                className="flex-1"
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="budget"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                type="number"
                label={t('input.budget.label')}
                value={value?.toString()}
                onNumberChange={onChange}
                placeholder={t('input.budget.placeholder')}
                info={t('input.budget.tooltip')}
                className="flex-1"
                separator=","
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

      </div>
    </>
  );
}

export default General;
