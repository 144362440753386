import classNames from 'classnames';
import React from 'react';
import { briefChatsStore } from '~/stores/chats-store';
import { useTranslation } from 'react-i18next';
import { useGetThreadTracks } from '~/hooks/api/thread';
import ChatHeader from './chat-header/chat-header';
import ChatMessages from './chat-messages/chat-messages';
import ChatInput from './chat-input/chat-input';
import { useChat } from './use-chat';
import ChatLoader from './chat.loader';

type ChatProps = {
  trackId: string;
  className?: string;
  onChatExit: () => void;
};

function Chat(props: ChatProps) {
  const { trackId, className, onChatExit } = props;
  const { isLoading, isError, channel } = useChat();
  const { brief } = briefChatsStore;
  const { data: tracks } = useGetThreadTracks(brief.value?.id, [trackId]);
  const { t } = useTranslation('brief-chat');

  if (isError) {
    return (
      <div className="p-4">{t('thread.loading.error')}</div>
    );
  }

  return !isLoading ? (
    <div className={classNames('flex flex-col h-full pr-2 gap-6 rounded-lg bg-dark-300', className)}>
      <ChatHeader track={tracks?.[0] ? tracks[0] : undefined} onBack={onChatExit} className="px-4 pt-6" />
      <ChatMessages className="self-stretch h-full overflow-y-auto px-4 scrollbar-on-hover" channel={channel} />
      <ChatInput className="px-4 pb-4" channel={channel} />
    </div>
  ) : (
    <ChatLoader />
  );
}

export default React.memo(Chat);
