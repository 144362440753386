import { z } from 'zod';

const CommonMessageSchema = z.object({
  created_at: z.string(),
  id: z.string(),
  seen: z.boolean(),
});

export const MatchingStatus = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  DONE: 'done',
} as const;

const MessageSchema = z.discriminatedUnion('type', [
  CommonMessageSchema.extend({
    type: z.literal('META_ARCHIVE'),
    status: z.enum(['COMPLETED', 'PROCESSING', 'CREATED', 'FAILED']),
    data: z.object({
      context: z.enum(['playlist', 'brief']),
      file: z.string().optional(),
      name: z.string(),
      progress: z.number(),
    }),
  }),
  CommonMessageSchema.extend({
    type: z.literal('META_INGEST_BRIEF_TRACKS'),
    status: z.enum(['COMPLETED', 'PROCESSING', 'CREATED', 'FAILED']),
    data: z.object({
      name: z.string(),
      progress: z.number(),
    }),
  }),
  CommonMessageSchema.extend({
    type: z.literal('META_SEARCH_SIMILARITY'),
    status: z.enum(['COMPLETED', 'PROCESSING', 'CREATED', 'FAILED']),
    data: z.object({
      id: z.string(),
      value: z.string(),
    }),
  }),
  CommonMessageSchema.extend({
    type: z.literal('SYNC_MATCHING'),
    status: z.nativeEnum(MatchingStatus),
    data: z.object({
      uploadId: z.string(),
      progress: z.number(),
    }),
  }),
  CommonMessageSchema.extend({
    type: z.literal('MUSEMIND_TRACK_VARIATION'),
    status: z.enum(['COMPLETED', 'PROCESSING', 'CREATED', 'FAILED']),
    data: z.object({
      trackId: z.string(),
      results: z.array(z.string()),
      error: z.object({
        message: z.string(),
        code: z.string(),
      }).optional(),
    }),
  }),
]);

type Message = z.infer<typeof MessageSchema>;

export { MessageSchema };
export type { Message };
