import { TagsAccordion } from '~/components/entities/tags/tags-accordion/tags-accordion';
import { usePopulatedTagsSearch } from '~/hooks/api/tag';
import { useSearchTagsAccordion } from '~/hooks/utils/use-tags-accordion';
import usePreSearchStore from '~/stores/presearch-store';
import Tab from '../tab';

function TargetTabs() {
  const presearch = usePreSearchStore(['query', 'setIsOpen']);
  const {
    data: { categories, rawData },
  } = usePopulatedTagsSearch(presearch.query, 'TARGET');
  const { subCategoryDefaultOpenState, categoryDefaultOpenState } =
    useSearchTagsAccordion(rawData?.categories);

  return (
    <Tab id="presearch-tags-tab" value="targets">
      <div className="col-span-full">
        <TagsAccordion
          categories={categories}
          subCategoryDefaultOpen={subCategoryDefaultOpenState}
          categoryDefaultOpen={categoryDefaultOpenState}
        />
      </div>
    </Tab>
  );
}

export default TargetTabs;
