import { components, SingleValueProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const SingleValue = (
  props: SingleValueProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children } = props;

  return (
    <components.SingleValue className="!m-0 capitalize !text-white" {...props}>
      {children}
    </components.SingleValue>
  );
};

export default SingleValue;
