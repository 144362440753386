import { trackUploadStore } from '~/stores/track-upload-store';
import { Message, MessageSchema } from '~/types/schemas/notifications/message.schema';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { useRealtimeListener } from './use-realtime-listener';

export function useTrackMatchProgress() {
  const realtime = useGetRealtimeData();
  const { addMatchDone } = trackUploadStore;

  function handleSyncMatching(message: Message) {
    switch (message.status) {
      case 'done':
        addMatchDone(message.data.uploadId);
        break;
      default: break;
    }
  }

  // Track match notifications
  useRealtimeListener(() => ({
    channels: realtime.data ? [realtime.data.channels.sync_notifications] : [],
    listener: {
      message(messageEvent) {
        const message = MessageSchema.safeParse(messageEvent.message);

        if (message.success) {
          switch (message.data.type) {
            case 'SYNC_MATCHING':
              handleSyncMatching(message.data);
              break;
            default: break;
          }
        }
      },
    },
  }), [realtime.data]);
}
