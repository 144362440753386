import { MusicTypeSelect } from './music-type-select';
import { VersionSelect } from './version-select';

type FileGlobalDataProps = {
  musicType?: string;
  version?: string;
  onMusicTypeChange?: (value: string) => void;
  onVersionChange?: (value: string) => void;
};

export function FileGlobalData(props: FileGlobalDataProps) {
  const { musicType, version, onMusicTypeChange, onVersionChange } = props;

  return (
    <div className="px-5 flex items-end pb-4  gap-4 h-full shrink-0">
      <MusicTypeSelect musicType={musicType} onMusicTypeChange={onMusicTypeChange} />
      <VersionSelect version={version} onVersionChange={onVersionChange} />
    </div>
  );
}
