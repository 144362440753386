import { MouseEvent } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import CheckLineIcon from 'virtual:icons/ri/check-line';
import AddIcon from 'virtual:icons/ri/add-fill';
import RemoveIcon from 'virtual:icons/ri/delete-bin-7-fill';
import IconArrowRightLine from 'virtual:icons/ri/arrow-right-line';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

type TracklistProps = {
    label: string;
    onClick?: (e:MouseEvent) => void;
    icon?: string;
    id?: string;
    type?: 'brief' | 'playlist';
    ownedByUser?: boolean;
    hasTrack?: boolean;
    className?: string,
};

function Tracklist(props: TracklistProps) {
  const { label, icon, className = '', type, ownedByUser, id, hasTrack, onClick } = props;

  const itemClassName = 'group/tracklist relative transition-colors w-full p-1 text-light-500 transition outline-none ease-in duration-100 border-none shadow-none text-left flex items-center justify-between rounded-sm focus:outline-none';

  return (
    <div
      data-tracklist-id={id}
      data-tracklist-type={type}
      data-tracklist-owned-by-user={ownedByUser}
      data-no-dnd="true"
      className={classNames(
        className,
        itemClassName,
      )}
    >
      <button type="button" className="tracklist flex flex-1 items-center hover:text-light-100 text-left " onClick={onClick}>
        <div className="flex items-center shrink-0">
          <div className="flex items-center justify-center w-5 mr-1">
            {icon ? <GeneralIcons icon={icon} /> : null}
          </div>
          <div className="flex items-center justify-center w-5 mr-1">
            <div className="block group-hover/tracklist:hidden">
              {hasTrack ? <CheckLineIcon /> : null}
            </div>
            <div className="hidden group-hover/tracklist:block">
              {hasTrack ? <RemoveIcon className="text-red-500" /> : <AddIcon />}
            </div>
          </div>
        </div>
        <h6 className="flex-1 text-sm text-middle overflow-hidden">
          {label}
        </h6>
      </button>
      {type && id ? (
        <NavLink to={`/${type}s/${id}`} className="tracklist__item__link absolute right-0 items-center justify-center w-5 hidden bg-dark-400 hover:text-light-100 group-hover/tracklist:flex">
          <IconArrowRightLine />
        </NavLink>
      ) : null}
    </div>
  );
}

export default Tracklist;
