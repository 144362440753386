import useDataStore from '~/stores/mewo-data-store';
import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';
import { useMemo } from 'react';
import { FilterList } from '~/types/schemas/search/filter.schema';
import { Owner, OwnerShips } from '~/types/schemas/music/track.schema';

type TrackCredentials = {
    rightsHoldersByType: Record<string, Owner[]>;
    addOwnerToFilter: (owner: Owner & {type: string}) => void;
};

export function useTrackCredentials(ownerships: OwnerShips):TrackCredentials {
  const search = useSearchStore(['addValueToMatchingBlock']);
  const rights = useDataStore((s) => s.rights.object);
  const rightsHoldersByType = useMemo((): Record<string, Owner[]> => {
    const parsedOwner: Record<string, Owner[]> = {};

    const parseOwner = (owner: Owner, type: keyof FilterList) => {
      if (rights[owner.rights_type]?.name) {
        if (parsedOwner[type]) {
          parsedOwner[type]?.push(owner);
        } else {
          parsedOwner[type] = [owner];
        }
      }
    };

    ownerships?.master.artists.forEach((artist) => {
      parseOwner(artist, 'artists');
    });

    ownerships?.publishing.artists.forEach((artist) => {
      parseOwner(artist, 'artists');
    });

    ownerships?.master.labels.forEach((label) => {
      parseOwner(label, 'labels');
    });

    ownerships?.publishing.publishers.forEach((publisher) => {
      parseOwner(publisher, 'publishers');
    });

    return parsedOwner;
  }, [rights, ownerships]);

  const handleOwnerClick = (owner: Owner & {type: string}) => {
    search.addValueToMatchingBlock(
      termOptions[owner.type]!,
      itemConditionsOptions.is!,
      {
        value: owner.id,
        label: owner.name,
      },
    );
  };

  return { rightsHoldersByType, addOwnerToFilter: handleOwnerClick };
}
