import classNames from 'classnames';
import Cover from '../cover';

type FileDataHeaderProps = {
    fileName?: string
    index: number,
    className?: string
};

export function FileDataHeader(props: FileDataHeaderProps) {
  const { fileName, index, className } = props;

  return (
    <div className={classNames('w-full h-full flex items-center p-3', className)}>
      <Cover
        title={fileName}
        titleClassName="line-clamp-2"
        customMedia={(
          <div className="flex h-10 aspect-square gap-0.5 items-center justify-center bg-dark-400 rounded-lg ">
            <span>
              {index + 1}
            </span>
          </div>
        )}
      />
    </div>
  );
}
