import FileUploadProvider from '~/components/features/file-upload/providers/file-upload.provider';
import { trackUploadStore } from '~/stores/track-upload-store';
import { useCallback } from 'react';
import { AnimatedList } from '~/components/layout/animated-list/animated-list';
import FileListItem from './file-list-item';

export default function FileList() {
  const { trackList, addUploadQueue, initUploadEnd } = trackUploadStore;

  const handleUploadInitSuccess = useCallback((fileId: string) => {
    initUploadEnd(fileId);
    addUploadQueue(fileId);
  }, []);

  return (
    <div className="relative w-full max-w-[70%] h-full overflow-y-auto hide-scrollbar flex flex-col gap-4">
      <AnimatedList className="flex flex-col gap-4 w-full">
        {trackList.value.map((file, index) => (
          <FileUploadProvider key={`${file.name}-${file.size}-${file.type}`} file={file} onUploadInitSuccess={() => handleUploadInitSuccess(file.id)}>
            <FileListItem file={file} index={index} />
          </FileUploadProvider>
        ))}
      </AnimatedList>
    </div>
  );
}
