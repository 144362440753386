import { useLicensingQuoteDialog } from '~/hooks/dialog/licensing-quote/use-licensing-quote-dialog';
import { LicensingQuoteFooter } from './footer';
import { LicensingQuoteFormContent } from './form-content';

type LicensingQuoteFormProps = {
  onSubmit?: () => void;
};

export function LicensingQuoteForm(props: LicensingQuoteFormProps) {
  const { onSubmit } = props;
  const { handleSubmit, control } = useLicensingQuoteDialog();

  function handleSubmitForm() {
    onSubmit?.();
    handleSubmit();
  }

  return (
    <form
      onSubmit={handleSubmitForm}
      className="h-full overflow-y-auto custom-scrollbar pl-4 pr-3 mr-1"
    >
      <LicensingQuoteFormContent control={control} />
      <LicensingQuoteFooter onClick={handleSubmitForm} />
    </form>
  );
}
