import { Track } from '~/types/schemas/music/track.schema';
import { usePlaylistTrackItemList } from '~/hooks/entities/track/track-item-list/use-playlist-track-item-list';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ProcessingTracks } from '~/pages/playlist/components/processing-tracks';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { TrackItemList } from '../track-item-list';
import { PlaylistListHeader } from './playlist-list-header';
import { PlaylistTrackItemListEmpty } from './playlist-track-item-list.empty';
import { TrackItem } from '../../track-item/track-item';
import { renderTrackItemSentinel } from '../../common/loader/sentinels';

type PlaylistTrackItemListProps = {
    playlist: Playlist,
    scrollParentId: string
};

export function PlaylistTrackItemList(props: PlaylistTrackItemListProps) {
  const { playlist, scrollParentId } = props;
  const { tracks, isFetching, isLoading, handleNextPageFetch } = usePlaylistTrackItemList(playlist.id);

  if (isLoading) {
    return (
      <>
        <PlaylistListHeader />
        {renderTrackItemSentinel({ flex: '1 1 0%' })}
      </>
    );
  }

  if (tracks.length) {
    return (
      <div className="@container">
        <ProcessingTracks id={playlist.id} tracks={tracks} />
        <PlaylistListHeader />
        <SortableContext
          id={playlist.id}
          items={tracks || []}
          strategy={verticalListSortingStrategy}
        >
          <TrackItemList
            context={{
              type: 'playlist',
              value: playlist,
            }}
            tracks={tracks}
            isFetching={isFetching}
            scrollParent={document.getElementById(scrollParentId)}
            onRangeChange={handleNextPageFetch}
            renderItem={(track) => <TrackItem type="playlist" tracklistId={playlist.id} track={track as Track} />}
            className="pt-2"
            preProcessMagicPreview
          />
        </SortableContext>
      </div>
    );
  }

  return (
    <PlaylistTrackItemListEmpty />
  );
}
