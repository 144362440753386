import classNames from 'classnames';
import { ReactNode } from 'react';

type CardProps = {
    children: ReactNode
    className?: string
};

export function Card(props: CardProps) {
  const { children, className } = props;

  return (
    <article className={classNames('relative h-full w-full grid grid-rows-6 rounded-lg border-2 border-dark-300 overflow-hidden backdrop-blur-3xl', className)}>
      {children}
    </article>
  );
}
