import { z } from 'zod';
import { BriefSchema } from './brief.schema';
import { PlaylistSchema } from './playlists.schema';
import { createSearchResultSchema } from '../search/search.schema';

export const TracklistSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('playlist'),
    value: PlaylistSchema,
    has_track: z.boolean(),
  }),
  z.object({
    type: z.literal('brief'),
    value: BriefSchema,
    has_track: z.boolean(),
  }),
]);

export const TracklistSearchSchema = createSearchResultSchema(TracklistSchema);

export type Tracklist = z.infer<typeof TracklistSchema>;
export type TracklistSearch = z.infer<typeof TracklistSearchSchema>;
