import { useGetThreadChannelParticipants } from '~/hooks/api/thread';

export function useChatData(briefId?: string, trackId?: string) {
  const { data: participants, isLoading: isParticipantsLoading, isError: isParticipantsError, error } = useGetThreadChannelParticipants(briefId, trackId);

  return {
    participants,
    isLoading: isParticipantsLoading,
    isError: isParticipantsError,
    error,
  };
}
