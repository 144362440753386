import classNames from 'classnames';
import { FormStep, FormStepProps } from './components/form-step';

type FormStepsProps = {
    steps: FormStepProps[]
    className?: string
    onStepClick?: (index?: number) => void
};

export function FormSteps(props: FormStepsProps) {
  const { steps, className, onStepClick } = props;

  return (
    <div className={classNames('form-steps w-fit flex flex-col items-end', className)}>
      {steps.map((step, index) => (
        <div key={`form-step-${index}`} className="relative flex flex-col items-end -mt-1 first:mt-0 group/step">
          <FormStep {...step} index={index + 1} onStepClick={onStepClick} />
          <div className="inline-block right-3 top-7 h-6 w-[1px] bg-dark-100 mr-3 -mt-1 group-last/step:hidden" />
        </div>
      ))}
    </div>
  );
}
