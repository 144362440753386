import { signal } from '@preact/signals-react';
import { addToProcess, processEnd } from '~/components/features/tracks-upload/utils/process-helper';
import { UploadFile } from '~/types/features/file-upload/context';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { TrackMetadata } from '~/types/features/track-upload/track-metadata.types';
import { STEPS_ORDER } from '~/components/features/tracks-upload/track-uploads.constants';
import { fileUploadStore } from './file-upload-store';

export const trackUploadStore = {
  isOpen: signal(false),
  isExitDialogOpen: signal(false),
  context: {
    type: signal<string | undefined>(undefined),
    id: signal<string | undefined>(undefined),
  },
  trackList: signal<UploadTrack[]>([]),
  matchDone: signal<string[]>([]),
  validated: signal<string[]>([]),
  selected: signal<string | undefined>(undefined),
  processes: {
    initUpload: {
      processing: signal<string[]>([]),
      queue: signal<string[]>([]),
    },
    upload: {
      processing: signal<string[]>([]),
      queue: signal<string[]>([]),
    },
  },
  reset() {
    trackUploadStore.isOpen.value = false;
    trackUploadStore.isExitDialogOpen.value = false;
    trackUploadStore.context.type.value = undefined;
    trackUploadStore.context.id.value = undefined;
    trackUploadStore.trackList.value = [];
    trackUploadStore.matchDone.value = [];
    trackUploadStore.validated.value = [];
    trackUploadStore.selected.value = undefined;
    trackUploadStore.processes.initUpload.processing.value = [];
    trackUploadStore.processes.initUpload.queue.value = [];
    trackUploadStore.processes.upload.processing.value = [];
    trackUploadStore.processes.upload.queue.value = [];
    trackUploadStore.isExitDialogOpen.value = false;
    fileUploadStore.reset();
  },
  close() {
    trackUploadStore.isOpen.value = false;
    trackUploadStore.reset();
  },
  openExitDialog() {
    trackUploadStore.isExitDialogOpen.value = true;
  },
  changeExitDialogState(newState: boolean) {
    trackUploadStore.isExitDialogOpen.value = newState;
  },
  addTracks(newFiles: UploadTrack[]) {
    trackUploadStore.trackList.value = [...trackUploadStore.trackList.value, ...newFiles];
    trackUploadStore.sortTracks();
  },
  deleteTrack(fileId: string) {
    if (trackUploadStore.selected.value === fileId) {
      trackUploadStore.selected.value = undefined;
    }

    trackUploadStore.trackList.value = trackUploadStore.trackList.value.filter((file) => file.id !== fileId);
    trackUploadStore.removeValidated(fileId);
    fileUploadStore.removeFile(fileId);
  },
  sortTracks() {
    trackUploadStore.trackList.value = [...trackUploadStore.trackList.value].sort((a, b) => {
      return STEPS_ORDER[a.step.value] - STEPS_ORDER[b.step.value];
    });
  },
  initProcesses(newFiles: UploadFile[]) {
    newFiles.forEach((file) => {
      trackUploadStore.addInitUploadQueue(file.id);
    });
  },
  changeSelected(fileId: string) {
    trackUploadStore.selected.value = fileId;
  },
  nextSelected() {
    const { trackList, selected, validated } = trackUploadStore;
    const index = trackList.value.findIndex((file) => !validated.value.includes(file.id));
    const nextFile = trackList.value[index];
    if (nextFile) {
      let selectedValue: string | undefined = nextFile.id;
      const nextFileIsNotReady = ['match', 'matchLaunch', 'upload', 'waiting'].includes(nextFile.step.value);

      if (nextFileIsNotReady) {
        selectedValue = undefined;
      }

      selected.value = selectedValue;
    } else {
      selected.value = undefined;
    }
  },
  addInitUploadQueue(fileId: string) {
    const { queue, processing } = trackUploadStore.processes.initUpload;
    addToProcess(fileId, queue, processing);
  },
  initUploadEnd(fileId: string) {
    const { queue, processing } = trackUploadStore.processes.initUpload;
    processEnd(fileId, queue, processing);
  },
  addUploadQueue(fileId: string) {
    const { queue, processing } = trackUploadStore.processes.upload;
    addToProcess(fileId, queue, processing);
  },
  uploadEnd(fileId: string) {
    const { queue, processing } = trackUploadStore.processes.upload;
    processEnd(fileId, queue, processing);
  },
  addValidated(file: UploadTrack, uploadId?: string) {
    if (!uploadId) return;

    trackUploadStore.validated.value = [...trackUploadStore.validated.value, file.id];
    trackUploadStore.nextSelected();
  },
  removeValidated(fileId: string) {
    trackUploadStore.validated.value = trackUploadStore.validated.value.filter((id) => id !== fileId);
  },
  onMatchFound(file: UploadTrack, matchedMetadata: TrackMetadata) {
    if (!trackUploadStore.selected.value) {
      trackUploadStore.selected.value = file.id;
    }
    if (!matchedMetadata.cover && file.metadata?.cover) {
      file.matchedMetadata.value = {
        cover: file.metadata.cover,
        useId3Cover: true,
        ...matchedMetadata,
      };
    }

    file.step.value = 'display';
    file.matchedMetadata.value = {
      type: 'identified',
      ...matchedMetadata,
    };
    trackUploadStore.sortTracks();
  },
  onMatchNotFound(file: UploadTrack) {
    if (!trackUploadStore.selected.value) {
      trackUploadStore.selected.value = file.id;
    }
    file.step.value = 'noMatch';
    file.matchedMetadata.value = {
      type: 'manual',
    };
    trackUploadStore.sortTracks();
  },
  addMatchDone(uploadId: string) {
    trackUploadStore.matchDone.value = [...trackUploadStore.matchDone.value, uploadId];
  },
  openTrackUpload(context: string, id: string) {
    trackUploadStore.isOpen.value = true;
    trackUploadStore.context.type.value = context;
    trackUploadStore.context.id.value = id;
  },
  spotifySelect(file: UploadTrack, spotifyId: string) {
    trackUploadStore.removeValidated(file.id);
    file.matchedMetadata.value = {
      ...file.matchedMetadata.value,
      type: 'spotify',
      spotifyId,
      useId3Cover: false,
    };
  },
  manualChange(file: UploadTrack) {
    trackUploadStore.removeValidated(file.id);
    file.matchedMetadata.value = {
      ...file.matchedMetadata.value,
      type: 'manual',
      spotifyId: undefined,
    };
  },
};
