import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';

type BriefFormFooterProps = {
  onSaveAndExitClick?: () => void
};

function PlaylistFormFooter(props: BriefFormFooterProps) {
  const { t } = useTranslation('playlist-form');
  const { onSaveAndExitClick } = props;
  return (
    <div className="w-full h-8 flex justify-end items-center opacity-0 animation-delay-800 animate-slideTop">
      <Button className="playlist-form__submit-button" onClick={onSaveAndExitClick}>{t('footer.exit.button.label')}</Button>
    </div>
  );
}

export default PlaylistFormFooter;
