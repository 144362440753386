import { signal } from '@preact/signals-react';
import Pubnub from 'pubnub';
import { ChatMessageContent, MessageAction, MessageActionType, MessageDTOParams, MessageReceipt } from '~/types/types/chat';

export class Message<MessageContent, MessageMeta = Record<string, unknown>> {
  private pubnub: Pubnub;

  timetoken: string;

  content: MessageContent;

  userId: string;

  channel: string;

  private pReceipts = signal<MessageReceipt[] | undefined>(undefined);

  deleted = signal(false);

  meta?: MessageMeta;

  private pActions: MessageAction = {
    delete: {
      delete: signal([]),
    },
    edit: {},
    quote: {},
    reaction: {},
    receipt: {
      read: signal([]),
    },
  };

  constructor(pubnub: Pubnub, params: MessageDTOParams, receipts?: MessageReceipt[]) {
    this.pubnub = pubnub;
    this.channel = params.channel;
    this.content = params.message;
    this.userId = 'publisher' in params ? params.publisher : params.uuid || 'unknown';
    this.timetoken = String(params.timetoken);
    this.pReceipts.value = receipts || [];

    if ('actions' in params) {
      Object.entries(params.actions).forEach(([type, values]) => {
        Object.entries(values).forEach(([value, actions]) => {
          this.addActions(actions.map((action) => {
            return {
              actionTimetoken: String(action.actionTimetoken),
              uuid: action.uuid,
              type: type as MessageActionType,
              value,
              messageTimetoken: this.timetoken,
            };
          }));
        });
      });
    }

    if ('meta' in params) {
      this.meta = params.meta as MessageMeta;
    } else if ('userMetadata' in params) {
      this.meta = params.userMetadata as MessageMeta;
    }

    this.update();
  }

  set receipts(receipts: MessageReceipt[] | undefined) {
    this.pReceipts.value = receipts;
  }

  get receipts() {
    return this.pReceipts.value;
  }

  isReadByUser(userId: string) {
    return !!this.receipts?.find((receipt) => receipt.userId === userId);
  }

  hasReadAction(userId: string) {
    return !!this.pActions?.receipt?.read?.value.find((action) => action.uuid === userId);
  }

  get readActions() {
    return this.pActions?.receipt?.read?.value;
  }

  async fetchMessageActions() {
    if (!this.pActions) {
      const response = await this.pubnub.getMessageActions({
        channel: this.channel,
        end: this.timetoken,
      });

      const actions = response.data.filter((action) => action.messageTimetoken === this.timetoken);

      this.addActions(actions);
    }
  }

  addActions(actions: Pubnub.MessageAction[]) {
    actions.forEach((action) => {
      this.pActions![action.type as MessageActionType] ??= {};
      this.pActions![action.type as MessageActionType]![action.value] ??= signal([]);
      this.pActions![action.type as MessageActionType]![action.value]!.value = [
        ...this.pActions![action.type as MessageActionType]![action.value]!.value,
        {
          uuid: action.uuid,
          actionTimetoken: action.actionTimetoken,
        },
      ];
    });

    this.update();
  }

  private update() {
    this.deleted.value = !!this.pActions?.delete?.delete?.value.length;
  }

  watchUpdates() {
    const listener: Pubnub.ListenerParameters = {
      messageAction: (messageActionEvent) => {
        if (messageActionEvent.channel === this.channel && messageActionEvent.data.messageTimetoken === this.timetoken) {
          if (messageActionEvent.event === 'added') {
            this.addActions([messageActionEvent.data]);
          }
        }
      },
    };

    this.pubnub.addListener(listener);

    return () => {
      this.pubnub.removeListener(listener);
    };
  }

  async markAsRead() {
    if (this.hasReadAction(this.pubnub.getUUID())) {
      return;
    }

    await this.pubnub.addMessageAction({
      channel: this.channel,
      messageTimetoken: this.timetoken,
      action: {
        type: 'receipt',
        value: 'read',
      },
    });
  }

  async delete() {
    if (this.deleted.value || this.userId !== this.pubnub.getUUID()) {
      return;
    }

    await this.pubnub.addMessageAction({
      channel: this.channel,
      messageTimetoken: this.timetoken,
      action: {
        type: 'delete',
        value: 'delete',
      },
    });
  }

  async download(file: Exclude<ChatMessageContent['files'], undefined>[0]) {
    this.pubnub.downloadFile({ channel: this.channel, id: file.id, name: file.name }).then(async (f) => {
      const url = URL.createObjectURL(await f.toBlob());

      const link = document.createElement('a');

      link.target = '_blank';
      link.download = file.name;
      link.href = url;
      link.click();
      link.remove();
    });
  }
}
