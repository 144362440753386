import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type DropdownMenuItemProps = {
  children: React.ReactNode;
};

export function ContextSubMenuContainer(props: DropdownMenuItemProps) {
  const { children } = props;

  return (
    <DropdownMenu.Portal>
      <DropdownMenu.SubContent
        sideOffset={5}
        className="bg-dark-300 border border-dark-100/50 w-fit min-w-60 rounded-md p-1 shadow-xl"
      >
        {children}
      </DropdownMenu.SubContent>
    </DropdownMenu.Portal>
  );
}
