import { useUpdatePlaylist } from '~/hooks/api/playlist';
import usePlaylistFormStore from '~/stores/forms/playlist-form-store';
import { Playlist, PlaylistUpdate } from '~/types/schemas/music/playlists.schema';

export function usePlaylistForm(playlist?: Playlist) {
  const { mutate: updatePlaylist } = useUpdatePlaylist(playlist!.id!);
  const playlistForm = usePlaylistFormStore(['close']);

  function handleDiscardAndExit() {
    playlistForm.close();
  }

  function handleSaveAndExit(data: PlaylistUpdate) {
    const update = async () => updatePlaylist({
      title: data.title,
      descriptions: [...(data.description !== '' ? [{
        locale: 'en',
        value: data.description,
      }] : [])],
    });
    const close = async () => playlistForm.close();

    if (data !== undefined) {
      update().then(close);
    } else close();
  }

  return ({
    handleDiscardAndExit,
    handleSaveAndExit,
  });
}
