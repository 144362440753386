import { useRemoveTracksFromPlaylist } from '~/hooks/api/playlist';
import { useTranslation } from 'react-i18next';
import TrackInteraction from '../interaction';

type RemoveFromPlaylist = {
    trackId: string;
    playlistId: string;
};

const RemoveTrackFromPlaylist = (props: RemoveFromPlaylist) => {
  const { playlistId, trackId } = props;
  const { mutate: removeTrack } = useRemoveTracksFromPlaylist(playlistId);
  const { t } = useTranslation('track-interactions');

  return (
    <TrackInteraction
      icon="remove-line"
      tooltip={t('remove-from-playlist.tooltip')}
      onClick={() => removeTrack({ ids: [trackId] })}
    />
  );
};

export default RemoveTrackFromPlaylist;
