import { useMutation } from '@tanstack/react-query';
import { globalStore } from '~/stores/global-store-v2';
import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';
import { Track } from '~/types/schemas/music/track.schema';
import { Action } from '~/types/types/actions';
import { getHttp } from '~/utils/http/client';

export const usePlayTrack = () => {
  const client = getHttp();
  const player = usePlayerStore(['playTrack']);
  const { setPlay } = useMusemindStore(['setPlay']);

  const mutation = useMutation({
    mutationFn: async (params: { track: Track, context: Action.Play, progress?: number }) => {
      const { track, context, progress = 0 } = params;

      player.playTrack(track, progress);
      setPlay(false);

      if (!globalStore.loggedAs.value && context.type !== 'player') {
        await client.post('action', {
          json: {
            context: 'SEARCHMODE',
            action: {
              type: 'PLAY',
              target: track.id,
              source: context,
            },
          },
        }).json();
      }

      return null;
    },
  });

  return mutation.mutate;
};
