import { lazy, Suspense, useEffect } from 'react';
import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';
import IconPause from 'virtual:icons/ri/pause-fill';
import IconPlay from 'virtual:icons/ri/play-fill';
import IconClose from 'virtual:icons/ri/eye-off-line';
import GeneralIcons from '~/components/ui/icon/general-icons';
import classNames from 'classnames';
import { Volume } from './volume';
import VideoSeek from './video-seek';
import VariationControlBase from './variation-control';
import { useTranslation } from 'react-i18next';

const VariationControlWithDownload = lazy(() => import('./variation-control-with-download'));

type ControlsProps = {
  className?: string
};

const Controls = (props: ControlsProps) => {
  const { className = '' } = props;
  const player = usePlayerStore(['setPlay', 'isPlaying']);
  const musemind = useMusemindStore(['audioTitle', 'isPlaying', 'isCreateVariationsLoading', 'togglePlay', 'setIsPipOpen']);
  const { t } = useTranslation('magic-preview');

  const handleTogglePlay = () => {
    if (player.isPlaying) {
      player.setPlay(false);
    }
    musemind.togglePlay();
  };

  useEffect(() => {
    if (musemind.isPlaying) {
      player.setPlay(false);
    }
  }, [musemind.isPlaying, player.setPlay]);

  return (
    <div className={classNames(className, musemind.isCreateVariationsLoading ? 'block' : 'hidden group-hover:block')}>
      <div className="absolute flex justify-center items-center w-full h-full left-0 top-0 transition-all duration-100 bg-gradient-to-t from-dark-400 to-dark-400/50">
        {musemind.isCreateVariationsLoading ? (
          <div><GeneralIcons icon="musemind-variation-loader" className="text-9xl text-light-500" /></div>
        ) : (
          <button data-no-dnd="true" type="button" className="musemind-pip__controls__button text-6xl" onClick={() => handleTogglePlay()}>
            {musemind.isPlaying ? <IconPause className="musemind-pip__controls__button--pause" /> : <IconPlay className="musemind-pip__controls__button--play" />}
          </button>
        )}
      </div>
      <div className=" absolute top-0 left-0 p-4 flex justify-between items-center w-full">
        <span className="musemind-pip__controls__title text-light-100 font-bold text-sm truncate max-w-full">{musemind.audioTitle || ''}</span>
        <button type="button" data-no-dnd="true" onClick={() => musemind.setIsPipOpen(false)} className="musemind-pip__controls__hide flex text-xs gap-2">
          {t('pip.header.hide')}
          {' '}
          <IconClose />
        </button>
      </div>
      <div className={classNames(
        'absolute bottom-0 p-4 w-full flex flex-col gap-2',
        musemind.isCreateVariationsLoading ? 'opacity-50 pointer-events-none' : 'block',
      )}
      >
        <VideoSeek />
        <div className="w-full flex justify-between items-center">
          <Suspense fallback={<VariationControlBase />}>
            <VariationControlWithDownload />
          </Suspense>
          <Volume />
        </div>
      </div>
    </div>
  );
};

export default Controls;
