import { IconifyIconProps } from '@iconify/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { useTrackContext } from '../../track.context';
import { ActionIcon } from './action-icon';

type TrackActionProps = {
  icon?: IconifyIconProps['icon']
  customIcon?: ReactNode
  tooltip: string
  displayOnHover?: boolean
  isDisabled?: boolean
  isError?: boolean
  isWarning?: boolean
  className?: string,
  onClick?: () => void
};

export function TrackAction(props: TrackActionProps) {
  const { icon, customIcon, tooltip, isDisabled = false, isWarning, isError, displayOnHover = false, className, onClick } = props;
  const store = useTrackContext();
  const isTrackActive = store.use.isTrackActive();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <Tooltip label={tooltip} asChild>
      <button
        type="button"
        onClick={handleClick}
        className={classNames('relative cursor-pointer size-7 rounded-md  p-1 flex items-center justify-center', {
          'hover:bg-dark-200 text-light-400 hover:text-light-100': !isError && !isWarning,
          'opacity-10': isDisabled,
          'opacity-0 group-hover/track-item:opacity-100': displayOnHover,
          'opacity-100!': isTrackActive,
        }, className)}
      >
        {isError || isWarning ? (
          <div className={classNames('absolute inset-1 size-1.5 rounded-full', {
            'bg-error-500': isError,
            'bg-warning-500': isWarning,
          })}
          />
        ) : null}
        <ActionIcon
          icon={icon}
          customIcon={customIcon}
          className={classNames({
            'opacity-10': isError || isWarning,
          })}
        />
      </button>
    </Tooltip>
  );
}
