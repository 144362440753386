import * as TrackActions from '~/components/ui/control/menus/dropdown-menu/track-actions/track-actions';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { useTrackItemListContext } from '../../../track-item-list/track-item-list.context';

export function TrackItemsDropdownItems() {
  const { values } = useSelectionContext();
  const { context } = useTrackItemListContext();

  const isTrackRemovable = context?.type !== 'brief' || !context?.value.owned_by_tenant;

  if (values.length > 1) {
    return (
      <>
        <TrackActions.AddToTracklist type="playlist" />
        <TrackActions.AddToTracklist type="brief" />
        {isTrackRemovable ? <TrackActions.RemoveFromTracklist /> : null}
      </>
    );
  }

  return (
    <>
      <TrackActions.Similarity />
      <TrackActions.Download.Container>
        <TrackActions.Download.Original />
        <TrackActions.Download.HDMP3 />
        <TrackActions.Download.Copyrights />
      </TrackActions.Download.Container>
      <TrackActions.LicensingQuote />
      <TrackActions.Lyrics />
      <TrackActions.Copyright />
      <TrackActions.AddToTracklist type="playlist" />
      <TrackActions.AddToTracklist type="brief" />
    </>
  );
}
