/* eslint-disable camelcase */
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { parseDateNumeric } from '~/utils/common/parse-date';
import PlaylistDescription from '~/pages/playlist/components/playlist-description';
import InfoChip from '~/pages/briefs/components/info-chip';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Artwork } from '~/components/ui/cover/artwork';
import PlaylistInteractions from './playlist-interactions';

type PlaylistHeaderProps = {
  detail?: Playlist
  className?: string
};

const PlaylistHeader = (props: PlaylistHeaderProps) => {
  const { detail, className } = props;
  const { t } = useTranslation('playlist-page');

  if (!detail) return null;

  const { created_by, created_at, id, tracks, tenant } = detail!;
  const { company_name } = tenant!;
  const { first_name, last_name } = created_by!;

  return (
    <section className={classNames('flex flex-col gap-6 h-fit', className)}>
      <div className="flex gap-6 h-64 px-4">
        <Artwork className="w-fit aspect-square" artworks={detail?.artworks?.map((artwork) => artwork.large)} alt="cover" icon="ri:headphone-line" />
        <div className="flex flex-1 flex-col gap-4 h-full">
          <div className="relative flex flex-col justify-end self-stretch h-full mb-4">
            <div className="w-full flex justify-between items-center">
              <h4 className="text-xs text-light-500 ">
                {t('header.creation-info', {
                  company: company_name,
                  user: `${first_name} ${last_name}`,
                  date: parseDateNumeric(created_at || '', {
                    month: 'long',
                    day: '2-digit',
                  }),
                })}
              </h4>
            </div>
            <h1 id="playlist-title" className="text-[3vw] leading-[3vw] font-bold text-start break-words line-clamp-2">
              {detail?.title}
            </h1>
          </div>
          <div className="flex gap-4">
            <InfoChip
              value={t('header.tracks-number', { count: tracks })}
            />
          </div>
          <PlaylistInteractions id={id!} data={detail!} ownedByTenant={detail?.owned_by_tenant} isEmpty={!((tracks || 0) > 0)} />
        </div>
      </div>
      <div className="px-4">
        {detail?.descriptions.length ? <PlaylistDescription descriptions={detail?.descriptions} /> : null}
      </div>
    </section>
  );
};

export default PlaylistHeader;
