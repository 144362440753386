import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { KyOptions } from 'ky/distribution/types/options';
import { api } from '../../../utils/api';

type useDeleteMultipleOptions = {
  url: string;
  queryParams?: KyOptions;
  mutateOptions?: UseMutationOptions<string, Error, {
    ids: string[];
  }>;
  updater?: (newData: { ids: string[] }) => void;
};

export const useDeleteMultiple = (params: useDeleteMultipleOptions) => {
  const { url, queryParams, mutateOptions, updater } = params;
  const queryClient = useQueryClient();
  const { onError, onSettled, ...otherOptions } = mutateOptions || {};

  return useMutation<string, Error, {ids: string[]}>({
    mutationFn: (data) => api.delete(url, { json: data }).text(),
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: [url!, queryParams] });

      const previousData = queryClient.getQueryData([url!, queryParams]);

      updater?.(newData);

      return { previousData };
    },
    onError: (...data) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_err, _var, context] = data;
      queryClient.setQueryData([url!, queryParams], context);
      onError?.(...data);
    },
    onSettled: (...data) => {
      queryClient.invalidateQueries({ queryKey: [url!, queryParams] });
      onSettled?.(...data);
    },
    ...otherOptions,
  });
};
