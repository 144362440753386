import classNames from 'classnames';
import { useMemo } from 'react';
import { TrackSummaryArtists } from './artists';
import { TrackSummaryCover } from './cover';
import { TrackSummaryStatusChip } from './status-chip';
import { TrackSummaryTitle } from './title';
import { TrackSummaryControls } from './controls';
import { TrackSummaryContext } from './track-summary.context';
import { useTrackContext } from '../track.context';

type TrackSummaryProps = {
  className?: string,
  children: React.ReactNode;
};

function TrackSummary(props: TrackSummaryProps) {
  const { className, children } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const defaultValue = useMemo(() => ({
    id: track.id,
    title: track.title,
    displayArtists: track.display_artists,
    artwork: track.artwork,
  }), [track]);

  return (
    <TrackSummaryContext.Provider value={defaultValue}>
      <div className={classNames('flex items-center', className)}>
        {children}
      </div>
    </TrackSummaryContext.Provider>
  );
}

const Root = TrackSummary;
const Title = TrackSummaryTitle;
const Artists = TrackSummaryArtists;
const Cover = TrackSummaryCover;
const StatusChip = TrackSummaryStatusChip;
const Controls = TrackSummaryControls;

export {
  Root,
  Title,
  Artists,
  Cover,
  StatusChip,
  Controls,
};
