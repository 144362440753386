import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';

type DialogTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export function DialogTitle(props: DialogTitleProps) {
  const { children, className } = props;

  return (
    <DialogPrimitive.Title className={classNames('font-bold text-xl mb-0.5', className)}>
      {children}
    </DialogPrimitive.Title>
  );
}
