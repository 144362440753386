import { InfiniteData, UseInfiniteQueryResult, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { FilterBlock, useSearchStore } from '~/stores/search-store';
import { Track, TrackSearchResult, TrackSearchResultSchema } from '~/types/schemas/music/track.schema';
import { getHttp } from '~/utils/http/client';
import { Filter } from '~/types/schemas/search/filter.schema';
import dayjs from 'dayjs';
import { ZodSchema } from 'zod';
import { InfinitePage } from '~/types/schemas/api/infinite-page.schema';
import { trackKeys } from '../query-keys';

export type SortKeys = 'release_date' | 'popularity' | 'relevance';

export type SortType = {
  key: SortKeys;
  direction: -1 | 1;
};

export function useTrackSearch(maxItems?: number): UseInfiniteQueryResult {
  const client = getHttp();
  const queryClient = useQueryClient();
  const search = useSearchStore(['query', 'blocks', 'similarityTrack', 'similarityURL', 'releaseDate', 'popularity', 'bpm', 'sort', 'musicType', 'setFacets', 'setNbResults']);

  const similarityTrackId = search.similarityTrack?.id;
  const similarityTrackURLJobId = search.similarityURL?.jobId;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useInfiniteQuery<InfinitePage<ZodSchema<Track>>, Error, InfinitePage<ZodSchema<Track>>, any, number>({
    queryKey: trackKeys.search(search.blocks, similarityTrackId, similarityTrackURLJobId, search.sort, search.releaseDate, search.popularity, search.bpm, search.query, search.musicType),
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const previousIds = queryClient.getQueryData<InfiniteData<TrackSearchResult>>(queryKey)?.pages.flatMap((page) => page.hits.map((hit) => hit.ref_id));
      const filters = createFiltersFromBlocks(search.blocks);
      const isFilterEmpty = !filters?.and?.length && !search.releaseDate?.from && !search.releaseDate?.to && !search.popularity.from && !search.popularity.to && !search.bpm.from && !search.bpm.to && search.query === '';

      const data = await client.post('searchmode/track/search', {
        json: {
          query: search.query,
          filters: {
            and: [
              ...filters?.and || [],
              ...(search.musicType.length ? [{ music_type: search.musicType }] : []),
              ...(isFilterEmpty ? [{ release_date: { gte: dayjs('1930').startOf('year'), lte: dayjs() } }] : []),
              ...(search.releaseDate.from && search.releaseDate.to ? [{
                release_date: {
                  gte: search.releaseDate.from,
                  lte: search.releaseDate.to,
                },
              }] : []),
              ...(search.popularity.from !== undefined && search.popularity.to !== undefined ? [{
                popularity: {
                  gte: search.popularity.from,
                  lte: search.popularity.to,
                },
              }] : []),
              ...(search.bpm.from !== undefined && search.bpm.to !== undefined ? [{
                bpm: {
                  gte: search.bpm.from,
                  lte: search.bpm.to,
                },
              }] : []),
            ],
            // We exclude the ids of the previous pages to avoid duplicates
            ...(pageParam > 1 && previousIds?.length && {
              nand: [
                { ref_id: previousIds },
              ],
            }),
          },
          ...(!similarityTrackURLJobId && similarityTrackId) && {
            similarity: {
              track: similarityTrackId,
            },
          },
          ...(similarityTrackURLJobId && !similarityTrackId) && {
            similarity: {
              search: similarityTrackURLJobId,
            },
          },
          ...(similarityTrackURLJobId && similarityTrackId) && {
            similarity: {
              track: similarityTrackId,
              search: similarityTrackURLJobId,
            },
          },
          ...search.sort && search.sort !== 'relevance' && {
            sort: {
              key: search.sort,
              direction: -1,
            },
          },
          page: pageParam || 1,
          size: maxItems || 50,
        },
      }).json();

      const parsedData = TrackSearchResultSchema.passthrough().parse(data);

      if (parsedData) {
        search.setFacets(parsedData.facets);

        if (pageParam === 1) search.setNbResults(parsedData.total.value);
      }

      return parsedData;
    },
    getNextPageParam: (lastpage, pages) => {
      return lastpage?.hits.length ? pages.length + 1 : undefined;
    },
    staleTime: 0,
    initialPageParam: 1,
  });
}

function createFiltersFromBlocks(blocks: FilterBlock[]): Filter | undefined {
  const filters = blocks.reduce((acc, block) => {
    const values = block.values.filter((v) => v.value);

    if (values.length) {
      switch (block.condition.value) {
        case 'is':
          acc.push({ [block.term.value]: values.map((v) => v.value) });
          break;
        case 'is_not':
          acc.push({ nor: [{ [block.term.value]: values.map((e) => e.value) }] });
          break;
        case 'eq':
          acc.push({
            [block.term.value]: [
              Math.floor(parseFloat(values[0]?.value as string)),
              Math.ceil(parseFloat(values[0]?.value as string)) - 0.001,
            ],
          });
          break;
        case 'not_eq':
          acc.push({
            nor: [{
              [block.term.value]: [
                Math.floor(parseFloat(values[0]?.value as string)),
                Math.ceil(parseFloat(values[0]?.value as string)),
              ],
            }],
          });
          break;
        default:
          break;
      }
    }

    return acc;
  }, [] as Filter[]);

  if (filters?.length === 0) {
    return undefined;
  }

  return {
    and: filters,
  };
}
