import { MouseEvent, ReactNode, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import IconArrowDown from 'virtual:icons/ri/arrow-down-s-line';
import classNames from 'classnames';
import FilterSection from './filter-section';

type FilterSliderProps = {
  label: string;
  className?: string;
  triggerContent?: ReactNode;
  isActive?: boolean;
  children?: ReactNode;
  onDelete?: (e: MouseEvent) => void;
};

const FilterDropDown = (props: FilterSliderProps) => {
  const { label, className, triggerContent, isActive = false, children, onDelete } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterSection label={label} className={className} isActive={isActive} onDelete={onDelete}>
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenu.Trigger className="flex items-center gap-2 h-6 w-full text-light-500 px-2 bg-dark-100 rounded outline-none">
          {triggerContent}
          <IconArrowDown
            className={classNames(
              'text-xs ml-auto',
              isOpen ? 'rotate-180' : 'rotate-0',
              'transition-all',
            )}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            alignOffset={-8}
            sideOffset={12}
            className="text-xs px-2 py-1 !bg-dark-200 !rounded z-[50] shadow-md"
          >
            {children}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </FilterSection>
  );
};

export default FilterDropDown;
