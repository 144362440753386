import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import { useTrackSummaryContext } from './track-summary.context';

type TrackArtistsProps = {
  onArtistClick?: (artist: DisplayArtist) => void;
};

export function TrackSummaryArtists(props: TrackArtistsProps) {
  const { onArtistClick } = props;
  const { displayArtists } = useTrackSummaryContext();

  const joinedDisplayArtists = displayArtists?.map((artist) => artist.name).join(', ');

  return (
    <Tooltip label={joinedDisplayArtists} side="top" align="start" delayDuration={1000} asChild triggerClassName="inline-block overflow-hidden whitespace-nowrap text-ellipsis w-fit max-w-full line-clamp-1 h-fit">
      <ClickableNameList names={displayArtists} onNameClick={onArtistClick} />
    </Tooltip>
  );
}
