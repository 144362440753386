import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useTrackWaveform } from '~/hooks/entities/track/use-track-waveform';
import { useTrackContext } from './track.context';

export type WaveformProps = {
    className?: string;
};

const WAVEFORM_PLACEHOLDER = 'https://mewo-preprod-api.s3.amazonaws.com/5e5e7fa5394c022e1ad07788/ingestions/waveforms/5e6103148ed15335b2707e40/import/ingestions/audiofiles/5e207d4f749a3512fc3ebcc0/cezame/GMT_8031_CHEMICAL%20CLUE/GMT_8031_021_SCIENCE%20LAB%20_BENJAMIN%20KANIEWSKI_320_300x45.svg';

export const TrackWaveform = (props: WaveformProps) => {
  const { className } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const { isPlaybackTrack, progress, seekTo } = useTrackWaveform();

  const waveformSrc = track.waveform || WAVEFORM_PLACEHOLDER;

  function handleSeek(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    const rect = (e.target as HTMLElement).getBoundingClientRect();

    if (rect) {
      const newProgress = ((e.clientX - rect.left) / rect.width) * 100;
      seekTo(newProgress);
    }
  }

  return (
    <div onClick={handleSeek} className={classNames('waveform relative h-8 w-[12rem] [&_svg]:h-8 [&_svg]:w-fit', className)}>
      <ReactSVG
        src={waveformSrc}
        className="[&_g]:!fill-dark-200 [&_path]:w-full [&_svg]:w-full"
      />
      {isPlaybackTrack && progress ? (
        <ReactSVG
          src={waveformSrc}
          className="absolute inset-0 overflow-hidden [&_g]:!fill-light-500"
          style={{
            width: `${progress}%`,
          }}
        />
      ) : null}
    </div>
  );
};
