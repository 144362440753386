type IconProps = {
  name: string;
  className?: string;
};

const Icon = (props: IconProps) => {
  const { name, className = '' } = props;

  return <i className={`ri-${name} ${className}`} />;
};

export default Icon;
