import * as Track from '~/components/entities/track/common/track';

export function ContentCards() {
  return (
    <div className="flex gap-3 items-stretch">
      <Track.Card.Album />
      <Track.Card.ReleaseDate />
      <Track.Card.Catalog />
    </div>
  );
}
