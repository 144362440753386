import { useTranslation } from 'react-i18next';
import { LyricsDialog } from '~/components/ui/overlays/dialogs/lyrics/lyrics';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as DropdownMenu from '../common/dropdown-menu';

export function TrackActionsLyricsModal() {
  const { t } = useTranslation('track-interactions');
  const store = useTrackContext();
  const track = store.use.track();

  return (
    <LyricsDialog disabled={!track.lyrics}>
      <DropdownMenu.DialogItem icon="ri:mic-line" disabled={!track.lyrics}>
        {t('lyrics.tooltip')}
      </DropdownMenu.DialogItem>
    </LyricsDialog>
  );
}
