import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useTonalityFilter } from '~/hooks/filter/use-tonality-filter';
import useDataStore from '~/stores/mewo-data-store';

export function useTonalityChip() {
  const { onTonalityClick } = useTonalityFilter();
  const data = useDataStore(['tonalities', 'versions']);
  const store = useTrackContext();
  const track = store.use.track();

  const parsedTonality = data.tonalities.object[track.tonality_key!]!;

  const key = parsedTonality?.key.toLocaleUpperCase();
  const tonality = parsedTonality?.tonality;

  function handleClick() {
    onTonalityClick(parsedTonality);
  }

  return {
    label: `${key} ${tonality}`,
    onClick: handleClick,
  };
}
