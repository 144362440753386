/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import ChatList from './components/chat-list/chat-list';
import { useChatList } from './components/chat-list/use-chat-list';
import Chat from './components/chat/chat';

type ThreadsProps = {
  brief: {
    id: string,
    title: string
  }
  onClose: () => void,
  className?: string
};

function BriefChatsPanel(props: ThreadsProps) {
  const { brief, onClose, className } = props;
  const { isLoading, handleChatClick, handleBackClick } = useChatList(brief);
  const { t } = useTranslation(['brief-chat', 'common']);

  useEffect(() => {
    if (!isLoading && activeChannelStore.trackId.value) {
      activeChannelStore.channel.value = briefChatsStore.channels.value.find((channel) => channel.custom?.trackId === activeChannelStore.trackId.value);
      activeChannelStore.trackId.value = undefined;
    }
  }, [isLoading, activeChannelStore.trackId.value]);

  const isListEmpty = !briefChatsStore.channels.value.length;

  return (
    <div
      className={classNames('relative rounded-lg bg-dark-300 w-full h-full overflow-hidden', className)}
    >
      {!activeChannelStore.channel.value ? (
        <div className="flex flex-col gap-2 h-full w-full px-3 py-6">
          <div className="flex justify-between items-center px-3 pb-3">
            <span className="text-xs text-center">{t('threads-list.header')}</span>
            <button type="button" onClick={onClose}>
              <Icon icon="ri:close-line" className="text-white" onClick={onClose} />
            </button>
          </div>
          <div className="self-stretch h-full overflow-y-auto pr-2 scrollbar-on-hover">
            {isLoading && (
              <div className="flex flex-col items-center justify-center h-full">
                <span className="text-sm text-gray-400">{t('loading', { ns: 'common' })}</span>
              </div>
            )}
            {isListEmpty && !isLoading
              ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <span className="text-sm text-gray-400">{t('threads-list.list-empty')}</span>
                </div>
              )
              : <ChatList onClick={handleChatClick} />
            }
          </div>
        </div>
      ) : <Chat trackId={activeChannelStore.channel.value.custom!.trackId} onChatExit={handleBackClick} />}
    </div>
  );
}

export default React.memo(BriefChatsPanel);
