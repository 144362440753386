import { useComputed } from '@preact/signals-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from '~/boot/react-query';
import { Alert } from '~/components/ui/notifications/alert/alert';
import { useGetBriefTracks } from '~/hooks/api/brief';
import { briefRoutes } from '~/routes/api';
import { briefStore } from '~/stores/briefs-store';
import { pathToUrl } from '~/utils/path-to-url';

type IngestLoadingProps = {
    id: string;
};

export function IngestLoading(props: IngestLoadingProps) {
  const { id } = props;
  const { ingest, removeIngestTrack, completeIngest } = briefStore;
  const { data: briefTracks } = useGetBriefTracks(id);

  const briefId = useComputed(() => ingest.value?.briefId).value;
  const processingTracks = useComputed(() => ingest.value?.tracks).value;
  const hits = briefTracks?.pages.flatMap((page) => page?.hits);
  const isAllTrackReady = !hits?.length || !!hits?.every((hit) => !!hit.waveform && !!hit.duration);
  const { t } = useTranslation('alert');

  // Check if the processing tracks are in the brief tracks
  useEffect(() => {
    const trackStatuses = hits?.map((hit) => {
      const track = processingTracks?.find((processingTrack) => {
        if (processingTrack.title === hit.title && processingTrack.artists?.join(', ') === hit.display_artists.map((artist) => artist.name).join(', ') && processingTrack.version === hit.version) {
          queryClient.invalidateQueries({ queryKey: [pathToUrl(briefRoutes.getThreadChannelParticipants, { id: briefId || 'no-id', trackId: hit.id })] });
          return true;
        }
        return false;
      });
      if (track) removeIngestTrack(track);
      return !track;
    });

    const isEveryTrackAdded = trackStatuses?.every((status) => status);

    if (isEveryTrackAdded) {
      queryClient.invalidateQueries({ queryKey: [pathToUrl(briefRoutes.brief, { id: briefId || 'no-id' })] });
      queryClient.invalidateQueries({ queryKey: [pathToUrl(briefRoutes.getThreadChannels, { id: briefId || 'no-id' })] });
    }
  }, [briefTracks]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!processingTracks?.length && isAllTrackReady) {
      completeIngest();
    } else {
      interval = setInterval(() => {
        queryClient.invalidateQueries({ queryKey: [pathToUrl(briefRoutes.tracks, { id })] });
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [briefId, processingTracks, isAllTrackReady]);

  if (!briefId || briefId !== id || !processingTracks) return null;

  return !!processingTracks.length && (
    <Alert type="info" title={t('ingest-progress.title')} message={t('ingest-progress.message', { 'track-count': processingTracks.length })} />
  );
}
