import classNames from 'classnames';
import { BriefFormUpdate, ProviderGroup } from '~/types/schemas/music/brief.schema';
import { Control, Controller } from 'react-hook-form';
import { Agent as ProviderType } from '~/types/schemas/common/agent.schema';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input-controls/input/input';
import Icon from '~/components/ui/icon/icon';
import Checkbox from '~/components/ui/forms/input-controls/checkbox/checkbox';
import Provider from './provider';
import SelectFilter from './select';
import { useProviderList } from '../../hooks/use-provider-list';

type ProviderListProps = {
  providers?: ProviderType[];
  defaultProvidersValue: ProviderGroup[];
  groupTitle?: string;
  groupIndex: number;
  control: Control<BriefFormUpdate>
  onBack?: () => void;
};

function ProviderList(props: ProviderListProps) {
  const { t } = useTranslation('brief-form');
  const { providers: defaultProviders = [], defaultProvidersValue, groupIndex, groupTitle, control, onBack } = props;
  const { providers, selectedCategories, favoriteAgents, isFavoriteSelected, toggleFavorites, setCategories, handleCheckClick, handleMainCheckClick, handleFavoriteClick, setQuery: searchByQuery } = useProviderList(defaultProviders);

  return (
    <div className="flex flex-col items-stretch gap-4 form-content h-full">
      <div className="flex flex-col gap-3 mb-3">
        <div className="flex gap-3 h-9">
          <button type="button" className="flex gap-3 p-2 items-center text-sm justify-center h-9 w-fit shrink-0 rounded bg-primary-500 border border-transparent cursor-pointer hover:border-dark-100" onClick={onBack}>
            <Icon name="arrow-left-line" className="text-light-500 text-base" />
            {t('step-three.providers-group.edit.back-button.label')}
          </button>
          <Input type="text" placeholder={t('step-three.providers-group.edit.input.placeholder', { type: groupTitle?.toLocaleLowerCase() })} containerClassName="h-full flex-1" iconLeft="search-line" onChange={(value) => searchByQuery(value)} />
        </div>
        <div className="flex gap-3">
          <div className="flex items-center justify-center h-7 w-9 rounded bg-dark-300 border border-transparent hover:border-dark-100">
            <Controller
              name="agents"
              control={control}
              defaultValue={defaultProvidersValue || []}
              render={({ field: { value: groups, onChange } }) => {
                const selectedProviders = groups?.[groupIndex]?.providers || [];
                const isSomeChecked = providers.some((provider) => selectedProviders?.includes(provider.id));
                const isAllChecked = providers.every((provider) => selectedProviders?.includes(provider.id));
                return (
                  <Checkbox
                    // eslint-disable-next-line no-nested-ternary
                    checked={isSomeChecked ? (isAllChecked ? true : 'indeterminate') : false}
                    onChange={() => handleMainCheckClick(onChange, groups || [], groupIndex)}
                  />
                );
              }}
            />
          </div>
          <SelectFilter
            options={selectedCategories}
            onChange={setCategories}
            placeholder={t('step-three.providers-group.edit.filter.provider-type.placeholder')}
          />
          <Button
            className={classNames('flex font-normal !bg-dark-300 hover:!bg-dark-200 !h-7 !text-sm', {
              '!text-light-100': isFavoriteSelected,
              '!text-light-500': !isFavoriteSelected,
            })}
            onClick={toggleFavorites}
          >
            {t('step-three.providers-group.edit.filter.favorites')}
            <Icon
              name={`heart-${isFavoriteSelected ? 'fill' : 'line'}`}
              className={classNames('transition-colors', {
                'text-error-500': isFavoriteSelected,
                'text-light-500': !isFavoriteSelected,
              })}
            />
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-3 h-full overflow-y-auto hide-scrollbar pb-16">
        {providers?.map((provider) => (
          <Controller
            key={provider.id}
            name="agents"
            control={control}
            defaultValue={defaultProvidersValue}
            render={({ field: { value: groups, onChange } }) => {
              const groupSelectedProviders = groups?.[groupIndex]?.providers || [];

              return (
                <Provider
                  key={provider.id}
                  className={classNames('form-content__row')}
                  title={provider.company_name}
                  categories={provider.organization_settings.meta.provider_types
                    .filter((type) => type === 'independent' || type === 'major' || type === 'production')
                    .map((item) => (item === 'independent' || item === 'production'
                      ? t('step-three.providers-group.edit.filter.provider-type.independent')
                      : t('step-three.providers-group.edit.filter.provider-type.major')))
                    .join(', ')}
                  checked={groupSelectedProviders?.includes(provider.id)}
                  isFavorite={favoriteAgents?.includes(provider.id)}
                  onCheckClick={() => handleCheckClick(provider.id, onChange, groups || [], groupIndex)}
                  onFavClick={() => handleFavoriteClick(provider.id)}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProviderList;
