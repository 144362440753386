import classNames from 'classnames';
import { useTrackSummaryChip } from '~/hooks/entities/track/summary/use-track-summary-chip';

export function TrackSummaryStatusChip() {
  const { status } = useTrackSummaryChip();

  return status ? (
    <div className={classNames('text-xs h-4 w-fit px-1 rounded select-none font-semibold', {
      [`bg-${status?.color}`]: status?.color,
    })}
    >
      {status?.label}
    </div>
  ) : null;
}
