import classNames from 'classnames';
import * as Track from '~/components/entities/track/common/track';
import { SummaryArtist } from './artists';
import { SummaryControl } from './control';

type TrackItemHeaderSummaryProps = {
  className?: string
};

export function TrackItemHeaderSummary(props: TrackItemHeaderSummaryProps) {
  const { className } = props;

  return (
    <Track.Summary.Root className={classNames('flex items-center gap-3 h-10 w-full overflow-hidden', className)}>
      <div className="group/track-item-cover size-10 aspect-square relative">
        <Track.Summary.Cover />
        <SummaryControl />
      </div>
      <div className="flex-1 overflow-hidden flex flex-col justify-center">
        <div className="flex items-center gap-2">
          <Track.Summary.StatusChip />
          <Track.Summary.Title className="w-fit max-w-full" />
        </div>
        <SummaryArtist />
      </div>
    </Track.Summary.Root>
  );
}
