import { userRoutes } from '~/routes/api';
import { globalStore } from '~/stores/global-store-v2';
import { User, UserSchema } from '~/types/schemas/auth/user';
import { api } from '~/utils/api';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';

export function useLogin() {
  return useGenericMutation<{email: string, password: string}, {user: User, token: string}>({
    url: userRoutes.login,
    func: async (data) => {
      const userData = await api.post(userRoutes.login, {
        json: data,
      });

      const user = UserSchema
        .passthrough()
        .parse(await userData.json());

      const token = userData.headers
        .get('x-auth')!;

      return { user, token };
    },
  });
}

export function useLogout() {
  return useGenericMutation({
    url: userRoutes.logout,
    func: () => api.post(userRoutes.logout),
  });
}

export function useGetUser() {
  const { setUser } = globalStore;

  return useFetch<User>({
    url: userRoutes.user,
    schema: UserSchema,
    onSuccess: (data) => {
      setUser(data);
    },
    staleTime: 0,
  });
}
