import './volume.scss';
import { useTranslation } from 'react-i18next';
import Slider from '~/components/ui/control/slider/slider';
import * as Track from '~/components/entities/track/common/track';

export interface VolumeProps {
  volume: number
  muted: boolean
  className?: string
  toggleMute: () => void
  onVolumeChange: (value: number) => void
}

export const TrackVolume = (props: VolumeProps) => {
  const { volume, onVolumeChange, toggleMute, muted, className } = props;
  const { t } = useTranslation('player');

  const handleVolumeIcon = () => {
    if (muted) {
      return 'ri:volume-mute-line';
    } if (volume > 0 && volume <= 50) {
      return 'ri:volume-down-line';
    }
    return 'ri:volume-up-line';
  };

  const handleVolumeChange = ([v]: number[]) => {
    if (v !== undefined) {
      onVolumeChange(v);
    }
  };

  return (
    <div className={`volume flex gap-2 items-center ${className}`}>
      <Track.Action
        icon={handleVolumeIcon()}
        tooltip={muted ? t('control.unmute.tooltip') : t('control.mute.tooltip')}
        onClick={toggleMute}
      />
      <Slider
        max={100}
        min={0}
        value={[volume]}
        className="!w-24"
        onValueChange={handleVolumeChange}
      />
    </div>
  );
};
