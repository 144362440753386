/* eslint-disable indent */
import useDataStore from '~/stores/mewo-data-store';
import { Tag, TagCategory } from '~/types/schemas/data/tags.schema';

export function useTagPath() {
    const { categories, subCategories } = useDataStore(['categories', 'subCategories']);

    function findTagPath(tag: Tag): string {
        let path;
        const parent: (TagCategory & { category?: string }) | undefined = subCategories.object[tag.category] || categories.object[tag.category];
        const grandParent = parent?.category && (subCategories.object[parent.category] || categories.object[parent.category]);

        if (grandParent) {
            path = `${grandParent.name} > ${parent.name}`;
        } else {
            path = `${parent?.name}`;
        }

        return path || '';
    }

    return { findTagPath };
}
