import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ListHeaderProps = PropsWithChildren<{
  className?: string
}>;

export function ListHeader(props: ListHeaderProps) {
  const { className, children } = props;

  return (
    <div className={classNames('sticky top-0 bg-dark-400 z-10 p-2 border-b border-dark-200', className)}>
      {children}
    </div>
  );
}
