import { createWithEqualityFn } from 'zustand/traditional';
import { nanoid } from 'nanoid';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import i18n from '~/i18n/config';
import { ParsedFilter } from '~/components/features/search-filters/utils/parse-filters';
import { createStorePicker } from './utilities';
import {
  Filter,
  FilterKeys,
  numberConditionKeys,
} from '../types/schemas/search/filter.schema';
import { OptionType } from '../components/ui/forms/select/select';
import { withImmer } from './middlewares/immer-combine';
import { Facet } from '../types/schemas/music/track.schema';

import { parseSearchParams, stringifySearchParams } from '../utils/common/search-params/stringify-search-params';

export type FilterTerm = {
  value: FilterKeys;
  label: string;
  type: 'item' | 'number';
};

export type FilterCondition = {
  value: 'is' | 'is_not' | numberConditionKeys;
  label: string;
};

export type BlockValue = OptionType & {
  id: string;
  blockId: string;
};

export type FilterBlock = {
  id: string;
  term: FilterTerm;
  condition: FilterCondition;
  values: BlockValue[];
};

export type SimilarityTrackType = {
  id: string | undefined;
  name: string | undefined;
  artists: string | undefined;
};

export type SimilarityURLType = {
  jobId: string;
  title: string;
  youtubeId: string;
};

export type ReleaseDateRangeType = {
  from?: string;
  to?: string;
};

export type PopularityRangeType = {
  from?: number;
  to?: number;
};

export type BpmRangeType = {
  from?: number;
  to?: number;
};

const defaultValues: BlockValue[] = [{
  id: 'core',
  value: 'core',
  label: 'Core',
  blockId: 'search-type',
}, {
  id: 'production',
  value: 'production',
  label: 'Production',
  blockId: 'search-type',
}];

const defaultSimilarityTrack = {
  id: undefined,
  name: undefined,
  artists: undefined,
};

const defaultRange = {
  from: undefined,
  to: undefined,
};

const defaultMusicType = ['core', 'production'];

const defaultBlocks: FilterBlock[] = [];

export const useSearchStore = createStorePicker(createWithEqualityFn(
  withImmer({
    query: '' as string,
    values: defaultValues as BlockValue[],
    blocks: defaultBlocks as FilterBlock[],
    parsedFilter: undefined as Filter | undefined,
    musicType: defaultMusicType as Array<string>,
    similarityURL: undefined as SimilarityURLType | undefined,
    similarityTrack: {
      id: undefined as string | undefined,
      name: undefined as string | undefined,
      artists: undefined as string | undefined,
    },
    facets: undefined as Facet | undefined,
    nbResults: 0 as number,
    nbFilters: 0 as number,
    releaseDate: defaultRange as ReleaseDateRangeType,
    popularity: defaultRange as PopularityRangeType,
    bpm: defaultRange as BpmRangeType,
    searchParams: undefined as string | undefined,
    sort: 'release_date' as string,
    writeToURL: false,
    isSearchLoading: false,
  })((set, get) => ({
    addValueToMatchingBlock: (term: FilterTerm, condition: FilterCondition, value: OptionType): BlockValue => {
      let next: BlockValue | undefined;

      set((state) => {
        const block = state.blocks.find((b) => b.term.value === term.value && b.condition.value === condition.value) || {
          id: nanoid(),
          term,
          condition,
          values: [],
        };

        if (!block.values.find((v) => v.value === value.value)) {
          next = {
            ...value,
            id: nanoid(),
            blockId: block.id,
          };

          block.values.push(next);

          state.values.push(next);
          state.writeToURL = true;
        }

        if (!state.blocks.find((b) => b.id === block.id)) {
          state.blocks.push(block);
          state.writeToURL = true;
        }
      });

      return next!;
    },
    addValueToNewBlock: (term: FilterTerm, condition: FilterCondition, value: OptionType): BlockValue => {
      let next: BlockValue | undefined;

      set((state) => {
        const block: FilterBlock = {
          id: nanoid(),
          term,
          condition,
          values: [],
        };

        if (!state.blocks.find((b) =>
          b.term.value === term.value
          && b.condition.value === condition.value
          && b.values.find((v) => v.value === value.value))) {
          next = {
            ...value,
            id: nanoid(),
            blockId: block.id,
          };

          block.values.push(next);

          state.values.push(next);
          state.blocks.push(block);

          state.writeToURL = true;
        }
      });

      return next!;
    },
    removeBlock: (blockId: string) => set((state) => {
      const block = state.blocks.find((b) => b.id === blockId);

      if (block) {
        block.values.forEach((value) => {
          state.values = state.values.filter((v) => v.id !== value.id);
        });

        state.blocks = state.blocks.filter((b) => b.id !== blockId);

        state.writeToURL = true;
      }
    }),
    removeValue: (valueId: string) => set((state) => {
      const value = state.values.find((v) => v.id === valueId);

      if (value) {
        const block = state.blocks.find((b) => b.id === value.blockId);

        if (block) {
          block.values = block.values.filter((v) => v.id !== valueId);

          if (block.values.length === 0) {
            state.blocks = state.blocks.filter((b) => b.id !== block.id);
          }
        }

        state.values = state.values.filter((v) => v.id !== valueId);

        state.writeToURL = true;
      }
    }),
    changeBlockTerm: (blockId: string, newTerm: FilterTerm) => set((state) => {
      const block = state.blocks.find((b) => b.id === blockId);

      if (block) {
        block.term = newTerm;

        switch (newTerm.type) {
          case 'item':
            block.condition = { value: 'is', label: 'Is' };
            break;
          case 'number':
            block.condition = { value: 'is', label: 'Equal To' };
            break;
          default:
            break;
        }

        state.writeToURL = true;
      }
    }),
    changeBlockCondition: (blockId: string, condition: FilterCondition) => set((state) => {
      const block = state.blocks.find((b) => b.id === blockId);

      if (block) {
        block.condition = condition;

        state.writeToURL = true;
      }
    }),
    changeValue: (valueId: string, newValue: OptionType) => set((state) => {
      const value = state.values.find((v) => v.id === valueId);

      if (value) {
        value.value = newValue.value;
        value.label = newValue.label;

        state.writeToURL = true;
      }
    }),
    toggleBlockCondition: (blockId: string) => set((state) => {
      const block = state.blocks.find((b) => b.id === blockId);

      if (block) {
        switch (block.condition.value) {
          case 'is':
            block.condition = itemConditionsOptions.is_not!;
            break;
          case 'is_not':
            block.condition = itemConditionsOptions.is!;
            break;
          default:
            break;
        }

        state.writeToURL = true;
      }
    }),
    toggleBlockValueCondition: (blockId: string, filter: ParsedFilter) => set((state) => {
      const block = state.blocks.find((b) => b.id === blockId);
      const { value } = filter;
      let newBlock: FilterBlock | undefined;

      if (block) {
        switch (block.condition.value) {
          case 'is':
            newBlock = state.blocks.find((b) => b.condition.value === 'is_not' && b.term.value === block.term.value);
            break;
          case 'is_not':
            newBlock = state.blocks.find((b) => b.condition.value === 'is' && b.term.value === block.term.value);
            break;
          case 'eq':
            newBlock = state.blocks.find((b) => b.condition.value === 'not_eq' && b.term.value === block.term.value);
            break;
          case 'not_eq':
            newBlock = state.blocks.find((b) => b.condition.value === 'eq' && b.term.value === block.term.value);
            break;
          default:
            break;
        }

        const newBlockId = newBlock ? newBlock.id : nanoid();
        let newValue: BlockValue | undefined;

        if (newBlock) {
          newValue = {
            blockId: newBlockId,
            id: nanoid(),
            value: value.value,
            label: value.label,
          };

          newBlock.values.push(newValue);
        } else {
          newValue = {
            blockId: newBlockId,
            id: nanoid(),
            value: value.value,
            label: value.label,
          };

          let condition: FilterCondition | undefined;

          if (block.condition.value === 'is' || block.condition.value === 'is_not') {
            condition = block.condition.value === 'is' ? itemConditionsOptions.is_not! : itemConditionsOptions.is!;
          } else {
            condition = block.condition.value === 'eq' ? numberConditionsOptions.not_eq! : numberConditionsOptions.eq!;
          }

          state.blocks.push({
            id: newBlockId,
            term: block.term,
            condition,
            values: [newValue],
          });
        }

        block.values = block.values.filter((v) => v.id !== value.id);
        state.values = state.values.filter((v) => v.id !== value.id);
        state.values.push(newValue);

        state.writeToURL = true;
      }
    }),
    writeFiltersToURL: (setParams: ReturnType<typeof useSearchParams>[1]) => {
      const { blocks, query, similarityTrack, similarityURL, releaseDate, popularity, bpm, musicType, sort } = get();
      const q = stringifySearchParams({
        blocks,
        query,
        similarityTrack,
        similarityURL,
        releaseDate,
        popularity,
        bpm,
        musicType,
        sort,
      });

      setParams({ q });

      set((state) => {
        const searchParams = new URLSearchParams(q);

        state.searchParams = encodeURIComponent(searchParams.toString());
        state.writeToURL = false;
      });
    },
    readFiltersFromURL: (data: string | null) => {
      const filters = data ? parseSearchParams(data) : null;
      let nbFilters = 0;

      set((state) => {
        if (filters) {
          state.blocks = filters.blocks;
          state.values = state.blocks.flatMap((block) => {
            nbFilters += 1;
            return block.values;
          });
        } else {
          state.blocks = defaultBlocks;
          state.releaseDate = defaultRange;
          state.popularity = defaultRange;
          state.musicType = ['core', 'production'];
        }

        if (filters?.query) {
          state.query = filters.query;
        } else {
          state.query = '';
        }

        if (filters?.similarityTrack) {
          state.similarityTrack = {
            id: filters.similarityTrack.id,
            name: filters.similarityTrack.name,
            artists: filters.similarityTrack.artists,
          };
          nbFilters += 1;
        } else {
          state.similarityTrack = defaultSimilarityTrack;
        }

        if (filters?.similarityURL) {
          state.similarityURL = {
            ...filters.similarityURL,
          };
          nbFilters += 1;
        } else {
          state.similarityURL = undefined;
        }

        if (filters?.releaseDate) {
          const releaseDate = {
            from: (filters.releaseDate.from && dayjs(filters.releaseDate.from).toISOString()),
            to: (filters.releaseDate.to && dayjs(filters.releaseDate.to).toISOString()),
          };
          state.releaseDate = releaseDate;
          if (JSON.stringify(releaseDate) !== JSON.stringify(defaultRange)) nbFilters += 1;
        }

        if (filters?.popularity) {
          state.popularity = {
            from: filters.popularity.from,
            to: filters.popularity.to,
          };
          if (JSON.stringify(filters.popularity) !== JSON.stringify(defaultRange)) nbFilters += 1;
        }

        if (filters?.bpm) {
          state.bpm = {
            from: filters.bpm.from,
            to: filters.bpm.to,
          };
          if (JSON.stringify(filters.bpm) !== JSON.stringify(defaultRange)) nbFilters += 1;
        }

        if (filters?.musicType) {
          state.musicType = filters.musicType;
          if (JSON.stringify(filters.musicType) !== JSON.stringify(defaultMusicType)) nbFilters += 1;
        }

        if (filters?.sort) {
          state.sort = filters.sort;
        }

        state.nbFilters = nbFilters;
      });
    },
    setSimilarityTrack: (id: string, title: string, artists: string) => set((state) => {
      if (id && title && state.similarityTrack) {
        state.similarityTrack.id = id;
        state.similarityTrack.name = title;
        state.similarityTrack.artists = artists || '';
        state.similarityURL = undefined;
      } else {
        state.similarityTrack = defaultSimilarityTrack;
      }

      state.writeToURL = true;
    }),
    resetSimilarityTrack: () => set((state) => {
      state.similarityTrack = defaultSimilarityTrack;
      state.writeToURL = true;
    }),
    setReleaseDate: (from: number, to: number) => set((state) => {
      const today = dayjs().endOf('day');

      state.releaseDate.from = from ? dayjs().year(from).startOf('year').toISOString() : undefined;
      state.releaseDate.to = to ? (to !== today.year() ? dayjs().year(to).endOf('year') : today).toISOString() : undefined;

      state.writeToURL = true;
    }),
    setPopularity: (from: number, to: number) => set((state) => {
      state.popularity.from = from;
      state.popularity.to = to;

      state.writeToURL = true;
    }),
    setBpm: (from: number, to: number) => set((state) => {
      state.bpm.from = from;
      state.bpm.to = to;

      state.writeToURL = true;
    }),
    setFacets: (facets?: Facet) => set((state) => {
      state.facets = facets;
    }),
    setSimilarityURL: (youtubeId: string, title: string, jobId: string) => set((state) => {
      state.similarityURL = {
        youtubeId,
        title,
        jobId,
      };
      state.similarityTrack = defaultSimilarityTrack;

      state.writeToURL = true;
    }),
    setNbFilters: (nbFilters: number) => set((state) => {
      state.nbFilters = nbFilters;
    }),
    setMusicType: (musicType: Array<string>) => set((state) => {
      state.musicType = musicType;

      state.writeToURL = true;
    }),
    setSearchParams: (searchParams: string) => set((state) => {
      state.searchParams = searchParams;

      state.writeToURL = true;
    }),
    setNbResults: (nbResults: number) => set((state) => {
      state.nbResults = nbResults;
    }),
    resetSimilarityURL: () => set((state) => {
      state.similarityURL = undefined;
      state.writeToURL = true;
    }),
    resetFilters: () => set((state) => {
      state.query = '';
      state.blocks = defaultBlocks;
      state.values = defaultValues;
      state.similarityTrack = defaultSimilarityTrack;
      state.similarityURL = undefined;
      state.musicType = ['core', 'production'];
      state.releaseDate = defaultRange;
      state.popularity = defaultRange;
      state.bpm = defaultRange;

      state.writeToURL = true;
    }),
    resetBpm: () => set((state) => {
      state.bpm = defaultRange;

      state.writeToURL = true;
    }),
    resetPopularity: () => set((state) => {
      state.popularity = defaultRange;

      state.writeToURL = true;
    }),
    resetReleaseDate: () => set((state) => {
      state.releaseDate = defaultRange;

      state.writeToURL = true;
    }),
    setQuery: (query: string) => set((state) => {
      state.query = query;

      state.writeToURL = true;
    }),
    setIsSearchLoading: (isLoading: boolean) => set((state) => {
      state.isSearchLoading = isLoading;
    }),
    setValues: (values?: BlockValue[]) => set((state) => {
      if (values) {
        state.values = values;
      } else {
        state.values = defaultValues;
      }

      state.writeToURL = true;
    }),
    setSort: (sort: string) => set((state) => {
      state.sort = sort;

      state.writeToURL = true;
    }),
    setWriteHistoryFalse: () => set((state) => {
      state.writeToURL = false;
    }),
  })),
));

export const termOptions: Record<string, FilterTerm> = {
  album: {
    label: 'Albums',
    value: 'album',
    type: 'item',
  },
  master_artists: {
    label: 'Artists',
    value: 'master_artists',
    type: 'item',
  },
  artists: {
    label: 'Artists',
    value: 'artists',
    type: 'item',
  },
  tags: {
    label: 'Tags & Audiences',
    value: 'tags',
    type: 'item',
  },
  tag_categories: {
    label: 'Tags & Audiences categories',
    value: 'tag_categories',
    type: 'item',
  },
  bpm: {
    label: 'BPM',
    value: 'bpm',
    type: 'number',
  },
  catalog: {
    label: 'Catalog',
    value: 'catalog',
    type: 'item',
  },
  playlists: {
    label: 'Playlist',
    value: 'internal_refs',
    type: 'item',
  },
  music_type: {
    label: 'Music type',
    value: 'music_type',
    type: 'item',
  },
  version: {
    label: 'Version',
    value: 'version',
    type: 'item',
  },
  tenant: {
    label: 'Tenant',
    value: 'tenant',
    type: 'item',
  },
  labels: {
    label: 'Labels',
    value: 'labels',
    type: 'item',
  },
  publishers: {
    label: 'Publisher',
    value: 'publishers',
    type: 'item',
  },
  internal_refs: {
    label: 'Playlist/Brief',
    value: 'internal_refs',
    type: 'item',
  },
  tonality_key: {
    label: 'Tonality Key',
    value: 'tonality_key',
    type: 'item',
  },
  id: {
    label: 'Track',
    value: 'id',
    type: 'item',
  },
};

export const itemConditionsOptions: Record<string, FilterCondition> = {
  is: {
    label: 'Is',
    value: 'is',
  },
  is_not: {
    label: 'Is Not',
    value: 'is_not',
  },
};

export const numberConditionsOptions: Record<string, FilterCondition> = {
  eq: {
    label: 'Equal To',
    value: 'eq',
  },
  not_eq: {
    label: 'Not Equal To',
    value: 'not_eq',
  },
  gt: {
    label: 'Greater Than',
    value: 'gt',
  },
  lt: {
    label: 'Less Than',
    value: 'lt',
  },
  gte: {
    label: 'Greater or Equal To',
    value: 'gte',
  },
  lte: {
    label: 'Less or Equal To',
    value: 'lte',
  },
};

export function translateFilters() {
  termOptions.album!.label = i18n.t('albums.title', { ns: 'filter' });
  termOptions.master_artists!.label = i18n.t('master-artists.title', { ns: 'filter' });
  termOptions.artists!.label = i18n.t('artists.title', { ns: 'filter' });
  termOptions.tags!.label = i18n.t('tags-audiences.title', { ns: 'filter' });
  termOptions.tag_categories!.label = i18n.t('tags-audiences-categories.title', { ns: 'filter' });
  termOptions.bpm!.label = i18n.t('bpm.title', { ns: 'filter' });
  termOptions.catalog!.label = i18n.t('catalogs.title', { ns: 'filter' });
  termOptions.playlists!.label = i18n.t('playlists.title', { ns: 'filter' });
  termOptions.music_type!.label = i18n.t('music-type.title', { ns: 'filter' });
  termOptions.version!.label = i18n.t('versions.title', { ns: 'filter' });
  termOptions.tenant!.label = i18n.t('tenants.title', { ns: 'filter' });
  termOptions.labels!.label = i18n.t('labels.title', { ns: 'filter' });
  termOptions.publishers!.label = i18n.t('publishers.title', { ns: 'filter' });
  termOptions.internal_refs!.label = i18n.t('playlists-briefs.title', { ns: 'filter' });
  termOptions.tonality_key!.label = i18n.t('tonality-key.title', { ns: 'filter' });
  termOptions.id!.label = i18n.t('tracks.title', { ns: 'filter' });
  itemConditionsOptions.is!.label = i18n.t('item.condition.is', { ns: 'filter' });
  itemConditionsOptions.is_not!.label = i18n.t('item.condition.is-not', { ns: 'filter' });
  numberConditionsOptions.eq!.label = i18n.t('item.condition.equal', { ns: 'filter' });
  numberConditionsOptions.not_eq!.label = i18n.t('item.condition.not-equal', { ns: 'filter' });
  numberConditionsOptions.gt!.label = i18n.t('item.condition.greater', { ns: 'filter' });
  numberConditionsOptions.lt!.label = i18n.t('item.condition.less', { ns: 'filter' });
  numberConditionsOptions.gte!.label = i18n.t('item.condition.greater-equal', { ns: 'filter' });
  numberConditionsOptions.lte!.label = i18n.t('item.condition.less-equal', { ns: 'filter' });
}
