import { Brief } from '~/types/schemas/music/brief.schema';
import { Playlist, PlaylistUpdateAPI } from '~/types/schemas/music/playlists.schema';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';

export function playlistUpdater(
  oldPlaylist: Playlist,
  newPlaylist: PlaylistUpdateAPI,
): Playlist {
  return {
    ...oldPlaylist,
    ...newPlaylist,
  };
}

export function tracklistUpdater(
  oldTracklist: Tracklist,
  newTracklist: Playlist | Brief,
): Tracklist {
  return {
    ...oldTracklist,
    value: {
      ...oldTracklist.value,
      ...newTracklist,
    },
  } as Tracklist;
}
