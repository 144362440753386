import * as Track from '~/components/entities/track/common/track';
import { usePlayTrack } from '~/hooks/music/use-player';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { MouseEvent } from 'react';

export function SummaryControl() {
  const store = useTrackContext();
  const track = store.use.track();
  const context = store.use.context();

  const playTrack = usePlayTrack();

  function handlePlay(e:MouseEvent) {
    e.stopPropagation();
    playTrack({ track, context });
  }

  return (
    <Track.Summary.Controls onTogglePlay={handlePlay} playingIcon="svg-spinners:bars-scale-middle" />

  );
}
