import { ColoredTagChip } from '~/components/ui/control/tag-chip/colored-tag-chip';
import { TagChip } from '../../ui/control/tag-chip/tag-chip';

export interface TagProps {
  id: string;
  name: string;
  synonyms?: string[];
  color?: string;
  relevant?: boolean;
  onTagAddClick?: (id: string, name: string) => void;
  onTagSubtractClick?: (id: string, name: string) => void;
  onTagChipClick?: (id: string, name: string) => void;
  tagSuffix?: string;
}

export function ColoredTag(props: TagProps) {
  const { id, name, color, onTagChipClick, tagSuffix } = props;

  function handleChipClick() {
    onTagChipClick?.(id, name);
  }

  return (
    <ColoredTagChip
      label={`${name}${`${tagSuffix ? ` (${tagSuffix})` : ''}`}`}
      color={color}
      onClick={handleChipClick}
    />
  );
}
