import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import Checkbox, { CheckedState } from '~/components/ui/forms/input-controls/checkbox/checkbox';
import Button from '~/components/ui/control/button/button';
import Icon from '~/components/ui/icon/icon';
import { useTranslation } from 'react-i18next';

type GroupProps = {
  title?: string
  subTitle?: string
  checked?: CheckedState
  disabled?: boolean
  isFavoriteCheck?: boolean
  className?: string
  onEditClick?: () => void
  onCheckClick?: (b: CheckedState) => void
  onFavoriteClick?: () => void
};

function Group(props: GroupProps) {
  const { t } = useTranslation('brief-form');
  const { title = 'Omnis molestias qui', subTitle = 'Lorem ipsum', checked = false, disabled = false, isFavoriteCheck = false, className, onEditClick, onCheckClick, onFavoriteClick } = props;
  const [isChecked, setIsChecked] = useState(checked);

  function handleEditClick(e: MouseEvent) {
    e.stopPropagation();
    onEditClick?.();
  }

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    setIsChecked?.(isChecked === 'indeterminate' ? false : !isChecked);
  }

  function handleFavClick(e: MouseEvent) {
    e.stopPropagation();
    onFavoriteClick?.();
  }

  function handleChange(s: CheckedState) {
    setIsChecked(s);
  }

  useEffect(() => {
    if (isChecked !== checked) onCheckClick?.(isChecked === 'indeterminate' ? false : !isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <div
      className={classNames('group flex cursor-pointer select-none justify-between items-center rounded bg-dark-300 w-full px-3 py-2 transition-all border border-transparent hover:border-dark-100', className, {
        'border !border-primary-500': isChecked,
        '!opacity-50 pointer-events-none': disabled,
      })}
      onClick={handleClick}
    >
      <div className="group__left flex gap-4 flex-[2]">
        <Checkbox checked={isChecked} onChange={handleChange} />
        <div className="flex flex-col">
          <h6 className="text-light-100 text-sm ">{title}</h6>
          <p className="text-light-500 text-xs">{subTitle}</p>
        </div>
      </div>
      {isChecked ? (
        <Button
          onClick={handleFavClick}
          className={classNames('border border-dark-100 bg-transparent hover:bg-transparent hover:bg-dark-300!', {
            'bg-primary-500 hover:bg-primary-600': isChecked,
          })}
        >
          {
            isFavoriteCheck
              ? <Icon name="heart-fill" className="text-error-500" />
              : <Icon name="heart-line" className="text-light-500" />
          }
          {t('step-three.providers-group.favorites')}
        </Button>
      ) : <div />}
      <div className="group__right flex flex-col items-end flex-1">
        <button
          type="button"
          className={classNames('group__right__edit')}
          onClick={handleEditClick}
        >
          <Icon name="pencil-line" className="text-light-500 hover:text-light-100" />
        </button>
      </div>
    </div>
  );
}

export default Group;
