import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import Icon from '~/components/ui/icon/icon';
import { activeChannelStore } from '~/stores/chats-store';
import { useMessageTime } from '../use-message-time';

type ReaderProps = {
    userId: string;
    timetoken: string;
};

function Reader(props: ReaderProps) {
  const { userId, timetoken } = props;
  const { participants } = activeChannelStore;
  const time = useMessageTime(timetoken);
  const user = participants.value[userId];

  return user ? (
    <Tooltip label={`Seen by ${user.first_name} ${user.last_name} at ${time}`}>
      <div className="text-xxs flex justify-center items-center rounded-full overflow-hidden bg-dark-100 h-4 w-4 text-light-400">
        {user.organization_settings.avatar ? <img src={user.organization_settings.avatar} alt={`${user.first_name} ${user.last_name}`} className="w-full h-full" /> : (
          <Icon name="user-line" />
        )}
      </div>
    </Tooltip>
  ) : null;
}

export default Reader;
