import { Track } from '~/types/schemas/music/track.schema';
import { useTranslation } from 'react-i18next';
import { useSimilarTracks } from '../../hooks/use-similar-track';
import TrackInteraction from '../interaction';

type SearchSimilarTracksProps = {
  track: Track;
  disabled?: boolean;
};

const SearchSimilarTracks = (props: SearchSimilarTracksProps) => {
  const { track, disabled } = props;
  const { id, title, display_artists: artists } = track;
  const { findSimilarTracks } = useSimilarTracks(id, title, artists);
  const { t } = useTranslation('track-interactions');

  return (
    <TrackInteraction
      icon="similarity"
      tooltip={t('similarity.tooltip')}
      classSuffix="similarity"
      onClick={findSimilarTracks}
      disabled={disabled}
    />
  );
};

export default SearchSimilarTracks;
