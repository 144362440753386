import { z } from 'zod';

export const TenantSchema = z.object({
  first_name: z.string().optional().nullable(),
  last_name: z.string().optional().nullable(),
  company_name: z.string().optional().nullable(),
  id: z.string(),
  country: z.string().optional().nullable(),
});

export type Tenant = z.infer<typeof TenantSchema>;
