import classNames from 'classnames';
import { MouseEvent } from 'react';
import Chip from '~/components/ui/control/chip/chip';
import Icon from '~/components/ui/icon/icon';

type SearchChipProps = {
    value: string;
    color?: string;
    isOutlined?: boolean;
    className?: string;
    onChipClick?: (e: MouseEvent) => void;
    onRemoveClick?: (e: MouseEvent) => void;
};

const SearchChip = (props: SearchChipProps) => {
  const { value, color, isOutlined = false, className, onChipClick, onRemoveClick } = props;

  return (
    <Chip
      className={classNames(
        'filter-chip flex !gap-2 relative group/filter-chip overflow-hidden pr-0 border-2',
        !isOutlined ? 'bg-dark-100 border-transparent filter-chip--is' : 'border-dark-100 filter-chip--is-not',
        className,
        {
          'pl-1': color,
        },
      )}
      outline={isOutlined}
      onClick={onChipClick}
    >
      {color ? <div className="w-3 h-3 shrink-0 rounded" style={{ backgroundColor: color }} /> : null}
      <span className="filter-chip__title">{value}</span>
      <div className="aspect-square h-3 w-3 mr-1">
        <div className="filter-chip__close-button h-full aspect-square bg-light-500 rounded ml-auto flex items-center justify-center hover:bg-light-100" onClick={onRemoveClick}>
          <Icon name="close-line" className="text-dark-100 text-[0.8rem]" />
        </div>
      </div>
    </Chip>
  );
};

export default SearchChip;
