import { Signal } from '@preact/signals-react';
import { QUEUE_SIZE } from '~/components/features/tracks-upload/track-uploads.constants';

export function processEnd(fileId: string, queue: Signal<string[]>, processing: Signal<string[]>) {
  processing.value = [...processing.value.filter((id) => id !== fileId), queue.value[0]].filter(Boolean);
  queue.value = queue.value.slice(1);
}

export function addToProcess(fileId: string, queue: Signal<string[]>, processing: Signal<string[]>) {
  if (processing.value.length < QUEUE_SIZE) {
    processing.value = [...processing.value, fileId];
  } else {
    queue.value = [...queue.value, fileId];
  }
}
