import { IconifyIconProps, Icon } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';

interface ChipButtonProps {
  icon: IconifyIconProps['icon'];
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export function ChipButton(props: ChipButtonProps) {
  const { icon, className, onClick } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.(e);
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-center aspect-square bg-light-100/80 opacity-50 group-hover/tag:opacity-100 hover:bg-light-100 hover:scale-105 rounded-md h-4',
        className,
      )}
      onClick={handleClick}
    >
      <Icon icon={icon} className="text-dark-400" />
    </div>
  );
}
