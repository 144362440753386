import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { pageRoutes } from '~/routes/pages';
import { globalStore } from '~/stores/global-store-v2';

export function useAuthCheck() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userToken } = globalStore;
  const { t } = useTranslation('toast');

  useEffect(() => {
    if (location.search.includes('invalid_token')) {
      toastr('error', t('auth.login.invalid-token.title'), t('auth.login.invalid-token.content'), 'invalid_token');
      window.history.replaceState({}, document.title, location.pathname);
    }
    if (user.value && userToken.value) {
      navigate(location.state?.from ? location.state.from : pageRoutes.main);
    }

    return () => {
      navigate(pageRoutes.login);
    };
  }, [location, navigate]);
}
