import { createContext, useContext } from 'react';
import { PopulatedTagSubCategory, Tag } from '~/types/schemas/data/tags.schema';

export interface TagsAccordionContextType {
  // Handle Accordion default open state
  subCategoryDefaultOpen?: (subCategory?: PopulatedTagSubCategory) => boolean;
  categoryDefaultOpen?: (category?: PopulatedTagSubCategory) => boolean;
}

export const TagsAccordionContext = createContext<TagsAccordionContextType>({});

export function useTagsAccordionContext() {
  const context = useContext(TagsAccordionContext);

  if (!context) {
    throw new Error('useTagsAccordionContext must be used within a TagsAccordionContextProvider');
  }

  return context;
}
