/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { dateDifferenceDetail, timeDifferenceWithToday } from '~/utils/common/date-difference';
import { parseDateNumeric } from '~/utils/common/parse-date';
import CalendarClose from 'virtual:icons/ri/calendar-close-fill';
import { useTranslation } from 'react-i18next';
import Icon from '../../../ui/icon/icon';
import { Tooltip } from '../../../ui/overlays/tooltip/tooltip';

type BriefStateProps = {
  deadline: string;
  isSended?: boolean;
};

function BriefState(props: BriefStateProps) {
  const { isSended, deadline } = props;
  const timeBeforeDeadline = timeDifferenceWithToday(deadline);
  const { days, hours, minutes } = dateDifferenceDetail(deadline);
  const { t } = useTranslation('libraries');

  return (
    <div className="flex h-6">
      <Tooltip label={
        isSended
          ? timeBeforeDeadline < 0
            ? t('brief-card.state.tooltip.expired')
            : t('brief-card.state.tooltip.live')
          : t('brief-card.state.tooltip.draft')
      }
      >
        <div className={classNames('h-full flex items-center px-2 rounded-l', {
          'bg-orange-400': !isSended,
          'bg-error-500': isSended && timeBeforeDeadline >= 0,
          'bg-dark-200': isSended && timeBeforeDeadline < 0,
        })}
        >
          {!isSended && (
            <Icon name="edit-line" className="text-light-100 font-bold" />
          )}
          {isSended && (timeBeforeDeadline < 0
            ? (
              <CalendarClose className="text-light-100 font-bold" />
            )
            : <Icon name="timer-line" className="text-light-100 font-bold" />
          )}
        </div>
      </Tooltip>

      <div className={classNames('h-full w-fit border border-transparent flex items-center px-2 rounded rounded-l-none text-light-100', {
        '!border-orange-400 border-l-0': !isSended,
        '!border-error-500  border-l-0': isSended && timeBeforeDeadline >= 0,
        'bg-dark-100': isSended && timeBeforeDeadline < 0,
      })}
      >
        <div>
          {!isSended ? t('brief-card.state.draft') : null}
          {isSended && (timeBeforeDeadline < 0
            ? parseDateNumeric(deadline, {
              month: 'long',
              day: '2-digit',
              year: 'numeric',
              hour12: true,
              hour: '2-digit',
              minute: '2-digit',
            })
            : t('brief-card.state.open', {
              day: days,
              hours,
              mins: minutes,
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default BriefState;
