import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';

type SubmenuTriggerProps = {
  label: string;
};

export function SubmenuTrigger(props: SubmenuTriggerProps) {
  const { values } = useSelectionContext();
  return (
    <ContextMenu.SubItem icon="ri:add-line">
      {props.label}
      {values.length > 1 ? <span>{` (${values.length})`}</span> : null}
    </ContextMenu.SubItem>
  );
}
