import { Agent } from '~/types/schemas/common/agent.schema';

export function sortingAgentsByName(a: Agent, b: Agent) {
  const nameA = a.company_name.toUpperCase().replace(/\s/g, ''); // ignore upper and lowercase
  const nameB = b.company_name.toUpperCase().replace(/\s/g, ''); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
}
