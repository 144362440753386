import { createWithEqualityFn } from 'zustand/traditional';
import { Rights } from '~/types/schemas/data/rights.schema';
import { PopulatedTagCategory, Tag, TagCategory, TagSubCategory } from '~/types/schemas/data/tags.schema';
import { Tonality } from '~/types/schemas/data/tonalities.schema';
import { Version } from '~/types/schemas/data/versions.schema';
import { Agent } from '~/types/schemas/common/agent.schema';
import { createStorePicker } from './utilities';
import { withImmer } from './middlewares/immer-combine';

const keyBy = <T>(array: T[], key: keyof T) => array.reduce((obj, item) => {
  obj[item[key] as string] = item;

  return obj;
}, {} as Record<string, T>);

function populateTagsCategories({ tags, categories, subCategories }: { tags?: Tag[], categories?: TagCategory[], subCategories?: TagSubCategory[] }) {
  return categories?.map((category) => ({
    ...category,
    name: category.name.replace('Target - ', ''),
    tags: category.tags?.map((tag) => tags?.find((t) => t.id === tag)).filter(Boolean),
    subCategories: category.subCategories?.map((subCategory) => {
      const parsedSubCategory = subCategories?.find((sc) => sc.id === subCategory);

      return parsedSubCategory ? {
        ...parsedSubCategory,
        tags: parsedSubCategory.tags?.map((tag) => {
          const populatedTag = tags?.find((t) => t.id === tag);
          return populatedTag;
        }).filter(Boolean),
      } : undefined;
    }).filter(Boolean),
  })).filter(Boolean);
}

const useDataStore = createStorePicker(createWithEqualityFn(
  withImmer({
    categories: {
      array: [] as TagCategory[],
      object: {} as Record<string, TagCategory>,
    },
    populatedCategories: {
      array: [] as PopulatedTagCategory[],
      object: {} as Record<string, PopulatedTagCategory>,
    },
    subCategories: {
      array: [] as TagSubCategory[],
      object: {} as Record<string, TagSubCategory>,
    },
    tags: {
      array: [] as Tag[],
      object: {} as Record<string, Tag>,
    },
    rights: {
      array: [] as Rights[],
      object: {} as Record<string, Rights>,
    },
    versions: {
      array: [] as Version[],
      object: {} as Record<string, Version>,
    },
    tonalities: {
      array: [] as Tonality[],
      object: {} as Record<string, Tonality>,
    },
    agents: {
      array: [] as Agent[],
      object: {} as Record<string, Agent>,
    },
  })((set) => ({
    setData: (data: {
      tags?: Tag[],
      categories?: TagCategory[],
      subCategories?: TagSubCategory[],
      rights?: Rights[],
      versions?: Version[],
      tonalities?: Tonality[],
      agents?: Agent[],
    }) => set((state) => {
      if (data.categories) {
        state.categories.array = data.categories;
        state.categories.object = keyBy(data.categories, 'id');
      }

      if (data.subCategories) {
        state.subCategories.array = data.subCategories;
        state.subCategories.object = keyBy(data.subCategories, 'id');
      }

      if (data.tags) {
        state.tags.array = data.tags;
        state.tags.object = keyBy(data.tags, 'id');
      }

      if (data.tags && data.categories && data.subCategories) {
        const populatedCategories = populateTagsCategories({ tags: data.tags, categories: data.categories, subCategories: data.subCategories });

        state.populatedCategories.array = populatedCategories || [];
        state.populatedCategories.object = keyBy(populatedCategories || [], 'id');
      }

      if (data.rights) {
        state.rights.array = data.rights;
        state.rights.object = keyBy(data.rights, 'id');
      }

      if (data.versions) {
        state.versions.array = data.versions;
        state.versions.object = keyBy(data.versions, 'id');
      }

      if (data.tonalities) {
        state.tonalities.array = data.tonalities;
        state.tonalities.object = keyBy(data.tonalities, 'id');
      }

      if (data.agents) {
        state.agents.array = data.agents;
        state.agents.object = keyBy(data.agents, 'id');
      }
    }),
  })),
));

export default useDataStore;
