import i18n from '~/i18n/config';

export function parseDateWithHour(timestamp: string): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes() >= 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  return `${hour}:${minutes} on ${day} ${month} ${year} `;
}

export function parseDate(timestamp: string): string {
  const date = new Date(timestamp);
  return date.toLocaleDateString(i18n.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
}

export function parseDateNumeric(timestamp: string, options?: Intl.DateTimeFormatOptions): string {
  const date = new Date(timestamp);
  return date.toLocaleDateString(i18n.language, options);
}

export function parseTimeNumeric(timestamp: string, options: Intl.DateTimeFormatOptions): string {
  const date = new Date(timestamp);
  return date.toLocaleTimeString(i18n.language, options);
}
