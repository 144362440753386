import { PropsWithChildren } from 'react';

type BriefDescriptionBlockProps = PropsWithChildren<{
    label: string
    isLoading: boolean
}>;

export function BriefDescriptionBlock(props: BriefDescriptionBlockProps) {
  const { label, isLoading, children } = props;

  return (
    <div className="size-full bg-dark-200 rounded-lg p-2 flex flex-col text-xs">
      <span className="w-full mb-0.5 text-light-500">
        {label}
      </span>
      { isLoading ? (
        <div className="w-full flex items-center">
          <div className="h-3 w-[50%] animate-pulse rounded bg-dark-100" />
        </div>
      ) : children}
    </div>
  );
}
