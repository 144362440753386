import { z } from 'zod';
import { ArtworkSchema } from '../common/image.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { TenantSchema } from './tenant.schema';
import { DisplayArtist } from './artist.schema';

export const AlbumSchema = z.object({
  created_at: z.string(),
  description: z.string().optional(),
  artists: DisplayArtist.array(),
  id: z.string(),
  artwork: ArtworkSchema.optional(),
  release_date: z.string(),
  tenant: TenantSchema,
  title: z.string(),
});

export const AlbumSearchResultSchema = createSearchResultSchema(AlbumSchema);

export type Album = z.infer<typeof AlbumSchema>;
export type AlbumSearchResult = z.infer<typeof AlbumSearchResultSchema>;
