/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import { IconifyIcon } from '@iconify/react';
import Mosaic from './mosaic';
import Single from './single';
import Empty from './empty';

export type ArtworkType = string[] | string | null;

interface ArtworkProps {
  artworks?: ArtworkType
  alt?: string
  icon?: IconifyIcon['body']
  className?: string
}

export function Artwork(props: ArtworkProps) {
  const { artworks, alt, icon = 'ri:music-line', className } = props;

  const isEmpty = !artworks || artworks.length === 0;
  const isArray = !isEmpty && Array.isArray(artworks);
  const isMosaic = isArray && artworks?.length >= 4;
  const isSingle = (!isMosaic && !isEmpty);

  return (
    <div title={alt} className={classNames('size-full overflow-hidden rounded text-6xl bg-theme-brand', className)}>
      {isMosaic && <Mosaic artworks={artworks} alt={alt} />}
      {isSingle && <Single artwork={isArray ? artworks[0]! : artworks as string} alt={alt} />}
      {isEmpty && <Empty icon={icon} alt={alt} />}
    </div>
  );
}
