import { Message, MessageSchema } from '~/types/schemas/notifications/message.schema';
import { globalStore } from '~/stores/global-store-v2';
import { useRealtimeListener } from './use-realtime-listener';

type RealtimeMessageData = {
  type: Message['type'];
  fn(data: Message): void;
};

export function useRealtimeMessageData(params: RealtimeMessageData) {
  const { type, fn } = params;
  const realtime = globalStore.realtimeData.value;

  useRealtimeListener(() => ({
    channels: realtime ? [realtime.channels.sync_notifications] : [],
    listener: {
      message(messageEvent) {
        const message = MessageSchema.safeParse(messageEvent.message);

        if (message.success) {
          switch (message.data.type) {
            case type:
              fn(message.data);
              break;
            default: break;
          }
        }
      },
    },
  }), [realtime]);
}
