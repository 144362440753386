import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { FileArrayToUploadTrackArray } from '~/components/features/tracks-upload/utils/file-parser';
import { fileUploadStore } from '~/stores/file-upload-store';
import { trackUploadStore } from '~/stores/track-upload-store';
import { EventToFileArray } from '~/utils/form/input-file';

type UploadTrackProps = {
    id: string;
};

export function UploadTrack(props: UploadTrackProps) {
  const { id } = props;
  const { addFiles } = fileUploadStore;
  const { addTracks, openTrackUpload, initProcesses } = trackUploadStore;
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('brief-page');

  function handleClick() {
    inputRef.current?.click();
  }

  async function handleChanges(e: ChangeEvent<HTMLInputElement>) {
    const files = EventToFileArray(e);
    const filteredFiles = files.filter((file) => {
      // filter out files that are larger than 200MB
      if (file.size > 200000000) {
        toastr(
          'error',
          t('upload-track.error.file-too-large.title', { ns: 'toast' }),
          t('upload-track.error.file-too-large.content', { filename: file.name, ns: 'toast' }),
        );
      }

      return file.size < 200000000;
    });
    const UploadTracks = await FileArrayToUploadTrackArray(filteredFiles);
    initProcesses(UploadTracks);
    addFiles(UploadTracks);
    addTracks(UploadTracks);
    openTrackUpload('brief', id);

    // empty the input
    e.target.value = '';
  }

  return (
    <>
      <input ref={inputRef} type="file" accept=".mp3,.wav,.ogg,.flac,.aac" id="filesInput" multiple onChange={handleChanges} className="hidden" />
      <Button className="playlist__edit-button w-fit h-full !bg-dark-100 hover:!bg-dark-200" iconRight="upload-line" onClick={handleClick}>
        {t('header.interactions.button.upload-tracks')}
      </Button>
    </>
  );
}
