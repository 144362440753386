import { useEffect, useState } from 'react';
import usePlayerStore from '../../stores/player-store';

const useCurrentTime = (trackId: string | undefined) => {
  const player = usePlayerStore(['currentTrack', 'isPlaying', 'audioFile']);
  const [currentTime, setCurrentTime] = useState(0);

  const isCurrentComponentPlaying = player.isPlaying && player.currentTrack?.id === trackId;

  useEffect(() => {
    const listener = () => {
      if (player.audioFile) {
        setCurrentTime((state) => {
          if (Math.floor(state) !== Math.floor(player.audioFile!.currentTime)) {
            return player.audioFile!.currentTime;
          }

          return state;
        });
      }
    };

    if (isCurrentComponentPlaying) {
      player.audioFile?.addEventListener('timeupdate', listener);
    }

    return () => {
      player.audioFile?.removeEventListener('timeupdate', listener);
    };
  }, [player.audioFile, isCurrentComponentPlaying]);

  return currentTime;
};

export default useCurrentTime;
