import { useBriefContext } from '~/pages/briefs/brief.context';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';
import { BriefDescriptionBlock } from './block';

export function VideoLinksBlock() {
  const { data, isLoading } = useBriefContext();

  return (
    <BriefDescriptionBlock label="Video Links" isLoading={isLoading}>
      <TextLinkParser>
        <p className="text-wrap break-words w-full overflow-hidden">
          {data.video_link}
        </p>
      </TextLinkParser>
    </BriefDescriptionBlock>
  );
}
