import { components, ControlProps, GroupBase } from 'react-select';
import classNames from 'classnames';
import { OptionType } from '../select';

const Control = (
  props: ControlProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children, isFocused, hasValue, selectProps } = props;

  const containerClass = classNames(
    'flex flex-col min-h-[48px] h-12 px-3 py-[5px] !items-start !shadow-none !bg-dark-300 rounded transition duration-200',
  );

  const labelClassFocus = '!translate-y-0 !text-xxs text-white/40 leading-none mb-0';
  const labelClass = `w-full text-white text-sm translate-y-2.5 transition-all duration-200 ${
    (isFocused || hasValue) && labelClassFocus
  }`;

  return (
    <components.Control className={containerClass} {...props}>
      <span className={labelClass}>{selectProps.placeholder}</span>
      {children}
    </components.Control>
  );
};

export default Control;
