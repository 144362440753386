import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Cover from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import usePreSearchStore from '~/stores/presearch-store';
import { Artwork } from '~/types/schemas/common/image.schema';

type HighlightProps = {
    title: string;
    description: string;
    chips: string[];
    artwork?: Artwork;
    linkParams?: string
};

function Highlight(props: HighlightProps) {
  const { title, description, chips, artwork, linkParams } = props;
  const navigate = useNavigate();
  const presearch = usePreSearchStore(['setIsOpen']);

  const handleClick = () => {
    navigate(`/${linkParams}`);
    presearch.setIsOpen(false);
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        'flex gap-3 h-full rounded-lg p-4 text-left',
        'bg-dark-200 hover:bg-dark-100',
      )}
    >
      <Cover className="!w-48" type="catalog" artworks={artwork && [artwork]} alt={title} />
      <div className="flex flex-col flex-1 h-full gap-2">
        <h5 className="font-bold text-2xl text-light-100">{title}</h5>
        <p className="text-sm text-light-500 line-clamp-6">{description}</p>
        <div className="flex gap-3 mt-auto">
          {chips?.map((chip) => (
            <Chip key={chip} className="bg-dark-500">
              {chip}
            </Chip>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Highlight;
