import Variation from 'virtual:icons/mewo/variation';
import { useMagicPreview } from '~/hooks/entities/track/actions/magic-preview/use-magic-preview';
import classNames from 'classnames';
import * as Track from '~/components/entities/track/common/track';
import { useMagicPreviewTooltip } from '~/hooks/entities/track/actions/magic-preview/use-magic-preview-tooltip';

type MagicPreviewProps = {
  className?: string;
};

export function MagicPreview(props: MagicPreviewProps) {
  const { className } = props;
  const { onMagicPreviewClick, status } = useMagicPreview();
  const { tooltip } = useMagicPreviewTooltip(status);

  return (
    <Track.Action
      tooltip={tooltip}
      className={classNames({
        'animate-pulse pointer-events-none': status === 'pending',
      }, className)}
      onClick={onMagicPreviewClick}
      isError={status === 'error'}
      isWarning={status === 'track-too-short' || status === 'track-not-ready'}
      isDisabled={status === 'video-missing'}
      customIcon={<Variation className="size-4" />}
    />
  );
}
