import { Signal, useSignal } from '@preact/signals-react';
import classNames from 'classnames';
import { Ref, forwardRef, useEffect } from 'react';
import { useCreateUpload, useS3Upload } from '~/hooks/api/upload';
import Input, { InputProps } from '~/components/ui/forms/input-controls/input/input';

interface CoverInputProps extends InputProps {
  uploadId?: string;
  isSubmitReady: Signal<boolean>;
}
// CoverInput
export function Cover(props: CoverInputProps, ref: Ref<HTMLInputElement>) {
  const { value, className, uploadId, isSubmitReady, onFilesChange, ...inputProps } = props;
  const { mutate: createUpload, data: upload } = useCreateUpload(uploadId, 'image');
  const file = useSignal<File | null>(null);
  const { mutate: uploadFileOnS3 } = useS3Upload();
  const imgSrc = file.value ? URL.createObjectURL(file.value) : '';

  function handleChange(files: File[]) {
    const newFile = files[0];

    if (newFile) {
      onFilesChange?.(files);
      file.value = newFile;

      isSubmitReady.value = false;
      createUpload({
        id: 'cover',
        name: newFile.name,
        type: newFile.type,
        size: newFile.size,
      });
    }
  }

  useEffect(() => {
    if (upload && file.value) {
      uploadFileOnS3({
        upload,
        file:
        file.value,
        onUploadCompleted() {
          isSubmitReady.value = true;
        },
        onProgress(progress) {
          console.log('Progress:', progress);
        },
      });
    }
  }, [upload]);

  return (
    <div className={classNames(className, 'flex gap-2')}>
      <Input
        ref={ref}
        type="file"
        accept="image/*"
        value={value}
        isLoading={!isSubmitReady.value}
        disabled={!isSubmitReady.value}
        onFilesChange={handleChange}
        {...inputProps}
      />
      {file.value && <img src={imgSrc} alt={file.value?.name} className="h-12 rounded object-cover aspect-square shrink-0 bg-red-500" />}
    </div>

  );
}

export const CoverInput = forwardRef(Cover);
