import { Locale } from '~/types/schemas/common/locale.schema';
import { Brief, BriefAPIUpdate, BriefFormUpdate } from '~/types/schemas/music/brief.schema';

export function briefUpdateParser(brief: BriefFormUpdate): BriefAPIUpdate {
  const { agents, descriptions, deadline, ...common } = brief;
  const { date, time } = deadline;

  const parsedAgents = agents?.flatMap((group) => group.providers) || [];
  const parsedDeadline = date && new Date(time ? `${date}T${time}` : date).toISOString();
  const parsedDescriptions: Locale[] = [];

  if (descriptions?.value) {
    parsedDescriptions.push(descriptions);
  }

  return {
    ...common,
    agents: [...new Set(parsedAgents)],
    deadline: parsedDeadline,
    descriptions: parsedDescriptions,
  };
}

export function briefUpdater(oldBrief: Brief, briefUpdate: BriefAPIUpdate): Brief {
  return {
    ...oldBrief,
    ...briefUpdate,
  };
}
