import IconAdd from 'virtual:icons/ri/add-fill';
import IconSubtract from 'virtual:icons/ri/subtract-fill';
import { MouseEvent } from 'react';
import classNames from 'classnames';
import GeneralIcons, { GeneralIcon } from '~/components/ui/icon/general-icons';

export type TagItemProps = {
    title: string;
    subtitle: string;
    subtitlePrefix?: string;
    color?: string;
    icon?: GeneralIcon;
    isUnrelevant?: boolean;
    onIncludeClick?: () => void;
    onExcludeClick?: () => void;
};

function TagItem(props: TagItemProps) {
  const { title, subtitle, color, isUnrelevant, subtitlePrefix, icon, onIncludeClick, onExcludeClick } = props;

  const handleIncludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e:MouseEvent) => {
    e.stopPropagation();
    onExcludeClick?.();
  };

  const buttonClassName = 'rounded flex items-center justify-center transition-colors bg-light-100/70 hover:bg-light-100 w-4 h-4 text-dark-400 text-xs';

  return (
    <div className={classNames(
      'tag-filter-item flex items-center p-2 gap-2 rounded bg-transparent hover:bg-dark-100',
      isUnrelevant && 'opacity-50',
    )}
    >
      <div className="w-8 h-8 shrink-0 flex items-center justify-center" style={{ color }}>
        {icon ? <GeneralIcons icon={icon} className="text-md" />
          : <div className="w-4 h-4 rounded" style={{ backgroundColor: color }} />}
      </div>
      <div className="tag-filter-item__information flex flex-col">
        <h6 className="tag-filter-item__title text-xs text-light-100">{title}</h6>
        {(subtitle || subtitlePrefix) && (
        <div className="flex gap-1 text-light-500 text-xs h-4">
          {subtitlePrefix && (
          <>
            <span className="flex shrink-0">{subtitlePrefix}</span>
            {(subtitle) && <span>{'>'}</span>}
          </>
          )}
          {subtitle}
        </div>
        )}
      </div>
      <div className="tag-filter-item__actions flex gap-2 ml-auto">
        <button type="button" className={classNames('tag-filter-item__actions--subtract', buttonClassName)} onClick={handleExcludeClick}>
          <IconSubtract />
        </button>
        <button type="button" className={classNames('tag-filter-item__actions--add', buttonClassName)} onClick={handleIncludeClick}>
          <IconAdd />
        </button>
      </div>
    </div>
  );
}

export default TagItem;
