import useElementSizeStore from '~/stores/elements-size-store';
import classNames from 'classnames';
import PresearchBlock from '../presearch-block/presearch-block';
import Card, { CardProps } from '../card/card';

type CardShelfProps = {
    label: string;
    containerClassName?: string | undefined;
    childrenClassName?: string | undefined;
    rowLimit?: number;
    cardsProps?: CardProps[];
} ;

function CardShelf(props: CardShelfProps) {
  const { label, rowLimit, cardsProps, containerClassName } = props;
  const { columnNumber } = useElementSizeStore(['columnNumber']);
  const nbItem = (rowLimit && columnNumber) ? rowLimit * columnNumber : cardsProps?.length;
  const cards = cardsProps?.slice(0, nbItem);

  return (
    <PresearchBlock
      label={label}
      containerClassName={classNames('flex-1', containerClassName)}
      childrenClassName="w-full grid gap-6"
      childrenStyle={{
        gridTemplateColumns: `repeat(${columnNumber}, minmax(0, 1fr))`,
      }}
    >
      {cards?.map((cardProps, index) => (
        <Card key={`${cardProps.type}-${cardProps.title}-${index}`} {...cardProps} />
      ))}
    </PresearchBlock>
  );
}

export default CardShelf;
