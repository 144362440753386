import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { useState } from 'react';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { TrackItemsDropdownItems } from './dropdown-items';

export function TrackItemDropdownMenu() {
  const { t } = useTranslation('track-interactions');
  const store = useTrackContext();
  const setIsTrackActive = store.use.setIsTrackActive();

  const [isOpen, setIsOpen] = useState(false);

  function handleDropDownMenuOpenChange(b: boolean) {
    setIsOpen(b);
    setIsTrackActive(b);
  }

  return (
    <RadixDropdownMenu.Root
      open={isOpen}
      onOpenChange={handleDropDownMenuOpenChange}
    >
      <Tooltip label={t('more-options.tooltip')} asChild>
        <RadixDropdownMenu.Trigger
          autoFocus={false}
          className="size-4 aspect-square flex place-content-center cursor-pointer my-auto opacity-0 group-hover/track-item:opacity-100"
        >
          <Icon icon="ri:more-line" />
        </RadixDropdownMenu.Trigger>
      </Tooltip>
      <DropdownMenu.Content>
        <TrackItemsDropdownItems />
      </DropdownMenu.Content>
    </RadixDropdownMenu.Root>
  );
}
