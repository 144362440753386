/* eslint-disable camelcase */
import classNames from 'classnames';
import { Brief } from '~/types/schemas/music/brief.schema';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { parseThousands } from '~/utils/common/parse-thousands';
import BudgetIcon from 'virtual:icons/ri/cash-line';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import BriefState from '~/components/entities/tracklist/brief/brief-state';
import { Artwork } from '~/components/ui/cover/artwork';

type BriefCardProps = {
  brief: Brief;
  onClick?: () => void;
};

const LOCALE = 'en';

const BriefCard = (props: BriefCardProps) => {
  const { brief, onClick } = props;
  const { title, artworks, tenant, public: isSended, created_at, created_by, descriptions, budget, deadline, currency, tracks } = brief;
  const { first_name, last_name, country: userCountry } = created_by!;
  const { company_name, country: tenantCountry } = tenant;
  const { t } = useTranslation('libraries');

  return (
    <button
      type="button"
      onClick={onClick}
      className="p-4 flex w-full shrink-0 text-left hover:bg-dark-300/40 outline outline-1 outline-dark-100/40 hover:outline-dark-300/40 transition-all duration-100 rounded-lg"
    >
      <div className="w-[25%] aspect-square mr-4 shrink-0">
        <Artwork icon="ri:megaphone-line" artworks={artworks?.map((artwork) => artwork.large)} alt="cover" />
      </div>
      <div className="flex-1 h-full flex flex-col items-stretch ">
        <div className="w-full flex justify-between mt-2 items-center text-xs ">
          <h4 className="text-light-500">
            {t('brief-card.attribution', {
              'company-name': company_name ? `${company_name} (${userCountry || tenantCountry})` : '',
              'user-fullname': `${first_name} ${last_name}`,
              date: parseDateNumeric(created_at || '', {
                month: 'long',
                day: '2-digit',
                year: dayjs(created_at).year() === dayjs().year() ? undefined : 'numeric',
              }),
            })}
          </h4>
          <span>
            {budget ? (
              <div className="flex items-center text-light-500">
                <BudgetIcon className="inline-block mr-2" />
                {`${parseThousands(budget.toString(), ' ')} ${currency}`}
              </div>
            )
              : null}
          </span>
        </div>
        <div className="h-full flex-1 mt-3 mb-3">
          <h3 className="font-bold line-clamp-1 text-light-100">{title}</h3>
          <p className="text-xs line-clamp-2 text-light-500 mt-2">
            {descriptions.find((description) => description.locale === LOCALE)?.value}
          </p>
        </div>
        <div className="w-full flex justify-between items-center text-xs mt-auto text-light-500 mb-2 flex-wrap gap-3">
          <span className={classNames(
            'rounded py-1 px-2 text-light-100 flex items-center gap-2',
            tracks ? 'bg-green-500' : 'bg-red-500',
          )}
          >
            {t('brief-card.tracks-count', {
              'track-count': tracks || 0,
            })}
          </span>
          {deadline ? (
            <BriefState deadline={deadline} isSended={isSended} />
          ) : null}
        </div>
      </div>
    </button>
  );
};

export default BriefCard;
