import GeneralIcons, { generalIcons } from '~/components/ui/icon/general-icons';
import React, { MouseEvent, Ref } from 'react';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import classNames from 'classnames';

type TrackInteractionProps = {
  icon: keyof typeof generalIcons;
  tooltip: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  classSuffix?: string;
  onClick?: (e?: MouseEvent) => void;
};

const TrackInteraction = React.forwardRef((props: TrackInteractionProps, forwardedRef: Ref<HTMLDivElement>) => {
  const { tooltip, icon, isLoading, disabled, classSuffix, className, onClick } = props;

  const triggerClassName = classNames(
    'h-7 aspect-square flex items-center justify-center cursor-pointer transition-colors duration-100   text-xl',
    disabled ? 'text-dark-100 pointer-evenet-none hover:text-dark-100' : 'text-light-100 hover:text-primary-400',
    className,
  );

  function handleClick(e: MouseEvent) {
    if (disabled) return;
    onClick?.(e);
    e.stopPropagation();
  }

  return (
    <Tooltip label={tooltip}>
      <div
        ref={forwardedRef}
        className={classNames(
          {
            'animate-pulse': isLoading,
            'opacity-50': disabled,
          },
          classSuffix ? `track-interactions__${classSuffix}` : '',
          triggerClassName,
        )}
        onClick={handleClick}
      >
        <GeneralIcons icon={icon} className="text-base" />
      </div>
    </Tooltip>
  );
});

export default TrackInteraction;
