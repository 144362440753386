import { useTranslation } from 'react-i18next';
import { LicensingQuoteDialog } from '~/components/ui/overlays/dialogs/licensing-quote/licensing-quote';
import * as ContextMenu from '../common/context-menu';

export function TrackActionsLicensingQuoteModal() {
  const { t } = useTranslation('track-interactions');

  return (
    <LicensingQuoteDialog>
      <ContextMenu.DialogItem icon="tabler:mail-dollar">
        {t('quote.tooltip')}
      </ContextMenu.DialogItem>
    </LicensingQuoteDialog>
  );
}
