import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import { parseResultNumber } from './util/parse-result-number';

type TabTriggerProps = {
    label: string;
    value: string;
    resultNumber?: number;
    isActive?: boolean;
};

function TabTrigger(props: TabTriggerProps) {
  const { label, value, resultNumber: suffix, isActive } = props;
  return (
    <Tabs.Trigger
      id={`presearch__tab-trigger--${value}`}
      value={value}
      className={classNames(
        'presearch__tab-trigger',
        'flex items-center w-fit  gap-2 h-8 px-3 py-1.5 rounded-lg ',
        suffix && 'pr-1',
        isActive ? 'bg-light-100 text-dark-100' : 'bg-dark-100 text-light-100',
      )}
    >
      <span className="text-sm leading-none">{label}</span>
      {(suffix || 0) > 0 && (
        <span className={classNames(
          'text-xs px-2 py-1 rounded-md',
          isActive ? 'bg-dark-100 text-light-100' : 'bg-light-100 text-dark-100',
        )}
        >
            {parseResultNumber(suffix!)}
        </span>
      )}
    </Tabs.Trigger>
  );
}

export default TabTrigger;
