import classNames from 'classnames';
import { MouseEvent } from 'react';
import CloseIcon from 'virtual:icons/ri/close-line';

type FilterSectionProps = {
  label: string;
  className?: string;
  isActive?: boolean;
  children: React.ReactNode;
  onDelete?: (e: MouseEvent) => void;
};

function FilterSection(props: FilterSectionProps) {
  const { label, className, isActive = false, children, onDelete } = props;
  return (
    <div className={classNames(
      `filter-section filter-section--${label.toLocaleLowerCase().replace(' ', '_')}`,
      'relative flex flex-col gap-1.5 p-2 pt-1 rounded-lg bg-dark-300',
      isActive ? 'border border-primary-500' : 'border border-dark-300',
      className,
    )}
    >
      <div className="flex justify-between items-center gap-3">
        <h5 className="filter-section__label text-xs text-light-500">
          {label}
        </h5>
        {onDelete ? (
          <button
            type="button"
            onClick={onDelete}
            className="text-xs p-0.25 text-dark-100 rounded bg-light-500 hover:bg-light-100"
          >
            <CloseIcon />
          </button>
        ) : null}
      </div>
      <div className="flex gap-1.5 items-center">
        {children}
      </div>
    </div>
  );
}

export default FilterSection;
