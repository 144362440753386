import { ChangeEvent, FocusEventHandler, MutableRefObject, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface TimeInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function Time(props: TimeInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    required = false,
    onChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  function handleClick() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.click();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className} data-no-dnd="true">
      <div
        className={classNames(containerClass, '!cursor-pointer group/file-input !flex-row gap-3 items-center')}
        onClick={handleClick}
      >
        <div className="flex flex-col flex-1">
          {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-error-500">*</span>}
          </span>
          )}
          <div className="flex items-center gap-3 overflow-hidden">
            <input
              ref={inputRef}
              aria-label={label}
              placeholder={placeholder || label}
              type="time"
              className={classNames(inputClass, 'opacity-100')}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              {...inputProps}
            />
          </div>
        </div>
        <div className="transition-all size-6 shrink-0 flex items-center justify-center rounded bg-dark-400 group-hover/file-input:bg-dark-400/60">
          <Icon icon="ri:time-line" className="text-light-100 text-xs" />
        </div>
      </div>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const TimeInput = forwardRef(Time);
