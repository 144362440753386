export function dateDifferenceWithToday(timestamp: string): number {
  const date = new Date(timestamp);
  const today = new Date();
  const diff = date.getTime() - today.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export function timeDifferenceWithToday(timestamp: string): number {
  const date = new Date(timestamp);
  const today = new Date();
  const diff = date.getTime() - today.getTime();
  return diff;
}

export function dateDifference(timestamp: string): { days: number, hours: number, minutes: number } {
  const date = new Date(timestamp);
  const today = new Date();
  const diff = today.getTime() - date.getTime();

  return {
    days: Math.floor(diff / (1000 * 3600 * 24)),
    hours: Math.floor((diff % (1000 * 3600 * 24)) / (1000 * 3600)),
    minutes: Math.floor((diff % (1000 * 3600)) / (1000 * 60)),
  };
}

export function dateDifferenceDetail(timestamp: string): { days: number, hours: number, minutes: number } {
  const timeDifference = timeDifferenceWithToday(timestamp);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  return {
    days,
    hours,
    minutes,
  };
}
