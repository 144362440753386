import { useEffect } from 'react';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useComputed } from '@preact/signals-react';
import { trackUploadStore } from '~/stores/track-upload-store';
import FileInfoLoader from '../../file-info-loader';

type TrackWaitingProps = {
    file: UploadTrack
};

export default function TrackWaiting(props: TrackWaitingProps) {
  const { file } = props;
  const { processes: { upload: uploadProcess } } = trackUploadStore;
  const isUploadReady = useComputed(() => uploadProcess.processing.value.includes(file.id)).value;

  useEffect(() => {
    if (isUploadReady) {
      file.step.value = 'upload';
    }
  }, [isUploadReady]);

  return <FileInfoLoader className="border-r border-orange-400" />;
}
