import { fileUploadStore } from '~/stores/file-upload-store';
import { UploadFile } from '~/types/features/file-upload/context';
import { FileUploadSetup } from '../components/file-upload-setup';

type FileUploadProviderProps = {
    file: UploadFile
    children: React.ReactNode
    onUploadInitSuccess?: (data?: unknown) => void
};

export default function FileUploadProvider(props: FileUploadProviderProps) {
  const { file, children, onUploadInitSuccess } = props;
  const { uploads } = fileUploadStore;

  if (!uploads.value[file.id]) {
    return <FileUploadSetup fileId={file.id} uploadFile={file} onSuccess={onUploadInitSuccess} />;
  }

  return children;
}
