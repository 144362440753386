import { useTrackContext } from '~/components/entities/track/common/track.context';
import useDataStore from '~/stores/mewo-data-store';
import { createRegexVersion, instrumental } from '~/utils/common/regex';

export function useTrackVersionLabel(versionId?: string) {
  const store = useTrackContext();
  const track = store.use.track();

  const { title } = track;
  const { versions } = useDataStore(['versions']);

  const parsedVersion = versionId && versions.object?.[versionId];

  if (!parsedVersion || versionId === '5989daf4d3f20f148cff8d5f') {
    const versionInTitle = title.match(createRegexVersion(instrumental));

    if (versionInTitle) {
      const instrumentalVersion = versions.object?.['5989dad4d3f20f148cff8d59']?.name;
      return { version: instrumentalVersion };
    }

    return { version: null };
  }

  return {
    version: parsedVersion?.name,
  };
}
