import LibraryLayout from '~/components/layout/library-layout';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import BriefLibraryHeader from './components/header';
import { useBriefLibrary } from './hooks/use-brief-library';
import BriefCard from './components/brief-card';
import CardLoader from './components/card-loader';
import CreateBrief from './components/create-brief';

function BriefLibrary() {
  const { t } = useTranslation('common');
  const { briefs, nbHits, isEachDataDisplayed, isFetching, isLoading, fetchNextPage, handleCardClick, handleCreateClick } = useBriefLibrary();

  return (
    <LibraryLayout
      nbHits={nbHits}
      addButton={
        <CreateBrief onClick={handleCreateClick} />
      }
      header={(
        <BriefLibraryHeader total={nbHits} briefs={briefs} />
      )}
    >
      {!isLoading ? (
        <>
          {briefs.map((brief) => (
            <BriefCard
              key={`brief-card-${brief.id}`}
              onClick={() => handleCardClick(brief.id!)}
              brief={brief}
            />
          ))}
          {!isEachDataDisplayed && (
            <Button
              type="button"
              isLoading={isFetching}
              onClick={() => fetchNextPage()}
              className="w-fit mx-auto mb-3 col-span-full"
            >
              {t('button.load-more.label')}
            </Button>
          )}
        </>
      ) : null}
      {isLoading && !briefs.length ? (
        Array.from({ length: 10 }).map((_, index) => (
          <CardLoader key={`card-loader-${index}`} />
        ))
      ) : null}
    </LibraryLayout>
  );
}

export default BriefLibrary;
