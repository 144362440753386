import Icon from '~/components/ui/icon/icon';

type MenuProps = {
    onDeleteClick?: () => void;
};

function Menu(props: MenuProps) {
  const { onDeleteClick } = props;
  return (
    <div className="flex items-center gap-2">
      {onDeleteClick ? (
        <button type="button" className="flex items-center justify-center w-3 h-3" onClick={onDeleteClick}>
          <Icon name="delete-bin-line" className="text-light-400" />
        </button>
      ) : null}
    </div>
  );
}

export default Menu;
