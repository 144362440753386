/* eslint-disable react/no-unescaped-entities */
import Dialog from '~/components/ui/overlays/dialog/dialog';
import { Track } from '~/types/schemas/music/track.schema';
import TrackInteraction from '../interaction';
import { useTranslation } from 'react-i18next';

type LyricsProps = {
  track: Track;
};

const DisplayLyrics = (props: LyricsProps) => {
  const { track } = props;
  const { title, lyrics } = track;
  const { t } = useTranslation('track-interactions');

  return (
    <Dialog title={title} trigger={<TrackInteraction icon="lyrics-line" tooltip={t('lyrics.tooltip')} disabled={!lyrics} />} asChild>
      <p className="text-light-500 whitespace-pre-line">
        {lyrics}
      </p>
    </Dialog>
  );
};

export default DisplayLyrics;
