/* eslint-disable camelcase */
import { usePostTrackLicenseQuote } from '~/hooks/api/track';
import useInterestFormStore from '~/stores/forms/interest-form-store';
import { TrackInterestForm } from '~/types/schemas/music/track.schema';

export function useInterestForm() {
  const interestForm = useInterestFormStore(['close', 'trackId', 'updateInterest', 'clearFileds', 'interest']);
  const { mutate: sendLicenseQuote } = usePostTrackLicenseQuote(interestForm.trackId!);

  function handleDiscardAndExit() {
    interestForm.close();
  }

  function handleSaveAndExit(formData: TrackInterestForm) {
    if (formData) {
      sendLicenseQuote({
        ...formData,
        budget: formData.budget || undefined,
      }, {
        onSuccess(data) {
          localStorage.setItem('last_interest_form', JSON.stringify(data));
          interestForm.close();
        },
      });
    }

    interestForm.close();
  }

  function handleClearFields() {
    interestForm.clearFileds();
  }

  return ({
    handleDiscardAndExit,
    handleSaveAndExit,
    handleClearFields,
    defaultValues: interestForm.interest,
  });
}
