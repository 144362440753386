import RemoveTrackFromPlaylist from './remove-from-playlist';
import RemoveTrackFromBrief from './remove-from-brief';

type RemoveFormInternalRefProps = {
    trackId: string;
    tracklistId: string;
    tracklistType: 'brief' | 'playlist';
};

const RemoveTrackFromTracklist = (props: RemoveFormInternalRefProps) => {
  const { tracklistType, tracklistId, trackId } = props;

  if (tracklistType === 'playlist') {
    return <RemoveTrackFromPlaylist playlistId={tracklistId} trackId={trackId} />;
  }

  if (tracklistType === 'brief') {
    return <RemoveTrackFromBrief briefId={tracklistId} trackId={trackId} />;
  }

  return null;
};

export default RemoveTrackFromTracklist;
