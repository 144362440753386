import classNames from 'classnames';
import GeneralIcons from '~/components/ui/icon/general-icons';
import Icon from '~/components/ui/icon/icon';

type StepIndexProps = {
    index?: number;
    state: 'active' | 'done' | 'todo' | 'error';
    disabled?: boolean;
    onStepClick?: (index?: number) => void;
};

export function StepIndex(props: StepIndexProps) {
  const { index, state, disabled, onStepClick } = props;
  return (
    <div className="relative group/step w-6 aspect-square">
      <div className={classNames(
        'step-index w-full h-full box-border flex justify-center rounded-full items-center text-xs',
        {
          'border border-primary-500 text-light-100': state === 'active',
          'bg-primary-500': state === 'done',
          'border border-dark-100 text-dark-100': state === 'todo',
          'bg-error-500': state === 'error',
        },
      )}
      >
        {state === 'active' || state === 'todo' ? index : null}
        {state === 'done' ? <Icon name="check-line" /> : null}
        {state === 'error' ? <GeneralIcons icon="warning" /> : null}
      </div>
      <div
        className={classNames('absolute inset-0 w-full h-full box-border hidden justify-center rounded-full items-center group-hover/step:bg-primary-500 ', {
          'group-hover/step:flex  cursor-pointer': !disabled,
        })}
        onClick={() => onStepClick?.(index ? index - 1 : 0)}
      >
        <Icon name="pencil-line" className="text-xs" />
      </div>
    </div>
  );
}
