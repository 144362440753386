import { useLocation } from 'react-router-dom';
import usePreSearchStore from '~/stores/presearch-store';
import classNames from 'classnames';
import useGlobalFormStore from '~/stores/forms/global-form-store';
import { useTranslation } from 'react-i18next';
import { trackUploadStore } from '~/stores/track-upload-store';
import PresearchInput from '../presearch-input';
import HeaderBrief from './brief';
import HideVideo from '../hide-video-button';
import ClosePresearchButton from '../close-presearch';

const HeaderContent = () => {
  const location = useLocation();
  const presearch = usePreSearchStore(['isOpen']);
  const globalForm = useGlobalFormStore(['isOpen']);
  const { t } = useTranslation(['pre-search']);
  const { isOpen: { value: trackUpload }, context } = trackUploadStore;

  return (
    <div className={classNames('w-full flex items-center justify-between transition-opacity', {
      'opacity-0 pointer-events-none': globalForm.isOpen,
    })}
    >
      {location.pathname === '/search' ? (
        <PresearchInput className={classNames('transition-all duration-300', presearch.isOpen ? 'w-1/2' : 'w-[34rem]')} />
      ) : null}
      {location.pathname.includes('/briefs/') ? (
        <HeaderBrief />
      ) : null}
      {presearch.isOpen && location.pathname === '/search'
        ? (
          <span className="flex items-center gap-3 text-xs">
            {t('header.close.label')}
            <ClosePresearchButton />
          </span>
        )
        : !(trackUpload && context.id.value) && <HideVideo className="ml-auto w-full max-w-[16rem]" />}
    </div>
  );
};

export default HeaderContent;
