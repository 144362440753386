import classNames from 'classnames';
import { StepIndex } from './step-index';

export type FormStepProps = {
    title: string;
    description: string;
    state: 'active' | 'done' | 'todo' | 'error';
    index?: number;
    disabled?: boolean;
    onStepClick?: (index?: number) => void;
};

export function FormStep(props: FormStepProps) {
  const { state, index, disabled = false, onStepClick } = props;
  return (
    <div className={classNames('form-step flex items-center gap-4', {
      'opacity-50 pointer-events-none !text-light-500': disabled,
    })}
    >
      <div className="text-right">
        <h6 className="text-xs font-bold text-light-100">{props.title}</h6>
        <p className="text-xs text-light-500">{props.description}</p>
      </div>
      <div className="w-6 aspect-square">
        <StepIndex disabled={disabled} state={state} index={index} onStepClick={onStepClick} />
      </div>
    </div>
  );
}
