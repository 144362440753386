import Button from '~/components/ui/control/button/button';
import DeleteDialog from '~/pages/briefs/components/delete-dialog';
import { useTranslation } from 'react-i18next';

type BriefFormFooterProps = {
  isLastStep?: boolean
  isFirstStep?: boolean
  onNextClick?: () => void
  onPreviousClick?: () => void
  onDeleteClick?: () => void
  onSendClick?: () => void
};

function BriefFormFooter(props: BriefFormFooterProps) {
  const { isLastStep, isFirstStep, onNextClick, onDeleteClick, onSendClick, onPreviousClick } = props;
  const { t } = useTranslation('brief-form');

  return (
    <div className="w-full h-8 flex justify-between items-center">
      <DeleteDialog handleDeleteClick={() => onDeleteClick?.()}>
        <Button className="!bg-error-500 tracklist__actions--delete">{t('footer.delete')}</Button>
      </DeleteDialog>
      <div className="flex items-center justify-end gap-3">
        {!isFirstStep ? <Button className="bg-transparent hover:bg-transparent hover:border-x-primary-400 border border-primary-500" onClick={onPreviousClick}>{t('footer.previous')}</Button> : null}
        {!isLastStep
          ? <Button onClick={onNextClick} className="brief-form__button--next-step">{t('footer.next')}</Button>
          : <Button onClick={onSendClick}>{t('footer.submit')}</Button>
        }
      </div>
    </div>
  );
}

export default BriefFormFooter;
