import { Controller } from 'react-hook-form';
import Textarea from '~/components/ui/forms/textarea/textarea';
import { reverseParseDeadline } from '~/utils/common/parse-deadline';
import { useTranslation } from 'react-i18next';
import Select, { OptionType } from '~/components/ui/forms/select/select';
import Input from '~/components/ui/forms/input-controls/input/input';
import { BriefStepProps } from '../brief-form';
import Tab from './tab';

function General(props: BriefStepProps) {
  const { t } = useTranslation(['brief-form', 'common']);
  const {
    title = '',
    brief,
    control,
    ...actions
  } = props;

  const currencies = [
    { value: 'USD', label: t('currency.usd', { ns: 'common' }) },
    { value: 'EUR', label: t('currency.eur', { ns: 'common' }) },
    { value: 'GBP', label: t('currency.gbp', { ns: 'common' }) },
    { value: 'JPY', label: t('currency.jpy', { ns: 'common' }) },
  ];

  const confidentiality = [
    { value: 'confidential', label: t('confidentiality.confidential', { ns: 'brief-page' }) },
    { value: 'high', label: t('confidentiality.high', { ns: 'brief-page' }) },
    { value: 'extreme', label: t('confidentiality.extreme', { ns: 'brief-page' }) },
  ];

  const musicTypes = [
    { value: 'production', label: t('music-type.prod', { ns: 'common' }) },
    { value: 'core', label: t('music-type.core', { ns: 'common' }) },
  ];

  return (
    <Tab title={title} briefTitle={brief?.title} {...actions} isFirstStep>
      <div className="form-content__row">
        <Controller
          name="title"
          defaultValue={brief?.title || ''}
          control={control}
          rules={{ required: t('step-one.input.title.empty-warning') }}
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              label={t('step-one.input.title.label')}
              placeholder={t('step-one.input.title.placeholder')}
              className="brief-form__title"
              value={value}
              onChange={onChange}
              required
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className="flex gap-4 form-content__row">
        <Controller
          name="budget"
          defaultValue={brief?.budget ? brief.budget : undefined}
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              label={t('step-one.input.budget.label')}
              type="number"
              value={value?.toString()}
              onNumberChange={onChange}
              placeholder={t('step-one.input.budget.placeholder')}
              className="flex-1"
              separator=","
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="currency"
          control={control}
          defaultValue={brief?.currency}
          render={({ field: { value, onChange } }) => (
            <Select
              id="select-currency"
              label={t('step-one.input.currency.label')}
              value={currencies.find((c) => c.value === value)}
              onSingleValueChange={(e: OptionType) => onChange(e.value)}
              containerClassName="flex-1"
              options={currencies}
            />
          )}
        />
      </div>
      <div className="form-content__row">
        <Controller
          name="confidentiality"
          defaultValue={brief?.confidentiality}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              id="select-confidentiality"
              label={t('step-one.input.confidentiality.label')}
              value={confidentiality.find((c) => c.value === value)}
              onSingleValueChange={(e: OptionType) => onChange(e.value)}
              containerClassName="flex-1"
              options={confidentiality}
            />
          )}
        />
      </div>
      <div className="form-content__row">
        <Controller
          name="music_type"
          defaultValue={brief?.music_type || []}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              label={t('step-one.input.music-type.label')}
              isMulti
              options={musicTypes}
              value={value.map((v) => (musicTypes.find((m) => m.value === v) as OptionType))}
              onMultiValueChange={(e) => onChange(e.map((v) => v.value))}
            />
          )}
        />
      </div>
      <div className="flex gap-4 form-content__row">
        <Controller
          name="deadline.date"
          defaultValue={reverseParseDeadline(brief?.deadline as string)?.date || ''}
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              value={value}
              onChange={onChange}
              label={t('step-one.input.deadline.date.label')}
              type="date"
              className="flex-1"
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="deadline.time"
          defaultValue={reverseParseDeadline(brief?.deadline as string)?.time || ''}
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              value={value}
              onChange={onChange}
              label={t('step-one.input.deadline.time.label')}
              type="time"
              className="flex-1"
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className="form-content__row">
        <Controller
          name="scope"
          defaultValue={brief?.scope}
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <Textarea
              value={value}
              onChange={onChange}
              label={t('step-one.input.scope.label')}
              placeholder={t('step-one.input.scope.placeholder')}
              rows={4}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
    </Tab>
  );
}

export default General;
