import FormLayout, { FormLayoutProps } from '~/components/layout/form-layout';
import { Control, useForm } from 'react-hook-form';
import { TrackInterestForm } from '~/types/schemas/music/track.schema';
import { useTranslation } from 'react-i18next';
import General from './tabs/general';
import { useInterestForm } from './use-interest-form';
import InterestFormFooter from './components/footer';

export type InterestStepProps = {
  title: string
  control: Control<TrackInterestForm>
  onDiscardAndExitClick?: () => void
  onClearFieldsClick?: () => void
};

function InterestForm() {
  const { handleSaveAndExit, handleDiscardAndExit, handleClearFields, defaultValues } = useInterestForm();
  const { control, handleSubmit } = useForm<TrackInterestForm>({
    values: defaultValues,
  });
  const { t } = useTranslation('request-form');

  const steps: FormLayoutProps['steps'] = [{
    tab: <General
      title={t('header.title')}
      control={control}
      onDiscardAndExitClick={() => handleDiscardAndExit()}
      onClearFieldsClick={() => handleClearFields()}
    />,
  }];

  return (
    <FormLayout
      steps={steps}
      footer={(
        <InterestFormFooter
          onSaveAndExitClick={handleSubmit(handleSaveAndExit)}
        />
      )}
    />
  );
}

export default InterestForm;
