import dayjs from 'dayjs';
import '../locales';
import RelativeTime from 'dayjs/plugin/relativeTime';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(
  RelativeTime,
);
dayjs.extend(UpdateLocale);

export function parseDateFromNow(timestamp: string | number | Date) {
  return dayjs(timestamp).fromNow();
}
