import * as AlertDialog from '@radix-ui/react-alert-dialog';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteDialogProps = {
  handleDeleteClick: () => void;
  children: ReactNode;
};

const DeleteDialog = (props: DeleteDialogProps) => {
  const { handleDeleteClick, children: trigger } = props;
  const { t } = useTranslation('brief-page');

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>
        {trigger}
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-dark-400/90 z-[90] backdrop-blur" />
        <AlertDialog.Content data-no-dnd="true" className={classNames('delete-modal fixed top-[50%] left-[50%] -translate-x-1/2 z-[100] -translate-y-1/2 p-4 rounded-lg w-full max-w-[40rem] max-h-[80vh] overflow-hidden flex flex-col items-stretch')}>
          <AlertDialog.Title className="font-bold text-2xl mb-4">
            {t('modal.delete.title')}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-md text-light-500">
            {t('modal.delete.content')}
          </AlertDialog.Description>
          <div className="flex justify-end gap-6 mt-4">
            <AlertDialog.Cancel className="outline-none " asChild>
              <button type="button" className="border-2 border-dark-100 hover:bg-dark-100 px-4 h-10 rounded transition-colors">
                {t('modal.delete.button.cancel.label')}
              </button>
            </AlertDialog.Cancel>
            <AlertDialog.Action className="outline-none bg-red-500 hover:bg-red-400" asChild>
              <button type="button" onClick={handleDeleteClick} className="delete-modal__button--confirm inline-flex h-10 transition-colors items-center justify-center rounded px-4 font-medium leading-none outline-none">
                {t('modal.delete.button.confirm.label')}
              </button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default DeleteDialog;
