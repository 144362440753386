import { globalStore } from '~/stores/global-store-v2';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { DiscriminatedValidateData, ValidateParams } from '~/types/schemas/track-upload/validate.schema';

export function getValidateParams(file: UploadTrack): ValidateParams {
  let params: DiscriminatedValidateData;

  switch (file.matchedMetadata.value?.type) {
    case 'identified':
      params = {
        type: 'identified',
      };
      break;
    case 'spotify':
      params = {
        type: 'spotify',
        spotifyId: file.matchedMetadata.value?.spotifyId || '',
      };
      break;
    default:
      params = {
        type: 'manual',
        title: file.matchedMetadata.value?.title || '',
        artists: file.matchedMetadata.value?.artists || [],
        album: file.matchedMetadata.value?.album || '',
        release_date: file.matchedMetadata.value?.releaseDate || '',
      };
      break;
  }
  return {
    ...params,
    version: file.version.value,
    music_type: file.musicType.value,
    catalog: file.matchedMetadata.value?.catalog || globalStore.user.value?.company_name || 'Catalog',
    use_id3_cover: file.matchedMetadata.value?.cover?.includes('blob') ? false : !file.matchedMetadata.value?.useId3Cover,
  };
}
