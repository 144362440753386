import classNames from 'classnames';
import { useSearchStore } from '~/stores/search-store';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n/config';
import { TranslatedFilterSchema } from '~/types/schemas/data/translated-filter.schema';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { FilterKeys } from '~/types/schemas/search/filter.schema';
import translatedFiltersRaw from '~/data/filters.json';
import FilterChip from './components/filter-chip';
import SimilarChip from './components/similar-chip';
import { useParsedFilterBlock } from './utils/parse-filters';
import FilterSection from './components/filter-section';
import ReleaseDate from './components/release-date';
import Popularity from './components/popularity';
import ContainChip from './components/contains-chip';
import MusicType from './components/music-type';
import AddFilterButton from './components/add-filter-button';
import Bpm from './components/bpm';
import { Tags } from './components/tags';

const translatedFilters = TranslatedFilterSchema.parse(translatedFiltersRaw);

type SearchPreviewProps = {
  className?: string;
};

function SearchFilters(props: SearchPreviewProps) {
  const { className } = props;
  const parsedFilterBlocks = useParsedFilterBlock();
  const search = useSearchStore(['similarityTrack', 'similarityURL', 'query', 'nbFilters']);
  const { t } = useTranslation('filter');

  return (
    <div className={classNames('flex flex-col gap-3 z-[11]', className)}>
      <div className="flex gap-2">
        <h4 className="text-xs text-light-500">{t('title')}</h4>
        {search.nbFilters ? <span className="size-4 bg-primary-400 flex items-center justify-center rounded text-xxs">{search.nbFilters}</span> : null}
      </div>
      <div className="w-full flex items-center gap-3 flex-wrap">
        <MusicType />
        <ReleaseDate />
        <Popularity />
        <Bpm />
        {
          Object.values(parsedFilterBlocks)?.map((block) => {
            if (block.term.value === 'tags' || block.term.value === 'tag_categories') {
              return <Tags block={block} />;
            }

            return (
              <FilterSection key={block.id} label={translatedFilters[i18n.language as SupportedLanguages]?.[block?.term.value as FilterKeys] || ''} isActive>
                {Object.values(block.filters).map((filter, i) => (
                  <div key={`block-${filter.id}-${i}`}>
                    <FilterChip block={block} filter={filter} />
                    {Object.values(block.filters).length - 1 > i ? (
                      <span className="ml-2 text-xs font-bold text-light-500">{(block.term.value === 'tags' || block.term.value === 'tag_categories') ? t('filter.operator.and') : t('filter.operator.or')}</span>
                    ) : null}
                  </div>
                ))}
              </FilterSection>
            );
          })
        }
        {(search.similarityTrack?.id || search.similarityURL?.jobId) ? (
          <FilterSection label={t('similarity.title')}>
            <SimilarChip />
          </FilterSection>
        ) : null}
        {search.query ? (
          <FilterSection label={t('contains.title')}>
            <ContainChip />
          </FilterSection>
        ) : null}
        <AddFilterButton />
      </div>
    </div>
  );
}

export default SearchFilters;
