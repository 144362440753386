import classNames from 'classnames';
import { CSSProperties } from 'react';
import { SearchTrackItemLayout } from '~/components/layout/track-items/search-track-item.layout';

export function renderTrackItemSentinel(style?: CSSProperties) {
  const backgroundImage = 'bg-repeat-y bg-repeat-space lg:bg-music-item-sentinel-sm 2xl:bg-music-item-sentinel-base';

  return (
    <SearchTrackItemLayout className={classNames('h-full w-full flex-1', style)}>
      <div className={classNames('h-full 2xl:w-[200px] overflow-hidden', backgroundImage)} style={{ backgroundPositionX: 1 }} />
      <div className="size-full flex flex-col items-center">
        <div className={classNames('h-full w-[24px] [background-position-x:-639px] 2xl:[background-position-x:-643px] overflow-hidden', backgroundImage)} />
      </div>
    </SearchTrackItemLayout>
  );
}

export function renderBriefTrackItemSentinel(className?: string) {
  const backgroundImage = 'bg-repeat-y bg-repeat-space lg:bg-music-item-sentinel-sm 2xl:bg-music-item-sentinel-base';

  return (
    <SearchTrackItemLayout className={classNames('h-full w-full', className)}>
      <div className={classNames('h-full 2xl:w-[200px] overflow-hidden', backgroundImage)} style={{ backgroundPositionX: 1 }} />
      <div className="size-full flex flex-col items-center">
        <div className={classNames('h-full w-[24px] [background-position-x:-639px] 2xl:[background-position-x:-643px] overflow-hidden', backgroundImage)} />
      </div>
    </SearchTrackItemLayout>
  );
}
