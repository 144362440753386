import classNames from 'classnames';
import { ComponentPropsWithoutRef, useRef, useState } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

interface TextareaProps extends ComponentPropsWithoutRef<'textarea'> {
    label?: string;
    labelClassName?: string;
    boxClassName?: string;
    className?: string;
    success?: boolean;
    error?: string;
    dataTestId?: string;
    info?: string;
  }

const Textarea = (props: TextareaProps) => {
  const {
    label,
    className = '',
    labelClassName,
    boxClassName,
    error,
    success,
    defaultValue = '',
    value = '',
    dataTestId = '',
    info,
    ...textareaProps
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const containerClass = classNames(
    'relative flex flex-col h-fit px-3 py-1.5 justify-center cursor-text border border-transparent bg-dark-300 rounded transition duration-200 hover:border-dark-100',
    {
      '!border-primary-500': isFocused,
      '!border-error-500': error !== undefined,
      '!border-success-500': success,
    },
    className,
  );

  const textareaClass = classNames(
    'w-full bg-transparent resize-none custom-scrollbar relative outline-none opacity-0 transition-all duration-200 appearance-none text-sm -z-10 placeholder:text-white/60 h-full',
    {
      '!opacity-100 h-fit !z-0': isFocused || !label || value !== undefined || defaultValue !== undefined,
    },
  );

  const labelClass = classNames(
    'text-light-100 text-sm translate-y-2.5 transition-all duration-200',
    {
      '!translate-y-0 !text-xxs text-light-500 leading-none mb-0': (isFocused || value !== undefined || defaultValue !== undefined),
      'text-success-500': success,
      'text-error-500': error !== undefined,
    },
    labelClassName,
  );

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
      textareaRef.current?.focus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={classNames('w-full', boxClassName)}>
      <div className={containerClass} onClick={handleFocus}>
        {label && <span className={labelClass}>{label}</span>}
        <textarea
          aria-label={label}
          ref={textareaRef}
          data-testid={dataTestId}
          className={textareaClass}
          defaultValue={defaultValue}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...textareaProps}
        />
        {info ? (
          <Tooltip label={info} triggerClassName="absolute right-3 top-2">
            <GeneralIcons icon="info" className="text-light-500 hover:text-light-100 text-xs" />
          </Tooltip>
        ) : null}
      </div>
      <span className="text-xs text-error-500 w-full mt-1">{error}</span>
    </div>
  );
};

export default Textarea;
