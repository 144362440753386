import { z } from 'zod';

export const SupportedLanguagesSchema = z.union([
  z.literal('en-US'),
  z.literal('es-ES'),
  z.literal('fr-FR'),
  z.literal('de-DE'),
  z.literal('pt-PT'),
  z.literal('ja-JP'),
]);

// list of supported languages
export const SupportedLanguages = SupportedLanguagesSchema.array();

export type SupportedLanguages = z.infer<typeof SupportedLanguagesSchema>;
