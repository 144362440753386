import { Track } from '~/types/schemas/music/track.schema';
import { useSearchTrackItemList } from '~/hooks/entities/track/track-item-list/use-search-track-item-list';
import { TrackItemList } from '../track-item-list';
import { SearchListHeader } from './search-list-header';
import { SearchTrackItemListEmpty } from './search-track-item-list.empty';
import { TrackItem } from '../../track-item/track-item';
import { renderTrackItemSentinel } from '../../common/loader/sentinels';

type SearchTrackItemListProps = {
    scrollParentId: string
};

export function SearchTrackItemList(props: SearchTrackItemListProps) {
  const { scrollParentId } = props;
  const { tracks, isFetching, isLoading, handleNextPageFetch } = useSearchTrackItemList();

  if (isLoading) {
    return (
      <div className="flex-1 flex flex-col @container">
        <SearchListHeader isSimilarityEnabled={false} />
        {renderTrackItemSentinel({ flex: '1 1 0%' })}
      </div>
    );
  }

  if (tracks.length) {
    return (
      <div className="@container">
        <SearchListHeader isSimilarityEnabled={!!tracks?.[0]?.similarity} />
        <TrackItemList
          tracks={tracks}
          isFetching={isFetching}
          scrollParent={document.getElementById(scrollParentId)}
          onRangeChange={handleNextPageFetch}
          renderItem={(track) => <TrackItem type="search" track={track as Track} />}
          className="pt-2"
        />
      </div>
    );
  }

  return (
    <SearchTrackItemListEmpty />
  );
}
