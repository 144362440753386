/* eslint-disable consistent-return */
export function parseThousands(value?: string, separator?: string): string | undefined {
  if (!value) return;
  if (separator === '' || separator === undefined) return value;

  const addSeparator = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  const removeNonNumeric = (num: string) => num.toString().replace(/[^0-9]/g, '');

  return addSeparator(removeNonNumeric(value));
}
