import { useTranslation } from 'react-i18next';
import { DownloadCopyrights } from './copyrights';
import { DownloadHDMP3 } from './hd-mp3';
import { DownloadOriginal } from './original';
import * as ContextMenu from '../../common/context-menu';

type TrackActionsDownloadContextMenuProps = {
  children: React.ReactNode;
};

function TrackActionsDownload(props: TrackActionsDownloadContextMenuProps) {
  const { children } = props;
  const { t } = useTranslation('track-interactions');

  return (
    <ContextMenu.SubMenu>
      <ContextMenu.SubItem icon="ri:download-line">{t('download.tooltip')}</ContextMenu.SubItem>
      <ContextMenu.SubMenuContainer>
        {children}
      </ContextMenu.SubMenuContainer>
    </ContextMenu.SubMenu>
  );
}

const Container = TrackActionsDownload;
const Original = DownloadOriginal;
const HDMP3 = DownloadHDMP3;
const Copyrights = DownloadCopyrights;

export {
  Container,
  Original,
  HDMP3,
  Copyrights,
};
