import { Icon } from '@iconify/react';
import { FocusEventHandler, KeyboardEvent, MutableRefObject, Ref, forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputProps } from '../input';

interface TagInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function Tag(props: TagInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    labelInfo,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    defaultValue,
    value,
    iconLeft,
    iconRight,
    required = false,
    onFocus,
    onBlur,
    onChange,
    ...inputProps
  } = props;

  const initValue = value || defaultValue || '';

  const [input, setInput] = useState('');
  const [tags, setTags] = useState<string[]>(initValue ? initValue?.split(',').map((item) => item.trim() || '') : []);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setTags(value ? value?.split(',').map((item) => item.trim() || '') : []);
  }, [value]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value);
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === 'Enter' || key === ',') && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prev) => [...prev, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag || '');
    }

    setIsKeyReleased(false);
  }

  function onKeyUp() {
    setIsKeyReleased(true);
  }

  function deleteTag(index: number) {
    setTags((prev) => prev.filter((_, i) => i !== index));
  }

  function handleFocus() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  useEffect(() => {
    if (!isFirstRender) {
      onChange?.(tags.join(','));
    }
    setIsFirstRender(false);
  }, [tags]);

  return (
    <div className={className} data-no-dnd="true">
      <div className={classNames(containerClass, 'min-h-12 h-fit')} onClick={handleFocus}>
        <div className="flex gap-1">
          {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-error-500">*</span>}
          </span>
          )}
          { labelInfo && (
          <span className={labelClass}>
            {`- ${labelInfo}`}
          </span>
          )}
        </div>
        <div className="flex items-center gap-3">
          {iconLeft ? <Icon icon={iconLeft} className="text-light-500" /> : null}
          <div className="flex items-center gap-3 flex-1 overflow-x-auto hide-scrollbar flex-wrap">
            <div className="flex w-fit shrink-0 gap-1 flex-wrap items-center">
              {tags.map((item, index) => (
                <label key={`${item}-${index}`} htmlFor={`input-text-multiple-${item}`} className="h-full w-fit pl-2 pr-1  text-sm text-light-400 rounded bg-dark-100 flex gap-1 shrink-0">
                  <span>{item}</span>
                  <button id={`input-text-multiple-${item}`} onClick={() => deleteTag(index)} aria-label="delete item" type="button">
                    <Icon icon="ri:close-fill" className="text-light-500" />
                  </button>
                </label>
              ))}
              <input
                ref={inputRef}
                aria-label={label}
                placeholder={placeholder || label}
                className={classNames(inputClass, 'flex-1')}
                value={input}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onInputChange}
                {...inputProps}
              />
            </div>
          </div>
          {iconRight ? <Icon icon={iconRight} className="text-light-500" /> : null}
        </div>
      </div>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const TagInput = forwardRef(Tag);
