import { TagsInput } from '@ark-ui/react';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { useState } from 'react';

type TagInputProps = {
    label: string;
    labelInfo?: string;
    value: string[];
    placeholder?: string;
    className?: string;
    required?: boolean;
    disabled?: boolean;
    success?: boolean;
    error?: string
    onChange: (value: string[]) => void;
};

export function TagInput(props: TagInputProps) {
  const { label, labelInfo, value, placeholder, className, required, disabled, success, error, onChange } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [input, setInput] = useState('');

  const containerClass = classNames(
    'relative transition-all flex flex-col py-2 px-3 justify-center cursor-text border-r bg-dark-300 rounded transition duration-200 hover:border-dark-100',
    {
      '!border-primary-500': isFocused,
      '!border-transparent': !isFocused,
      '!border-error-500': !!error,
      '!border-success-500': success,
      'opacity-70 pointer-event-none': disabled,
    },
  );

  const inputClass = classNames(
    'w-fit bg-transparent opacity-100 relative outline-none transition-all duration-200 appearance-none text-sm h-4 !z-0 placeholder:text-white/60',
  );

  const labelClass = classNames(
    'text-light-100 text-sm translate-y-2.5 transition-all duration-200 !translate-y-0 !text-xxs text-light-500 leading-none mb-0',
    {
      'text-success-500': success,
      'text-error-500': error !== undefined,
    },
  );

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
    if (value.length === 0 && !!input) {
      onChange([input]);
      setInput('');
    }
  }

  return (
    <TagsInput.Root
      value={value}
      className={classNames('w-full', className)}
      onValueChange={(e) => onChange(e.value)}
      delimiter=";"
      validate={(details) => {
        return !details.value.includes(details.inputValue);
      }}
    >
      <TagsInput.Context>
        {(tagsInput) => (
          <div className={containerClass}>
            <div className="flex gap-1">
              {label && (
              <span className={labelClass}>
                {label}
                {required && <span className="text-error-500">*</span>}
              </span>
              )}
              { labelInfo && (
              <span className={labelClass}>
                {`- ${labelInfo}`}
              </span>
              )}
            </div>
            <div className="flex flex-wrap items-center gap-1">
              <TagsInput.Control className="flex w-fit shrink-0 gap-1 flex-wrap items-center">
                {tagsInput.value.map((v, index) => (
                  <TagsInput.Item key={index} index={index} value={v}>
                    <TagsInput.ItemPreview className="h-full w-fit pl-2 pr-1  text-sm text-light-400 border border-transparent rounded bg-dark-100 flex gap-1 shrink-0 data-[highlighted]:border-light-400/20">
                      <TagsInput.ItemText>{v}</TagsInput.ItemText>
                      <TagsInput.ItemDeleteTrigger>
                        <Icon icon="ri:close-fill" className="text-light-500" />
                      </TagsInput.ItemDeleteTrigger>
                    </TagsInput.ItemPreview>
                    <TagsInput.ItemInput className={inputClass} />
                  </TagsInput.Item>
                ))}
              </TagsInput.Control>
              <TagsInput.Input
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={classNames(inputClass, { '!opacity-20': !isFocused })}
              />
            </div>
          </div>
        )}
      </TagsInput.Context>
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
      <TagsInput.HiddenInput />
    </TagsInput.Root>
  );
}
