import useDataStore from '~/stores/mewo-data-store';

export function useTagData(id: string) {
  const data = useDataStore(['tags']);
  const tagsObject = data.tags.object;

  const parsedTag = tagsObject[id]!;

  return {
    tag: parsedTag,
  };
}
