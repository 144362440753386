import { Icon } from '@iconify/react';

interface EmptyProps {
  alt?: string
  icon: string
}

function Empty(props: EmptyProps) {
  const { icon, alt } = props;

  return (
    <div aria-label={alt} className="w-full h-full flex justify-center items-center bg-dark-300 text-full text-light-500">
      <Icon icon={icon} />
    </div>
  );
}

export default Empty;
