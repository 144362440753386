import * as Portal from '@radix-ui/react-portal';
import { motion } from 'framer-motion';
import { useRelativeTime } from '~/hooks/common/use-relative-time';
import Icon from '~/components/ui/icon/icon';
import classNames from 'classnames';
import { RealtimeUser } from '~/types/types/realtime';
import { parseMessage } from '../../chat/chat-messages/message-group/message/message-parser';

type ChatPreviewProps = {
    user?: RealtimeUser
    message?: string;
    totalMessages?: number;
    timetoken: string;
    portalStyle: React.CSSProperties;
};

function ChatPreview(props: ChatPreviewProps) {
  const { user, message, timetoken, portalStyle, totalMessages } = props;
  const textParts = parseMessage(message || '');
  const sentAt = useRelativeTime(+timetoken / 1e4);

  return (
    <Portal.Root className="absolute z-10" style={portalStyle}>
      <motion.div
        initial={{ width: '0', opacity: 0 }}
        animate={{ width: '15rem', opacity: 1 }}
        exit={{ width: '0', padding: 0, opacity: 0 }}
        transition={{ ease: 'easeIn', duration: 0.3 }}
        className="absolute left-0 bottom-0  w-fit rounded-lg overflow-hidden cursor-pointer"
      >
        <motion.div
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: '0%', opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
          className="flex gap-2 p-2 pr-9 bg-primary-500"
        >
          <div
            className="flex items-center justify-center w-6 h-6 shrink-0 overflow-hidden rounded-full aspect-square bg-light-500"
          >
            {user?.custom?.avatar ? <img src={user?.custom?.avatar} alt={user.name || ''} className="w-full h-full object-cover" /> : <Icon name="user-line" className="text-xs" />}
          </div>
          <div className="flex flex-col w-fit">
            <div className="flex text-xs gap-1.5 h-4 overflow-hidden">
              <h6 className="font-bold text-light-100 whitespace-nowrap line-clamp-1">{`${user?.name}`}</h6>
              {message && <span className="text-light-500 whitespace-nowrap w-fit">{sentAt}</span>}
            </div>
            <p className="text-xs line-clamp-3 text-light-100 shrink-0">
              {textParts.map((part, i) => (
                <span
                  key={`${part.text}-${part.type}-${i}`}
                  className={classNames({ 'font-bold': part.type === 'mention' })}
                >
                  {part.text}
                </span>
              ))}
            </p>
            <span className="text-xs text-lighth-500">{`${totalMessages} Messages`}</span>
          </div>
        </motion.div>
      </motion.div>
    </Portal.Root>
  );
}

export default ChatPreview;
