import dayjs from 'dayjs';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useEffect, useState } from 'react';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { useTranslation } from 'react-i18next';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import CardShelf from '../../../card-shelf/card-shelf';

function AlbumResultBlock() {
  const { albums, isLoading } = useAllTabData();
  const [currentAlbums, setCurrentAlbums] = useState(albums);
  const { handleFocusClick, handleClick, handleArtistClick } = useCardClick('album');
  const { t } = useTranslation('pre-search');

  useEffect(() => {
    if (!isLoading) {
      setCurrentAlbums(albums);
    }
  }, [isLoading, JSON.stringify(albums)]);

  return currentAlbums?.length ? (
    <CardShelf
      label={t('tab.all.albums.title')}
      containerClassName="flex-1 col-span-full"
      rowLimit={1}
      cardsProps={currentAlbums?.map((album) => ({
        type: 'album',
        artworks: album.artwork ? [album.artwork] : undefined,
        title: album.title,
        customSubtitle: album.artists.length ? <ClickableNameList onNameClick={handleArtistClick} names={album.artists} /> : null,
        subtitlePrefx: album.release_date ? dayjs(album.release_date).format('YYYY') : undefined,
        onCardClick: () => handleFocusClick(album.title, album.id),
        onIncludeClick: () => handleClick(album.title, album.id, 'is'),
        onExcludeClick: () => handleClick(album.title, album.id, 'is_not'),
      }))}
    />
  ) : null;
}

export default AlbumResultBlock;
