import { createWithEqualityFn } from 'zustand/traditional';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { createStorePicker } from '../utilities';
import { withImmer } from '../middlewares/immer-combine';

const usePlaylistFormStore = createStorePicker(createWithEqualityFn(
  withImmer({
    isOpen: false,
    playlist: undefined as Playlist | undefined,
  })(
    (set) => ({
      open: (playlist?: Playlist) => set((state) => {
        state.isOpen = true;
        state.playlist = playlist;
      }),
      close: () => set((state) => {
        state.isOpen = false;
        state.playlist = undefined;
      }),
      updatePlaylist: (playlist: Playlist) => set((state) => {
        state.playlist = playlist;
      }),
    }),
  ),
));

export default usePlaylistFormStore;
