import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type SearchTrackItemLayoutProps = PropsWithChildren<{
    className?: string
}>;

export function SearchTrackItemLayout(props: SearchTrackItemLayoutProps) {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        'grid items-center gap-4',
        'grid-cols-search-track-item-base',
        '@[50rem]:grid-cols-search-track-item-lg',
        className,
      )}
    >
      {children}
    </div>
  );
}
