import { Steps } from '~/types/features/track-upload/track-upload.types';

export const QUEUE_SIZE = 3;

export const STEPS_ORDER: Record<Steps, number> = {
  display: 1,
  noMatch: 1,
  search: 1,
  custom: 1,
  match: 2,
  matchLaunch: 2,
  upload: 2,
  waiting: 2,
};
