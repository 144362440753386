import { stringContainsTerm } from "../common/string-contains-term";
import removeAccents from 'remove-accents';
import { Tag } from "~/types/schemas/data/tags.schema";

export function tagSuffix(query:string, tag: Tag) {
  const cleanQuery = removeAccents(query.toLowerCase());
  const nameMatch = stringContainsTerm(cleanQuery, removeAccents(tag.name.toLowerCase()));
  const synonymMatch = query && !nameMatch
      ? tag.synonyms.find((v) => stringContainsTerm(cleanQuery, removeAccents(v.toLowerCase())))
      : undefined;

  if (synonymMatch === tag.name) return '';
  if (!synonymMatch) return '';

  return synonymMatch;
}
