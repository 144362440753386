import { useEffect, useState } from 'react';
import { TimeTicker } from '~/utils/common/time-ticker';
import { parseDateFromNow } from '~/utils/common/dayjs/relative-time/relative-time';

export function useRelativeTime(timestamp: number) {
  const [time, setTime] = useState<string>(parseDateFromNow(timestamp));

  useEffect(() => {
    let nextTime = timestamp;

    if (nextTime > Date.now()) {
      nextTime = Date.now();
    }

    const cb = TimeTicker.subscribe(() => {
      setTime(parseDateFromNow(nextTime));
    });

    return () => {
      TimeTicker.unsubscribe(cb);
    };
  }, [timestamp]);

  return time;
}
