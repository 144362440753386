/* eslint-disable camelcase */
import { useVariationCreate } from '~/hooks/api/track/use-variation-create';
import useMusemindStore from '~/stores/musemind-store';
import { useTranslation } from 'react-i18next';

export function useCreateTrackVariation(id: string, title: string, variation_ready?: boolean) {
  const musemind = useMusemindStore([
    'videoElement', 'isCreateVariationsLoading', 'setIsCreateVariationsLoading',
    'setIsUploadDialogOpen', 'setAudioTracks', 'setPlay',
  ]);
  const {
    isPending,
    data: variations,
    mutate: createVariationsMutation,
  } = useVariationCreate();
  const { t } = useTranslation('track-interactions');

  const isDisabled = !variation_ready || !musemind.videoElement;

  function createVariations() {
    if (!isDisabled) {
      musemind.setPlay(false);
      createVariationsMutation(id);
    } else {
      musemind.setIsUploadDialogOpen(true);
    }
  }

  let tooltip: string;

  if (variation_ready) {
    if (musemind.videoElement) {
      tooltip = t('magic-preview.tooltip.ready');
    } else {
      tooltip = t('magic-preview.tooltip.ready.empty');
    }
  } else {
    tooltip = t('magic-preview.tooltip.not-ready');
  }

  return {
    createVariations,
    tooltip,
    isDisabled,
    variations: variations?.result || [],
    isPending,
  };
}
