import useDataStore from '~/stores/mewo-data-store';

export function useTagColor(id?: string): string | undefined {
  const { tags, tagCategories } = useDataStore((s) => ({
    tags: s.tags.object,
    tagCategories: s.subCategories.object,
  }));

  return id && (tags[id]?.color || tagCategories[id]?.color || undefined);
}
