import { KeyboardEvent, MouseEvent, PropsWithChildren, useRef } from 'react';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { Track } from '~/types/schemas/music/track.schema';

type SelectionItemProps = PropsWithChildren<{
  value: Track;
  className?: string;
}>;

export function SelectionItem(props: SelectionItemProps) {
  const { value, className, children } = props;
  const { values, handleItemClick } = useSelectionContext();
  const ref = useRef<HTMLDivElement>(null);

  function handleEnterClick(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      handleItemClick(e, value);
    }
  }

  function handleClick(e: MouseEvent) {
    handleItemClick(e, value);
  }

  function mouseEnter() {
    ref.current?.focus();
  }

  function mouseLeave() {
    ref.current?.blur();
  }

  return (
    <div
      ref={ref}
      className={className}
      onKeyDown={handleEnterClick}
      onClick={handleClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      aria-selected={values.includes(value)}
    >
      {children}
    </div>
  );
}
