import Icon from '~/components/ui/icon/icon';
import { useTranslation } from "react-i18next";

export function NotificationListEmpty() {
  const { t } = useTranslation('notification-center');

  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <Icon name="notification-2-line" className="text-light-500 text-3xl mb-1" />
      <span className="text-xl font-bold">{t('empty-notification-list.title')}</span>
      <p className="text-light-400">{t('empty-notification-list.description')}</p>
    </div>
  );
}