import { z } from 'zod';
import { ArtworkSchema } from '../common/image.schema';
import { LocaleSchema } from '../common/locale.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { TenantSchema } from './tenant.schema';
import { TrackSchema } from './track.schema';

export const PlaylistUpdateAPISchema = z.object({
  title: z.string(),
  descriptions: z.array(LocaleSchema),
});

export const PlaylistSchema = z.object({
  artworks: ArtworkSchema.array().optional(),
  created_by: TenantSchema,
  created_at: z.string(),
  descriptions: LocaleSchema.array(),
  id: z.string(),
  owned_by_tenant: z.boolean(),
  tenant: TenantSchema,
  title: z.string(),
  tracks: z.number().optional(),
});

export const PlaylistUpdateSchema = z.object({
  title: z.string(),
  description: z.string(),
});

export const PlaylistSearchResultSchema = createSearchResultSchema(PlaylistSchema);
export const PlaylistTracksSchema = createSearchResultSchema(TrackSchema);

export type Playlist = z.infer<typeof PlaylistSchema>;
export type PlaylistSearchResult = z.infer<typeof PlaylistSearchResultSchema>;
export type PlaylistUpdate = z.infer<typeof PlaylistUpdateSchema>;
export type PlaylistUpdateAPI = z.infer<typeof PlaylistUpdateAPISchema>;
export type PlaylistTracks = z.infer<typeof PlaylistTracksSchema>;
