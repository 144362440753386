import * as Track from '~/components/entities/track/common/track';
import { SearchTrackItemLayout } from '~/components/layout/track-items/search-track-item.layout';
import { TrackItemDropdownMenu } from '../menus/dropdown-menu/dropdown-menu';
import { TrackItemHeaderSummary } from './summary/summary';
import { MagicPreview } from './actions/magic-preview';

export function SearchTrackItemHeader() {
  // named grid rows
  return (
    <SearchTrackItemLayout>
      <TrackItemHeaderSummary className="col-start-[summary]" />
      <Track.MusicType className="col-start-[music-type] justify-self-center" />
      <Track.Versions className="col-start-[versions] justify-self-center" />
      <Track.Popularity className="col-start-[popularity] justify-self-center" />
      <Track.Similarity className="col-start-[similarity] justify-self-center" />
      <Track.Waveform className="col-start-[waveform] justify-self-end hidden @[50rem]:block " />
      <Track.Duration className="col-start-[duration] justify-self-end" />
      <Track.ActionList className="col-start-[actions] justify-self-center">
        <MagicPreview />
        <TrackItemDropdownMenu />
      </Track.ActionList>
    </SearchTrackItemLayout>
  );
}
