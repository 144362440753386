import { FileUploadSessionSetup } from '../components/file-upload-session-setup';

type UploadSessionProviderProps = {
    context: string,
    contextId: string,
    session?: string,
    children: React.ReactNode;
    className?: string;
};

export function FileUploadSessionProvider(props: UploadSessionProviderProps) {
  const { context, contextId, session, children, className } = props;

  if (!session) {
    return <FileUploadSessionSetup context={context} contextId={contextId} />;
  }

  return (
    <div className={className}>
      {children}
    </div>
  );
}
