import { useTranslation } from 'react-i18next';
import { useDownloadCopyrights } from '~/hooks/entities/track/actions/download/use-download-copyrights';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as ContextMenu from '../../common/context-menu';

export function DownloadCopyrights() {
  const store = useTrackContext()
const track = store.use.track()
  const { t } = useTranslation('track-interactions');
  const { downloadTrackCopyright } = useDownloadCopyrights(track);

  return (
    <ContextMenu.Item
      onClick={downloadTrackCopyright}
      icon="ic:baseline-copyright"
    >
      {t('download.dropdown.group.info.copyrights')}
    </ContextMenu.Item>
  );
}
