import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import usePreSearchStore from '~/stores/presearch-store';
import Navigation from './components/navigation/navigation';
import Content from './components/content/content';

type PresearchProps = {
    className?: string;
};

function Presearch(props: PresearchProps) {
  const { className } = props;
  const presearch = usePreSearchStore(['isOpen', 'currentTab', 'setCurrentTab']);

  return presearch.isOpen ? (
    <div id="presearch" className={classNames('animate-slideInBottom bg-dark-300/80 backdrop-blur p-6 !pb-0', className)}>
      <Tabs.Root
        value={presearch.currentTab}
        onValueChange={presearch.setCurrentTab}
        className="h-full flex flex-col items-stretch gap-6"
      >
        <Navigation />
        <Content />
      </Tabs.Root>
    </div>
  ) : null;
}

export default Presearch;
