import * as SliderPrimitive from '@radix-ui/react-slider';
import classNames from 'classnames';

type SliderProps = {
    className?: string;
    min: number;
    max: number;
    value: number[];
    onValueChange?: (v: number[]) => void;
    onValueCommit?: (v: number[]) => void;
};

const Slider = (props: SliderProps) => {
  const { value, min, max, className, onValueChange, onValueCommit } = props;

  return (
    <SliderPrimitive.Root
      className={classNames('SliderRoot relative flex items-center select-none touch-none w-full', className)}
      value={value}
      max={max}
      min={min}
      step={1}
      aria-label="Volume"
      onValueCommit={onValueCommit}
      onValueChange={onValueChange}
    >
      <SliderPrimitive.Track className="relative grow rounded-full h-1 bg-[#3c3c3c]">
        <SliderPrimitive.Range className="SliderRange absolute bg-white h-full rounded-full" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="SliderThumb cursor-pointer hidden parent-hover:block w-4 h-4 bg-white shadow rounded-md" />
    </SliderPrimitive.Root>
  );
};

export default Slider;
