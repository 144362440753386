import { useTranslation } from 'react-i18next';
import { useRemoveTracksFromBrief } from '~/hooks/api/brief';
import TrackInteraction from '../interaction';

type RemoveFromBrief = {
    trackId: string;
    briefId: string;
};

const RemoveTrackFromBrief = (props: RemoveFromBrief) => {
  const { briefId, trackId } = props;
  const { mutate: removeTrack } = useRemoveTracksFromBrief(briefId);
  const { t } = useTranslation('track-interactions');

  return (
    <TrackInteraction
      icon="remove-line"
      tooltip={t('remove-from-brief.tooltip')}
      onClick={() => removeTrack({ ids: [trackId] })}
    />
  );
};

export default RemoveTrackFromBrief;
