import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { KyOptions } from 'ky/distribution/types/options';
import { ZodSchema } from 'zod';

export type useGenericMutationOptions<T, S> = {
  url: string;
  queryParams?: KyOptions;
  schema?: ZodSchema;
  updater?: (newData: T) => void;
  mutateOptions?: UseMutationOptions<S, Error, T>;
};

type useGenericMutationParams<T, S> = {
  func: (data?: T) => Promise<S>;
} & useGenericMutationOptions<T, S>;

export const useGenericMutation = <T, S>(params: useGenericMutationParams<T, S>) => {
  const { func, url, queryParams, schema, updater, mutateOptions } = params;
  const queryClient = useQueryClient();
  const { onError, onSettled, ...otherOptions } = mutateOptions || {};

  return useMutation<S, Error, T>({
    mutationFn: func,
    onMutate: async (data) => {
      const newData = schema ? schema.parse(data) : data;
      await queryClient.cancelQueries({ queryKey: [url!, queryParams] });

      const previousData = queryClient.getQueryData([url!, queryParams]);

      updater?.(newData);

      return { previousData };
    },
    onError: (...data) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_err, _var, context] = data;
      console.error({
        _err,
        _var,
        context,
      });
      queryClient.setQueryData([url!, queryParams], context);
      onError?.(...data);
    },
    onSettled: (...data) => {
      queryClient.invalidateQueries({ queryKey: [url!, queryParams] });
      onSettled?.(...data);
    },
    ...otherOptions,
  });
};
