import { fileUploadStore } from '~/stores/file-upload-store';
import { FileUploadSessionProvider } from '../file-upload/providers/file-upload-session.provider';
import FileList from './components/file-list/file-list';
import { Header } from './components/header';

type TracksUploadProps = {
    context: string
    contextId: string
};

export default function TracksUpload(props: TracksUploadProps) {
  const { context, contextId } = props;
  const { session } = fileUploadStore;

  return (
    <FileUploadSessionProvider
      session={session.value}
      context={context}
      contextId={contextId}
      className="w-full h-full overflow-hidden flex flex-col items-center gap-6 pt-4 relative"
    >
      <Header id={contextId} />
      <FileList />
    </FileUploadSessionProvider>
  );
}
