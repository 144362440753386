import { AnimatePresence } from 'framer-motion';
import { ContentCards } from './cards';
import { ChipList } from './chip-list/chip-list';
import { ContentDescription } from './description';
import { ContentVersions } from './versions/versions';

export function Content() {
  return (
    <div className="w-full flex flex-col gap-3">
      <ContentDescription />
      <ChipList />
      <ContentCards />
      <AnimatePresence>
        <ContentVersions />
      </AnimatePresence>
    </div>
  );
}
