const CardLoader = () => {
  return (
    <div className="flex w-full outline outline-dark-100/40 p-4  animate-pulse rounded-lg gap-4">
      <div className="flex-1 bg-dark-200 aspect-square rounded-md" />
      <div className="flex-[3]  flex flex-col">
        <div className="mt-2 h-3 mb-2 w-[30%] bg-dark-200 rounded" />
        <div className="my-3">
          <div className="bg-dark-200 h-4 mb-2 w-[50%] rounded" />
          <div className="bg-dark-200 h-3 w-[70%] rounded" />
        </div>
      </div>
    </div>
  );
};

export default CardLoader;
