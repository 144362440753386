import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';
import { Tonality } from '~/types/schemas/data/tonalities.schema';

export function useTonalityFilter() {
  const search = useSearchStore(['addValueToMatchingBlock']);

  function handleTonalityClick(tonality: Tonality) {
    search.addValueToMatchingBlock(termOptions.tonality_key!, itemConditionsOptions.is!, {
      value: tonality?.id,
      label: `${tonality.key} ${tonality.tonality}`,
    });
  }

  return {
    onTonalityClick: handleTonalityClick,
  };
}
