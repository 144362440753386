import classNames from 'classnames';
import { Brief } from '~/types/schemas/music/brief.schema';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';

type BriefLibraryHeaderProps = {
  briefs?: Brief[];
  total?: number;
  className?: string;
};

const BriefLibraryHeader = (props: BriefLibraryHeaderProps) => {
  const { briefs, total, className } = props;
  const opportunities = briefs?.reduce((acc, item) => acc + (item.budget || 0), 0).toString();
  const { t } = useTranslation("libraries");

  return (
    <div className={classNames('bg-dark-500 z-10', className)}>
      <div className="w-full flex">
        <h1 className=" text-xl ">
          {t('briefs.header.title')}
          {total ? ` (${parseThousands(total.toString(), ' ')})` : ''}
        </h1>
        <div className="flex items-center ml-auto">
          {opportunities ? (
            <span className="text-xs bg-dark-100 py-1 px-2 rounded">
              {t('briefs.header.opportunities')}
              {opportunities ? `: ${parseThousands(opportunities, ' ')}` : ''}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BriefLibraryHeader;
