import Button from '~/components/ui/control/button/button';
import usePreSearchStore from '~/stores/presearch-store';
import { useTranslation } from 'react-i18next';

const EmptyPlaceholder = () => {
  const presearch = usePreSearchStore(['query', 'setCurrentTab']);
  const { t } = useTranslation("pre-search");

  return (
    <div className="flex flex-col items-center justify-center w-full h-full col-span-full mt-[25vh]">
      <h1>{t('tab.empty.title', { query: presearch.query })}</h1>
      <p className="mt-2 text-gray-500">
        {t('tab.empty.description')}
      </p>
      <Button className="mt-6" onClick={() => presearch.setCurrentTab('all')}>
        {t('tab.empty.button.label')}
      </Button>
    </div>
  );
};

export default EmptyPlaceholder;
