import { Icon, IconifyIconProps } from '@iconify/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { ForwardedRef, PropsWithChildren, MouseEvent } from 'react';

type DropdownMenuItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  onClick?: (e: MouseEvent) => void;
  className?: string;
}> &
  DropdownMenu.DropdownMenuItemProps;

export const DropdownMenuItem = React.forwardRef(
  (props: DropdownMenuItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
    const { icon, onClick, children, className, ...rest } = props;

    function handleClick(e:MouseEvent) {
      e.stopPropagation();
      onClick?.(e);
    }

    return (
      <DropdownMenu.Item
        className={classNames(
          'flex items-center gap-3 h-8 px-2 text-white text-sm select-none rounded cursor-default outline-none border border-transparent focus:bg-dark-100  focus:border-white/10',
          className,
        )}
        onClick={handleClick}
        ref={ref}
        {...rest}
      >
        {icon && (
          <div className="size-4 flex items-center justify-center">
            <Icon icon={icon} className="w-4 h-4" />
          </div>
        )}
        <div className="flex-1">{children}</div>
      </DropdownMenu.Item>
    );
  },
);
