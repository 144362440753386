import { useState } from 'react';
import Button from '~/components/ui/control/button/button';
import { useSearchStore } from '~/stores/search-store';
import useAddFilterStore from '~/stores/add-filter-store';
import Range from '../../../range/range';
import Tab from '../tab';
import { useTranslation } from 'react-i18next';

function Bpm() {
  const [value, setValue] = useState<[number, number]>([0, 220]);
  const search = useSearchStore(['setBpm']);
  const addFilter = useAddFilterStore(['setIsOpen']);
  const { t } = useTranslation('filter');

  function handleApply() {
    search.setBpm(value[0], value[1]);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.bpm.tab.title')} value="bpm">
      <div className="flex flex-col justify-between h-full">
        <Range value={value} onValueChange={setValue} min={0} max={220} />
        <Button className="filter-panel__apply-button ml-auto mt-auto col-span-full" onClick={() => handleApply()}>{t('panel.slider.apply')}</Button>
      </div>
    </Tab>
  );
}

export default Bpm;
