import usePreSearchStore from '~/stores/presearch-store';
import IconClose from 'virtual:icons/ri/close-line';

function ClosePresearchButton() {
  const presearch = usePreSearchStore(['setIsOpen']);

  function handleClick() {
    presearch.setIsOpen(false);
  }

  return (
    <button type="button" className="ml-auto flex items-center justify-center h-6 w-6 bg-dark-100 rounded-full" onClick={handleClick}>
      <IconClose className="text-light-100" />
    </button>
  );
}

export default ClosePresearchButton;
