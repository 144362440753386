import { Artwork } from '~/components/ui/cover/artwork';
import { useTrackSummaryContext } from './track-summary.context';

export function TrackSummaryCover() {
  const { title, artwork } = useTrackSummaryContext();

  return (
    <div className="relative rounded select-none size-full">
      <Artwork alt={title} artworks={artwork?.small} icon="ri:music-line" className="text-base" />
    </div>
  );
}
