import { useState } from 'react';
import Dropdown, { dropdownGroups } from '~/components/ui/control/dropdown/dropdown';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import { useLogout } from '~/hooks/api/auth';
import { queryClient } from '~/boot/react-query';
import { useNavigate } from 'react-router-dom';

/* eslint-disable camelcase */
type ProfilProps = {
  isMini?: boolean
  className?: string
};

const Profil = (props: ProfilProps) => {
  const { isMini, className = '' } = props;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { mutate: logout } = useLogout();
  const { user } = globalStore;
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');

  const profilDropdown: dropdownGroups = [{
    elements: [
      {
        label: t('profil.settings.dropdown.settings'),
        navlink: '/settings',
      },
      {
        label: t('profil.settings.dropdown.logout'),
        onclick: () => logout(undefined, {
          onSuccess: () => {
            queryClient.removeQueries();
            navigate('/login');
          },

        }),
      },
    ],
  }];

  return (
    <div className="flex p-6 mt-auto">
      <div className={`flex gap-2 items-center w-full overflow-hidden ${className}`}>
        <div
          style={{ backgroundColor: user.value?.avatar_color }}
          className="w-8 flex justify-center items-center aspect-square rounded-lg"
        >
          <span className="text-xs font-bold text-white truncate">
            {user.value?.first_name[0]!.toUpperCase()}
            {user.value?.last_name[0]!.toUpperCase()}
          </span>
        </div>
        {!isMini && (
          <div>
            <h3 className="text-sm leading-4 font-bold">{`${user.value?.first_name} ${user.value?.last_name}`}</h3>
            <h4 className="text-xs leading-3">{user.value?.company_name}</h4>
          </div>
        )}
      </div>
      <Dropdown groups={profilDropdown} align="start" side="top" open={isDropDownOpen} onOpenChange={setIsDropDownOpen}>
        <Icon icon="ri:settings-2-line" />
      </Dropdown>
    </div>
  );
};

export default Profil;
