import { Agent } from '~/types/schemas/common/agent.schema';
import { useEffect, useState } from 'react';
import { ProviderGroup } from '~/types/schemas/music/brief.schema';
import { globalStore } from '~/stores/global-store-v2';

export type Group = {
  label: string;
  providers: Agent[];
};

export function useGroupList(defautlGroups: Group[], briefId?: string) {
  const [groups, setGroups] = useState<Group[]>(defautlGroups);
  const { user } = globalStore;

  useEffect(() => {
    // if default group is not deep equal to groups, update groups
    const isDeepEqual = JSON.stringify(defautlGroups) === JSON.stringify(groups);
    if (!isDeepEqual) setGroups(defautlGroups);
  }, [defautlGroups]);

  function disabledGroupsProviders(providers: ProviderGroup[]): ProviderGroup[] {
    const newGroups = providers.map((group) => {
      if (group.name === 'All companies') return group;
      return {
        ...group,
        providers: [],
        checked: false,
        disabled: true,
      };
    });
    return newGroups;
  }

  function recoverGroupsState(group: ProviderGroup, index: number): ProviderGroup[] {
    const selectedProviders = localStorage.getItem('brief-sharing_selected-providers');
    localStorage.removeItem('brief-sharing_selected-providers');

    if (selectedProviders) {
      const recoverGroups = JSON.parse(selectedProviders) as ProviderGroup[];
      recoverGroups[index] = group;
      return recoverGroups.map((g: ProviderGroup) => ({
        ...g,
        disabled: false,
      }));
    }

    return groups.map((g) => ({
      name: g.label,
      providers: [],
      checked: false,
      disabled: false,
    }));
  }

  function handleCheckClick(
    providers: string[],
    onChange: (value: ProviderGroup[]) => void,
    currentGroups: ProviderGroup[],
    groupIndex: number,
  ) {
    let newSelectedProviders: string[] = [];
    const isAllSelected = providers.every((provider) => currentGroups[groupIndex]?.providers.includes(provider));

    if (isAllSelected) {
      newSelectedProviders = [];
      currentGroups[groupIndex]!.checked = false;
      if (currentGroups[groupIndex]!.name === 'All companies') {
        currentGroups = recoverGroupsState(currentGroups[groupIndex]!, groupIndex);
      }
    } else {
      newSelectedProviders = [...providers];
      currentGroups[groupIndex]!.checked = true;
      if (currentGroups[groupIndex]!.name === 'All companies') {
        if (!localStorage.getItem('brief-sharing_selected-providers')) {
          localStorage.setItem('brief-sharing_selected-providers', JSON.stringify(currentGroups));
          currentGroups = disabledGroupsProviders(currentGroups);
        }
      }
    }

    currentGroups[groupIndex]!.providers = newSelectedProviders;
    localStorage.setItem(`brief-${briefId}-agents_unsaved`, JSON.stringify(currentGroups));

    onChange(currentGroups);
  }

  function handleFavClick(
    providers: string[],
    onChange: (value: ProviderGroup[]) => void,
    currentGroups: ProviderGroup[],
    groupIndex: number,
  ) {
    const newSelectedProviders = currentGroups[groupIndex]!.providers.filter((provider) => user.value?.favorite_agents?.includes(provider));
    const isSomeSelected = newSelectedProviders.length > 0 ? 'indeterminate' : false;
    const isEverySelected = newSelectedProviders.length === providers.length;

    currentGroups[groupIndex]!.providers = newSelectedProviders;
    currentGroups[groupIndex]!.checked = isEverySelected ? true : isSomeSelected;

    onChange(currentGroups);
  }

  return {
    groups,
    handleCheckClick,
    handleFavClick,
  };
}
