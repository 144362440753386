import { useTranslation } from 'react-i18next';
import { useAlbumCard } from '~/hooks/entities/track/cards/use-album-card';
import TrackCard from './card';

export function TrackCardAlbum() {
  const { content, onClick } = useAlbumCard();
  const { t } = useTranslation('music-item');

  return (
    <TrackCard
      label={t('album.title')}
      content={content}
      onContentClick={onClick}
    />
  );
}
