/* eslint-disable camelcase */
import { createWithEqualityFn } from 'zustand/traditional';
import { TrackInterestForm } from '~/types/schemas/music/track.schema';
import { createStorePicker } from '../utilities';
import { withImmer } from '../middlewares/immer-combine';

const defaultFormValues:TrackInterestForm = {
  production_type: '',
  production_name: '',
  requested_rights: '',
  licensing_client_name: '',
  licensing_country: '',
  creative_description: '',
  max_length: '',
  video_links: '',
  medias: '',
  duration: '',
  launch_date: '',
  territories: '',
  budget: 0,
  currency: '',
};

const useInterestFormStore = createStorePicker(createWithEqualityFn(
  withImmer({
    isOpen: false,
    trackId: undefined as string | undefined,
    trackTitle: undefined as string | undefined,
    interest: undefined as TrackInterestForm | undefined,
  })(
    (set) => ({
      open: (trackId: string, trackTitle: string) => set((state) => {
        const lastInterestFormData: TrackInterestForm | undefined = JSON.parse(localStorage.getItem('last_interest_form') || '{}') as TrackInterestForm;

        state.isOpen = true;
        state.trackTitle = trackTitle;
        state.interest = lastInterestFormData || defaultFormValues;
        state.trackId = trackId;
      }),
      close: () => set((state) => {
        state.isOpen = false;
        state.interest = undefined;
      }),
      updateInterest: (interest: TrackInterestForm) => set((state) => {
        state.interest = interest;
      }),
      clearFileds: () => set((state) => {
        state.interest = defaultFormValues;
      }),
    }),
  ),
));

export default useInterestFormStore;
