import { z } from 'zod';

export const ParticipantOrganizationSettingsSchema = z.object({
  meta: z.object({
    provider_types: z.array(z.string()),
    music_types: z.object({
      wants: z.array(z.string()),
    }),
  }),
  avatar: z.string().nullable().optional(),
});

export const ParticipantSchema = z.object({
  id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  company_name: z.string(),
  organization_settings: ParticipantOrganizationSettingsSchema,
  avatar_color: z.string().optional(),
});

export type Participant = z.infer<typeof ParticipantSchema>;
export type ParticipantOrganizationSettings = z.infer<typeof ParticipantOrganizationSettingsSchema>;
