type TextProps = {
  label: string
};

export function Text(props: TextProps) {
  const { label } = props;

  return (
    <div className="flex rounded items-center py-2 px-3 text-sm hover:bg-dark-200">
      {label}
    </div>
  );
}
