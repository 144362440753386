import classNames from 'classnames';

export type NameBase = {
  id: string;
  name: string;
};

type ClickableNameListProps<T> = {
    names?: T[];
    onNameClick?: (name: T) => void;
  };

export function ClickableNameList<T extends NameBase>(props: ClickableNameListProps<T>) {
  const { names, onNameClick } = props;

  return (
    <div className="text-xs text-light-500 inline-block overflow-hidden whitespace-nowrap text-ellipsis w-full line-clamp-1 select-none">
      {names?.map((name, i) => (
        <span
          key={`name-${name.id}`}
          className={classNames('w-fit shrink-0 whitespace-nowrap mr-0.5', {
            'cursor-pointer hover:underline  hover:text-light-100': onNameClick,
          })}
          onClick={() => onNameClick?.(name)}
        >
          {`${name.name}${names.length - 1 !== i ? ', ' : ''}`}
        </span>
      ))}
    </div>
  );
}
