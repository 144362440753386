/* eslint-disable react/button-has-type */
import React, { Ref } from 'react';
import Icon from '~/components/ui/icon/icon';
import { Icon as Iconify } from '@iconify/react';

type ButtonProps = {
  className?: string
  children?: React.ReactNode
  iconLeft?: string
  iconRight?: string
  external?: boolean
  disabled?: boolean
  dataTestId?: string
  isLoading?: boolean
  dataNoDnd?: boolean
  href?: string
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
};

const Button = React.forwardRef((props: ButtonProps, forwardRef: Ref<HTMLButtonElement>) => {
  const {
    children,
    iconLeft,
    iconRight,
    dataNoDnd = false,
    external = false,
    href,
    isLoading = false,
    disabled = false,
    className = '',
    dataTestId = '',
    type = 'button',
    style,
    onClick,
  } = props;

  const buttonContent = (
    <>
      {iconLeft && <Icon name={iconLeft} />}
      {children && children}
      {!isLoading && iconRight && <Icon name={iconRight} />}
      {isLoading && <Iconify icon="svg-spinners:90-ring-with-bg" className="text-xs text-light-100" />}
    </>
  );

  const disabledClassName = disabled
    ? 'opacity-50 hover:bg-primary-500 pointer-events-none'
    : '';
  const buttonClassName = `text-white gap-2 text-xs font-bold block bg-primary-500 h-fit select-none inline-flex px-3 py-2 items-center rounded hover:bg-primary-400 transition-colors duration-300 ${className} ${disabledClassName}`;

  if (external && href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={buttonClassName}
      >
        {buttonContent}
      </a>
    );
  }

  return (
    <button
      ref={forwardRef}
      type={type}
      data-testid={dataTestId}
      data-no-dnd={dataNoDnd}
      onClick={onClick}
      className={buttonClassName}
      style={style}
    >
      {buttonContent}
    </button>
  );
});

export default Button;
