import FormLayout, { FormLayoutProps } from '~/components/layout/form-layout';
import { Brief, BriefFormUpdate } from '~/types/schemas/music/brief.schema';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetBriefDetails } from '~/hooks/api/brief';
import General from './tabs/general';
import { useBriefForm } from './use-brief-form';
import Content from './tabs/content';
import Sharing from './tabs/sharing';

export type BriefFormProps = {
  brief?: Brief
  step?: number
};

export type BriefStepProps = {
  title?: string
  control: Control<BriefFormUpdate>
  brief?: Brief
  onExitClick?: () => void
  onDiscardAndExitClick?: () => void
  onSaveAndExitClick?: () => void
  onNextClick?: () => void
  onPreviousClick?: () => void
  onSendClick?: () => void
  onDeleteClick?: () => void
};

function BriefForm(props: BriefFormProps) {
  const { t } = useTranslation('brief-form');
  const { brief, step = 1 } = props;
  const { data: briefData } = useGetBriefDetails(brief?.id);
  const { control, handleSubmit } = useForm<BriefFormUpdate>();
  const { activeStep, handleNext, handleSaveAndExit, handleDeleteDraft, handleDiscardAndExit, handleSend, handlePreviousClick, handleStepClick } = useBriefForm(step, brief);

  const tabCommonProps: BriefStepProps = {
    brief: briefData,
    control,
    onDiscardAndExitClick: handleDiscardAndExit,
    onSaveAndExitClick: handleSubmit(handleSaveAndExit),
    onNextClick: handleSubmit(handleNext),
    onPreviousClick: handlePreviousClick,
    onDeleteClick: handleDeleteDraft,
    onSendClick: handleSubmit(handleSend),
  };

  const steps: FormLayoutProps['steps'] = [{
    title: t('stepper.step-one.title'),
    description: t('stepper.step-one.description'),
    tab: <General title={t('step-one.title')} {...tabCommonProps} />,
  }, {
    title: t('stepper.step-two.title'),
    description: t('stepper.step-two.description'),
    tab: <Content title={t('step-two.title')} {...tabCommonProps} />,
    disabled: !brief?.id,
  }, {
    title: t('stepper.step-three.title'),
    description: t('stepper.step-three.description'),
    tab: <Sharing title={t('step-three.title')} {...tabCommonProps} />,
    disabled: !brief?.id,
  }];

  return (
    <FormLayout
      activeStepIndex={activeStep}
      steps={steps}
      onStepClick={handleStepClick}
    />
  );
}

export default BriefForm;
