import classNames from 'classnames';
import GeneralIcons from '~/components/ui/icon/general-icons';
import useMusemindStore from '~/stores/musemind-store';
import UploadDialog from '~/components/features/musemind/components/upload-dialog';
import Icon from '~/components/ui/icon/icon';
import VideoThumbnail from './video-thumbnail';

/* eslint-disable camelcase */
type HideVideoProps = {
    className?: string
};

const HideVideo = (props: HideVideoProps) => {
  const { className = '' } = props;
  const musemind = useMusemindStore(['audioTitle', 'videoElement', 'videoName', 'isPipOpen', 'setIsPipOpen', 'resetStore']);

  const handleClick = () => {
    musemind.setIsPipOpen(!musemind.isPipOpen);
  };

  const handleVideoDelete = () => {
    musemind.resetStore();
  };

  return (
    <div className={`flex gap-2 items-center overflow-hidden ${className}`}>
      {musemind.videoElement ? (
        <>
          <button
            type="button"
            onClick={handleClick}
            className="relative shrink-0 group/hide-button w-14 h-10 flex justify-center items-center  rounded-md overflow-hidden bg-dark-100"
          >
            <VideoThumbnail />
            <div className={classNames(
              'absolute transition-opacity text-light-300 group-hover/hide-button:opacity-100 flex items-center justify-center text-lg inset-0 bg-dark-400/50',
              !musemind.isPipOpen ? 'opacity-100' : 'opacity-0',
            )}
            >
              {musemind.isPipOpen ? (
                <GeneralIcons icon="eye-line" />
              ) : (
                <GeneralIcons icon="eye-off-line" />
              )}

            </div>
          </button>
          <div className="overflow-hidden w-full">
            <h3 className="text-sm leading-4 font-bold whitespace-nowrap">{musemind.videoName}</h3>
            <h4 className="text-xs text-light-500 leading-3 line-clamp-1">{musemind.audioTitle}</h4>
          </div>
          <button type="button" className="w-5 h-5 text-light-500 hover:text-light-100 flex items-center justify-center rounded-full shrink-0  bg-dark-200 hover:bg-dark-100 transition-colors" onClick={handleVideoDelete}>
            <Icon name="close-line" />
          </button>
        </>
      ) : null}
      {!musemind.videoElement ? (
        <UploadDialog className="ml-auto" />
      ) : null}
    </div>
  );
};

export default HideVideo;
