import useInterestFormStore from '~/stores/forms/interest-form-store';
import { Track } from '~/types/schemas/music/track.schema';

export function useInterest(track: Track) {
  const interestForm = useInterestFormStore(['open']);

  function handleClick() {
    interestForm.open(track.id, `${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`);
  }

  return {
    handleClick,
  };
}
