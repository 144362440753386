import { z } from 'zod';

export const UserSchema = z.object({
  id: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  company_name: z.string().nullable().optional(),
  job: z.string().nullable().optional(),
  phone_number: z.string().nullable().optional(),
  account_type: z.string(),
  avatar_color: z.string(),
  tenant_id: z.string(),
  favorite_agents: z.array(z.string()).optional(),
});

export type User = z.infer<typeof UserSchema>;
