import { parseDateNumeric, parseTimeNumeric } from '~/utils/common/parse-date';

export function useMessageTime(rawTimetoken?: string) {
  if (!rawTimetoken) return '';

  // Parse the timetoken
  const timetoken = new Date(parseInt(rawTimetoken, 10) / 10e3);

  // Check if the timetoken is today
  const isToday = timetoken.getDate() === new Date().getDate();

  // Check if the timetoken is less than a week
  const isLessThanAWeek = new Date().getTime() - timetoken.getTime() < 604800000;

  // Check if the timetoken is less than a month
  const isLessThanAMonth = new Date().getTime() - timetoken.getTime() < 2592000000;

  if (isToday) {
    return parseTimeNumeric(timetoken.toString(), {
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  if (isLessThanAWeek) {
    return parseDateNumeric(timetoken.toString(), {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  if (isLessThanAMonth) {
    return parseDateNumeric(timetoken.toString(), {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  return parseDateNumeric(timetoken.toString(), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}
