import { useEffect, useState } from 'react';
import usePreSearchStore from '~/stores/presearch-store';
import { useSearchStore } from '~/stores/search-store';
import { useURLSimilarityTrack } from '~/hooks/api/track';

export function useHandleSimilarityUrlSearch() {
  const presearch = usePreSearchStore(['videoURL', 'resetVideoURL', 'setIsOpen']);
  const search = useSearchStore(['similarityURL', 'setSimilarityURL']);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: similarityUrlSearch } = useURLSimilarityTrack();

  const video = presearch.videoURL;

  useEffect(() => {
    if (video?.title && video.youtubeId) {
      similarityUrlSearch({ url: `https://www.youtube.com/watch?v=${video.youtubeId}` });
      presearch.setIsOpen(false);
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [similarityUrlSearch, video?.title, video?.youtubeId]);

  useEffect(() => {
    if (video?.jobId) {
      setIsLoading(false);
      search.setSimilarityURL(video.youtubeId, video.title, video.jobId);
    }
  }, [video?.jobId, search.setSimilarityURL, video?.title, video?.youtubeId]);

  useEffect(() => {
    presearch.resetVideoURL();
  }, [search.similarityURL, presearch.resetVideoURL]);

  return {
    title: video?.title,
    isLoading,
  };
}
