import classNames from 'classnames';
import { ReactNode } from 'react';

type InfoChipProps = {
    value: string;
    color?: string;
    iconLeft?: ReactNode;
    className?: string;
};

const InfoChip = (props: InfoChipProps) => {
  const { value, iconLeft, color, className } = props;

  return (
    <span
      className={classNames(
        'h-fit rounded py-1 px-2 text-light-100 flex items-center gap-2 text-xs bg-dark-300',
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {iconLeft}
      {value}
    </span>
  );
};

export default InfoChip;
