import { z } from 'zod';

const SpotifyTrackSchema = z.object({
  id: z.string(),
  title: z.string(),
  artists: z.array(z.string()),
  album: z.string().optional(),
  cover: z.string().optional(),
});

const SpotifySearchHitsSchema = z.array(SpotifyTrackSchema);

type SpotifyTrack = z.infer<typeof SpotifyTrackSchema>;
type SpotifySearchHits = z.infer<typeof SpotifySearchHitsSchema>;

export { SpotifyTrackSchema, SpotifySearchHitsSchema };
export type { SpotifyTrack, SpotifySearchHits };
