import classNames from 'classnames';
import { filesize } from 'filesize';

type FileProps = {
    name?: string;
    extension?: string;
    size?: number;
    onClick?: () => void;
};

function File(props: FileProps) {
  const { name, extension, size, onClick } = props;
  const parsedSize = size ? filesize(size, { standard: 'jedec' }) : null;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames('relative flex gap-3 text-start items-center w-fit max-w-[85%] h-fit text-xs text-ligth-400 py-1.5 pl-2 pr-4 bg-dark-100 rounded group/file-preview')}
    >
      <div onClick={props.onClick} className="p-1 px-2 text-xxs bg-dark-200 rounded">
        {`${extension?.toUpperCase()}`}
      </div>
      <div className="flex flex-col flex-1 overflow-hidden">
        <span className="flex-1 ellipsis overflow-hidden text-ellipsis">{name}</span>
        {size ? <span className="text-xxs text-ligth-500">{parsedSize}</span> : null}
      </div>
    </button>
  );
}

export default File;
