import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useBlur } from '~/hooks/utils/use-has-focus';

export function SensitiveContent() {
  const blur = useBlur();

  return (
    <div className={classNames('h-full overflow-hidden', { 'blur-md pointer-events-none': blur })}>
      <Outlet />
    </div>
  );
}
