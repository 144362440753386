import { BpmRangeType, FilterBlock, PopularityRangeType, ReleaseDateRangeType } from '~/stores/search-store';

export const albumKeys = {
  root: ['album'] as const,
  search: (query: string, options?: unknown) => [...albumKeys.root, 'albums', query, options] as const,
};

export const artistKeys = {
  root: ['artist'] as const,
  search: (query?: string) => [...artistKeys.root, 'search', query] as const,
};

export const authKeys = {
  root: ['auth'] as const,
  login: () => [...authKeys.root, 'login'] as const,
  logout: () => [...authKeys.root, 'logout'] as const,
  session: () => [...authKeys.root, 'session'] as const,
};

export const briefKeys = {
  root: ['brief'] as const,
  list: () => [...briefKeys.root, 'list'] as const,
  search: (query?: string) => [...briefKeys.root, 'search', query] as const,
  detail: (id?: string) => [...briefKeys.root, id, 'detail'] as const,
  tracks: (id?: string) => [...briefKeys.root, id, 'tracks'] as const,
  channels: (id?: string) => [...briefKeys.root, id, 'channels'] as const,
  participants: (id?: string, trackId?: string) => [...briefKeys.root, id, 'participants', trackId] as const,
};

export const catalogKeys = {
  root: ['catalog'] as const,
  search: (query?: string) => [...catalogKeys.root, 'search', query] as const,
};

export const dashboardKeys = {
  root: ['dashboard'] as const,
  channels: () => [...dashboardKeys.root, 'channels'] as const,
  agents: () => [...dashboardKeys.root, 'agents'] as const,
  providers: () => [...dashboardKeys.root, 'providers'] as const,
};

export const optionKeys = {
  root: ['option'] as const,
  other: (language: string) => [...optionKeys.root, 'others', language] as const,
};

export const playlistKeys = {
  root: ['playlist'] as const,
  list: () => [...playlistKeys.root, 'list'] as const,
  search: (query?: string) => [...playlistKeys.root, 'search', query] as const,
  reorder: (id?: string, tracksId?: string[]) => [...playlistKeys.root, id, 'reorder', tracksId] as const,
  detail: (id?: string) => [...playlistKeys.root, id, 'detail'] as const,
  tracks: (id?: string) => [...playlistKeys.root, id, 'tracks'] as const,
  removeTrack: (playlistId?: string, trackId?: string) =>
    [...playlistKeys.root, playlistId, 'removeTrack', trackId] as const,
};

export const tagKeys = {
  root: ['tags'] as const,
  list: (language: string) => [...tagKeys.root, 'list', language] as const,
  search: (query?: string, type?: number, language?: string) => [...tagKeys.root, 'search', query, type, language] as const,
};

export const trackKeys = {
  root: ['track'] as const,
  versions: (trackId?: string) => [...trackKeys.root, 'versions', trackId] as const,
  presearch: (query?: string) => [...trackKeys.root, 'presearch', query] as const,
  search: (blocks?: FilterBlock[], similarityTrackId?: string, similarityURL?: string, sort?: string, releaseDate?: ReleaseDateRangeType, popularity?: PopularityRangeType, bpm?: BpmRangeType, query?: string, musicType?: string[]) => [
    ...trackKeys.root,
    ...(blocks ? blocks.flatMap((block) => {
      const values = block.values.filter((value) => value.value);

      if (values.length) {
        return [
          block.term.value,
          block.condition.value,
          ...values.map((value) => value.value),
        ];
      }

      return [];
    }) : []),
    similarityTrackId,
    similarityURL,
    sort,
    releaseDate,
    popularity,
    bpm,
    query,
    musicType,
  ] as const,
  detailList: (briefId?: string, trackId?: string) => [...trackKeys.root, 'detailList', briefId, trackId] as const,
};

export const samplersKeys = {
  root: ['samplers'] as const,
  search: (query?: string) => [...samplersKeys.root, 'search', query] as const,
};

export const tracklistingKeys = {
  root: ['internalRef'] as const,
  list: (query?: string, trackId?: string, collection?: ('playlists' | 'briefs')[]) => [...tracklistingKeys.root, 'list', query, trackId, collection] as const,
  addTrack: (refType?: string, refId?: string, trackId?: string) =>
    [...playlistKeys.root, 'addTrack', refType, refId, trackId] as const,
  copyrights: () => [...tracklistingKeys.root, 'copyrights'] as const,
};

export const uploadKeys = {
  root: ['upload'] as const,
  querySearch: (query: string) => [...uploadKeys.root, 'query', query] as const,
  sessionUploads: (sessionId: string) => [...uploadKeys.root, 'session', sessionId] as const,
  uploadsInfo: (uploadsId: Array<string>) => [...uploadKeys.root, 'uploads', ...uploadsId] as const,
  audioMatches: (fileIds: Array<string>) => [...uploadKeys.root, 'audio', ...fileIds] as const,
  trackSearch: (query: string) => [...uploadKeys.root, 'trackSearch', query] as const,
};
