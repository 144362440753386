import { SuggestedSearchesSchema } from '~/types/types/suggested-searches';
import suggestedSearch from '~/data/suggested-searches.json';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import usePreSearchStore from '~/stores/presearch-store';
import PresearchBlock from '../../../presearch-block/presearch-block';
import SuggestedSearch from '../../../suggested-search/suggested-search';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n/config';

function ComplexSearchExamples() {
  const parsedSuggestedSearch = z.array(SuggestedSearchesSchema).parse(suggestedSearch);
  const presearch = usePreSearchStore(['setIsOpen']);
  const navigate = useNavigate();
  const { t } = useTranslation("pre-search");

  const handleClick = (searchParams: string) => {
    presearch.setIsOpen(false);
    navigate(searchParams);
  };

  return (
    <PresearchBlock
      label={t('tab.home.search-examples.label')}
      containerClassName="flex-1"
      childrenClassName="grid"
      childrenStyle={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
    >
      {parsedSuggestedSearch.map((item, index) => {
        const title = item.locales[i18n.language as keyof typeof item.locales]?.title || '';

        return (<SuggestedSearch
          key={`complex-example-${title}-${index}`}
          title={title}
          artwork={item.artworks}
          onClick={() => handleClick(item.searchParams)}
        />
        )
      })}
    </PresearchBlock>
  );
}

export default ComplexSearchExamples;
