import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useAlbumFilter } from '~/hooks/filter/use-album-filter';

export function useAlbumCard() {
  const { onAlbumClick } = useAlbumFilter();
  const store = useTrackContext();
  const track = store.use.track();

  const title = track.album?.name;
  const artists = track.album?.display_artists?.map((artist) => artist.name).join(', ');

  const content = `${title} ${track.album?.display_artists?.length ? `- ${artists}` : ''}`;

  function handleClick() {
    if (track.album) onAlbumClick(track.album?.id, track.album?.name);
  }

  return {
    content,
    onClick: handleClick,
  };
}
