import classNames from 'classnames';
import { ContentBlock } from './content';
import { MusicTypeBlock } from './music-type';
import { ScopeBlock } from './scope';
import { VideoLinksBlock } from './video-links';

export function BriefDescriptionBlocks() {
  return (
    <div className={classNames('flex gap-2 p-2 h-full overflow-hidden')}>
      <div className="flex-1">
        <ContentBlock />
      </div>
      <div className="w-[30%] flex flex-col gap-2 items-stretch">
        <div className="h-14">
          <MusicTypeBlock />
        </div>
        <ScopeBlock />
        <VideoLinksBlock />
      </div>
    </div>
  );
}
