/* eslint-disable camelcase */
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Locale } from '~/types/schemas/common/locale.schema';
import Information from '~/pages/briefs/components/information';

type PlaylistInformationsProps = {
  descriptions?: Locale[],
};

const LOCALE = 'en';

const PlaylistInformations = (props: PlaylistInformationsProps) => {
  const { descriptions } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('playlist-page');

  if (!props) return null;
  const description = descriptions?.find((d) => d.locale === LOCALE)?.value || descriptions?.find((d) => d.value)?.value || '';

  return (

    <div className={classNames(
      'relative w-full p-2 bg-dark-300 rounded-lg overflow-hidden h-fit',
      {
        'max-h-36': !isExpanded,
      },
    )}
    >
      <div className="flex">
        <Information
          title={t('header.playlist-content.description.label')}
          className="w-[70%] pb-6"
        >
          <div className="gap-2 text-xs whitespace-pre-wrap">
            {description}
          </div>
        </Information>
      </div>
      <div className={classNames(
        'left-0 bottom-0 w-full flex items-center justify-center bg-gradient-to-t from-dark-300 absolute',
      )}
      >
        <button
          type="button"
          className="text-xs text-center p-2 bg-dark-300 text-light-500 hover:text-light-100 transition-all duration-100"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? t('header.brief-content.show-less.label', { ns: 'brief-page' }) : t('header.brief-content.show-more.label', { ns: 'brief-page' })}
        </button>
      </div>
    </div>
  );
};

export default PlaylistInformations;
