import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(UpdateLocale);
dayjs.updateLocale('fr', {
  relativeTime: {
    future(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `dans ${time}`;
    },
    past(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `il y a ${time}`;
    },
    s: 'à l\'instant',
    ss: 'quelques secondes',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 heure',
    hh: '%d heures',
    d: '1 jour',
    dd: '%d jours',
    M: '1 mois',
    MM: '%d mois',
    y: '1 an',
    yy: '%d ans',
  },
});
