import { useMutation } from '@tanstack/react-query';
import { globalStore } from '~/stores/global-store-v2';
import useMusemindStore from '~/stores/musemind-store';
import { TrackVariationCreateOutputSchema } from '~/types/schemas/musemind/variation.schema';
import { getHttp } from '~/utils/http/client';

export function useVariationCreate() {
  const client = getHttp();
  const musemind = useMusemindStore(['videoElement', 'setAudioTracks', 'setIsCreateVariationsLoading']);

  return useMutation({
    mutationFn: async (trackId: string) => {
      const [data] = await Promise.all([
        client.post('searchmode/track/variation/create', {
          json: {
            id: trackId,
            duration: musemind.videoElement?.duration,
            variations: 3,
          },
          timeout: 200000,
        }).json(),
        ...(!globalStore.loggedAs.value ? [
          client.post('action', {
            json: {
              context: 'SEARCHMODE',
              action: {
                type: 'GENERATE_MIX',
                target: trackId,
              },
            },
          }).json(),
        ] : []),
      ]);

      return TrackVariationCreateOutputSchema.parse(data);
    },
    onMutate: () => {
      musemind.setIsCreateVariationsLoading(true);
    },
    onSuccess: (data) => {
      if (data?.result && musemind.videoElement) {
        setTimeout(() => {
          musemind.setIsCreateVariationsLoading(false);
        }, 1000);
      }
    },
  });
}
