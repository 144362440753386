/* eslint-disable no-extra-boolean-cast */
import { ChangeEvent, FocusEventHandler, MouseEvent, MutableRefObject, Ref, forwardRef, useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface FileInputProps extends InputProps {
    containerClass?: string;
    inputClass?: string;
    labelClass?: string;
    isFocused: boolean;
    onFocus: FocusEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
}

function File(props: FileInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    value,
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    labelClass,
    error,
    required = false,
    isLoading,
    onChange,
    onFilesChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  const containerRef = useRef<HTMLLabelElement>(null);
  const [files, setFiles] = useState<FileList | null>((inputRef as MutableRefObject<HTMLInputElement>).current?.files);

  function handleUploadCLick(e: MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    (inputRef as MutableRefObject<HTMLInputElement>).current?.click();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.files?.[0]?.name || '');
    onFilesChange?.(Array.from(e.target.files || []));
    setFiles(e.target.files);
  }

  function handleFocus() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  return (
    <div className={className} data-no-dnd="true">
      <label
        ref={containerRef}
        htmlFor={`file-${label}`}
        onClick={handleFocus}
        className={classNames(containerClass, '!cursor-pointer group/file-input !flex-row gap-3 items-center')}
      >
        <div className="flex flex-col flex-1">
          {label && (
            <span className={labelClass}>
              {label}
              {required && <span className="text-error-500">*</span>}
            </span>
          )}
          <div className="flex items-center gap-3 overflow-hidden">
            <span className={classNames(inputClass, 'line-clamp-1 break-all opacity-100', {
              'text-light-100': files?.length || value,
              'text-light-400': !files?.length && !value,
            })}
            >
              {
                value || files?.length
                  ? value || files?.item(0)?.name
                  : placeholder || label
              }
            </span>
          </div>
        </div>
        <div className="transition-all size-6 shrink-0 flex items-center justify-center rounded bg-dark-400 group-hover/file-input:bg-dark-400/60" onClick={handleUploadCLick}>
          <Icon icon={isLoading ? 'svg-spinners:90-ring-with-bg' : 'ri:upload-line'} className="text-light-100 text-xs" />
        </div>
      </label>
      <input
        id={`file-${label}`}
        ref={inputRef}
        aria-label={label}
        type="file"
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...inputProps}
        hidden
      />
      {error ? <span className="error text-xs text-error-500 w-full mt-1">{error}</span> : null}
    </div>
  );
}

export const FileInput = forwardRef(File);
