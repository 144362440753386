import Tracker from '@openreplay/tracker';
import { StartOptions } from '@openreplay/tracker/dist/lib/main/app';
import { useEffect } from 'react';
import { globalStore } from '~/stores/global-store-v2';

class OpenReplay {
  private static Tracker: Tracker | null = null;

  static init = false;

  // eslint-disable-next-line class-methods-use-this
  static get tracker() {
    if (!OpenReplay.Tracker) {
      OpenReplay.Tracker = new Tracker({
        projectKey: import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY,
        defaultInputMode: 0,
        network: {
          failuresOnly: false,
          sessionTokenHeader: false,
          ignoreHeaders: false,
          capturePayload: true,
          captureInIframes: true,
        },
      });
      OpenReplay.init = true;
    }

    return OpenReplay.Tracker;
  }

  static start(options: StartOptions) {
    if (!OpenReplay.init) {
      OpenReplay.tracker.start(options);
    }
  }

  static stop() {
    if (OpenReplay.init) {
      OpenReplay.tracker.stop();
    }
  }
}

export const useOpenReplay = () => {
  const { user: { value: user }, loggedAs: { value: loggedAs } } = globalStore;

  useEffect(() => {
    if (!!user && !loggedAs && import.meta.env.VITE_APP_ENV !== 'dev') {
      console.debug('OpenReplay started');
      OpenReplay.start({
        metadata: {
          tenantId: user.id,
          name: `${user.first_name} ${user.last_name}`,
        },
        userID: user.email,
      });
    } else {
      OpenReplay.stop();
    }
  }, [user, loggedAs]);
};
