import { useQuery } from '@tanstack/react-query';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { realtimeStore } from '~/stores/realtime-store';
import { RealtimeUser } from '~/types/types/realtime';

export function useRealtimeUser() {
  const { pubnub } = useRealtime();

  return useQuery({
    enabled: !!realtimeStore.userToFetch.value.length,
    queryKey: ['chat-user', realtimeStore.userToFetch.value],
    queryFn: async () => {
      const filter = realtimeStore.userToFetch.value.map((id) => `id == "${id}"`).join(' || ');

      realtimeStore.userToFetch.value = [];

      if (!filter.length) {
        return [];
      }

      const result = await pubnub.objects.getAllUUIDMetadata<RealtimeUser['custom']>({
        include: {
          customFields: true,
        },
        filter,
      });

      const users: Record<string, RealtimeUser> = {};

      result.data.forEach((user) => {
        users[user.id] = user as RealtimeUser;
      });

      realtimeStore.users.value = {
        ...realtimeStore.users.value,
        ...users,
      };

      return result.data;
    },
  });
}
