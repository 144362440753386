import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type TrackActionListProps = PropsWithChildren<{
    className?: string
}>;

export function TrackActionList(props: TrackActionListProps) {
  const { children, className } = props;

  return (
    <div className={classNames('flex gap-2 justify-end', className)}>
      {children}
    </div>
  );
}
