import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { Brief } from '~/types/schemas/music/brief.schema';

type BriefAsideChannelProps = {
    brief?: Brief
    children: React.ReactNode;
};

export function BriefProvider(props: BriefAsideChannelProps) {
  const { brief, children } = props;
  const location = useLocation();
  const briefForm = useBriefFormStore(['isOpen', 'open', 'clear', 'updateBrief']);

  useEffect(() => {
    const isNotPublic = !brief?.public && location.pathname === `/briefs/${brief?.id}`;

    if (isNotPublic) {
      if (!briefForm.isOpen) {
        const clear = async () => briefForm.clear();
        const openNew = async () => briefForm.open(brief);
        clear().then(openNew);
      }
    }
  }, [brief?.id]);

  return children;
}
