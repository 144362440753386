import { useBpmChip } from '~/hooks/entities/track/chips/use-bpm-chip';
import TrackChip from './chip';

export function TrackChipBpm() {
  const { bpm, onClick } = useBpmChip();

  return (
    <TrackChip icon="ri:pulse-line" label={bpm} onClick={onClick} />
  );
}
