import { z } from 'zod';

export const organizationMeta = z.object({
  provider_types: z.array(z.string()),
  music_types: z.object({
    wants: z.array(z.string()),
  }),
});

export const organizationSettings = z.object({
  meta: organizationMeta,
  avatar: z.string().nullable().optional(),
});

export const AgentSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  account_type: z.string(),
  avatar_color: z.string(),
  language: z.string(),
  company_name: z.string(),
  phone_number: z.string().nullable().optional(),
  job: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  organization_settings: organizationSettings,
  id: z.string(),
  tenant_id: z.string(),
  active_as_agent: z.boolean(),
  read_only_agent: z.boolean(),
  agent_can_access_contact_infos: z.boolean(),
  agent_can_access_tracks_splits: z.boolean(),
  agent_can_access_notes: z.boolean(),
});

export const ProviderSchema = z.object({
  id: z.string(),
  organization_settings: organizationSettings,
});

export type Agent = z.infer<typeof AgentSchema>;
export type Provider = z.infer<typeof ProviderSchema>;
