import DownloadButton from '~/components/entities/tracklist/brief/interactions/download-button';
import EditPlaylist from '~/components/entities/tracklist/brief/interactions/edit-playlist-button';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import DeletePlaylist from '~/components/entities/tracklist/brief/interactions/delete-playlist';
import MoreActionButton from './more-action-button';

type InteractionsProps = {
    id: string;
    data: Playlist;
    isEmpty?: boolean;
    ownedByTenant?: boolean;
};

const PlaylistInteractions = (props: InteractionsProps) => {
  const { id, ownedByTenant, isEmpty, data } = props;

  return (
    <div className="tracklist__actions flex h-8 gap-4 text-xs">
      {!isEmpty ? <DownloadButton context="playlist" id={id!} className="w-fit h-full" /> : null}
      <EditPlaylist data={data} />
      {ownedByTenant ? <DeletePlaylist id={id!} className="tracklist__actions--delete w-fit h-full bg-red-500 hover:bg-red-400" /> : null}
      {!isEmpty ? <MoreActionButton id={id!} isEmpty={isEmpty} /> : null}
    </div>
  );
};

export default PlaylistInteractions;
