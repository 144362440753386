import classNames from 'classnames';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useComputed } from '@preact/signals-react';
import { trackUploadStore } from '~/stores/track-upload-store';
import React from 'react';
import { TrackSteps } from '../track-steps/track-steps';
import FileData from '../file-data/file-data';

type TrackListRowProps = {
    file: UploadTrack
    index: number
};

function FileListItem(props: TrackListRowProps) {
  const { file, index } = props;
  const { selected, validated } = trackUploadStore;
  const isSelected = useComputed(() => selected?.value === file.id).value;
  const isValidated = useComputed(() => validated?.value?.includes(file.id)).value;

  return (
    <li
      className={classNames('flex gap-4 group/track-item pb-', {
        'h-[32rem]': isSelected,
        'h-20': !isSelected,
      })}
    >
      <FileData file={file} isSelected={isSelected} index={index} />
      <TrackSteps file={file} isSelected={isSelected} isValidated={isValidated} />
    </li>
  );
}

export default React.memo(FileListItem);
