/* eslint-disable camelcase */
import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { PropsWithChildren, useMemo } from 'react';
import { useTrackContext } from '../track.context';

type TrackDragProviderProps = PropsWithChildren<{
  isSortable?: boolean;
}>;

const TrackDragProvider = (props: TrackDragProviderProps) => {
  const { children, isSortable = false } = props;
  const { values } = useSelectionContext();

  const store = useTrackContext();
  const track = store.use.track();

  const { id } = track;

  const defaultData = useMemo(
    () => ({ type: 'track', track, isSortable }),
    [track, isSortable],
  );

  const multipleData = useMemo(
    () => ({ type: 'track-group', tracks: values, isSortable }),
    [values, isSortable],
  );

  const {
    isOver,
    activeIndex,
    overIndex,
    attributes,
    listeners,
    setNodeRef,
  } = useSortable({ id, data: values.length > 1 ? multipleData : defaultData });

  const direction = useMemo(
    () => activeIndex - overIndex <= 0,
    [activeIndex, overIndex],
  );

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames('relative group/track-item outline-none border-y border-transparent', isSortable && isOver && {
        'border-b-primary-500/70': direction,
        'border-t-primary-500/70': !direction,
      })}
    >
      {children}
    </div>
  );
};

export default TrackDragProvider;
