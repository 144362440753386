import { useTranslation } from 'react-i18next';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { DialogDescription, DialogTitle } from '../common';
import { DialogExit } from '../common/exit';

export function LyricsHeader() {
  const store = useTrackContext()
const track = store.use.track()
  const { t } = useTranslation('track-interactions');

  return (
    <div className="w-full flex justify-between items-center mb-3">
      <div>
        <DialogTitle>
          {t('lyrics.tooltip')}
        </DialogTitle>
        <DialogDescription className="line-clamp-1 mr-4">
          {`${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`}
        </DialogDescription>
      </div>
      <div className="flex items-center gap-3">
        <DialogExit className="!bg-dark-100 hover:!bg-dark-200">
          {t('button.exit.label', { ns: 'common' })}
        </DialogExit>
      </div>
    </div>

  );
}
