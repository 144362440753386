import * as Track from '~/components/entities/track/common/track';
import { useArtistFilter } from '~/hooks/filter/use-artist-filter';

export function SummaryArtist() {
  const { onArtistClick } = useArtistFilter();

  return (
    <Track.Summary.Artists onArtistClick={onArtistClick} />
  );
}
