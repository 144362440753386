import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useComputed } from '@preact/signals-react';
import { trackUploadStore } from '~/stores/track-upload-store';
import { Icon } from '@iconify/react/dist/iconify.js';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';
import { useDeleteUpload, useValidateTrack } from '~/hooks/api/upload';
import { getValidateParams } from '~/components/features/tracks-upload/utils/validate-match-helper';
import { TrackInformationDisplay } from '../../file-info-display';
import { FileGlobalData } from '../../file-data/file-global-data/file-global-data';
import TrackDetailGroup from '../../track-details/track-detail-group';

type TrackOldProps = {
  file: UploadTrack
  uploadId?: string
  isSelected?: boolean
  isValidated?: boolean
};

export default function TrackDisplay({ file, uploadId, isSelected, isValidated }: TrackOldProps) {
  const matchedMetadata = useComputed(() => file.matchedMetadata.value).value;
  const { mutate: deleteUpload } = useDeleteUpload(uploadId);
  const { mutate: validateTrack } = useValidateTrack(uploadId);
  const { deleteTrack, addValidated, changeSelected } = trackUploadStore;
  const { t } = useTranslation('tracks-upload-form');

  const versionObj = mewoDataStore.versions.object.value;
  const version = versionObj[file.version.value as keyof typeof versionObj]?.name;

  function handleEditClick() {
    file.step.value = 'custom';
  }

  function handleSelectClick() {
    changeSelected(file.id);
  }

  function handleDeleteClick() {
    deleteUpload(undefined, {
      onSuccess: () => {
        deleteTrack(file.id);
      },
    });
  }

  function handleValidateClick() {
    validateTrack(getValidateParams(file), {
      onSuccess: () => {
        addValidated(file, uploadId);
      },
    });
  }

  return (
    <>
      <TrackInformationDisplay
        cover={matchedMetadata?.cover}
        version={version}
        title={matchedMetadata?.title || file.file?.name}
        subtitle={matchedMetadata?.artists?.join(', ')}
        onEditClick={handleEditClick}
        onSelectClick={handleSelectClick}
        onDeleteClick={handleDeleteClick}
        isValidated={isValidated}
        isSelected={isSelected}
      />
      {isSelected && (
        <div className="grid bg-dark-300/50 grid-rows-subgrid row-start-2 row-span-full">
          <FileGlobalData musicType={file.musicType.value} version={file.version.value} />
          <div className=" row-start-2 row-span-full">
            <div className="grid grid-rows-5 h-full">
              <TrackDetailGroup track={matchedMetadata} />
              <div className="flex justify-end items-center gap-3 px-4">
                <button
                  type="button"
                  onClick={handleDeleteClick}
                  className={classNames('flex gap-3  items-center text-sm px-3 py-1 rounded bg-dark-100 hover:bg-dark-200', {
                  })}
                >
                  {t('card.footer.delete')}
                  <Icon icon="ri:delete-bin-line" className="text-light-100 text-xs" />
                </button>
                <button
                  type="button"
                  onClick={handleValidateClick}
                  className={classNames('flex gap-3  items-center text-sm px-3 py-1 rounded bg-green-500 hover:bg-green-600', {
                  })}
                >
                  {t('card.footer.validate')}
                  <Icon icon="ri:check-line" className="text-light-100 text-xs" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
