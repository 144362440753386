import LibraryLayout from '~/components/layout/library-layout';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import CardLoader from './components/card-loader';
import PlaylistCard from './components/playlist-card';
import PlaylistLibraryHeader from './components/header';
import { usePlaylistLibrary } from './hooks/use-playlist-library';
import CreatePlaylist from './components/create-playlist';

function PlaylistLibrary() {
  const { t } = useTranslation('common');
  const { playlists, nbHits, isEachDataDisplayed, isLoading, fetchNextPage, handleCardClick, handleCreatePlaylist } = usePlaylistLibrary();

  return (
    <LibraryLayout
      nbHits={nbHits}
      addButton={
        <CreatePlaylist onClick={handleCreatePlaylist} />
      }
      header={(
        <PlaylistLibraryHeader total={nbHits} />
      )}
    >
      {!isLoading ? (
        <>
          {playlists.map((playlist) => (
            <PlaylistCard
              key={`playlist-card-${playlist.id}`}
              onClick={() => handleCardClick(playlist.id)}
              playlist={playlist}
            />
          ))}
          {!isEachDataDisplayed && (
            <Button
              type="button"
              onClick={() => fetchNextPage()}
              className="w-fit mx-auto mb-3 col-span-full"
            >
              {t('button.load-more.label')}
            </Button>
          )}
        </>
      ) : (
        Array.from({ length: 10 }).map((_, index) => (
          <CardLoader key={`card-loader-${index}`} />
        ))
      )}
    </LibraryLayout>
  );
}

export default PlaylistLibrary;
