/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
import { ReactNode } from 'react';

type LibraryProps = {
  nbHits?: number,
  header: ReactNode,
  addButton?: ReactNode,
  emptyPlaceholder?: ReactNode,
  children?: ReactNode,
};

function LibraryLayout(props: LibraryProps) {
  const { nbHits, header, addButton, emptyPlaceholder, children } = props;

  return (
    <div className="h-full overflow-hidden relative">
      <section className="flex flex-col gap-6 items-stretch h-full overflow-y-auto p-4 relative">
        {header}
        {nbHits ? (
          <div
            className="@container grid gap-4 pb-4"
            style={{
              gridTemplateColumns: 'repeat(auto-fill, minmax(30rem, 1fr))',
            }}
          >
            {children}
          </div>
        ) : (
          <>
            {emptyPlaceholder}
          </>
        )}
      </section>
      <div className="absolute right-6 bottom-6">
        {addButton}
      </div>
    </div>
  );
}

export default LibraryLayout;
