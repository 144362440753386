import usePlayerStore from '~/stores/player-store';
import classNames from 'classnames';
import { useEffect } from 'react';
import * as Track from '~/components/entities/track/common/track';
import { Controls } from './controls';
import { Time } from './time';
import { PlayerVolume } from './volume';

type PlayerControlsProps = {
  className?: string
};

export const PlayerControls = (props: PlayerControlsProps) => {
  const { className } = props;
  const player = usePlayerStore(['isPlaying', 'setIsPlaying', 'currentTrack', 'audioFile']);

  useEffect(() => {
    const onPlay = () => player.setIsPlaying(true);
    const onPause = () => player.setIsPlaying(false);

    player.audioFile?.addEventListener('play', onPlay);
    player.audioFile?.addEventListener('pause', onPause);

    return () => {
      player.audioFile?.removeEventListener('play', onPlay);
      player.audioFile?.removeEventListener('pause', onPause);
    };
  }, [player.audioFile, player.setIsPlaying]);

  return (
    <div className={classNames('w-full h-full flex gap-4 items-center justify-center select-none', className)}>
      <Controls isPlaying={player.isPlaying} />
      <div className="@4xl:flex items-center hidden gap-4">
        <Track.Waveform className="w-[16rem]" />
        <Time track={player.currentTrack} />
        <PlayerVolume />
      </div>
    </div>
  );
};
