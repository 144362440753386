import * as Track from '~/components/entities/track/common/track';
import { useRemoveFromTracklist } from '~/hooks/entities/track/actions/use-remove-track-from-tracklist';

export function RemoveFromTracklist() {
  const { tooltip, removeSingleTrackFromTracklist: removeTrackFromTracklist } = useRemoveFromTracklist();

  return (
    <Track.Action
      icon="ri:delete-bin-line"
      displayOnHover
      tooltip={tooltip}
      onClick={removeTrackFromTracklist}
    />
  );
}
