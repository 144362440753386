import classNames from 'classnames';
import SearchIcon from 'virtual:icons/ri/search-line';

type SearchInputProps = {
    value: string;
    placeholder?: string;
    className?: string;
    onChange?: (value: string) => void;
};

function SearchInput(props: SearchInputProps) {
  const { value, placeholder = 'Search', className, onChange } = props;

  return (
    <div className={classNames(
      'flex items-center gap-2 bg-dark-100 w-fit p-2 rounded text-xs text-light-100 placeholder:text-light-500',
      className,
    )}
    >
      <SearchIcon />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        className="bg-transparent outline-none w-full"
      />
    </div>
  );
}

export default SearchInput;
