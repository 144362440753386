import { useTrackContext } from '../track.context';
import * as Track from '../track';

type TrackPopularityProps = {
  className?: string;
};

export function TrackSimilarity(props: TrackPopularityProps) {
  const { className } = props;
  const store = useTrackContext();
  const { similarity } = store.use.track();

  const roundedSimilarity = Math.round(similarity || 0);

  return roundedSimilarity ? (
    <Track.Label className={className}>
      {roundedSimilarity ? `${roundedSimilarity}` : '-'}
    </Track.Label>
  ) : null;
}
