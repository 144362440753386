import classNames from 'classnames';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { useTranslation } from 'react-i18next';

type FilterProps = {
  className?: string;
  setSelectedFilter?: (collections?: ('briefs' | 'playlists')[]) => void;
  onChange: (query: string) => void;
};

const Filter = (props: FilterProps) => {
  const { className, setSelectedFilter, onChange } = props;
  const [query, setQuery] = useState('');
  const [isPlaylistSelected, setIsPlaylistSelected] = useState(false);
  const [isBriefSelected, setIsBriefSelected] = useState(false);

  const { t } = useTranslation('sidebar');

  useEffect(() => {
    const collections = [];
    if (isPlaylistSelected) collections.push('playlists');
    if (isBriefSelected) collections.push('briefs');
    if (!isPlaylistSelected && !isBriefSelected) collections.push('playlists', 'briefs');
    setSelectedFilter?.(collections as ('briefs' | 'playlists')[]);
  }, [isPlaylistSelected, isBriefSelected]);

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setQuery(newValue);
    onChange(newValue);
  }

  return (
    <div className={classNames('tracklist-filter flex items-center gap-1', className)} onClick={(e) => handleClick(e)}>
      <input value={query} onChange={handleChange} className="flex-1 py-1 px-2  bg-dark-200 outline-none rounded placeholder:text-light-400 text-light-500 text-xs " placeholder={t('briefs-playlists-list.filter.input.placeholder')} />
      <div className="tracklist-filter__button flex gap-1">
        <TypeSwitch icon="playlist-line" classSuffix="playlist" isSelected={isPlaylistSelected} onClick={() => setIsPlaylistSelected(!isPlaylistSelected)} />
        <TypeSwitch icon="brief-line" classSuffix="brief" isSelected={isBriefSelected} onClick={() => setIsBriefSelected(!isBriefSelected)} />
      </div>
    </div>
  );
};

const TypeSwitch = (props: { icon: string, isSelected: boolean, classSuffix?: string, onClick: () => void }) => {
  const { icon, isSelected, classSuffix, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        `tracklist-filter__button--${classSuffix}`,
        'flex items-center justify-center text-xs w-6 h-6 aspect-square text-light-100  rounded',
        isSelected ? 'bg-dark-200 hover:bg-dark-100' : 'border border-dark-200 hover:border-dark-100 text-dark-200 hover:text-dark-100',
      )}
    >
      <GeneralIcons icon={icon} />
    </button>
  );
};

export default Filter;
