import { useSimilarTracks } from '~/components/features/track-interactions/hooks/use-similar-track';
import { Track } from '~/types/schemas/music/track.schema';

export function useSimilaritySearch(track: Track) {
  const { findSimilarTracks } = useSimilarTracks(track.id, track.title, track.display_artists);

  return {
    findSimilarTracks,
  };
}
