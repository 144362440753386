import { signal } from '@preact/signals-react';
import i18n from '~/i18n/config';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';

export const userSettingsStore = {
  language: signal<SupportedLanguages>((localStorage.getItem('language') || navigator.language || 'en-US') as SupportedLanguages),
  setLanguage: (language: SupportedLanguages) => {
    const newLanguage = language || navigator.language || 'en-US';
    i18n.changeLanguage(newLanguage);
    userSettingsStore.language.value = language;
    window.localStorage.setItem('language', newLanguage);
  },
};
