import { useTrackContext } from '~/components/entities/track/common/track.context';
import { parseDate } from '~/utils/common/parse-date';

export function useReleaseDateCard() {
  const store = useTrackContext();
  const track = store.use.track();

  const content = track.release_date && parseDate(track.release_date).toString();

  return {
    content,
  };
}
