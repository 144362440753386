import { useEffect, useState } from 'react';
import Slider from '~/components/ui/control/slider/slider';
import useMusemindStore from '~/stores/musemind-store';

// TODO: FIX SEEK ISSUE

const VideoSeek = () => {
  const musemind = useMusemindStore(['videoElement', 'videoSeek']);

  const [currentSeek, setCurrentSeek] = useState<number>(musemind.videoElement?.currentTime || 0);
  const [isDraging, setIsDraging] = useState<boolean>(false);

  useEffect(() => {
    const onTimeUpdate = () => {
      if (!isDraging) {
        setCurrentSeek(musemind.videoElement?.currentTime || 0);
      }
    };

    musemind.videoElement?.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      musemind.videoElement?.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [isDraging, musemind.videoElement]);

  const handleChange = ([v]: number[]) => {
    setIsDraging(true);
    setCurrentSeek(v || 0);
  };

  const handleCommit = ([v]: number[]) => {
    setIsDraging(false);
    musemind.videoSeek(v || 0);
  };

  return (
    <Slider
      min={0}
      max={musemind.videoElement?.duration || 0}
      value={[currentSeek]}
      onValueChange={handleChange}
      onValueCommit={handleCommit}
      className="!w-full h-fit"
    />
  );
};

export default VideoSeek;
