type TextPart = {
    type: 'text' | 'link' | 'mention';
    text: string;
};

export function parseMessage(input: string) {
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const result: TextPart[] = [];

  let lastIndex = 0;
  let match = mentionRegex.exec(input);

  while (match !== null) {
    // Push preceding text as plain text
    if (match.index > lastIndex) {
      result.push({
        type: 'text',
        text: input.substring(lastIndex, match.index),
      });
    }

    // Push mention
    result.push({
      type: 'mention',
      text: `@${match[1]}`,
    });

    lastIndex = mentionRegex.lastIndex;
    match = mentionRegex.exec(input);
  }

  // Push remaining text
  if (lastIndex < input.length) {
    result.push({
      type: 'text',
      text: input.substring(lastIndex),
    });
  }

  return result;
}
