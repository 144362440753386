import { useLocation } from 'react-router-dom';
import PresearchContent from '~/components/features/presearch';

function SearchTopPage() {
  const location = useLocation();
  const isSearch = location.pathname === '/search';

  return isSearch ? (
    <section className="sticky top-0 flex flex-col z-20">
      <PresearchContent className="absolute inset-0 h-[calc(100vh_-_4rem)] z-10 overflow-hidden" />
    </section>
  ) : null;
}

export default SearchTopPage;
