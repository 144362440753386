import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { useTrackContext } from '../track.context';

type TrackAddedByProps = {
    className: string
};

function TrackAddedBy(props: TrackAddedByProps) {
  const { className } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const addedBy = track.metadata?.added_by;
  const avatar = addedBy?.user.organization_settings?.avatar || undefined;

  if (!addedBy) return null;

  return (
    <Tooltip
      label={`Added by ${addedBy.user.first_name} ${addedBy.user.last_name} / ${addedBy.user.company_name} - ${parseDateNumeric(addedBy.date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })}`}
      asChild
    >
      <div className={classNames('w-fit h-6 pr-2 bg-dark-200 flex gap-1.5 items-center justify-center rounded text-xs font-semibold', className)}>
        <div className="flex items-center justify-center w-6 h-6 overflow-hidden rounded-full aspect-square bg-light-500">
          {avatar ? <img src={avatar} alt={addedBy.user.company_name || ''} className="w-full h-full object-cover" /> : <Icon name="user-line" className="w-4 h-4" />}
        </div>
        {`${addedBy?.user.first_name?.[0]!.toUpperCase()}${addedBy?.user.last_name?.[0]!.toUpperCase()}`}
      </div>
    </Tooltip>
  );
}

export default TrackAddedBy;
