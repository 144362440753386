import Button from '~/components/ui/control/button/button';
import dayjs from 'dayjs';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTranslation } from 'react-i18next';
import { useAlbumSearch } from '~/hooks/api/album';
import usePreSearchStore from '~/stores/presearch-store';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import CardShelf from '../../../card-shelf/card-shelf';
import Tab from '../tab';

function AlbumsTab() {
  const { query } = usePreSearchStore(['query']);
  const { data: albums, fetchNextPage } = useAlbumSearch(query);
  const total = albums?.pages[0]?.total.value || 0;
  const flatedAlbums = albums?.pages.flatMap((page) => page.hits);
  const { handleFocusClick, handleClick, handleArtistClick } = useCardClick('album');
  const { t } = useTranslation(['pre-search', 'common']);

  return (
    <Tab value="albums" isEmpty={!flatedAlbums?.length || !flatedAlbums}>
      {flatedAlbums ? (
        <>
          <CardShelf
            label={t('tab.all.albums.title')}
            containerClassName="flex-1 col-span-full"
            cardsProps={flatedAlbums?.map((album) => ({
              type: 'album',
              artworks: album.artwork ? [album.artwork] : undefined,
              title: album.title,
              customSubtitle: album.artists.length ? <ClickableNameList onNameClick={(artist) => handleArtistClick(artist)} names={album.artists} /> : undefined,
              subtitlePrefx: album.release_date ? dayjs(album.release_date).format('YYYY') : undefined,
              onCardClick: () => handleFocusClick(album.title, album.id),
              onIncludeClick: () => handleClick(album.title, album.id, 'is'),
              onExcludeClick: () => handleClick(album.title, album.id, 'is_not'),
            }))}
          />
          {flatedAlbums?.length < total ? (
            <Button
              onClick={() => fetchNextPage()}
              className="mx-auto col-span-full"
            >
              {t('button.load-more.label', 'common')}
            </Button>
          ) : null}
        </>
      ) : null}
    </Tab>
  );
}

export default AlbumsTab;
