import { useCallback, useEffect } from 'react';
import { globalStore } from '~/stores/global-store-v2';
import { ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';

export function useChatMessages(channel: Channel<ChatMessageContent, ChatMessageMeta>) {
  const { user } = globalStore;

  const loadMore = useCallback(() => {
    channel.getOlderMessages();
  }, []);

  useEffect(() => {
    const lastMessage = channel.lastMessage.value;

    if (!lastMessage) {
      return;
    }

    const isReadByUser = lastMessage.isReadByUser(user.value!.id);

    if (!isReadByUser) {
      channel.setLastReadMessageTimetoken(lastMessage.timetoken);
    }
  }, [channel.lastMessage.value]);

  useEffect(() => {
    if (channel.messages.value.length === 1) {
      channel.getOlderMessages();
    }
  }, []);

  return {
    messages: channel.messages,
    showLoadMoreButton: channel.hasOlderMessages.value,
    loadMore,
  };
}
