import * as DialogPrimitive from '@radix-ui/react-dialog';
import { DialogContainer } from '../common';
import { LyricsHeader } from './header';
import { LyricsContent } from './content';

type RightHoldersDialogProps = {
  disabled?: boolean;
  children: React.ReactNode;
};

export function LyricsDialog(props: RightHoldersDialogProps) {
  const { disabled, children } = props;

  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Trigger asChild disabled={disabled}>
        {children}
      </DialogPrimitive.Trigger>
      <DialogContainer>
        <LyricsHeader />
        <LyricsContent />
      </DialogContainer>
    </DialogPrimitive.Root>
  );
}
