import classNames from 'classnames';
import { FooterExtendButton } from './extend-button';

type DescriptionFooterProps = {
    extend: boolean
    onExtendClick: () => void;
};

export function DescriptionFooter(props: DescriptionFooterProps) {
  const { extend, onExtendClick } = props;

  return (
    <div className={classNames('absolute left-0 bottom-0 flex items-center justify-center w-full h-12 bg-gradient-to-b from-transparent  transition-all', {
      '!to-dark-300': !extend,
    })}
    >
      <div className="relative size-full">
        <FooterExtendButton extend={extend} onExtendClick={onExtendClick} className="absolute top-0 left-1/2 -translate-x-1/2 translate-y-20 group-hover/description:translate-y-0 transition-transform duration-100" />
      </div>
    </div>
  );
}
