import {
  DataRef,
  DndContext,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { TrackDragOverlay } from '~/components/ui/overlays/drag/track-drag-overlay';
import { useDraggedProps } from '~/providers/dragged-props-provider';
import { Track } from '~/types/schemas/music/track.schema';

export type DragTypeOverlay =
  | {
      type: 'track';
      track: Track;
    }
  | {
      type: 'track-group';
      tracks: string[];
    };

export function DndProvider({ children }: { children: React.ReactNode }) {
  const { draggedProps, setDraggedProps } = useDraggedProps();

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    if (
      active.data.current?.type === 'track' ||
      active.data.current?.type === 'track-group'
    ) {
      setDraggedProps(active);
    }
  }

  function handleDragEnd() {
    setDraggedProps(null);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
  );

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
      <TrackDragOverlay
        data={(draggedProps?.data as DataRef<DragTypeOverlay>)?.current}
      />
    </DndContext>
  );
}
