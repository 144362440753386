import { z } from 'zod';

export const TAG_TYPE = {
  TAG: 0,
  TARGET: 1,
};

export const Tag = z.object({
  category: z.string(),
  color: z.string().optional(),
  id: z.string(),
  name: z.string(),
  synonyms: z.string().array(),
  type: z.number(),
  relevant: z.boolean().optional(),
});

export const TagCategory = z.object({
  color: z.string(),
  id: z.string(),
  name: z.string(),
  subCategories: z.string().array().optional(),
  tags: z.string().array().optional(),
  type: z.number(),
});

export const TagSubCategory = z.object({
  category: z.string(),
  color: z.string(),
  id: z.string(),
  name: z.string(),
  tags: z.string().array(),
  type: z.number(),
});

export const PopulatedTagSubCategorySchema = z.object({
  color: z.string(),
  id: z.string(),
  name: z.string(),
  tags: z.array(Tag).optional(),
  type: z.number(),
});

export const PopulatedTagCategorySchema = z.object({
  color: z.string(),
  id: z.string(),
  name: z.string(),
  subCategories: z.array(PopulatedTagSubCategorySchema).optional(),
  tags: z.array(Tag).optional(),
  type: z.number(),
});

export const TagSearchSchema = z.object({
  tags: z.string().array(),
  categories: z.string().array(),
});

export type Tag = z.infer<typeof Tag>;
export type TagSubCategory = z.infer<typeof TagSubCategory>;
export type TagCategory = z.infer<typeof TagCategory>;
export type TagSearch = z.infer<typeof TagSearchSchema>;
export type PopulatedTagCategory = z.infer<typeof PopulatedTagCategorySchema>;
export type PopulatedTagSubCategory = z.infer<typeof PopulatedTagSubCategorySchema>;
