import { usePubNub } from 'pubnub-react';
import { realtimeStore } from '~/stores/realtime-store';

export function useRealtime() {
  const pubnub = usePubNub();

  return {
    pubnub,
    ready: realtimeStore.ready.value,
    error: realtimeStore.error.value,
  };
}
