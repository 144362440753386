import { useEffect } from 'react';
import { useGetThreadChannels } from '~/hooks/api/thread';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { globalStore } from '~/stores/global-store-v2';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';
import { ChannelsManager } from '~/utils/chat/channel-manager';

export function useChatList(brief: {id: string, title: string}) {
  const { user } = globalStore;
  const realtime = useRealtime();
  const channelList = useGetThreadChannels(brief.id);
  const ready = realtime.ready && !channelList.isLoading && channelList.isSuccess;

  function handleChatClick(channel: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>) {
    activeChannelStore.channel.value = channel;
  }

  function handleBackClick() {
    activeChannelStore.channel.value = undefined;
  }

  useEffect(() => {
    briefChatsStore.reset();
    briefChatsStore.brief.value = brief;
  }, [brief.id]);

  useEffect(() => {
    if (!ready) {
      return () => {};
    }

    const allChannels = Object.values(channelList.data || {});

    if (!allChannels.length) {
      return () => {};
    }

    const manager = new ChannelsManager(realtime.pubnub, user.value!.id, `sync_brief_${brief.id}.*`);
    const unsub = manager.subscribe();

    realtime.pubnub.fetchMessages({
      channels: allChannels,
      count: 1,
      includeUUID: true,
    }, (status, result) => {
      if (status.error) {
        return;
      }

      const messages = Object.values(result.channels).flatMap((messageList) => messageList);

      manager.addMessageToChannel(messages);

      briefChatsStore.isLoading.value = false;
    });

    return () => {
      unsub();
    };
  }, [brief.id, ready]);

  return {
    isLoading: !realtime.ready || channelList.isLoading || briefChatsStore.isLoading.value,
    isError: channelList.isError,
    handleChatClick,
    handleBackClick,
  };
}
