import * as Slider from '@radix-ui/react-slider';
import classNames from 'classnames';

type RangeSliderProps = {
    value: [number, number];
    min: number;
    max: number;
    ariaLabel?: string;
    trackClassName?: string;
    rangeClassName?: string;
    className?: string;
    onValueChange?: (value: [number, number]) => void;
    onSlideEnd?: (value: [number, number]) => void;
};

function RangeSlider(props: RangeSliderProps) {
  const { value, min, max, ariaLabel = 'slider', trackClassName, rangeClassName, className, onValueChange, onSlideEnd } = props;
  const handleValueChange = (v: number[]) => {
    onValueChange?.([v[0]!, v[1]!]);
  };

  const handleCommit = (v: number[]) => {
    onSlideEnd?.([v[0]!, v[1]!]);
  };
  return (
    <Slider.Root
      className={classNames('relative flex items-center select-none touch-none w-40 h-5', className)}
      value={value}
      onValueChange={handleValueChange}
      onValueCommit={handleCommit}
      min={min}
      max={max}
      step={1}
      aria-label={ariaLabel}
    >
      <Slider.Track className={classNames('bg-dark-300 relative grow rounded-full h-1', trackClassName)}>
        <Slider.Range className={classNames('absolute bg-light-100 rounded-full h-full', rangeClassName)} />
      </Slider.Track>
      <Thumb />
      <Thumb />
    </Slider.Root>
  );
}

function Thumb(props: {className?: string}) {
  const { className } = props;
  return (
    <Slider.Thumb className={classNames('block w-1 h-3 bg-light-100 rounded hover:bg-violet3 focus:outline-none ', className)} />
  );
}

export default RangeSlider;
