import { useTranslation } from 'react-i18next';
import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useAddToTracklist } from '~/hooks/entities/track/actions/use-add-track-to-tracklist';

export function CreatePlaylist() {
  const { t } = useTranslation('track-interactions');
  const { createNewPlaylist } = useAddToTracklist();

  return (
    <ContextMenu.Item icon="ri:add-line" onClick={createNewPlaylist}>{t('add-playlist.dropdown.footer')}</ContextMenu.Item>
  );
}
