import { Icon, IconifyIconProps } from '@iconify/react';
import { PropsWithChildren } from 'react';
import { TrackChipBpm } from './bpm';
import { TrackChipTonality } from './tonality';
import { TrackChipTag } from './tag';

type TrackChip = PropsWithChildren<{
    icon?: IconifyIconProps['icon'],
    color?: string,
    label: string,
    onClick?: () => void
}>;

function TrackChip(props: TrackChip) {
  const { icon, color, label, onClick } = props;

  return (
    <button
      type="button"
      className="flex gap-2 pl-1 pr-2 items-center bg-dark-100/50 h-6 w-fit rounded border border-transparent transition-colors hover:border-dark-100 text-xs font-semibold"
      onClick={onClick}
    >
      {icon ? (
        <Icon icon={icon} className="text-base" />
      ) : null }
      {color ? (
        <div className="size-2 rounded " style={{ backgroundColor: color }} />
      ) : null}
      {label}
    </button>
  );
}

const Bpm = TrackChipBpm;
const Tonality = TrackChipTonality;
const Tag = TrackChipTag;

export default TrackChip;

export {
  Bpm,
  Tonality,
  Tag,
};
