/* eslint-disable quote-props */
import BriefFill from 'virtual:icons/ri/megaphone-fill';
import BriefLine from 'virtual:icons/ri/megaphone-line';
import PlaylistFill from 'virtual:icons/ri/headphone-fill';
import PlaylistLine from 'virtual:icons/ri/headphone-line';
import CollabPlaylistLine from 'virtual:icons/mewo/collabPlaylistLine';
import CollabPlaylistFill from 'virtual:icons/mewo/collabPlaylistFill';
import SearchFill from 'virtual:icons/ri/search-fill';
import SearchLine from 'virtual:icons/ri/search-line';
import EyeLine from 'virtual:icons/ri/eye-line';
import EyeOffLine from 'virtual:icons/ri/eye-off-line';
import PlayFill from 'virtual:icons/ri/play-fill';
import PauseFill from 'virtual:icons/ri/pause-fill';
import CredentialLine from 'virtual:icons/ri/information-line';
import CredentialFill from 'virtual:icons/ri/information-fill';
import SimilarLine from 'virtual:icons/ri/magic-line';
import SimilarFill from 'virtual:icons/ri/magic-fill';
import DownloadLine from 'virtual:icons/ri/download-line';
import DownloadFill from 'virtual:icons/ri/download-fill';
import AddToCacheLine from 'virtual:icons/ri/database-2-line';
import AddToCacheFill from 'virtual:icons/ri/database-2-fill';
import VariationLine from 'virtual:icons/mewo/variation';
import VariationFill from 'virtual:icons/ri/video-fill';
import LyricsLine from 'virtual:icons/ri/mic-line';
import LyricsFill from 'virtual:icons/ri/mic-fill';
import VolumeMuteLine from 'virtual:icons/ri/volume-mute-line';
import VolumeUpLine from 'virtual:icons/ri/volume-up-line';
import VolumeDownLine from 'virtual:icons/ri/volume-down-line';
import RemoveLine from 'virtual:icons/ri/delete-bin-line';
import RemoveFill from 'virtual:icons/ri/delete-bin-fill';
import LogoutLine from 'virtual:icons/ri/logout-box-line';
import Popularity from 'virtual:icons/ph/trend-up-bold';
import Similarity from 'virtual:icons/tabler/chart-circles';
import MusemindVariaitonLoader from 'virtual:icons/svg-spinners/pulse';
import AddToPlaylist from 'virtual:icons/ic/round-playlist-add';
import Spotify from 'virtual:icons/mdi/spotify';
import Youtube from 'virtual:icons/mdi/youtube';
import Instagram from 'virtual:icons/mdi/instagram';
import Tiktok from 'virtual:icons/ic/baseline-tiktok';
import TagIcon from 'virtual:icons/mdi/tag-outline';
import TargetIcon from 'virtual:icons/tabler/target';
import WarningIcon from 'virtual:icons/material-symbols/exclamation';
import Interest from 'virtual:icons/tabler/mail-dollar';
import Info from 'virtual:icons/ep/info-filled';
import Bpm from 'virtual:icons/ri/pulse-line';
import Key from 'virtual:icons/ri/music-fill';
import WaveformPlaying from 'virtual:icons/svg-spinners/bars-scale-middle';
import StarPlaying from 'virtual:icons/line-md/star-pulsating-filled-loop';
import PaperPlane from 'virtual:icons/bx/paper-plane';
import Loader from 'virtual:icons/svg-spinners/180-ring-with-bg';
import classNames from 'classnames';

export const generalIcons = {
  'search-fill': <SearchFill />,
  'search-line': <SearchLine />,
  'brief-fill': <BriefFill />,
  'brief-line': <BriefLine />,
  'playlist-fill': <PlaylistFill />,
  'playlist-line': <PlaylistLine />,
  'eye-line': <EyeLine />,
  'eye-off-line': <EyeOffLine />,
  'play-fill': <PlayFill />,
  'pause-fill': <PauseFill />,
  'credential-line': <CredentialLine />,
  'credential-fill': <CredentialFill />,
  'similar-line': <SimilarLine />,
  'similar-fill': <SimilarFill />,
  'download-line': <DownloadLine />,
  'download-fill': <DownloadFill />,
  'add-to-cache-line': <AddToCacheLine />,
  'add-to-cache-fill': <AddToCacheFill />,
  'variation-line': <VariationLine />,
  'variation-fill': <VariationFill />,
  'lyrics-line': <LyricsLine />,
  'lyrics-fill': <LyricsFill />,
  'volume-mute-line': <VolumeMuteLine />,
  'volume-up-line': <VolumeUpLine />,
  'volume-down-line': <VolumeDownLine />,
  'remove-line': <RemoveLine />,
  'remove-fill': <RemoveFill />,
  'logout-line': <LogoutLine />,
  'popularity': <Popularity />,
  'similarity': <Similarity />,
  'musemind-variation-loader': <MusemindVariaitonLoader />,
  'add-to-playlist': <AddToPlaylist />,
  'spotify': <Spotify />,
  'youtube': <Youtube />,
  'instagram': <Instagram />,
  'tiktok': <Tiktok />,
  'tag': <TagIcon />,
  'target': <TargetIcon />,
  'collab-playlist-line': <CollabPlaylistLine />,
  'collab-playlist-fill': <CollabPlaylistFill />,
  'warning': <WarningIcon />,
  'interest': <Interest />,
  'info': <Info />,
  'bpm': <Bpm />,
  'key': <Key />,
  'waveform-playing': <WaveformPlaying />,
  'star-playing': <StarPlaying />,
  'paper-plane': <PaperPlane />,
  'loader': <Loader />,
};

export type GeneralIcon = keyof typeof generalIcons;

type GeneralIconsProps = {
  icon: string;
  className?: string;
};

const GeneralIcons = (props: GeneralIconsProps) => {
  const { icon, className } = props;
  return (
    <div data-icon-name={icon} className={classNames('general-icon', className)}>
      {generalIcons[icon as keyof typeof generalIcons]}
    </div>
  );
};

export default GeneralIcons;
