import { useSearchStore } from '~/stores/search-store';
import SearchChip from './search-chip';

const ContainChip = () => {
  const search = useSearchStore(['query', 'setQuery']);

  const handleQueryClear = () => {
    search.setQuery('');
  };

  return (
    <SearchChip
      value={search.query}
      onRemoveClick={handleQueryClear}
    />
  );
};

export default ContainChip;
