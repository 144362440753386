import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brief, BriefFormUpdate } from '~/types/schemas/music/brief.schema';
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { useCreateBrief, useDeleteBriefs, useUpdateBrief } from '~/hooks/api/brief';
import { briefUpdateParser } from './utils/brief-update-parser';

export function useBriefForm(step: number, brief?: Brief) {
  const { data: newBrief, mutate: createBrief } = useCreateBrief();
  const { mutate: updateBriefMutation } = useUpdateBrief(brief?.id || newBrief?.id);
  const { mutate: deleteBrief } = useDeleteBriefs();

  const [activeStep, setActiveStep] = useState(step - 1);
  const [waitingData, setWaitingData] = useState<BriefFormUpdate | undefined>(undefined);
  const briefForm = useBriefFormStore(['close']);
  const navigate = useNavigate();

  function updateBrief(data: BriefFormUpdate) {
    updateBriefMutation(briefUpdateParser(data));
  }

  useEffect(() => {
    if (waitingData && newBrief) {
      updateBrief(waitingData);
      setWaitingData(undefined);
    }
  }, [waitingData, newBrief]);

  function onSubmit(data: BriefFormUpdate) {
    updateBrief({ ...data, public: true });
    setTimeout(() => briefForm.close(), 100);
  }

  async function handleNext(data: BriefFormUpdate) {
    const isPublicBriefChanged = !!(JSON.stringify(brief) !== JSON.stringify(data) && brief?.public);

    if (step === 1 && (!brief?.id ? !newBrief : isPublicBriefChanged)) {
      createBrief({ title: data.title });
      setWaitingData(data);
    } else if (!isPublicBriefChanged) {
      updateBrief(data);
    }

    setActiveStep(activeStep + 1);
  }

  function handleDiscardAndExit() {
    localStorage.removeItem(`brief-${brief?.id}-agents_unsaved`);
    const close = async () => briefForm.close();
    close().then(() => navigate('/briefs'));
  }

  function handleSaveAndExit(data: BriefFormUpdate) {
    const unsavedAgentChange = localStorage.getItem(`brief-${brief?.id || newBrief?.id}-agents_unsaved`);
    if (unsavedAgentChange) {
      localStorage.removeItem(`brief-${brief!.id}-agents_unsaved`);
      localStorage.setItem(`brief-${brief!.id}-agents`, unsavedAgentChange);
    }
    const close = async () => briefForm.close();
    const update = async () => updateBrief(data);

    if (brief !== undefined) {
      update().then(() => close()).then(() => navigate('/briefs'));
    } else navigate('/briefs');
  }

  function handleDeleteDraft() {
    const deleteDraft = async () => brief?.id && deleteBrief({ ids: [brief.id] });

    if (brief !== undefined) {
      deleteDraft().then(() => navigate('/briefs'));
    } else navigate('/briefs');
  }

  function handlePreviousClick() {
    setActiveStep(activeStep - 1);
  }

  function handleStepClick(index?: number) {
    setActiveStep(index || 0);
  }

  return ({
    handleSend: onSubmit,
    handleNext,
    handleDiscardAndExit,
    handleSaveAndExit,
    handleDeleteDraft,
    handlePreviousClick,
    handleStepClick,
    activeStep,
  });
}
