import { z } from 'zod';
import { Rights } from './rights.schema';
import { Tag, TagCategory, TagSubCategory } from './tags.schema';
import { Tonality } from './tonalities.schema';
import { Version } from './versions.schema';

export const TagsResponse = z.object({
  categories: TagCategory.array(),
  subCategories: TagSubCategory.array(),
  tags: Tag.array(),
});

export const TagsSearchResponse = z.object({
  categories: z.string().array(),
  tags: z.string().array(),
});

export const OthersResponseSchema = z.object({
  versions: Version.array(),
  rights: Rights.array(),
  tonalities: Tonality.array(),
});

export type TagsResponse = z.infer<typeof TagsResponse>;
export type TagsSearchResponse = z.infer<typeof TagsSearchResponse>;
export type OthersResponse = z.infer<typeof OthersResponseSchema>;
