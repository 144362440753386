import { components, GroupBase, InputProps } from 'react-select';
import { OptionType } from '../select';

const Input = (
  props: InputProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children, hasValue } = props;

  const inputClassFocus = '!h-4';
  const inputClass = `inset-0 flex-1 !text-white !m-0 !p-0 bg-transparent relative outline-none transition-all duration-200 appearance-none text-sm !placeholder:text-white/60 ${
    hasValue && inputClassFocus
  }`;

  return (
    <components.Input className={inputClass} {...props}>
      {children}
    </components.Input>
  );
};

export default Input;
