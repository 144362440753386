import { useState } from 'react';
import classNames from 'classnames';
import { BriefDescriptionBlocks } from './blocks/blocks';
import { DescriptionHeader } from './header/header';
import { DescriptionFooter } from './footer/footer';

export function BriefDescription() {
  const [extend, setExtend] = useState(false);

  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className={classNames('group/description relative w-full bg-dark-300 rounded-2xl max-h-52 overflow-hidden transition-all', {
          'max-h-full': extend,
        })}
        >
          <DescriptionHeader />
          <BriefDescriptionBlocks />
          <DescriptionFooter extend={extend} onExtendClick={() => setExtend(!extend)} />
        </div>
      </div>
    </div>
  );
}
