import * as TrackActions from '~/components/ui/control/menus/context-menu/track-actions/track-actions';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';

export function TrackItemsContextItems() {
  const { values } = useSelectionContext();

  if (values.length > 1) {
    return (
      <>
        <TrackActions.AddToTracklist type="playlist" />
        <TrackActions.AddToTracklist type="brief" />
        <TrackActions.RemoveFromTracklist />
      </>
    );
  }

  return (
    <>
      <TrackActions.Similarity />
      <TrackActions.Download.Container>
        <TrackActions.Download.Original />
        <TrackActions.Download.HDMP3 />
        <TrackActions.Download.Copyrights />
      </TrackActions.Download.Container>
      <TrackActions.LicensingQuote />
      <TrackActions.Lyrics />
      <TrackActions.Copyright />
      <TrackActions.AddToTracklist type="playlist" />
      <TrackActions.AddToTracklist type="brief" />
      <TrackActions.RemoveFromTracklist />
    </>
  );
}
