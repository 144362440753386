import { parseDateNumeric } from '~/utils/common/parse-date';
import { useTranslation } from 'react-i18next';
import Chip from '~/components/ui/control/chip/chip';
import { useBriefContext } from '../../brief.context';

export function DeadlineChip() {
  const { data } = useBriefContext();
  const { deadline } = data;
  const { t } = useTranslation('brief-page');

  return (
    <Chip
      iconLeft="tabler:calendar-cancel"
      className="bg-dark-300 flex items-center justify-center !px-2"
    >
      {deadline ? parseDateNumeric(deadline, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      }) : t('header.unspecified').toUpperCase()}
    </Chip>
  );
}
