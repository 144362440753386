import { useSearchStore } from '~/stores/search-store';

export function useBpmFilter() {
  const search = useSearchStore(['setBpm']);

  function handleBpmClick(bpm: number) {
    search.setBpm(Math.floor(bpm), Math.floor(bpm));
  }

  return {
    onBpmClick: handleBpmClick,
  };
}
