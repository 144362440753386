import { useTranslation } from 'react-i18next';
import { useDownloadTrack } from '~/hooks/api/track';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { Track } from '~/types/schemas/music/track.schema';

export function useDownloadTrackFile(track: Track, format: 'hd_mp3' | 'original') {
  const { mutate: downloadTrack } = useDownloadTrack();
  const { t } = useTranslation('toast');

  function downloadTrackFile() {
    downloadTrack({ trackId: track.id, format }, {
      onSuccess: () => {
        toastr('success', t('archive.download-wait.title'), t('archive.download-wait.content'));
      },
    });
  }

  return {
    downloadTrackFile,
  };
}
