import { BriefTrackItemLayout } from '~/components/layout/track-items/brief-track-item.layout';
import { ListHeader } from '../common/list-header';
import { ListHeaderLabel } from '../common/list-header-label';

export function BriefListHeader() {
  return (
    <ListHeader>
      <BriefTrackItemLayout>
        <ListHeaderLabel label="Title" className="col-start-[summary]" />
        <ListHeaderLabel label="Type" className="col-start-[music-type] justify-self-center hidden @[48rem]:block" />
        <ListHeaderLabel label="Added by" className="col-start-[added-by] justify-self-center  hidden @[45rem]:block" />
        <ListHeaderLabel icon="ph:trend-up-bold" tooltip="Popularity" className="col-start-[popularity] justify-self-center" />
        <ListHeaderLabel icon="ri:time-line" tooltip="Duration" className="col-start-[duration] justify-self-end pr-1" />
      </BriefTrackItemLayout>
    </ListHeader>
  );
}
