import { useAddTrackToTracklist, useRemoveTrackFromTracklist } from '~/hooks/api/track-list';
import { Track } from '~/types/schemas/music/track.schema';

export function useTracklist(track: Track) {
  const { mutate: addToTracklistMutation } = useAddTrackToTracklist();
  const { mutate: removeTrack } = useRemoveTrackFromTracklist();

  function addToTracklist(params: {
    type: 'brief' | 'playlist';
    id: string;
  }) {
    addToTracklistMutation({
      ...params,
      tracks: [track],
    });
  }

  function removeFromTracklist(params: {
    refType: 'brief' | 'playlist';
    refId: string;
  }) {
    removeTrack({
      type: params.refType,
      id: params.refId,
      tracks: [track],
    });
  }

  return {
    addToTracklist,
    removeFromTracklist,
  };
}
