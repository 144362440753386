import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/ui/control/button/button';

type ErrorPageProps = {
    title: string;
    message: string;
    errorCode: number | string;
};

export function ErrorPage(props: ErrorPageProps) {
  const { title, message, errorCode } = props;
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  function handleBackToHome() {
    navigate('/');
  }

  return (
    <section className="w-full h-full p-12">
      <div className="flex flex-col justify-center items-center h-full">
        <h1 className="text-9xl font-bold text-light-500 mb-3">{errorCode}</h1>
        <h2 className="text-4xl text-light-100">{title}</h2>
        <p className="text-light-200 mt-1">{message}</p>
        <Button className="w-fit mt-4" onClick={handleBackToHome}>{t('back-home')}</Button>
      </div>
    </section>
  );
}
