import { createContext, useContext } from 'react';
import { Brief } from '~/types/schemas/music/brief.schema';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { Track } from '~/types/schemas/music/track.schema';

type TrackItemListContextType = {
  context?: {
    type : 'playlist' | 'brief';
    value: Brief | Playlist;
  }
  tracks?: Track[]
};

export const TrackItemListContext = createContext<TrackItemListContextType | null>(null);

export function useTrackItemListContext() {
  const context = useContext(TrackItemListContext);

  if (!context) {
    throw new Error('useTrackItemListContext must be used within a TrackItemListProvider');
  }

  return context;
}
