import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useColumnCount } from '../../hooks/utils/use-column-count';
import { useSearchParams } from '../../hooks/utils/use-search-params';

type AppContentProps = {
  className?: string;
  children: ReactNode;
};

const NEW_PAGES = ['briefs', 'playlists', 'search'];

const AppContent = (props: AppContentProps) => {
  const { className, children } = props;
  const { ref } = useColumnCount();
  const location = useLocation();

  const isNewPage = NEW_PAGES.some((page) => location.pathname.includes(page));

  useSearchParams();

  if (isNewPage) return children;

  return (
    <section
      ref={ref}
      id="content-container"
      className={classNames('relative flex-1 overflow-y-auto custom-scrollbar p-4', className)}
    >
      {children}
    </section>
  );
};

export default AppContent;
