import { useBriefContext } from '~/pages/briefs/brief.context';
import { t } from 'i18next';
import { BriefDescriptionBlock } from './block';

export function MusicTypeBlock() {
  const { data, isLoading } = useBriefContext();
  const musicType = data.music_type?.map((type) => (type === 'core' ? t('music-type.core', { ns: 'common' }) : t('music-type.prod', { ns: 'common' }))).join(', ');

  return (
    <BriefDescriptionBlock label="Music Type" isLoading={isLoading}>
      {musicType}
    </BriefDescriptionBlock>
  );
}
