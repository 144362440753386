import { useTranslation } from 'react-i18next';
import { useDownloadTrackFile } from '~/hooks/entities/track/actions/download/use-download-hd-mp3';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as DropdownMenu from '../../common/dropdown-menu';

export function DownloadHDMP3() {
  const store = useTrackContext()
const track = store.use.track()
  const { t } = useTranslation('track-interactions');
  const { downloadTrackFile } = useDownloadTrackFile(track, 'hd_mp3');

  return (
    <DropdownMenu.Item
      onClick={downloadTrackFile}
      icon="teenyicons:mp3-outline"
    >
      {t('download.dropdown.group.tracks.mp3')}
    </DropdownMenu.Item>
  );
}
