import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';

type PlaylistFormHeaderProps = {
  onDiscardAndExitClick?: () => void
};

function PlaylistFormHeader(props: PlaylistFormHeaderProps) {
  const { onDiscardAndExitClick } = props;
  const { t } = useTranslation('playlist-form');

  return (
    <div className="h-8 flex justify-between items-center w-full">
      <h1 className="text-2xl font-bold">{t('header.title')}</h1>
      <Button className="!bg-dark-100 hover:!bg-dark-200" onClick={onDiscardAndExitClick}>
        Exit
      </Button>
    </div>
  );
}

export default PlaylistFormHeader;
