import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { useBriefContext } from '~/pages/briefs/brief.context';
import classNames from 'classnames';
import { SHORT_LANGUAGES } from '~/constants/languages';
import { TranslateDropdownMenu } from '../menus/dropdown-menu/translate/translate';
import { useTranslateSelectContext } from './translate-select.context';

export function TranslateToAnotherLanguageButton() {
  const { userLanguage, targetLanguage, onLanguageChange } = useTranslateSelectContext();
  const { isLoading } = useBriefContext();
  const { t } = useTranslation('translate-select');

  return (
    <RadixDropdownMenu.Root>
      <div className={classNames('w-fit flex gap-2 items-center  p-2 rounded-lg h-10 bg-dark-200', {
        'pointer-events-none animate-pulse': isLoading,
      })}
      >
        <Icon icon="ri:translate-ai-2" />
        <div className="flex flex-col text-xs pr-1.5">
          <div className="flex gap-1">
            <span>
              {t('detected-language')}
              {' '}
              →
              {' '}
              {SHORT_LANGUAGES.find((l) => l.value === targetLanguage)?.label}
            </span>
          </div>
          <span onClick={() => onLanguageChange(undefined)} className="hover:underline cursor-pointer">
            {t('show-original')}
          </span>
        </div>
        <RadixDropdownMenu.Trigger type="button" className="group/trigger size-6 flex items-center justify-center rounded hover:bg-dark-100 transition-all outline-none border border-transparent focus:bg-dark-100  focus:border-white/10">
          <Icon icon="ri:settings-3-line" className="text-light-500 text-lg group-focus/trigger:text-light-100 group-hover/trigger:text-light-100" />
        </RadixDropdownMenu.Trigger>
      </div>
      <TranslateDropdownMenu userLanguage={userLanguage} targetLanguage={targetLanguage} onLanguageChange={onLanguageChange} align="end" alignOffset={-8} sideOffset={16} />
    </RadixDropdownMenu.Root>
  );
}
