/* eslint-disable jsx-a11y/control-has-associated-label */
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import BriefChatsPanel from '~/components/features/brief-chats/brief-chats-panel';
import { briefChatsStore } from '~/stores/chats-store';

type BriefAsideChannelProps = {
    id: string;
    title: string;
};

export function BriefAsideChannel(props: BriefAsideChannelProps) {
  const { id, title } = props;
  const { isPanelOpen } = briefChatsStore;

  function handleClick() {
    isPanelOpen.value = !isPanelOpen.value;
  }

  return (
    <aside className={classNames('relative h-full transition-all', {
      'w-0': !isPanelOpen.value,
      'w-96 p-4': isPanelOpen.value,
    })}
    >
      <div className="h-full w-full">
        <button
          type="button"
          className={classNames('absolute transition-all -left-12 top-4 w-8 h-8 bg-dark-300 hover:bg-dark-200 hover:scale-105 active:scale-95 rounded z-10', {
            'opacity-0': isPanelOpen.value,
            'opacity-100': !isPanelOpen.value,
          })}
          onClick={handleClick}
        >
          <Icon icon="ri:chat-4-line" className="w-4 h-4 m-auto text-white" />
        </button>
        <BriefChatsPanel brief={{ id, title }} onClose={() => { isPanelOpen.value = false; }} />
      </div>
    </aside>
  );
}
