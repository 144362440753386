import classNames from 'classnames';

type TrackInformationProps = {
    label: string
    value?: string
    className?: string
};

export default function TrackDetailItem(props: TrackInformationProps) {
  const { label, className, value = '-' } = props;

  return (
    <li className={classNames('flex flex-col h-12 justify-center self-center', className)}>
      <h6 className="text-sm text-light-500 leading-[0.875rem]">{label}</h6>
      <p className="text-sm leading-4 text-light-100 line-clamp-1 break-all">{value}</p>
    </li>
  );
}
