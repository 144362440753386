/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchFilters from '~/components/features/search-filters';
import { useColumnCount } from '~/hooks/utils/use-column-count';
import { SearchTrackItemList } from '~/components/entities/track/track-item-list/search/search-track-item-list';
import ResultNumber from './components/search-top-page/components/result-number';
import TrackSort from './components/track-sort';

const Search = () => {
  const { t } = useTranslation('search');
  const location = useLocation();
  const { ref } = useColumnCount();

  function handleScrollUp() {
    const scrolParent = document.getElementById('content-container');
    if (!scrolParent) return;
    scrolParent.scrollTo({ top: 0 });
  }

  const sectionId = `${location.pathname.split('/').pop()}-${location.search}-section`;

  return (
    <section ref={ref} id={sectionId} className="relative flex flex-col items-stretch flex-1 h-full overflow-y-auto">
      <SearchFilters className="bg-dark-500 pt-4 px-4" />
      <div className="sticky -top-1 px-4 bg-dark-500 z-10" onClick={handleScrollUp}>
        <div className="w-full h-12 flex items-center gap-4">
          <h1 className="font-bold text-xl">{t('results.title')}</h1>
          <ResultNumber />
          <div className="ml-auto flex gap-3 items-center">
            <TrackSort />
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="flex flex-col relative px-4 min-h-full h-fit">
          <SearchTrackItemList scrollParentId={sectionId} />
        </div>
      </div>
    </section>
  );
};

export default Search;
