import { useTranslation } from 'react-i18next';
import { queryClient } from '~/boot/react-query';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { playlistRoutes, tracklistRoutes } from '~/routes/api';
import { globalStore } from '~/stores/global-store-v2';
import { Playlist, PlaylistSchema, PlaylistSearchResultSchema, PlaylistTracksSchema, PlaylistUpdateAPI } from '~/types/schemas/music/playlists.schema';
import { Track } from '~/types/schemas/music/track.schema';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';
import { pathToUrl } from '~/utils/path-to-url';
import { addInfiniteDataUpdater, multipleDeleteUpdater, reorderInfiniteDataUpdater, updateData, updateInfiniteDataUpdater } from '~/hooks/utils/react-query/update-helper';
import { useDelete } from '~/hooks/utils/react-query/use-delete';
import { useDeleteMultiple } from '~/hooks/utils/react-query/use-delete-multiple';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';
import { usePost } from '~/hooks/utils/react-query/use-post';
import { useUpdate } from '~/hooks/utils/react-query/use-update';
import { playlistUpdater, tracklistUpdater } from '~/utils/updater/tracklist.updater';
import { useAddTrackToTracklist } from './track-list';

export const useGetPlaylistDetails = (playlistId?: string) => {
  return useFetch<Playlist>({
    url: pathToUrl(playlistRoutes.playlist, { id: playlistId }),
    schema: PlaylistSchema,
  });
};

export const useGetPlaylistTracks = (playlistId?: string, maxItems: number = 20) => {
  return useLoadMore<Track>({
    url: pathToUrl(playlistRoutes.tracks, { id: playlistId }),
    schema: PlaylistTracksSchema,
    queryParams: {
      size: maxItems,
    },
  });
};

export const useGetPlaylistsList = () => {
  const { user } = globalStore;

  return useLoadMore<Playlist>({
    url: playlistRoutes.search,
    schema: PlaylistSearchResultSchema,
    queryParams: {
      query: '',
      size: 50,
      sort: {
        key: 'created_at',
        direction: -1,
      },
      filters: {
        and: [{
          tenant: user.value?.tenant_id,
        }],
      },
    },
  });
};

export const useDuplicatePlaylist = (
  playlistId: string,
) => {
  const { t } = useTranslation('toast');
  return usePost<unknown, Playlist>({
    url: pathToUrl(playlistRoutes.duplicate, { id: playlistId }),
    mutateOptions: {
      onSuccess: (newData) => {
        toastr('success', t('playlist.success-duplication.title'), t('playlist.success-duplication.content'));
        addInfiniteDataUpdater<Playlist>(
          [playlistRoutes.playlists],
          playlistUpdater({} as Playlist, newData) || {} as Playlist,
        );
        addInfiniteDataUpdater<Tracklist>(
          [tracklistRoutes.search],
          {
            type: 'playlist',
            value: playlistUpdater({} as Playlist, newData) || {} as Playlist,
            has_track: false,
          },
        );
      },
    },
  });
};

export const useCreatePlaylist = () => {
  const { t } = useTranslation('toast');

  return usePost<unknown, Playlist>({
    url: playlistRoutes.playlists,
    mutateOptions: {
      onSuccess: (newPlaylist) => {
        toastr('success', t('playlist.success-creation.title'), t('playlist.success-creation.content'));
        addInfiniteDataUpdater<Playlist>(
          [playlistRoutes.playlists],
          playlistUpdater({} as Playlist, newPlaylist) || {} as Playlist,
        );
        addInfiniteDataUpdater<Tracklist>(
          [tracklistRoutes.search],
          {
            type: 'playlist',
            value: playlistUpdater({} as Playlist, newPlaylist) || {} as Playlist,
            has_track: false,
          },
        );
      },
      onError: () => {
        toastr('error', t('playlist.error-creation.title'), t('playlist.error-creation.content'));
      },
    },
  });
};

export const useCreatePlaylistFromTrack = (track: Track) => {
  const { t } = useTranslation('toast');
  const { mutate: addTrackToTracklist } = useAddTrackToTracklist();

  return usePost<unknown, Playlist>({
    url: playlistRoutes.playlists,
    mutateOptions: {
      onSuccess: (newPlaylist) => {
        toastr('success', t('playlist.success-creation.title'), t('playlist.success-creation.content'));
        addInfiniteDataUpdater<Playlist>(
          [playlistRoutes.playlists],
          playlistUpdater({} as Playlist, newPlaylist) || {} as Playlist,
        );
        addInfiniteDataUpdater<Tracklist>(
          [tracklistRoutes.search],
          {
            type: 'playlist',
            value: playlistUpdater({} as Playlist, newPlaylist) || {} as Playlist,
            has_track: false,
          },
        );
        addTrackToTracklist({ type: 'playlist', id: newPlaylist.id!, tracks: [track] });
      },
      onError: () => {
        toastr('error', t('playlist.error-creation.title'), t('playlist.error-creation.content'));
      },
    },
  });
};

export const useUpdatePlaylist = (
  playlistId: string,
) => {
  const { t } = useTranslation('toast');

  return useUpdate<PlaylistUpdateAPI, PlaylistUpdateAPI>({
    url: pathToUrl(playlistRoutes.playlistMeta, { id: playlistId }),
    updater: (newData) => {
      updateData<Playlist>(
        [pathToUrl(playlistRoutes.playlist, { id: playlistId })],
        (item) => playlistUpdater(item, newData),
      );
      updateInfiniteDataUpdater<Tracklist>(
        [tracklistRoutes.search],
        (item) => tracklistUpdater(
          item,
          playlistUpdater(item.value as Playlist, newData),
        ),
        (item) => item.value.id === playlistId,
      );
    },
    mutateOptions: {
      onSuccess: () => {
        toastr('success', t('playlist-success-update.title'), t('playlist-success-update.content'));
      },
      onError: () => {
        toastr('error', t('playlist-error-update.title'), t('playlist-error-update.content'));
      },
    },
  });
};

export const useDeletePlaylist = () => {
  const { t } = useTranslation('toast');

  return useDeleteMultiple({
    url: playlistRoutes.playlists,
    updater: (data) => {
      multipleDeleteUpdater<Playlist>(
        [playlistRoutes.search],
        (item) => (item.id ? !data.ids.includes(item.id) : true),
      );
      multipleDeleteUpdater<Tracklist>(
        [tracklistRoutes.search],
        (item) => (item.value.id ? !data.ids.includes(item.value.id) : true),
      );
    },
    mutateOptions: {
      onSuccess: async () => {
        toastr('success', t('playlist.success-delete.title'), t('playlist.success-delete.content'));
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: [tracklistRoutes.search] });
        queryClient.invalidateQueries({ queryKey: [playlistRoutes.search] });
        toastr('error', t('playlist.error-delete.title'), t('playlist.error-delete.content'));
      },
    },
  });
};

export const useRemoveTracksFromPlaylist = (
  playlistId: string,
) => {
  const { t } = useTranslation('toast');

  return useDelete<{ ids: string[] }, unknown>({
    url: pathToUrl(playlistRoutes.tracksMeta, { id: playlistId }),
    updater: (data) => {
      multipleDeleteUpdater<Playlist>(
        [pathToUrl(playlistRoutes.tracks, { id: playlistId })],
        (item) => !data.ids.includes(item.id),
      );
    },
    mutateOptions: {
      onSuccess: () => {
        toastr('success', t('playlist.success-track-remove.title'), t('playlist.success-track-remove.content'));
      },
      onError: () => {
        toastr('error', t('playlist.error-track-remove.title'), t('playlist.error-track-remove.content'));
      },
    },
  });
};

export const useReorderTracksInPlaylist = (
  playlistId: string,
) => {
  return usePost<{ ids: string[] }, unknown>({
    url: pathToUrl(playlistRoutes.reorder, { id: playlistId }),
    updater: (data) => {
      reorderInfiniteDataUpdater<Playlist>(
        [pathToUrl(playlistRoutes.tracks, { id: playlistId })],
        (a, b) => {
          return data.ids.indexOf(a.id) - data.ids.indexOf(b.id);
        },
      );
    },
  });
};

export const usePlaylistSearch = (query: string) => {
  const { user } = globalStore;

  return useLoadMore<Playlist>({
    url: playlistRoutes.search,
    schema: PlaylistSearchResultSchema,
    queryParams: {
      query,
      size: 50,
      filters: {
        and: [{
          tenant: user.value?.tenant_id,
        }],
      },
    },
  });
};
