import { z } from 'zod';
import { ArtworkSchema } from '../common/image.schema';
import { LocaleSchema } from '../common/locale.schema';
import { TenantSchema } from './tenant.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { TrackSchema } from './track.schema';

export const BriefSchema = z.object({
  agents: z.array(z.string()).optional(),
  artworks: ArtworkSchema.array().optional(),
  budget: z.number().nullable().optional(),
  confidentiality: z.string().nullable(),
  created_at: z.string().optional(),
  created_by: TenantSchema.optional(),
  currency: z.string().nullable().optional(),
  deadline: z.string().nullable().optional(),
  descriptions: LocaleSchema.array(),
  id: z.string(),
  music_type: z.string().array(),
  owned_by_tenant: z.boolean(),
  public: z.boolean().optional(),
  scope: z.string().optional(),
  tenant: TenantSchema,
  title: z.string(),
  tracks: z.number().optional(),
  video_link: z.string().optional(),
});

export const TranslatedBriefSchema = z.object({
  detectedSourceLanguage: z.enum(['en', 'fr', 'es', 'de', 'it', 'zh', 'pt', 'ja', 'ko']),
  data: BriefSchema,
});

export const ProviderGroupSchema = z.object({
  name: z.string(),
  providers: z.string().array(),
  checked: z.union([
    z.boolean(),
    z.literal('indeterminate'),
  ]).optional(),
  disabled: z.boolean().optional(),
});

export const BriefUpdateAPISchema = z.object({
  id: z.string().optional(),
  agents: z.array(z.string()).optional(),
  brief: z.array(LocaleSchema).optional(),
  budget: z.number().nullable().optional(),
  descriptions: z.array(LocaleSchema),
  confidentiality: z.string().nullable(),
  currency: z.string().nullable().optional(),
  deadline: z.string().nullable().optional(),
  music_type: z.string().array(),
  recipients: z.array(z.string()).optional(),
  scope: z.string().optional(),
  title: z.string(),
  video_link: z.string().optional(),
  public: z.boolean().optional(),
});

export const BriefUpdateFormSchema = z.object({
  agents: z.array(ProviderGroupSchema).optional(),
  brief: z.array(LocaleSchema).optional(),
  budget: z.number().nullable().optional(),
  descriptions: LocaleSchema,
  confidentiality: z.string().nullable(),
  currency: z.string().nullable(),
  deadline: z.object({
    date: z.string().optional(),
    time: z.string().optional(),
  }),
  music_type: z.string().array(),
  recipients: z.array(z.string()).optional(),
  scope: z.string().optional(),
  title: z.string(),
  video_link: z.string().optional(),
  public: z.boolean().optional(),
});

export const TrackPitcherSchema = z.object({
  user: TenantSchema,
  date: z.string(),
});

export const TrackPitcherRecordSchema = z.record(TrackPitcherSchema);

export const BriefSearchResultSchema = createSearchResultSchema(BriefSchema);
export const BriefTracksSchema = createSearchResultSchema(TrackSchema);

export type Brief = z.infer<typeof BriefSchema>;
export type TranslatedBrief = z.infer<typeof TranslatedBriefSchema>;
export type BriefSearchResult = z.infer<typeof BriefSearchResultSchema>;
export type BriefFormUpdate = z.infer<typeof BriefUpdateFormSchema>;
export type BriefAPIUpdate = z.infer<typeof BriefUpdateAPISchema>;
export type BriefTracks = z.infer<typeof BriefTracksSchema>;
export type ProviderGroup = z.infer<typeof ProviderGroupSchema>;
