import { z } from 'zod';
import { ArtworkSchema } from '../common/image.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { TenantSchema } from './tenant.schema';

export const CatalogSchema = z.object({
  name: z.string(),
  created_at: z.string(),
  id: z.string(),
  tenant: TenantSchema,
  description: z.string().optional(),
  artwork: ArtworkSchema.optional(),
});

export const CatalogSearchResultSchema = createSearchResultSchema(CatalogSchema);

export type Catalog = z.infer<typeof CatalogSchema>;
export type CatalogSearchResult = z.infer<typeof CatalogSearchResultSchema>;
