import { Combobox, ComboboxValueChangeDetails } from '@ark-ui/react';
import classNames from 'classnames';
import { ChangeEvent, ReactNode, useState } from 'react';
import { SelectInputPortal } from './portal';

export type SelectInputItem<T> = {
  value: string;
  content: T
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComboboxInputProps<T = any> = {
    value?: string;
    items: SelectInputItem<T>[];
    className?: string;
    render: (item: T) => ReactNode;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onItemSelect?: (item: T) => void;
    label?: string
    error?: string;
    success?: boolean;
    required?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
};

export const ComboboxInput = (props: ComboboxInputProps) => {
  const { label, items, value = '', onChange, render, onItemSelect, className, error, success, required, disabled, isLoading } = props;
  const [isFocused, setIsFocused] = useState(false);

  const containerClass = classNames(
    'relative transition-all flex flex-col py-2 px-3 justify-center cursor-text border-r bg-dark-300 rounded transition duration-200 hover:border-dark-100',
    {
      '!border-primary-500': isFocused,
      '!border-transparent': !isFocused,
      '!border-error-500': !!error,
      '!border-success-500': success,
      'opacity-70 pointer-event-none': disabled,
    },
  );

  const inputClass = classNames(
    'w-full bg-transparent relative outline-none opacity-0 transition-all duration-200 appearance-none text-sm h-4 !z-0 placeholder:text-white/60',
    {
      'opacity-100': isFocused || !label || !!value,
    },
  );

  const labelClass = classNames(
    'text-light-100 text-sm translate-y-2.5 transition-all duration-200 !translate-y-0 !text-xxs text-light-500 leading-none mb-0',
    {
      'text-success-500': success,
      'text-error-500': error !== undefined,
    },
  );

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  function handleItemSelect(e: ComboboxValueChangeDetails<SelectInputItem<unknown>>) {
    onItemSelect?.(e.items[0]?.content);
  }

  return (
    <Combobox.Root
      items={items}
      className={classNames('w-full', className)}
      onChange={onChange}
      onValueChange={handleItemSelect}
      openOnClick
      lazyMount
      unmountOnExit
    >
      <div className={containerClass}>
        <div className={labelClass} onClick={handleFocus}>
          {label && <Combobox.Label>{label}</Combobox.Label>}
          {required && <span className="text-error-500">*</span>}
        </div>
        <Combobox.Control className="flex items-center gap-3">
          <Combobox.Input
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={inputClass}
            value={value}
          />
        </Combobox.Control>
      </div>
      <SelectInputPortal
        items={items}
        render={render}
        isLoading={isLoading}
      />
    </Combobox.Root>
  );
};
