import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useGetBriefDetails } from '~/hooks/api/brief';
import { useElementIsNotVisible } from '~/components/features/header/hooks/use-element-is-not-visible';

const HeaderBrief = () => {
  const { id } = useParams<{id: string}>();
  const { data } = useGetBriefDetails(id);
  const isTitleHidden = useElementIsNotVisible(document.getElementById('brief-title'));

  return (
    <h1 className={classNames('text-2xl font-semibold transition-opacity duration-300', isTitleHidden ? 'opcaity-100' : 'opacity-0')}>
      {data?.title}
    </h1>
  );
};

export default HeaderBrief;
