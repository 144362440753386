import { useTranslation } from 'react-i18next';
import { RightHoldersDialog } from '~/components/ui/overlays/dialogs/right-holders/right-holders';
import * as DropdownMenu from '../common/dropdown-menu';

export function TrackActionsCopyrightModal() {
  const { t } = useTranslation('track-interactions');

  return (
    <RightHoldersDialog>
      <DropdownMenu.DialogItem icon="ic:baseline-copyright">
        {t('copyright.tooltip')}
      </DropdownMenu.DialogItem>
    </RightHoldersDialog>
  );
}
