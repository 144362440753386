import { useTranslation } from 'react-i18next';
import { trackUploadStore } from '~/stores/track-upload-store';
import { useGetBriefDetails } from '~/hooks/api/brief';
import { CloseButton } from './close-dialog';
import { SendButton } from './send-button';

type HeaderProps = {
    id: string
};

export function Header(props: HeaderProps) {
  const { id } = props;
  const { data } = useGetBriefDetails(id);
  const { t } = useTranslation('tracks-upload-form');
  const { trackList } = trackUploadStore;

  return (
    <div className="max-w-[70%] h-fit flex justify-between gap-3 items-center w-full mb-4">
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold pb-2">
          {t('header.title')}
        </h1>
        <span className="text-light-500">{t('header.description', { 'tracks-count': trackList.value.length, 'context-title': data?.title, company: data?.tenant.company_name })}</span>
      </div>
      <div className="flex gap-3">
        <SendButton briefId={id} />
        <CloseButton />
      </div>
    </div>
  );
}
