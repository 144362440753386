/* eslint-disable camelcase */
import { Track } from '~/types/schemas/music/track.schema';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useMusemindStore from '~/stores/musemind-store';
import { useTranslation } from 'react-i18next';
import TrackInteraction from '../interaction';
import { useCreateTrackVariation } from '../../hooks/use-create-track-variation';

type MusemindVariationProps = {
  track: Track;
  isBrief?: boolean;
  isMusemind?: boolean;
};

function CreateVariation(props: MusemindVariationProps) {
  const { track, isBrief, isMusemind } = props;
  const { id, title, variation_ready } = track;
  const { createVariations, variations, tooltip, isDisabled, isPending } = useCreateTrackVariation(id, title, variation_ready);
  const { videoElement, setAudioTracks } = useMusemindStore(['videoElement', 'setAudioTracks']);
  const [isTempDisabled, setIsTempDisabled] = useState(isBrief);
  const { t } = useTranslation('track-interactions');

  const isTrackShorter = (videoElement?.duration || 0) > (track.duration || 0);

  useEffect(() => {
    if (videoElement) {
      if (isBrief && variation_ready && videoElement?.duration) {
        setIsTempDisabled(true);
        setTimeout(() => {
          createVariations();
          setIsTempDisabled(false);
        }, 1500);
      }
    }
  }, [variation_ready, videoElement?.src, videoElement?.duration]);

  useEffect(() => {
    if (!isBrief && variations && variations?.length) setAudioTracks(title, track.id, variations);
  }, [variations]);

  function handleClick() {
    if (isBrief && variations && variations?.length) setAudioTracks(title, track.id, variations);
    if (!isBrief) createVariations();
  }

  return (
    <TrackInteraction
      icon="variation-line"
      tooltip={isTrackShorter ? t('magic-preview.tooltip.track-shorter') : tooltip}
      onClick={handleClick}
      classSuffix="variation"
      className={classNames({
        '!text-dark-100 hover:!text-dark-100': isDisabled,
        '!text-primary-500': isMusemind,
      })}
      isLoading={isPending}
      disabled={isDisabled || isTempDisabled || isTrackShorter}
    />
  );
}

export default CreateVariation;
