import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPlaylistDetails } from '~/hooks/api/playlist';
import { ErrorPage } from '../Errors/error';
import Playlist from './playlist';

export function PlaylistCheck() {
  const { id } = useParams<{ id: string }>();
  const { error } = useGetPlaylistDetails(id);
  const { t } = useTranslation('error-page');

  if (error) {
    return (
      <ErrorPage
        title={t('not-found.playlist.title')}
        message={t('not-found.playlist.message')}
        errorCode={404}
      />
    );
  }

  return <Playlist />;
}
