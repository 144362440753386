/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import { useForm, SubmitHandler, FieldValues, Controller } from 'react-hook-form';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useComputed, useSignal } from '@preact/signals-react';
import classNames from 'classnames';
import { fileUploadStore } from '~/stores/file-upload-store';
import { trackUploadStore } from '~/stores/track-upload-store';
import { SpotifyTrack } from '~/types/schemas/track-upload/spotify-search.schema';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input-controls/input/input';
import { TagInput } from '~/components/ui/forms/tag/tag';
import { useValidateTrack } from '~/hooks/api/upload';
import { getValidateParams } from '~/components/features/tracks-upload/utils/validate-match-helper';
import { TrackInformationDisplay } from '../../file-info-display';
import { FileGlobalData } from '../../file-data/file-global-data/file-global-data';
import { CoverInput } from '../../cover-input';
import { CatalogInput } from '../../catalog-input';
import { TitleInput } from '../../title-input';

type TrackFormProps = {
    file: UploadTrack
    uploadId?: string
};

export function TrackForm(props: TrackFormProps) {
  const { file, uploadId } = props;
  const { uploads } = fileUploadStore;
  const { spotifySelect, addValidated, manualChange } = trackUploadStore;
  const upload = useComputed(() => uploads.value[file.id]).value;
  const { control, handleSubmit, setValue } = useForm();
  const isSubmitReady = useSignal(true);
  const { t } = useTranslation('tracks-upload-form');
  const { mutate: validateTrack } = useValidateTrack(uploadId);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (data) {
      file.matchedMetadata.value = {
        ...file.matchedMetadata.value,
        title: data.title,
        artists: data.artists,
        album: data.album,
        releaseDate: data.releaseDate,
        catalog: data.catalog,
        cover: data.cover,
      };
    }
    validateTrack(getValidateParams(file), {
      onSuccess: () => {
        addValidated(file, uploadId);
        file.step.value = 'display';
      },
    });
  };

  function handleMusicTypeChange(musicType: string) {
    file.musicType.value = musicType;
  }

  function handleVersionChange(version: string) {
    file.version.value = version;
  }

  function handleSpotifySelect(track: SpotifyTrack) {
    const { title, artists, album, cover } = file.matchedMetadata.value || {};
    setValue('title', track.title || title);
    setValue('artists', track.artists || artists);
    setValue('album', track.album || album);
    setValue('cover', track.cover || cover);

    spotifySelect(file, track.id);
  }

  const data = file.matchedMetadata.value || file.metadata;

  return (
    <>
      <TrackInformationDisplay
        cover={data?.cover}
        title={data?.title || file.file?.name}
        subtitle={data?.artists?.join(', ')}
        isSelected
      />
      <div className="grid bg-dark-300/50 grid-rows-subgrid row-start-2 row-span-full overflow-y-auto">
        <FileGlobalData
          version={file.version.value}
          musicType={file.musicType.value}
          onMusicTypeChange={handleMusicTypeChange}
          onVersionChange={handleVersionChange}
        />
        <form onSubmit={handleSubmit(onSubmit)} id="track-form" className="w-full items-stretch px-4 min-h-full overflow-hidden row-span-4 grid grid-rows-5" autoComplete="off">
          <div className="w-full h-full pb-4 overflow-y-auto overflow-x-hidden custom-scrollbar row-span-4 flex flex-col gap-3 items-center justify-between">
            <Controller
              name="title"
              control={control}
              defaultValue={data?.title || file.file?.name}
              rules={{ required: t('card.form.title.empty') }}
              render={({ field, fieldState }) => {
                const { value, onChange } = field;

                function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
                  onChange(e);
                  manualChange(file);
                }

                return (
                  <TitleInput
                    label={t('card.form.title.label')}
                    error={fieldState.error?.message}
                    value={value}
                    onItemSelect={handleSpotifySelect}
                    onChange={handleChange}
                    required
                  />
                );
              }}
            />
            <Controller
              name="artists"
              control={control}
              defaultValue={data?.artists}
              rules={{ required: t('card.form.artists.empty') }}
              render={({ field, fieldState }) => {
                const { value, onChange } = field;

                function handleChange(v: string[]) {
                  onChange(v);
                  manualChange(file);
                }

                return (
                  <TagInput
                    label={t('card.form.artists.label')}
                    labelInfo='Hit "Enter" to add artist'
                    value={value}
                    onChange={handleChange}
                    error={fieldState.error?.message}
                    required
                  />
                );
              }}
            />
            <div className="flex gap-3 items-start w-full">
              <Controller
                name="album"
                control={control}
                defaultValue={data?.album}
                rules={{ required: t('card.form.album.empty') }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field;

                  function handleChange(v: string) {
                    onChange(v);
                    manualChange(file);
                  }

                  return (
                    <Input
                      type="text"
                      label={t('card.form.album.label')}
                      className="flex-1 shrink-0"
                      error={fieldState.error?.message}
                      value={value}
                      onChange={handleChange}
                      required
                    />
                  );
                }}
              />
              <Controller
                name="catalog"
                control={control}
                defaultValue={data?.catalog}
                rules={{ required: t('card.form.catalog.empty') }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field;

                  return (
                    <CatalogInput
                      label={t('card.form.catalog.label')}
                      className="flex-1 shrink-0"
                      onChange={onChange}
                      value={value}
                      error={fieldState.error?.message}
                      required
                    />
                  );
                }}
              />
            </div>
            <div className="flex gap-3 items-start w-full">
              <Controller
                name="releaseDate"
                control={control}
                defaultValue={data?.releaseDate && new Date(data?.releaseDate).toLocaleDateString('sv-SE')}
                rules={{ required: file.matchedMetadata.value?.type === 'manual' && t('card.form.release.empty') }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field;

                  function handleChange(v: string) {
                    onChange(v);
                    manualChange(file);
                  }

                  return (
                    <Input
                      className="flex-1 shrink-0"
                      label={t('card.form.release.label')}
                      type="date"
                      error={file.matchedMetadata.value?.type === 'manual' ? fieldState.error?.message : undefined}
                      value={value}
                      onChange={handleChange}
                      required={file.matchedMetadata.value?.type === 'manual'}
                      disabled={file.matchedMetadata.value?.type !== 'manual'}
                    />
                  );
                }}
              />
              <Controller
                name="cover"
                control={control}
                defaultValue={data?.cover}
                render={({ field, fieldState }) => {
                  const { onChange } = field;

                  return (
                    <CoverInput
                      label={t('card.form.cover.label')}
                      className="flex-1 shrink-0"
                      placeholder={t('card.form.cover.placeholder')}
                      error={fieldState.error?.message}
                      uploadId={upload?.id}
                      isSubmitReady={isSubmitReady}
                      value={data?.cover}
                      onFilesChange={(files) => {
                        if (files[0]) onChange(URL.createObjectURL(files[0]));
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="flex justify-end px-4 items-center">
            <button
              type="submit"
              disabled={!isSubmitReady.value}
              className={classNames('flex gap-3  items-center text-sm px-3 py-1 rounded bg-primary-500 hover:bg-primary-400', {
                'opacity-50': !isSubmitReady.value,
              })}
            >
              {t('card.footer.save')}
              <Icon icon="ri:save-line" className="text-light-100 text-xs" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
