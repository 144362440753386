import { useTranslation } from 'react-i18next';
import { useRemoveTrackFromTracklist } from '~/hooks/api/track-list';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';

export function useRemoveFromTracklist() {
  const store = useTrackContext();
  const track = store.use.track();
  const context = store.use.context() as {
    type: Tracklist['type'];
    id: string;
  };

  const { values } = useSelectionContext();

  const { t } = useTranslation('track-interactions');

  const { mutate: removeTrackFromTrackListMutation } = useRemoveTrackFromTracklist();

  function removeSingleTrackFromTracklist() {
    removeTrackFromTrackListMutation({
      type: context.type,
      id: context.id!,
      tracks: [track] });
  }

  function removeTracksFromTracklist() {
    removeTrackFromTrackListMutation({
      type: context.type,
      id: context.id!,
      tracks: values,
    });
  }

  function chooseTooltip() {
    switch (context.type) {
      case 'playlist':
        return t('remove-from-playlist.tooltip');
      case 'brief':
        return t('remove-from-brief.tooltip');
      default:
        return t('remove-from-playlist.tooltip');
    }
  }

  return {
    tooltip: chooseTooltip(),
    removeSingleTrackFromTracklist,
    removeTracksFromTracklist,
  };
}
