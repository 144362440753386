import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useAddToTracklist } from '~/hooks/entities/track/actions/use-add-track-to-tracklist';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { globalStore } from '~/stores/global-store-v2';
import { Tracklist as TracklistType } from '~/types/schemas/music/tracklist.schema';

type TracklistProps = {
  tracklist: TracklistType;
  link: string;
};

export function Tracklist(props: TracklistProps) {
  const { tracklist, link } = props;
  const { addTrackToTracklist, removeTrackFromTracklist } = useAddToTracklist();
  const { values } = useSelectionContext();
  const isMultiSelection = values.length > 1;
  const user = globalStore.user.value;

  const isTracklistOwnedByUser = tracklist.value.tenant.id === user?.id;

  const icon = () => {
    switch (tracklist.type) {
      case 'brief':
        return 'ri:megaphone-line';
      case 'playlist':
        return isTracklistOwnedByUser ? 'ri:headphone-line' : 'ri:group-line';
      default:
        return undefined;
    }
  };

  function handleAddTrackToTracklist(e: MouseEvent) {
    e.stopPropagation();
    if (tracklist.has_track && !isMultiSelection) {
      removeTrackFromTracklist(tracklist);
    } else {
      addTrackToTracklist(tracklist);
    }
  }

  return (
    <div className="flex">
      <ContextMenu.Item
        icon={icon()}
        onClick={handleAddTrackToTracklist}
        className={classNames('flex-1', {
          'bg-dark-100/80': tracklist.has_track && !isMultiSelection,
        })}
      >
        {tracklist.value.title}
      </ContextMenu.Item>
      <Link
        to={link}
        className="w-8 h-8 mx-1 flex items-center justify-center hover:bg-dark-100 rounded"
      >
        <Icon icon="ri:link" className="w-4 h-4" />
      </Link>
    </div>
  );
}
