import { Message, MessageSchema } from '~/types/schemas/notifications/message.schema';
import { briefStore } from '~/stores/briefs-store';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { useRealtimeListener } from './use-realtime-listener';

export function useIngestProgress() {
  const realtime = useGetRealtimeData();
  const { updateIngestProgress } = briefStore;

  function handleTrackIngest(message: Message) {
    switch (message.status) {
      case 'COMPLETED':
        updateIngestProgress(100);
        break;
      case 'PROCESSING':
        updateIngestProgress((message.data as {progress: number}).progress);
        break;
      default: break;
    }
  }

  useRealtimeListener(() => ({
    channels: realtime.data ? [realtime.data.channels.notifications] : [],
    listener: {
      message(messageEvent) {
        const message = MessageSchema.safeParse(messageEvent.message);

        if (message.success) {
          switch (message.data.type) {
            case 'META_INGEST_BRIEF_TRACKS':
              handleTrackIngest(message.data);
              break;
            default: break;
          }
        }
      },
    },
  }), [realtime.data]);
}
