import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(UpdateLocale);
dayjs.updateLocale('ja', {
  relativeTime: {
    future(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `${time}後`;
    },
    past(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `${time}前`;
    },
    s: 'たった今',
    ss: '数秒',
    m: '1 分',
    mm: '%d 分',
    h: '1 時間',
    hh: '%d 時間',
    d: '1 日',
    dd: '%d 日',
    M: '1 ヶ月',
    MM: '%d ヶ月',
    y: '1 年',
    yy: '%d 年',
  },
});
