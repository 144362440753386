import { uploadRoutes } from '~/routes/api';
import { fileUploadStore } from '~/stores/file-upload-store';
import { trackUploadStore } from '~/stores/track-upload-store';
import { UploadFile } from '~/types/features/file-upload/context';
import { Upload, UploadSchema } from '~/types/schemas/file-upload-session/upload.schema';
import { SpotifySearchHitsSchema } from '~/types/schemas/track-upload/spotify-search.schema';
import { MatchResultArraySchema } from '~/types/schemas/track-upload/track-match.schema';
import { ValidateParams } from '~/types/schemas/track-upload/validate.schema';
import { api } from '~/utils/api';
import { pathToUrl } from '~/utils/path-to-url';
import { useAlternativeFetch } from '~/hooks/utils/react-query/use-alternative-fetch';
import { useDelete } from '~/hooks/utils/react-query/use-delete';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';
import { usePost } from '~/hooks/utils/react-query/use-post';

// GLOBAL UPLOADS

export const useCreateUploadSession = () => {
  return useGenericMutation<{
    context: string,
    contextId: string
  }, string>({
    url: uploadRoutes.createUploadSession,
    func: (data) => api.post(uploadRoutes.createUploadSession, {
      json: data,
    }).text(),
  });
};

export const useCreateUpload = (uploadId?: string, format: 'audio' | 'image' = 'audio') => {
  const { session } = fileUploadStore;

  return useGenericMutation<UploadFile, Upload | null>({
    url: uploadRoutes.createUpload,
    func: async (data) => {
      try {
        if (!session.value) throw new Error('No session value');
        const file = {
          name: data?.name,
          size: data?.size,
          type: data?.type,
        };
        let json: object;

        switch (format) {
          case 'audio':
            json = {
              session,
              file,
              format,
            };
            break;
          case 'image':
            json = {
              session,
              file,
              format,
              uploadId,
            };
            break;
          default:
            throw new Error('Invalid format');
        }

        const upload = await api.post(uploadRoutes.createUpload, { json }).json();
        return UploadSchema.parse(upload);
      } catch (error) {
        console.error('Error creating upload', error);
      }

      return null;
    },
  });
};

export const useDeleteUpload = (uploadId?: string) => {
  return useDelete<unknown, void>({
    url: pathToUrl(uploadRoutes.upload, { id: uploadId }),
  });
};

export const useS3Upload = () => {
  return useGenericMutation<{
      upload:Upload,
      file?: File,
      onProgress?:(progress: number) => void,
      onUploadCompleted?: () => void,
        }, unknown>({
          url: '',
          func: async (params) => {
            const { upload, file, onProgress, onUploadCompleted } = params || {};
            const { url, fields } = upload || {};

            if (!file || !url) return;
            const body = new FormData();
            if (fields) {
              Object.entries(fields).forEach(([key, value]) => {
                body.append(key, value);
              });
              body.append('file', file);
            }

            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => {
              if (event.lengthComputable) {
                const progress = Math.round((event.loaded / event.total) * 100);
                onProgress?.(progress);
              }
            });

            xhr.upload.onloadend = () => {
              onUploadCompleted?.();
            };

            xhr.open('POST', url, true);
            xhr.send(body);
          },
        });
};

// TRACK UPLOAD

export const useGetMatchByUploadId = (uploadId?: string) => {
  const { matchDone } = trackUploadStore;

  return useFetch({
    url: pathToUrl(uploadRoutes.upload, { id: uploadId }),
    schema: MatchResultArraySchema,
    enabled: !!(uploadId && matchDone.value.includes(uploadId)),
  });
};

export const useSearchSpotifyTracks = (query?: string) => {
  return useAlternativeFetch({
    url: uploadRoutes.searchTrack,
    schema: SpotifySearchHitsSchema,
    queryParams: {
      json: { query },
    },
  });
};

export const useMatchTrack = (uploadId?: string) => {
  return usePost<unknown, void>({
    url: pathToUrl(uploadRoutes.matchUpload, { id: uploadId }),
  });
};

export const useValidateTrack = (uploadId?: string) => {
  return usePost<ValidateParams, void>({
    url: pathToUrl(uploadRoutes.validateUpload, { id: uploadId }),
  });
};

export const useIngestUploadSession = (sessionId?: string) => {
  return usePost<unknown, void>({
    url: pathToUrl(uploadRoutes.ingestUploadSession, { id: sessionId }),
  });
};
