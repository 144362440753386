import ReactSelect, {
  MultiValue,
  SingleValue as SingleValueType,
} from 'react-select';
import { useState } from 'react';
import Control from './components/control';
import ValueContainer from './components/value-container';
import IndicatorsContainer from './components/indicator';
import Input from './components/input';
import SingleValue from './components/single-value';
import Menu from './components/menu';
import OptionComponent from './components/option';
import Placeholder from './components/placeholder';

import './select.scss';

export type OptionType = {
  value?: string;
  label?: string | null;
};
export interface SelectProps {
  id?: string;
  label?: string;
  options: OptionType[];
  value?: OptionType | OptionType[];
  isMulti?: boolean;
  defaultValue?: OptionType | OptionType[];
  dataTestId?: string;
  containerClassName?: string;
  isLoading?: boolean;
  required?: boolean;
  onSingleValueChange?: (value: OptionType) => void;
  onMultiValueChange?: (value: OptionType[]) => void;
}

const Select = (props: SelectProps) => {
  const { onMultiValueChange, onSingleValueChange, containerClassName, value, isMulti, label, required, isLoading } = props;
  const [currentValue, setCurrentValue] = useState<OptionType | OptionType[]>(
    value as OptionType | OptionType[],
  );

  const handleChange = (
    newValue: MultiValue<OptionType> | SingleValueType<OptionType>,
  ) => {
    if (Array.isArray(newValue)) {
      onMultiValueChange?.(newValue as OptionType[]);
    } else {
      onSingleValueChange?.(newValue as OptionType);
    }
    setCurrentValue(newValue as OptionType | OptionType[]);
  };

  return (
    <ReactSelect
      {...props}
      className={containerClassName}
      classNames={{
        control: (state) =>
          (state.isFocused ? '!border-primary-500' : '!border-transparent hover:!border-dark-100'),
      }}
      placeholder={label}
      components={{
        Control,
        ValueContainer,
        IndicatorsContainer,
        SingleValue,
        Input,
        IndicatorSeparator: () => null,
        Menu,
        Option: OptionComponent,
        Placeholder,
      }}
      classNamePrefix="select"
      onChange={handleChange}
      value={currentValue}
      isMulti={isMulti}
      isLoading={isLoading}
      required={required}
    />
  );
};

export default Select;
