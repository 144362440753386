import { useLocation, useNavigate } from 'react-router-dom';
import ArrowLeft from 'virtual:icons/ri/arrow-left-s-line';
import ArrowRight from 'virtual:icons/ri/arrow-right-s-line';
import usePreSearchStore from '~/stores/presearch-store';
import classNames from 'classnames';
import NavButton from './components/nav-button';
import HeaderContent from './components/content/content';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const presearch = usePreSearchStore(['isOpen', 'setIsOpen']);

  function handleNavigate(direction: number) {
    navigate(direction);
    presearch.setIsOpen(false);
  }

  const isSearch = location.pathname === '/search';

  return (
    <header className={classNames(
      'w-full px-6 py-5 h-16 flex items-center transition-all duration-300',
      presearch.isOpen && isSearch ? 'bg-dark-300/80 backdrop-blur' : 'bg-dark-600',
    )}
    >
      <div className="flex-1 flex gap-6 items-center">
        <div className="flex gap-2 h-fit">
          <NavButton Icon={<ArrowLeft />} onClick={() => handleNavigate(-1)} />
          <NavButton Icon={<ArrowRight />} onClick={() => handleNavigate(1)} />
        </div>
        <HeaderContent />
      </div>
    </header>
  );
};

export default Header;
