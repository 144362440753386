import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import UpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(UpdateLocale);
dayjs.updateLocale('pt', {
  relativeTime: {
    future(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `em ${time}`;
    },
    past(time: string, withoutSuffix?: boolean) {
      if (time === 'just now' || withoutSuffix) {
        return time;
      }

      return `${time} atrás`;
    },
    s: 'agora mesmo',
    ss: 'alguns segundos',
    m: '1 minuto',
    mm: '%d minutos',
    h: '1 hora',
    hh: '%d horas',
    d: '1 dia',
    dd: '%d dias',
    M: '1 mês',
    MM: '%d meses',
    y: '1 ano',
    yy: '%d anos',
  },
});
