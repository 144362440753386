import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import Checkbox, { CheckedState } from '~/components/ui/forms/input-controls/checkbox/checkbox';
import Icon from '~/components/ui/icon/icon';

type AgentProps = {
    title?: string
    subTitle?: string
    categories?: string
    checked?: CheckedState
    className?: string
    isFavorite?: boolean
    onFavClick?: () => void
    onCheckClick?: (b: CheckedState) => void
};

function Agent(props: AgentProps) {
  const { title = 'Omnis molestias qui', subTitle, categories, checked = false, className, isFavorite = false, onFavClick, onCheckClick } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const [isFaved, setIsFaved] = useState(isFavorite);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  function handleFavClick(e: MouseEvent) {
    e.stopPropagation();
    setIsFaved(!isFaved);
    onFavClick?.();
  }

  function handleCheckClick(b: CheckedState) {
    setIsChecked(b === 'indeterminate' ? false : !b);
    onCheckClick?.(b === 'indeterminate' ? false : !b);
  }

  return (
    <div
      className={classNames('agent flex cursor-pointer justify-between items-center rounded bg-dark-300 w-full px-3 py-2 transition-all border border-transparent hover:border-dark-100', className, {
        'border !border-primary-500': isChecked,
      })}
      onClick={() => handleCheckClick(isChecked)}
    >
      <div className="agent__left flex gap-4">
        <Checkbox checked={isChecked} onChange={handleCheckClick} />
        <div className="flex flex-col">
          <h6 className="text-light-100">{title}</h6>
          {subTitle ? <p className="text-light-500 text-xs">{subTitle}</p> : null}
        </div>
      </div>
      <div className="agent__right flex flex-col items-end justify-center">
        <button
          type="button"
          className={classNames('agent__right__fav')}
          onClick={handleFavClick}
        >
          {
                isFaved
                  ? <Icon name="heart-fill" className="text-error-500" />
                  : <Icon name="heart-line" className="text-light-500" />
            }
        </button>
        <p className="text-xs text-light-500 capitalize">{categories}</p>
      </div>
    </div>
  );
}

export default Agent;
