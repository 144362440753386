import { Cookies } from 'react-cookie';

const cookie = new Cookies();

export function getCookie(...params: Parameters<typeof cookie.get>): string | undefined {
  return cookie.get(...params) || undefined;
}

export function setCookie(...params: Parameters<typeof cookie.set>): void {
  cookie.set(...params);
}

export function removeCookie(...params: Parameters<typeof cookie.remove>): void {
  cookie.remove(...params);
}
