import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import * as Popover from '@radix-ui/react-popover';
import FilterPanel from '~/components/features/filter-panel';
import useAddFilterStore from '~/stores/add-filter-store';
import { useEffect } from 'react';

type AddFilterButtonProps = {
    onClick?: () => void;
};

function AddFilterButton(props: AddFilterButtonProps) {
  const { onClick } = props;
  const addFilter = useAddFilterStore(['isOpen', 'setIsOpen']);

  useEffect(() => {
    function closeOnScroll() {
      addFilter.setIsOpen(false);
    }

    document.getElementById('content-container')?.addEventListener('scroll', closeOnScroll);
    return () => {
      document.getElementById('content-container')?.removeEventListener('scroll', closeOnScroll);
    };
  }, []);

  return (
    <Popover.Root open={addFilter.isOpen} onOpenChange={addFilter.setIsOpen}>
      <Popover.Trigger asChild>
        <button
          type="button"
          onClick={onClick}
          className={classNames(
            'add-filter-button rounded w-6 h-6 shrink-0 flex items-center outline-none',
            'bg-dark-300 hover:bg-dark-200 transition duration-100',
            addFilter.isOpen && '!bg-dark-200',
          )}
        >
          <Icon name="add-line" className="mx-auto text-xl text-light-500" />
        </button>
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content align="start" style={{ zIndex: 20 }}>
          <FilterPanel />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default AddFilterButton;
