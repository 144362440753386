import { useEffect } from 'react';
import { useCreateUpload } from '~/hooks/api/upload';
import { fileUploadStore } from '~/stores/file-upload-store';
import { trackUploadStore } from '~/stores/track-upload-store';
import { UploadFile } from '~/types/features/file-upload/context';

type FileUploadSetupProps = {
    fileId: string
    uploadFile: UploadFile
    onSuccess?: (data?: unknown) => void
};

export function FileUploadSetup(props: FileUploadSetupProps) {
  const { fileId, uploadFile, onSuccess } = props;
  const { mutate: createUpload } = useCreateUpload();
  const { addUploads } = fileUploadStore;
  const { deleteTrack } = trackUploadStore;

  useEffect(() => {
    createUpload(uploadFile, {
      onSuccess: (data) => {
        if (data) {
          addUploads({
            [fileId]: data,
          });
          onSuccess?.(data);
        }
      },
      onError: () => {
        deleteTrack(fileId);
      },
    });
  }, []);

  return null;
}
