import Cover from '~/components/features/tracks-upload/components/cover';

type TrackProps = {
  title: string;
  artists: string[];
  album: string;
  cover: string;
};

export function Track(props: TrackProps) {
  const { title, artists, album, cover } = props;

  return !!title && !!artists && !!album && (
    <div className="rounded text-left hover:bg-dark-200 flex items-center group/list-item justify-between gap-4 px-3 py-2 pr-4">
      <Cover
        height="3rem"
        title={title}
        artists={`${artists.join(', ')}`}
        album={`${album}`}
        img={cover}
        className="flex-1"
      />
    </div>
  );
}
