import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

import { Participant } from '~/types/schemas/chats/participant.schema';
import { ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Message as MessageType } from '~/utils/chat/message';
import { globalStore } from '~/stores/global-store-v2';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';
import { useMessageTime } from '../use-message-time';
import { parseMessage } from './message-parser';
import File from './file';
import Menu from './menu';

import './message.styles.scss';
import Reader from './reader';

type MessageProps = {
  user?: Participant;
  message: MessageType<ChatMessageContent, ChatMessageMeta>
};

function Message(props: MessageProps) {
  const { user, message } = props;
  const time = useMessageTime(message.timetoken);
  const textParts = parseMessage(message.deleted.value ? 'Message deleted' : (message.content.text || ''));
  const isSender = globalStore.user.value?.id === user?.id;

  useEffect(() => {
    const unsub = message.watchUpdates();

    return () => {
      unsub();
    };
  }, []);

  return (
    <div className={classNames(' w-full h-fit flex flex-col gap-2', {
      message: !message.deleted.value,
      'message--received': !isSender,
      'message--sent': isSender,
    })}
    >
      {message.content.text ? (
        <div className={classNames('flex w-full group/message items-center', {
          'justify-start ': !isSender,
          'flex-row-reverse': isSender,
        })}
        >
          <TextLinkParser>
            <Tooltip
              label={`${time}`}
              delayDuration={50}
              triggerClassName={classNames('max-w-[80%] text-start')}
              align="center"
              side={isSender ? 'left' : 'right'}
              sideOffset={16}
            >
              <p
                className={classNames('message w-fit h-fit py-1.5 pl-3 pr-3 text-sm ', {
                  'bg-dark-200 text-light-100 rounded-r-md': !isSender,
                  'bg-light-100 text-dark-200 rounded-l-md': isSender,
                  'border border-dark-100 bg-transparent': message.deleted.value,
                })}
              >
                {textParts.map((part, i) => (
                  <span
                    key={`${part.text}-${part.type}-${i}`}
                    className={classNames({
                      'self-start text-light-100 [&>a]:text-light-100': !isSender,
                      'self-end text-dark-200 [&>a]:text-dark-200': isSender,
                      '!text-light-400': message.deleted.value,
                      'font-semibold': part.type === 'mention',
                    })}
                  >
                    {part.text}
                  </span>
                ))}
              </p>
            </Tooltip>
          </TextLinkParser>
          <div className="hidden group-hover/message:block mx-4">
            <Menu onDeleteClick={!message.deleted.value && isSender ? () => message.delete() : undefined} />
          </div>
        </div>
      ) : null}
      {!message.deleted.value && message.content.files?.length ? (
        <div className={classNames('w-full flex flex-col gap-2', {
          'items-start': !isSender,
          'items-end': isSender,
        })}
        >
          {message.content.files.map((file) => (
            <File
              key={file.id}
              name={file.name}
              extension={file.name.split('.').pop()}
              size={file.size}
              onClick={() => message.download(file)}
            />
          ))}
        </div>
      ) : null}
      {!!(message.receipts && message.receipts.length) && (
        <span className="text-xxs self-end flex gap-1">
          {message.receipts?.length ? message.receipts.map((receipt) => (
            receipt.userId !== user?.id && <Reader key={receipt.userId} userId={receipt.userId} timetoken={receipt.timetoken} />
          )) : null}
        </span>
      )}
    </div>
  );
}

export default React.memo(Message);
