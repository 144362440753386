/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ProviderGroup } from '~/types/schemas/music/brief.schema';
import { useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import { useGetAgents } from '~/hooks/api/dashboard';
import { BriefStepProps } from '../brief-form';
import Group from '../components/group';
import ProviderList from '../components/provider-list';
import { useGroupList } from '../../hooks/use-group-list';
import Tab from './tab';

function Sharing(props: BriefStepProps) {
  const { t } = useTranslation('brief-form');
  const {
    title = '',
    brief,
    control,
    ...actions
  } = props;
  const { data: agents } = useGetAgents();
  const { user } = globalStore;
  const [currentTab, setCurrentTab] = useState(-1);
  const localAgents = localStorage.getItem(`brief-${brief?.id}-agents`);
  const parsedLocalAgents = (localAgents ? JSON.parse(localAgents) : undefined) as ProviderGroup[] | undefined;

  const defaultGroups = [
    {
      label: t('step-three.providers-group.all'),
      providers: agents || [],
    }, {
      label: t('step-three.providers-group.core-master'),
      providers: agents?.filter((agent) => agent.organization_settings.meta.provider_types?.includes('core-master')) || [],
    }, {
      label: t('step-three.providers-group.core-publishing'),
      providers: agents?.filter((agent) => agent.organization_settings.meta.provider_types?.includes('core-publishing')) || [],
    }, {
      label: t('step-three.providers-group.production'),
      providers: agents?.filter((agent) => agent.organization_settings.meta.provider_types?.includes('production')) || [],
    }];

  const providersDefaultValues = parsedLocalAgents || defaultGroups.map((group) => ({
    name: group.label,
    providers: group.providers?.map((a) => a.id).filter((id) => brief?.agents?.includes(id)) || [],
    checked: false,
    disabled: false,
  }));

  const { groups, handleCheckClick, handleFavClick } = useGroupList(defaultGroups, brief?.id);

  function handleEditClick(tabIndex: number) {
    setCurrentTab(tabIndex);
  }

  return (
    <Tab title={title} briefTitle={brief?.title} {...actions} displayFooter={currentTab === -1} isLastStep>
      {currentTab === -1 ? groups.map((group, i) => {
        const providers = group.providers?.map((a) => a.id) || [];

        return providers.length ? (
          <Controller
            key={`controller-${group.label}-${i}`}
            name="agents"
            control={control}
            defaultValue={providersDefaultValues}
            render={({ field: { value, onChange } }) => {
              const groupValue = value?.[i];
              const groupSelectedProviders = groupValue?.providers || [];

              return (
                <Group
                  key={`group-${group.label}-${i}`}
                  title={group?.label}
                  // subTitle={`${group.providers?.length} providers  ${groupSelectedProviders.length > 0 ? `(${groupSelectedProviders.length} selected)` : ''}`}
                  subTitle={`${t('step-three.providers-group.subtitle', { count: group.providers?.length })} ${groupSelectedProviders.length > 0 ? `(${t('step-three.providers-group.subtitle.selected', { count: groupSelectedProviders.length })})` : ''}`}
                  className="form-content__row"
                  onEditClick={() => handleEditClick(i)}
                  isFavoriteCheck={groupSelectedProviders.every((p) => user.value?.favorite_agents?.includes(p))}
                  onCheckClick={() => {
                    handleCheckClick(providers, onChange, value || [], i);
                  }}
                  onFavoriteClick={() => {
                    handleFavClick(providers, onChange, value || [], i);
                  }}
                  // eslint-disable-next-line no-nested-ternary
                  checked={groupValue?.checked ? groupValue?.checked : groupSelectedProviders.length === group.providers?.length ? true : groupSelectedProviders.length === 0 ? false : 'indeterminate'}
                  disabled={groupValue?.disabled}
                />
              );
            }}
          />
        ) : null;
      }) : <ProviderList defaultProvidersValue={providersDefaultValues} providers={groups[currentTab]?.providers} groupIndex={currentTab} groupTitle={groups[currentTab]?.label} onBack={() => setCurrentTab(-1)} control={control} />}
    </Tab>
  );
}

export default Sharing;
