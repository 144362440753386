import { Track } from '~/types/schemas/music/track.schema';
import { useTranslation } from 'react-i18next';
import TrackInteraction from '../interaction';
import { useInterest } from '../../hooks/use-track-interest';

type InterestProps = {
  track: Track;
};

function Interest(props: InterestProps) {
  const { track } = props;
  const { handleClick } = useInterest(track);
  const { t } = useTranslation('track-interactions');

  return (
    <TrackInteraction
      icon="interest"
      tooltip={t('quote.tooltip')}
      classSuffix="interest"
      onClick={handleClick}
      disabled={false}
    />
  );
}

export default Interest;
