import Musemind from '~/components/features/musemind';
import { Outlet } from 'react-router-dom';
import { Player } from '~/components/features/player';
import Sidebar from '~/components/features/sidebar';
import Header from '~/components/features/header';
import MinWidthWindow from '~/components/layout/min-width-window/min-width-window';
import SearchTopPage from '~/pages/search/components/search-top-page/search-top-page';
import FormHandler from '~/components/features/forms/form-handler';
import { useOpenReplay } from '~/hooks/utils/use-open-replay';
import { useRealtimeInit } from '~/hooks/realtime/use-realtime-init';
import { useTrackMatchProgress } from '~/hooks/realtime/use-track-match-progress';
import { useIngestProgress } from '~/hooks/realtime/use-ingest-progress';
import { useNotifications } from '~/hooks/utils/use-notifications';
import { useGetAgents } from '~/hooks/api/dashboard';
import AppContent from './app-content';

// AppLayout is inside the Protected route
// Here user will always be set so we can init everything that uses user
const AppLayout = () => {
  useOpenReplay();
  useIngestProgress();
  useRealtimeInit();
  useTrackMatchProgress();
  useNotifications();
  useGetAgents();

  return (
    <div className="h-screen flex">
      <MinWidthWindow />
      <Sidebar />
      <Musemind />
      <main className="flex flex-col flex-1 h-screen custom-scrollbar items-stretch">
        <Header />
        <SearchTopPage />
        <FormHandler />
        <AppContent>
          <Outlet />
        </AppContent>
        <Player />
      </main>
    </div>
  );
};

export default AppLayout;
