import classNames from 'classnames';
import { MouseEvent } from 'react';
import { ChipButton } from '~/components/ui/control/chip-button/chip-button';
import Chip from '~/components/ui/control/chip/chip';
import { Tag } from '~/types/schemas/data/tags.schema';

type TagChipProps = {
  label: string;
  color?: string;
  onClick?: () => void;
};

export function ColoredTagChip(props: TagChipProps) {
  const { label, color, onClick } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <Chip
      className={classNames(
        'bg-dark-100/50 transition border border-dark-100/0 hover:border-dark-100 w-fit flex justify-center !p-1',
      )}
      onClick={handleClick}
    >
      <div className="size-2 rounded-sm" style={{ backgroundColor: color }} />
      <span>{label}</span>
    </Chip>
  );
}
