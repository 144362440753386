import { useTranslation } from 'react-i18next';
import EmptyIcon from 'virtual:icons/solar/expressionless-square-linear';

function EmptyBlock() {
  const { t } = useTranslation("pre-search");

  return (
    <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
      <EmptyIcon className="text-5xl text-light-500" />
      <h5 className="text-sm text-light-500">{t('tab.all.no-result')}</h5>
    </div>
  );
}

export default EmptyBlock;
