import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuDialogItem } from './dialog-item';
import { DropdownMenuSubItem } from './sub-item';
import { ContextSubMenu } from './sub-menu';
import { ContextSubMenuContainer } from './sub-menu-container';
import { DropdownMenuItem } from './item';
import { DropdownMenuGroup } from './group';

type DropdownMenuContainerProps = {
  children: React.ReactNode;
} & RadixDropdownMenu.DropdownMenuContentProps;

export function DropdownMenu(props: DropdownMenuContainerProps) {
  const { children, ...rest } = props;

  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content className="bg-dark-300 border border-dark-100/50 w-fit min-w-60 rounded-md p-1" {...rest}>
        {children}
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
}

const Content = DropdownMenu;
const DialogItem = DropdownMenuDialogItem;
const Group = DropdownMenuGroup;
const Item = DropdownMenuItem;
const SubItem = DropdownMenuSubItem;
const SubMenu = ContextSubMenu;
const SubMenuContainer = ContextSubMenuContainer;

export {
  Content,
  DialogItem,
  Group,
  Item,
  SubItem,
  SubMenu,
  SubMenuContainer,
};
