import Pubnub from 'pubnub';
import { PubNubProvider as PNProvider } from 'pubnub-react';
import React, { useMemo } from 'react';

type PubNubProviderProps = {
  children: React.ReactNode;
};

const PubnubProvider = (props: PubNubProviderProps) => {
  const pubnub = useMemo(
    () =>
      new Pubnub({
        subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
        publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
        uuid: 'empty',
        restore: true,
      }),
    [],
  );

  return <PNProvider client={pubnub}>{props.children}</PNProvider>;
};

export default PubnubProvider;
