import { userSettingsStore } from '~/stores/user-settings-store';
import { LANGUAGES } from '~/constants/languages';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { useTranslation } from 'react-i18next';
import Select from '~/components/ui/forms/select/select';
import { Setting } from './components/setting';

export function Settings() {
  const { language, setLanguage } = userSettingsStore;
  const { t } = useTranslation('user-settings');

  return (
    <div className="size-full">
      <div className="size-full max-w-4xl flex flex-col gap-6 mx-auto px-4">
        <h1 className="text-3xl font-bold mb-4">{t('title')}</h1>
        <Setting title={t('language.title')} description={t('language.description')}>
          <Select
            containerClassName="mt-3"
            label={t('language.input.label')}
            options={LANGUAGES}
            value={LANGUAGES.find((l) => l.value === language.value) || LANGUAGES[0]}
            onSingleValueChange={(v) => setLanguage(v.value as SupportedLanguages)}
          />
        </Setting>
      </div>
    </div>
  );
}
