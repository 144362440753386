import { useComputed } from '@preact/signals-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SelectMini from '~/components/ui/forms/select-mini/select-mini';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';

type VersionSelectProps = {
    version?: string
    onVersionChange?: (value: string) => void
};

export function VersionSelect(props: VersionSelectProps) {
  const { version, onVersionChange } = props;
  const { t } = useTranslation(['tracks-upload-form', 'common']);
  const isNotDefaultVersion = useComputed(() => version !== '5989daf4d3f20f148cff8d5f').value;
  const isEditing = Boolean(onVersionChange);

  const versionsIds = ['5989daf4d3f20f148cff8d5f', '5989dad4d3f20f148cff8d59', '5989da94d3f20f148cff8d4d', '5989da6dd3f20f148cff8d41', '5989daa0d3f20f148cff8d50', '5da190127850230060ddeabd'];
  const versionsObj = mewoDataStore.versions.object.value;
  const versions = mewoDataStore.versions.array.value.map((v) => ({
    value: v.id,
    label: v.name || v.id,
  })).filter((v) => versionsIds.includes(v.value));

  return (
    <div className="flex flex-col flex-1 gap-1">
      <h6 className="text-sm text-light-500 leading-[0.875rem]">{t('card.informations.version')}</h6>
      {(isEditing && version) && (
      <SelectMini
        value={version}
        options={versions}
        position="popper"
        onChange={onVersionChange}
        className={classNames('border-r border-transparent', {
          '!border-primary-500': isNotDefaultVersion,
        })}
      />
      )}
      {!isEditing && <span className="text-sm leading-4 pb-2 text-light-100 line-clamp-1 break-all">{version ? versionsObj[version as keyof typeof versionsObj]?.name : '-'}</span>}
    </div>
  );
}
