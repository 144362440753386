import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { SelectFilterOption } from './components/select';

export const agentCategories: Record<SupportedLanguages, SelectFilterOption[]> = {
  'en-US': [
    {
      label: 'Independent',
      value: 'independent',
      checked: true,
    },
    {
      label: 'Major',
      value: 'major',
      checked: true,
    },
  ],
  'es-ES': [
    {
      label: 'Independiente',
      value: 'independent',
      checked: true,
    },
    {
      label: 'Importante',
      value: 'major',
      checked: true,
    },
  ],
  'fr-FR': [
    {
      label: 'Indépendant',
      value: 'independent',
      checked: true,
    },
    {
      label: 'Majeur',
      value: 'major',
      checked: true,
    },
  ],
  'de-DE': [
    {
      label: 'Unabhängig',
      value: 'independent',
      checked: true,
    },
    {
      label: 'Wesentlich',
      value: 'major',
      checked: true,
    },
  ],
  'pt-PT': [
    {
      label: 'Independente',
      value: 'independent',
      checked: true,
    },
    {
      label: 'Principal',
      value: 'major',
      checked: true,
    },
  ],
  'ja-JP': [
    {
      label: '独立',
      value: 'independent',
      checked: true,
    },
    {
      label: 'メジャー',
      value: 'major',
      checked: true,
    },
  ],
};
