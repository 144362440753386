import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as Track from '~/components/entities/track/common/track';

export function ChipTagList() {
  const store = useTrackContext();
  const track = store.use.track();

  return (
    track.tags?.map((tag) => (
      <Track.Chip.Tag key={tag} id={tag} type="tag" />
    ))
  );
}
