import { IconifyIconProps } from '@iconify/react';
import React, { ForwardedRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import * as DropdownMenu from './dropdown-menu';

type DropdownMenuDialogItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  disabled?: boolean;
  onClick?: () => void;
}>;

export const DropdownMenuDialogItem = React.forwardRef((props: DropdownMenuDialogItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
  const { icon, disabled, onClick, children } = props;

  return (
    <DropdownMenu.Item
      ref={ref}
      icon={icon}
      onClick={onClick}
      onSelect={(event) => {
        event.preventDefault();
      }}
      className={classNames({
        'opacity-50 pointer-events-none': disabled,
      })}
    >
      {children}
    </DropdownMenu.Item>
  );
});
