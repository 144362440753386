import { useTrackContext } from '~/components/entities/track/common/track.context';
import { createRegexVersion, instrumental } from '~/utils/common/regex';

export function useTrackTitle() {
  const store = useTrackContext();
  const track = store.use.track();

  const versionInTitle = track.title.match(createRegexVersion(instrumental));

  if (versionInTitle) {
    return versionInTitle[1];
  }

  return track.title;
}
