import { useTranslation } from 'react-i18next';
import { useReleaseDateCard } from '~/hooks/entities/track/cards/use-release-date-card';
import TrackCard from './card';

export function TrackCardReleaseDate() {
  const { content } = useReleaseDateCard();
  const { t } = useTranslation('music-item');

  return (
    <TrackCard
      label={t('release-date.title')}
      content={content}
    />
  );
}
