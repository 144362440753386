import { components, OptionProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const OptionComponent = (
  props: OptionProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children } = props;

  return (
    <components.Option
      className="!bg-transparent !cursor-pointer !text-sm !px-1.5 !py-1 hover:!bg-dark-100 rounded"
      {...props}
    >
      {children}
    </components.Option>
  );
};

export default OptionComponent;
