import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';

type AnimatedListProps = {
    className?: string
    children: React.ReactNode
};

export function AnimatedList(props: AnimatedListProps) {
  const { className, children } = props;
  const [animationParent] = useAutoAnimate();
  return (
    <div className="relative h-full">
      <ul className={classNames('h-fit', className)} ref={animationParent}>
        {children}
      </ul>
      <div className="sticky bottom-0 w-full h-16 bg-gradient-to-t from-dark-400/70 pointer-events-none" />
    </div>
  );
}
