import { useComputed } from '@preact/signals-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SelectMini from '~/components/ui/forms/select-mini/select-mini';

type MusicTypeSelectProps = {
    musicType?: string;
    onMusicTypeChange?: (value: string) => void
};

export function MusicTypeSelect(props: MusicTypeSelectProps) {
  const { musicType, onMusicTypeChange } = props;
  const isNotDefaultMusicType = useComputed(() => musicType !== 'core').value;
  const isEditing = Boolean(onMusicTypeChange);
  const { t } = useTranslation(['tracks-upload-form', 'common']);

  const musicTypes = [
    { value: 'production', label: t('music-type.prod', { ns: 'common' }) },
    { value: 'core', label: t('music-type.core', { ns: 'common' }) },
  ];

  return (
    <div className="flex flex-col flex-1 gap-1">
      <h6 className="text-sm text-light-500 leading-[0.875rem]">{t('card.informations.music-type')}</h6>
      {(isEditing && musicType) && (
        <SelectMini
          value={musicType}
          options={musicTypes}
          position="popper"
          onChange={onMusicTypeChange}
          className={classNames('border-r border-transparent', {
            '!border-primary-500': isNotDefaultMusicType,
          })}
        />
      )}
      {!isEditing && <span className="text-sm leading-4 pb-2 text-light-100 line-clamp-1 break-all">{musicType ? musicTypes.find((type) => type.value === musicType)?.label : '-'}</span>}
    </div>
  );
}
