import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import classNames from 'classnames';
import TrackDetailGroup from '../track-details/track-detail-group';
import { Card } from '../../layout/card';
import { FileDataHeader } from './file-data-header';
import { FileGlobalData } from './file-global-data/file-global-data';

type TrackOldProps = {
    file: UploadTrack
    index: number
    isSelected?: boolean
};

export default function FileData(props: TrackOldProps) {
  const { file, index, isSelected } = props;

  return (
    <Card className="!bg-dark-300">
      <FileDataHeader
        fileName={file.file?.name}
        index={index}
        className={classNames({
          'row-span-full': !isSelected,
        })}
      />
      {isSelected && (
        <div className="grid bg-dark-300/50 grid-rows-subgrid row-start-2 row-span-full">
          <FileGlobalData />
          <div className=" row-span-4">
            <div className="grid grid-rows-5 h-full">
              <TrackDetailGroup track={file.metadata} />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
