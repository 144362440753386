import { DragTypeOverlay } from '~/providers/dnd-provider';
import { TrackGroupOverlay } from './track-group-overlay';
import { TrackOverlay } from './track-overlay';

type TrackDragOverlayProps = {
  data?: DragTypeOverlay;
};
export function TrackDragOverlay(props: TrackDragOverlayProps) {
  const { data } = props;

  if (!data) return null;

  if (data.type === 'track-group') {
    return <TrackGroupOverlay trackIds={data.tracks} />;
  }

  if (data.type === 'track') {
    return <TrackOverlay activeTrack={data.track} />;
  }

  return null;
}
