import { api } from '../../../utils/api';
import { useGenericMutation, useGenericMutationOptions } from './use-generic-mutation';

export const useDelete = <T, S>(options: useGenericMutationOptions<T, S>) => {
  const { url } = options;

  return useGenericMutation<T, S>({
    func: (data) => api.delete(url, { json: data }).json<S>(),
    ...options,
  });
};
