import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { globalStore } from '~/stores/global-store-v2';

export const Protected = () => {
  const location = useLocation();
  const { user, invalidToken } = globalStore;

  if (invalidToken.value) {
    return <Navigate to="/login?error=invalid_token" state={{ from: location }} replace />;
  }

  if (!user.value) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Outlet />
  );
};
