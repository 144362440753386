import Pubnub from 'pubnub';
import { briefChatsStore } from '~/stores/chats-store';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta, MessageDTOParams } from '~/types/types/chat';
import { Channel } from './channel';
import { Message } from './message';

export class ChannelsManager {
  private pubnub: Pubnub;

  id: string;

  userId: string;

  constructor(pubnub: Pubnub, userId: string, channel: string) {
    this.pubnub = pubnub;
    this.id = channel;
    this.userId = userId;
  }

  private static sortChannels(
    a: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>,
    b: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>,
  ) {
    if (!a.lastMessage.value || !b.lastMessage.value) {
      return 0;
    }

    return a.lastMessage.value.timetoken > b.lastMessage.value.timetoken ? -1 : 1;
  }

  addMessageToChannel(messageEvents: MessageDTOParams[]) {
    const channels: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>[] = [];

    messageEvents.forEach((messageEvent) => {
      const channel = briefChatsStore.channels.value.find((chan) => chan.id === messageEvent.channel);

      if (!channel) {
        const message = new Message<ChatMessageContent, ChatMessageMeta>(this.pubnub, messageEvent);
        const trackId = message.channel.split('.')[1]!;
        const newChannel = new Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>({
          pubnub: this.pubnub,
          userId: this.userId,
          channel: messageEvent.channel,
          lastMessage: message,
          custom: { trackId },
        });

        newChannel.listen();

        channels.push(newChannel);
      } else {
        channel.addMessage(messageEvent);
      }
    });

    briefChatsStore.channels.value = [
      ...channels,
      ...briefChatsStore.channels.value,
    ].sort(ChannelsManager.sortChannels);
  }

  subscribe() {
    const listener: Pubnub.ListenerParameters = {
      message: (messageEvent) => {
        if (!messageEvent.channel.startsWith(this.id.replace('.*', ''))) {
          return;
        }

        this.addMessageToChannel([messageEvent]);
      },
      messageAction: (messageActionEvent) => {
        if (!messageActionEvent.channel.startsWith(this.id.replace('.*', ''))) {
          return;
        }

        const channel = briefChatsStore.channels.value.find((chan) => chan.id === messageActionEvent.channel);

        if (channel) {
          channel.addAction(messageActionEvent.data);
        }
      },
    };

    this.pubnub.subscribe({ channels: [this.id] });
    this.pubnub.addListener(listener);

    return () => {
      this.pubnub.unsubscribe({ channels: [this.id] });
      this.pubnub.removeListener(listener);

      briefChatsStore.channels.value.forEach((channel) => {
        channel.listenOff();
      });
    };
  }
}
