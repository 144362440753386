import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from './search-input';
import { SearchResult } from './search-result';

export function AddToBriefContent() {
  const [query, setQuery] = useState('');
  const { t } = useTranslation('track-interactions');

  return (
    <ContextMenu.SubMenuContainer>
      <SearchInput query={query} setQuery={setQuery} placeholder={t('add-brief.dropdown.input')} />
      <SearchResult query={query} collection="briefs" />
    </ContextMenu.SubMenuContainer>
  );
}
