import { z } from 'zod';

export const RealtimeResponseSchema = z.object({
  channels: z.object({
    notifications: z.string(),
    sync_notifications: z.string(),
    sync_chat_notifications: z.string(),
  }),
  token: z.string(),
  expires_at: z.string(),
});

export type RealtimeResponse = z.infer<typeof RealtimeResponseSchema>;
