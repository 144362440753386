import { useEffect, useMemo } from 'react';
import { useRealtimeListener } from '~/hooks/realtime/use-realtime-listener';
import { notificationStore } from '~/stores/notifications-store';
import usePreSearchStore from '~/stores/presearch-store';
import { Message } from '~/types/schemas/notifications/message.schema';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { Channel } from '~/utils/chat/channel';
import { globalStore } from '~/stores/global-store-v2';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { useRealtime } from '../realtime/use-realtime';

export function useNotifications() {
  const { user } = globalStore;
  const presearch = usePreSearchStore(['setURLJobID']);
  const { pubnub, ready } = useRealtime();
  const realtime = useGetRealtimeData();
  const channel = useMemo(() => {
    if (!realtime.data || !ready) {
      return null;
    }

    return new Channel<ChatNotificationMessage>({
      pubnub,
      userId: user.value!.id,
      channel: realtime.data.channels.sync_chat_notifications,
      membersSelfOnly: true,
    });
  }, [realtime.data, ready]);

  useEffect(() => {
    if (!channel) {
      return () => { };
    }

    notificationStore.channel.value = channel;

    channel.getOlderMessages();

    const unsub = channel.subscribe();

    return () => {
      unsub();
    };
  }, [channel]);

  // Global notifications
  useRealtimeListener(() => ({
    channels: realtime.data ? [realtime.data.channels.notifications] : [],
    listener: {
      message(messageEvent) {
        const message = messageEvent.message as Message;
        switch (message.type) {
          case 'META_SEARCH_SIMILARITY':
            if (message.status === 'COMPLETED') {
              presearch.setURLJobID(message.data.id);
            }
            break;
          case 'META_ARCHIVE':
            if (message.status === 'COMPLETED') {
              const link = document.createElement('a');
              link.href = message.data.file!;
              link.click();
            }
            break;
          default: break;
        }
      },
    },
  }), [realtime.data]);
}
