import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type DropdownMenuItemProps = PropsWithChildren<{
    label: string
    className?: string
}>;

export function DropdownMenuGroup(props: DropdownMenuItemProps) {
  const { label, className, children } = props;
  return (
    <DropdownMenu.Group className={classNames('', className)}>
      <DropdownMenu.Label className="text-xs text-light-500 px-2 pt-2 pb-1">
        {label}
      </DropdownMenu.Label>
      {children}
    </DropdownMenu.Group>
  );
}
