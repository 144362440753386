import * as Tabs from '@radix-ui/react-tabs';
import useAddFilterStore from '~/stores/add-filter-store';
import Navigation from './components/navigation/navigation';
import Content from './components/content/content';

function FilterPanel() {
  const addFilter = useAddFilterStore(['currentTab', 'setCurrentTab']);

  return (
    <section className="filter-panel animate-slideInBottom w-[40rem] h-80 z-20 bg-dark-300 border border-dark-100 rounded-lg">
      <Tabs.Root className="flex h-full" defaultValue="tag" value={addFilter.currentTab} onValueChange={addFilter.setCurrentTab}>
        <Navigation />
        <Content />
      </Tabs.Root>
    </section>
  );
}

export default FilterPanel;
