import { FocusEvent, MouseEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import LoadingSpinner from 'virtual:icons/svg-spinners/6-dots-scale-middle';
import useDebounce from '~/hooks/common/use-debounce';
import { useURLVideoTiltle } from '~/components/features/header/hooks/use-url-video-title';
import usePreSearchStore from '~/stores/presearch-store';
import { url, youtube } from '~/utils/common/regex';
import { useHandleSimilarityUrlSearch } from '~/components/features/header/hooks/use-handle-similarity-url-search';
import IconClose from 'virtual:icons/ri/close-line';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import GeneralIcons from '~/components/ui/icon/general-icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import i18n from '~/i18n/config';
import { useTranslation } from 'react-i18next';

type PresearchInputProps = {
  className?: string;
};

const PresearchInput = (props: PresearchInputProps) => {
  const { className } = props;
  const presearch = usePreSearchStore(['query', 'setQuery', 'setIsOpen', 'resetVideoURL', 'setVideoURL']);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutate: getVideoTitle } = useURLVideoTiltle();
  const videoLoader = useHandleSimilarityUrlSearch();
  const [searchTerm, setSearchTerm] = useState<string>(presearch.query);
  const { t } = useTranslation(['toast', 'pre-search']);

  const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let term = e.target.value;
    const youtubeMatch = term.match(youtube);
    const urlMatch = term.match(url);

    if (youtubeMatch) {
      setSearchTerm('');
      // toastr('warning', i18n.t('maintenance.youtube-similarity.title', { ns: 'toast' }), i18n.t('maintenance.youtube-similarity.content', { ns: 'toast' }));

      const videoId = youtubeMatch[1]!;

      getVideoTitle({ videoId }, {
        onSuccess: (data) => {
          const { title } = data.snippet;

          if (title) {
            presearch.setVideoURL(videoId, title);
            setSearchTerm('');
          }
        },
      });
    } else if (urlMatch) {
      toastr('warning', i18n.t('presearch.bad-link.title', { ns: 'toast' }), i18n.t('presearch.bad-link.content', { ns: 'toast' }));
      term = '';
    }

    presearch.setIsOpen(true);

    setSearchTerm(term);
  };

  const handleCancel = () => {
    presearch.resetVideoURL();
    presearch.setIsOpen(false);

    setSearchTerm('');
  };

  const handleFocus = (e: MouseEvent | FocusEvent) => {
    e.stopPropagation();
    if (!isFocused) {
      setIsFocused(true);
      (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
    }
  };

  const handleBlur = () => {
    if (isFocused) {
      setIsFocused(false);
      (inputRef as MutableRefObject<HTMLInputElement>).current?.blur();
    }
  };

  const debouncedTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedTerm !== undefined) {
      presearch.setQuery(debouncedTerm);
    }
  }, [debouncedTerm, presearch.setQuery]);

  // reset presearch on mount
  useEffect(() => {
    setSearchTerm('');
    presearch.setIsOpen(false);
  }, [presearch.setIsOpen]);

  useEffect(() => {
    // handle "enter" key press
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFocused) {
        toastr('warning', t('presearch.warning.title'), t('presearch.warning.content'));
      }
      if (event.key === 'Escape') {
        presearch.setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFocused]);

  return (
    <div className={classNames('relative', className)}>
      <div id="presearch-input-container" className="flex items-center rounded-lg bg-dark-100 overflow-hidden h-9 flex-1" onClick={handleFocus}>
        <input
          ref={inputRef}
          id="presearch-input"
          type="text"
          className="flex-1 h-full px-4 text text-ligth-100 bg-dark-100  outline-none placeholder:text-light-500"
          placeholder={t('input.placeholder', { ns: 'pre-search' })}
          onChange={handleTermChange}
          onClick={() => presearch.setIsOpen(true)}
          value={searchTerm}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
        />
        <div className="h-full px-2 flex items-center gap-2">
          <GeneralIcons icon="youtube" />
        </div>
      </div>
      <AnimatePresence>
        {videoLoader.isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            id="youtube-video-loader"
            className="absolute top-0 ml-3 left-full group/url-loader h-9 rounded-lg px-4  bg-dark-200 flex justify-between items-center gap-2 w-fit"
          >
            <span className="text-light-100 text-xs line-clamp-1 w-32">{videoLoader.title}</span>
            <div className="block group-hover/url-loader:hidden h-fit">
              <LoadingSpinner className="text-xs text-light-100" />
            </div>
            <button type="button" onClick={handleCancel} className="items-center justify-center h-4 aspect-square rounded-full transition-colors bg-light-500 hover:bg-light-100 hidden group-hover/url-loader:flex">
              <IconClose className="text-dark-100 text-xs" />
            </button>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default PresearchInput;
